import { PropulsionSystem } from '../../models/ApiModels';
import { Action, ActionType } from '../actionTypes';

interface State {
    propulsionSystems: PropulsionSystem[];
}

const initialState: State = {
  propulsionSystems: [],
};

const propulsionSystemsReducer = (state: State = initialState, action: Action):State => {
  switch(action.type) {
  case ActionType.GET_PROPULSIONSYSTEMS:
    return {
      propulsionSystems: action.payload
    };
  default: 
    return state;
  }
};

export default propulsionSystemsReducer;