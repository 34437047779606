import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { Input } from 'component-library';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import LCPImg from '../../assets/LCP.svg';
import LCUImg from '../../assets/LCU.svg';
import systemImg from '../../assets/LHS.svg';
import LSUImg from '../../assets/LSU.svg';

function HardwareUnits() {
  const navigate = useNavigate();
  const [serialLHS, setSerialLHS] = useState('');
  const [serialLCP, setSerialLCP] = useState('');
  const [serialLSU, setSerialLSU] = useState('');
  const [serialLCU, setSerialLCU] = useState('');

  return (
    <div className='max-w-[2000px]'>
      <div className='prose-heading3 mb-14'>Hardware units</div>
      <div className='prose-heading4 pb-2'>Lightning Hardware system</div>
      <div className='prose-paragraphSmaller text-gray-60 leading-5 max-w-[785px] pb-3'>
        Lightning hardware systems are the composition of hardware units and the Humphree-ID.
        <br />
        <br /> Search for any LCU serial number to get the log to the hardware system and it’s individual lightning
        units serial numbers.
      </div>
      <div className='border-b border-gray-10 mb-10'></div>

      <div className='w-72'>
        <div className='flex flex-col items-center'>
          <div></div>
          <div className='h-40 flex my-2'>
            <img src={systemImg} alt='' />
          </div>
          <div
            onKeyDown={(event) => {
              if (event.key === 'Enter') navigate('/units/lhs/' + serialLHS);
            }}
            className='flex gap-1 items-end w-72'
          >
            <Input
              label={'The systems LCU serial number'}
              icon={faSearch}
              iconbefore
              value={serialLHS}
              onChange={(input: string) => setSerialLHS(input)}
            />
          </div>
        </div>
      </div>

      <div className='mt-14'>
        <div className='prose-heading4 pb-2'>Individual Lightning units</div>
        <div className='prose-paragraphSmaller text-gray-60 leading-5 max-w-[785px] pb-3'>
          Lightning hardware units have their data object where we keep software, keys, part numbers and change log.
        </div>
        <div className='border-b border-gray-10 mb-10'></div>
        <div className='flex grow flex-wrap gap-5 justify-between'>
          <div className='w-72'>
            <div className='flex flex-col items-center'>
              <div className='prose-heading5 mb-2'>Lightning Control Panel</div>
              <div className='h-40 flex my-2'>
                <img src={LCPImg} alt='' />
              </div>
              <div
                onKeyDown={(event) => {
                  if (event.key === 'Enter') navigate('/units/lcp/' + serialLCP);
                }}
                className='flex gap-1 items-end w-72'
              >
                <Input
                  label={'LCP serial number:'}
                  icon={faSearch}
                  iconbefore
                  value={serialLCP}
                  onChange={(input: string) => setSerialLCP(input)}
                />
              </div>
            </div>
          </div>
          <div className='w-72'>
            <div className='flex flex-col items-center'>
              <div className='prose-heading5 mb-2'>Lightning Control Unit</div>
              <div className='h-40 flex my-2'>
                <img src={LCUImg} alt='' />
              </div>
              <div
                onKeyDown={(event) => {
                  if (event.key === 'Enter') navigate('/units/lcu/' + serialLCU);
                }}
                className='flex gap-1 items-end w-72'
              >
                <Input
                  label={'LCU serial number:'}
                  icon={faSearch}
                  iconbefore
                  value={serialLCU}
                  onChange={(input: string) => setSerialLCU(input)}
                />
              </div>
            </div>
          </div>
          <div className='w-72'>
            <div className='flex flex-col items-center'>
              <div className='prose-heading5 mb-2'>Lightning Servo Unit</div>
              <div className='h-40 flex my-2'>
                <img src={LSUImg} alt='' />
              </div>
              <div
                onKeyDown={(event) => {
                  if (event.key === 'Enter') navigate('/units/lsu/' + serialLSU);
                }}
                className='flex gap-1 items-end w-72'
              >
                <Input
                  label={'LSU serial number:'}
                  icon={faSearch}
                  iconbefore
                  value={serialLSU}
                  onChange={(input: string) => setSerialLSU(input)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HardwareUnits;
