import { faCheck, faPlus, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Package, PackageUpgrade, PriceListItem } from '../../../../models/ApiModels';

interface Props {
  package: Package;
  currentPackage: Package;
  newPackage?: string;
  setNewPackage?: (id: string) => void;
  customerPriceListItem?: PriceListItem;
  priceListItem?: PriceListItem;
}

export default function FunctionPackageItem(props: Props) {
  const upgrades: PackageUpgrade[] = useSelector((state: any) => state.configurations.upgrades);
  const chosen = props.newPackage
    ? props.newPackage === props.package.id
    : props.currentPackage?.id === props.package.id;

  const includedInPackage = () => {
    if (props.newPackage) {
      switch (props.newPackage) {
        case 'PKG-2-1':
          return props.package.id === 'PKG-1-1';
        case 'PKG-3-1':
          return props.package.id === 'PKG-1-1';
        case 'PKG-4-1':
          return props.package.id === 'PKG-1-1' || props.package.id === 'PKG-2-1' || props.package.id === 'PKG-3-1';
      }
    } else if (props.currentPackage && props.package?.id) {
      if (
        (props.currentPackage.id === 'PKG-2-1' && props.package.id === 'PKG-3-1') ||
        (props.currentPackage.id === 'PKG-3-1' && props.package.id === 'PKG-2-1')
      ) {
        return false;
      }
      return !props.currentPackage?.availableUpgrades?.some((availablePackage: string) => {
        const upgradePackage = upgrades.find((p: PackageUpgrade) => p.id === availablePackage);
        return upgradePackage?.upgradeBasePackageId === props.package?.id;
      });
    }
  };

  const isUnavailable = () => {
    if (includedInPackage()) return false;
    if (
      (props.currentPackage?.id === 'PKG-2-1' && props.package.id === 'PKG-3-1') ||
      (props.currentPackage?.id === 'PKG-3-1' && props.package.id === 'PKG-2-1')
    ) {
      return true;
    } else {
      return false;
    }
  };

  const iconChecked = () => {
    const [hover, setHover] = useState(false);
    function handleMouseEnter() {
      setHover(true);
    }
    function handleMouseLeave() {
      setHover(false);
    }
    if (isUnavailable()) return <FontAwesomeIcon icon={faXmark} size='lg' color='white' />;
    if (includedInPackage()) return <FontAwesomeIcon icon={faCheck} size='lg' color='white' />;
    if (chosen)
      return (
        <div
          className='w-10 h-10 rounded-full flex justify-center items-center cursor-pointer'
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {hover ? (
            <div
              className='w-10 h-10 rounded-full flex justify-center items-center cursor-pointer'
              onClick={() => props.setNewPackage && props.setNewPackage('')}
            >
              <FontAwesomeIcon icon={faXmark} size='lg' color='white' />
            </div>
          ) : (
            <FontAwesomeIcon icon={faCheck} size='lg' color='white' />
          )}
        </div>
      );
    else return <FontAwesomeIcon icon={faPlus} size='lg' />;
  };

  return (
    <div
      className={`grid grid-cols-9 col-span-9 border-b border-gray-10 py-6 px-5 my-1 ${
        chosen ? 'bg-primary-10 border-none' : includedInPackage() ? 'bg-primary-5 border-none' : ''
      } `}
    >
      <span
        className={`col-span-2 flex items-center ${
          chosen ? 'prose-mainMenuL2ItemActive gap-2' : 'prose-mainMenuL2Default'
        }`}
      >
        {props.package.displayName}
      </span>
      <div
        className={`col-span-5 grid grid-cols-5 ${
          chosen ? 'text-gray-100' : !chosen && !includedInPackage() ? 'text-gray-20' : 'text-gray-40'
        }`}
      >
        <div className='flex justify-center items-center'>
          {props.package.featureIds.includes('FEAT-2-1') && (
            <FontAwesomeIcon icon={faCheck} className='mx-2' size='lg' />
          )}
        </div>
        <div className='flex justify-center items-center'>
          {props.package.featureIds.includes('FEAT-3-1') && (
            <FontAwesomeIcon icon={faCheck} className='mx-2' size='lg' />
          )}
        </div>
        <div className='flex justify-center items-center'>
          {props.package.featureIds.includes('FEAT-4-1') && (
            <FontAwesomeIcon icon={faCheck} className='mx-2' size='lg' />
          )}
        </div>
        <div className='flex justify-center items-center'>
          {props.package.featureIds.includes('FEAT-6-1') && (
            <FontAwesomeIcon icon={faCheck} className='mx-2' size='lg' />
          )}
        </div>
        <div className='flex justify-center items-center'>
          {props.package.featureIds.includes('FEAT-5-1') && (
            <FontAwesomeIcon icon={faCheck} className='mx-2' size='lg' />
          )}
        </div>
      </div>
      <div className={`flex items-center justify-end gap-5 col-span-2 ${!includedInPackage() && 'text-gray-60'}`}>
        {includedInPackage() && !isUnavailable() ? (
          <span className='flex flex-col prose-heading5'>Included</span>
        ) : isUnavailable() ? (
          <span className='flex flex-col prose-heading5'>Unavailable</span>
        ) : (
          props.customerPriceListItem?.unitPrice && (
            <span className='flex flex-col prose-heading5 text-right gap-1'>
              {props.currentPackage?.id !== props.package.id &&
                props.customerPriceListItem?.unitPrice.toLocaleString('en-US') +
                  ' ' +
                  (props.customerPriceListItem?.currencyCode || 'SEK')}
              {props.priceListItem?.unitPrice !== props.customerPriceListItem?.unitPrice && ( //only display retail price if there is a discount (which it almost always is)
                <span className='prose-buttonTiny text-gray-60'>
                  (Retail price {props.priceListItem?.unitPrice.toLocaleString('en-US')}{' '}
                  {props.priceListItem?.currencyCode || 'SEK'})
                </span>
              )}
            </span>
          )
        )}
        <div
          onClick={() =>
            !includedInPackage() &&
            !chosen &&
            !isUnavailable() &&
            props.package.id &&
            props.setNewPackage &&
            props.setNewPackage(props.package.id)
          }
          className={`border border-gray-60 w-10 h-10 rounded-full flex justify-center items-center cursor-pointer text-gray-60 hover:text-white hover:bg-primary-200 ${
            (chosen || (includedInPackage() && !isUnavailable())) && 'bg-primary-200 border-0 cursor-default'
          } ${isUnavailable() && 'bg-gray-60 hover:bg-gray-60 cursor-default'}`}
        >
          {iconChecked()}
        </div>
      </div>
    </div>
  );
}
