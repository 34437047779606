import { faArrowUpFromBracket, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { InvertButton, PrimaryButton, StatusButton } from 'component-library';
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ServiceHelper from '../helpers/ServiceHelper';
import { SoftwareUpdate } from '../models/ApiModels';
import { ActionType } from '../store/actionTypes';

interface Props {
  data: SoftwareUpdate | any;
  setData: (file: SoftwareUpdate | any) => void;
  uploadType: string;
  navigateToResponse?: boolean;
  navigateUrl?: string;
  reload?: boolean;
  appendData: { key: string; value: string }[];
  uploadButtonLabel: string;
  requestUrl: string;
  cancelIsReturn?: boolean;
  validData?: boolean;
}

export const useUploadForm = (requestUrl: string) => {
  const [abortController, setAbortController] = useState<AbortController>();
  const [fileResponse, setFileResponse] = useState<any>();
  const [progress, setProgress] = useState(0);
  const [loaded, setLoaded] = useState(0);
  const [percent, setPercent] = useState(0);

  const uploadForm = async (formData: FormData) => {
    const abortController = new AbortController();
    setAbortController(abortController);
    setFileResponse(undefined);

    return new Promise<any>((resolve, reject) => {
      ServiceHelper({
        method: 'POST',
        url: requestUrl,
        timeout: 0,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data; boundary=------WebKitFormBoundary7MA4YWxkTrZu0gW',
        },
        signal: abortController.signal,
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          const percent = Math.floor((loaded * 100) / total);
          setProgress(progress);
          setLoaded(loaded);
          setPercent(percent);
        },
      })
        .then((response) => {
          setFileResponse(response.data);
          resolve(response.data);
        })
        .catch((error) => {
          setFileResponse(error);
          reject(error);
        });
    });
  };

  return {
    uploadForm,
    fileResponse,
    progress,
    loaded,
    percent,
    abortController,
  };
};

export default function UploadFile(props: Props) {
  const dispatch = useDispatch();
  const [requestUrl, setRequestUrl] = useState(props.requestUrl);
  const { uploadForm, fileResponse, percent, loaded, abortController } = useUploadForm(requestUrl);
  const navigate = useNavigate();
  const [uploadPopup, setUploadPopup] = useState(false);
  const inputRef = useRef<any>();
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<File>();
  const [dragActive, setDragActive] = useState(false);

  function handleFileChange(e: ChangeEvent<HTMLInputElement>) {
    setDragActive(false);
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      const newFile = e.target.files[0];

      if (newFile) {
        setFile(newFile);
        props.data.originalFileName = newFile.name;
        props.setData({ ...props.data });
      }
    }
  }

  function onButtonClick() {
    inputRef.current.click();
  }

  function handleUploadClick() {
    if (!file) {
      return;
    }
    const formData = new FormData();
    if (file) {
      setUploadPopup(true);
      formData.append('file', file);
      props.appendData.forEach((item) => {
        formData.append(item.key, item.value);
      });
      uploadForm(formData);
    }
  }

  const validForm = useMemo(() => {
    if (file && props.validData) {
      return true;
    }
  }, [props.data, file]);

  useEffect(() => {
    if (percent === 100 && axios.isAxiosError(fileResponse)) {
      setUploadPopup(false);
    } else if (percent === 100 && fileResponse?.id) {
      {
        props.reload &&
          setTimeout(() => {
            window.location.reload();
          }, 10);
      }
      {
        !props.reload && navigate(`/${props.navigateUrl}/${fileResponse?.id}`);
      }
    }
  }, [percent, fileResponse]);

  return (
    <>
      {uploadPopup && (
        <div className='flex justify-center items-center w-screen h-screen fixed top-0 left-0 z-40'>
          <div className='fixed w-screen h-screen bottom-0 bg-gray-100 opacity-50 overlay'></div>
          <div className='absolute flex flex-col gap-8 left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-50 bg-white shadow-popup p-7'>
            <h3 className='prose-heading3'>Uploading {props.uploadType}...</h3>
            <p className='prose-paragraphBig'>Please wait until upload is finished.</p>
            <div className='flex items-center gap-4 justify-between'>
              <StatusButton
                icon={faXmark}
                label={'Cancel upload'}
                status={'error'}
                onClick={() => {
                  abortController?.abort('Upload cancelled');
                  dispatch({
                    type: ActionType.SET_SNACKBAR,
                    payload: {
                      heading: 'File upload cancelled!',
                      status: 'error',
                    },
                  });
                  setUploadPopup(false);
                }}
              />
              {percent}% or {loaded}bytes
              <div className='w-12 h-12 border-l-2 border-primary-400 rounded-full animate-spin'></div>
            </div>
          </div>
        </div>
      )}
      {file && (
        <div>
          Selected file:
          <div className='font-code mb-3'>{file && `${file.name} - ${file.size} bytes`}</div>
        </div>
      )}
      <form
        className='relative h-28 text-center'
        onDragEnter={() => setDragActive(true)}
        onDragLeave={() => setDragActive(false)}
        onSubmit={(e) => e.preventDefault()}
      >
        <label
          className={`flex h-full items-center justify-center bg-gray-3 cursor-pointer hover:bg-gray-10 ${
            dragActive ? 'bg-gray-10' : 'bg-gray-3'
          }`}
        >
          <input
            ref={inputRef}
            className='absolute top-0 bottom-0 left-0 right-0 opacity-0'
            type='file'
            multiple={true}
            onChange={handleFileChange}
          />
          <div className='flex flex-col items-center gap-2 p-4'>
            <FontAwesomeIcon icon={faArrowUpFromBracket} />
            <p className='prose-labelStandard uppercase'>Drop file here or select a file</p>
            <button onClick={onButtonClick}></button>
          </div>
        </label>
      </form>
      <div className='flex gap-2 justify-end pt-10'>
        <InvertButton label='Cancel' onClick={() => (props.cancelIsReturn ? navigate(-1) : setFile(undefined))} />
        <PrimaryButton
          label={props.uploadButtonLabel}
          disabled={!validForm || loading}
          onClick={() => handleUploadClick()}
        />
      </div>
    </>
  );
}
