import { faX } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Feature, Package, PackageUpgrade, PriceListItem, SoftwarePrice } from '../../../../models/ApiModels';

type Props = {
  newPackage?: string;
  setNewPackage?: () => void;
  additionalFeatures: string[];
  setAdditionalFeatures: (id: string) => void;
  prices?: SoftwarePrice;
  listPrices?: SoftwarePrice;
};

function OrderOverview(props: Props) {
  const packages = useSelector((state: any) => state.configurations.packages);
  const features = useSelector((state: any) => state.configurations.features);
  const upgrades: PackageUpgrade[] = useSelector((state: any) => state.configurations.upgrades);

  const currentPackage = useMemo<Package>(() => {
    return (
      packages.find((pack: Package) => pack.id === props.newPackage) ||
      packages.find(
        (pack: Package) =>
          pack.id === upgrades.find((u: PackageUpgrade) => u.id === props.newPackage)?.upgradeBasePackageId
      )
    );
  }, [props.newPackage]);

  const currentCustomerPriceList = useMemo<PriceListItem | undefined>(
    () => props.prices?.priceList.find((price: PriceListItem) => price.internalId === props.newPackage),
    [props.newPackage]
  );

  const currentPriceList = useMemo<PriceListItem | undefined>(
    () => props.listPrices?.priceList.find((price: PriceListItem) => price.internalId === props.newPackage),
    [props.newPackage]
  );

  const totalPrice = useMemo<number>(() => {
    let total = 0;
    total =
      total +
      (props.prices?.priceList.find((price: PriceListItem) => price.internalId === props.newPackage)?.unitPrice || 0);

    props.additionalFeatures.forEach((additionalFeature: string) => {
      total =
        total +
        (props.prices?.priceList.find((price: PriceListItem) => price.internalId === additionalFeature)?.unitPrice ||
          0);
    });

    return total;
  }, [props.newPackage, props.additionalFeatures]);

  function GetAddonPrice(addonId: string) {
    return props.prices?.priceList.find((price: PriceListItem) => price.internalId === addonId);
  }

  const calculateDiscountPercent = useMemo(() => {
    if (currentCustomerPriceList?.unitPrice && currentPriceList?.unitPrice !== undefined) {
      const value = 1 - currentCustomerPriceList.unitPrice / currentPriceList.unitPrice;
      const toPercent = value * 100;
      return toPercent.toFixed(2);
    } else if (props.additionalFeatures?.length > 0) {
      const addonCustomerPrice = props.prices?.priceList.find(
        (price: PriceListItem) => price.internalId === props.additionalFeatures[0]
      );
      const addonPrice = props.listPrices?.priceList.find(
        (price: PriceListItem) => price.internalId === props.additionalFeatures[0]
      );
      if (addonCustomerPrice && addonPrice) {
        const value = 1 - addonCustomerPrice?.unitPrice / addonPrice?.unitPrice;
        const toPercent = value * 100;
        return toPercent.toFixed(2);
      }
    }
  }, [props.additionalFeatures, props.newPackage]);

  return (
    <div className='mb-20'>
      <h1 className='prose-heading4 border-b border-gray-10 pb-5 pt-14 mb-6'>Order overview</h1>
      {props.newPackage || props.additionalFeatures.length > 0 ? (
        <div className='flex flex-col gap-2'>
          {props.newPackage && (
            <div className='flex justify-between'>
              <span className='flex items-start gap-3'>
                <div className='pt-[1px] cursor-pointer' onClick={() => props.setNewPackage && props.setNewPackage()}>
                  <FontAwesomeIcon icon={faX} size='xs' />
                </div>
                <div className='flex flex-col prose-paragraphBig text-black'>
                  {currentPackage?.displayName} - Lightning (Software, no physical item)
                </div>
              </span>
              <span className='prose-paragraphBig text-black'>
                {currentCustomerPriceList?.unitPrice.toLocaleString('en-US') +
                  ' ' +
                  (currentCustomerPriceList?.currencyCode || 'SEK')}
              </span>
            </div>
          )}
          {props.additionalFeatures &&
            props.additionalFeatures.map((id: string) => {
              const featureAddon: Feature = features.find((feat: Feature) => feat.id === id);
              const priceItem = GetAddonPrice(id);
              return (
                <div key={id} className='flex justify-between'>
                  <span className='flex items-start gap-3'>
                    <div
                      className='pt-[1px] cursor-pointer'
                      onClick={() => props.setAdditionalFeatures && props.setAdditionalFeatures(id)}
                    >
                      <FontAwesomeIcon icon={faX} size='xs' />
                    </div>
                    <div className='flex flex-col prose-paragraphBig text-black'>{featureAddon?.displayName}</div>
                  </span>
                  <span className='prose-paragraphBig text-black'>
                    {priceItem?.unitPrice + ' ' + (priceItem?.currencyCode || 'SEK')}
                  </span>
                </div>
              );
            })}
          {calculateDiscountPercent && calculateDiscountPercent !== '0.00' && (
            <span className='text-gray-40 mt-2'>Received customer discount ({calculateDiscountPercent}%)</span>
          )}
        </div>
      ) : (
        <span className='italic text-gray-60'>Nothing added</span>
      )}

      <div className='flex justify-between border-t border-gray-40 pt-6 mt-4 prose-heading4 uppercase'>
        <span>Total:</span>
        {totalPrice > 0 && (
          <span>{totalPrice.toLocaleString('en-US') + ' ' + (currentCustomerPriceList?.currencyCode || 'SEK')}</span>
        )}
      </div>
    </div>
  );
}

export default OrderOverview;
