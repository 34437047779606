import { Link, useNavigate } from 'react-router-dom';
import { SoftwareGroup, SoftwareUpdate } from '../../../models/ApiModels';

interface Props {
  groups?: any;
  software: SoftwareUpdate;
}

function SoftwareUpdateDetailsGroups(props: Props) {
  const navigate = useNavigate();
  const assignedGroups = props.groups.filter(
    (group: SoftwareGroup) =>
      group.lcuFirmwareId === props.software.id ||
      group.lcpFirmwareId === props.software.id ||
      group.lsuFirmwareId === props.software.id
  );

  return (
    <div className='flex flex-col'>
      <h1 className='prose-heading4 border-b border-gray-10 pb-5'>Groups</h1>
      <div className='flex flex-row justify-between /border-b border-gray-10 py-5 items-center'>
        <h2 className='prose-paragraphBase mb-1 flex w-full flex-col gap-5'>
          {assignedGroups.map((item: SoftwareGroup) => (
            <Link
              to={`/software/group/${item.id}`}
              className='cursor-pointer underline text-primary-100 hover:text-primary-200'
              key={item.id}
            >
              {item.groupName}
            </Link>
          ))}
        </h2>
      </div>
    </div>
  );
}

export default SoftwareUpdateDetailsGroups;
