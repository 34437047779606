import { ControlPanel, ControlUnit, HardwareSystem, ServoUnit } from '../../../models/ApiModels';
interface Props {
  lhs?: HardwareSystem;
  lcu?: ControlUnit;
  lcp?: ControlPanel;
  lsu?: ServoUnit;
}

function HardwareUnitsDetailsLog(props: Props) {
  return (
    <>
      {props.lhs && props.lhs.logEntries.length !== 0 && (
        <div className='mt-14'>
          <div className='prose-heading4 pb-5 mb-5 border-b border-gray-10'>System logs</div>
          <div className='bg-gray-5 p-5'>
            <pre className='max-h-[480px] overflow-y-auto whitespace-pre-wrap'>
              <code>{JSON.stringify(props.lhs.logEntries, null, 2)}</code>
            </pre>
          </div>
        </div>
      )}
      {props.lcu && props.lcu.logEntries.length !== 0 && (
        <div className='mt-14'>
          <div className='prose-heading4 pb-5 mb-5 border-b border-gray-10'>Unit logs</div>
          <div className='bg-gray-5 p-5'>
            <pre className='max-h-[480px] overflow-y-auto whitespace-pre-wrap'>
              <code>{JSON.stringify(props.lcu.logEntries, null, 2)}</code>
            </pre>
          </div>
        </div>
      )}
      {props.lcp && props.lcp.logEntries.length !== 0 && (
        <div className='mt-14'>
          <div className='prose-heading4 pb-5 mb-5 border-b border-gray-10'>Unit logs</div>
          <div className='bg-gray-5 p-5'>
            <pre className='max-h-[480px] overflow-y-auto whitespace-pre-wrap'>
              <code>{JSON.stringify(props.lcp.logEntries, null, 2)}</code>
            </pre>
          </div>
        </div>
      )}
      {props.lsu && props.lsu.logEntries.length !== 0 && (
        <div className='mt-14'>
          <div className='prose-heading4 pb-5 mb-5 border-b border-gray-10'>Unit logs</div>
          <div className='bg-gray-5 p-5'>
            <pre className='max-h-[480px] overflow-y-auto whitespace-pre-wrap'>
              <code>{JSON.stringify(props.lsu.logEntries, null, 2)}</code>
            </pre>
          </div>
        </div>
      )}
    </>
  );
}

export default HardwareUnitsDetailsLog;
