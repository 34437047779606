import { faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { faSortDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InvertButton } from 'component-library';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

interface PaginationProps {
  table: any;
}

function TablePagination({ table }: PaginationProps) {
  const [searchParams, setSearchParams] = useSearchParams({ page: '1', pageSize: '10' });
  const [goToPage, setGoToPage] = useState<number>(table.getState().pagination.pageIndex + 1);
  const totalPages = [...new Array(table.getPageCount())];
  const [showOptions, setShowOptions] = useState(false);

  const onNextPage = () => {
    if (table.getCanNextPage()) {
      table.setPageIndex(table.getState().pagination.pageIndex + 1);
      const currentPage = searchParams.get('page');

      if (currentPage) {
        searchParams.set('page', (parseInt(currentPage) + 1).toString());
        setSearchParams(searchParams);
      }
    }
  };

  const onPrevPage = () => {
    if (table.getCanPreviousPage()) {
      table.setPageIndex(table.getState().pagination.pageIndex - 1);
      const currentPage = searchParams.get('page');

      if (currentPage) {
        searchParams.set('page', (parseInt(currentPage) - 1).toString());
        setSearchParams(searchParams);
      }
    }
  };

  const handleEnter = (e: any) => {
    if (e.key === 'Enter') {
      setGoToPage(Number(e.target.value));
      setSearchParams({ page: e.target.value });
      goToPage! <= table.getPageCount() ? table.setPageIndex(goToPage - 1) : null;
    }
  };

  useEffect(() => {
    const page = searchParams.get('page');
    const pageSize = searchParams.get('pageSize');

    if (pageSize) {
      table.setPageSize(parseInt(pageSize));
    }
    if (page) {
      table.setPageIndex(parseInt(page) - 1);
      setGoToPage(parseInt(page));
    }
  }, [searchParams]);

  return (
    <div className='border-b border-l border-r border-gray-10 flex flex-col gap-4 lg:flex-row justify-between items-center p-5'>
      <div className='-order-first lg:-order-last flex items-center justify-center lg:justify-start gap-2 prose-paragraphSmaller'>
        View
        <div
          className='w-20 bg-gray-3 h-9 flex justify-between items-center py-2 px-3 relative cursor-pointer'
          onClick={() => setShowOptions(!showOptions)}
        >
          {table.getState().pagination.pageSize}
          <FontAwesomeIcon icon={faSortDown} className='mb-1' />
          {showOptions && (
            <div className='absolute bg-white left-0 bottom-10 w-20 border border-gray-10 overflow-auto max-h-20 scrollbar'>
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <div
                  key={pageSize}
                  className='pl-2 py-1 cursor-pointer hover:bg-gray-5'
                  onClick={() => {
                    if (table.getState().pagination.pageIndex !== 0) {
                      table.setPageIndex(0);
                      searchParams.set('page', '1');
                    }
                    searchParams.set('pageSize', pageSize.toString());
                    setSearchParams(searchParams);
                    table.setPageSize(pageSize);
                    setShowOptions(false);
                  }}
                >
                  {pageSize}
                </div>
              ))}
            </div>
          )}
        </div>
        items per page
      </div>
      <div className='flex items-center justify-center lg:justify-start gap-2 prose-paragraphSmaller'>
        <div
          className={`${
            table.getState().pagination.pageIndex + 1 !== 1
              ? 'cursor-pointer text-black hover:bg-primary-100 hover:text-white'
              : 'text-white'
          } py-1 px-2`}
          onClick={() => {
            onPrevPage();
          }}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </div>
        {totalPages.map((num: number, index: number) => {
          <div key={index}>{index + 1}</div>;
        })}

        <div className='flex w-20 justify-around'>
          {table.getState().pagination.pageIndex + 1} of {totalPages.length}
        </div>
        <div
          className={`${
            totalPages.length !== table.getState().pagination.pageIndex + 1
              ? 'cursor-pointer text-black hover:bg-primary-100 hover:text-white'
              : 'text-white'
          } py-1 px-2 `}
          onClick={() => {
            onNextPage();
          }}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </div>
      </div>
      <div className='flex items-center justify-center lg:justify-start gap-2 prose-paragraphSmaller'>
        Go to page
        <input
          onKeyDown={handleEnter}
          className='max-w-[60px] bg-gray-3 h-9 pl-2'
          onChange={(e) => setGoToPage(Number(e.target.value))}
        ></input>
        <InvertButton
          label='Go'
          onClick={() => {
            searchParams.set('page', goToPage.toString());
            setSearchParams(searchParams);
            goToPage! <= table.getPageCount() ? table.setPageIndex(goToPage - 1) : null;
          }}
        />
      </div>
    </div>
  );
}

export default TablePagination;
