import { EventLog, System } from '../../../../models/ApiModels';
import LogEventTimeData from './EventLogsTimeUserData';

interface Props {
  system?: System;
  event: EventLog;
  isUsersLoading: boolean;
  user: string;
  isHumphreeUser: boolean;
}

export default function LogLicenseInstalled(props: Props) {
  return (
    <div className='flex lg:flex-nowrap flex-wrap justify-between items-center border-b border-gray-10'>
      <div className='py-8 flex flex-wrap flex-row items-center gap-2'>
        <span className=' flex prose-paragraphBase text-gray-100'>License was successfully installed</span>
      </div>
      <LogEventTimeData
        event={props.event}
        isUsersLoading={props.isUsersLoading}
        user={props.user}
        isHumphreeUser={props.isHumphreeUser}
      />
    </div>
  );
}
