import { Dropdown, Input } from 'component-library';
import Qty from 'js-quantities';
import { useEffect, useState } from 'react';
import { BoatModel } from '../../../../models/ApiModels';

interface Props {
  newBoatModel: BoatModel;
  setNewBoatModel: (newBoatModel: BoatModel) => void;
}

function Dimensions(props: Props) {
  const [currentUnit, setCurrentUnit] = useState<string>('meter');
  const [inputLength, updateInputLength] = useState<number>();
  const [inputBeam, updateInputBeam] = useState<number>();
  const [fromUnit, setFromUnit] = useState('meter');
  const [toUnit, setToUnit] = useState('');

  useEffect(() => {
    if (currentUnit === 'meter') {
      setFromUnit('meter');
      setToUnit('foot');
    } else if (currentUnit === 'foot') {
      setFromUnit('foot');
      setToUnit('meter');
    }
    if (inputLength !== undefined || inputBeam !== undefined) {
      conversion();
    }
  }, [currentUnit]);

  const conversion = () => {
    if (inputLength !== undefined) {
      if (fromUnit !== undefined && toUnit !== undefined) {
        const qtyString = inputLength + fromUnit;
        const qty = new Qty(qtyString);
        const resultNum = qty.to(toUnit);

        const { scalar } = resultNum;
        updateInputLength(Math.round(scalar));
        updateBoatModel();
      }
    }
    if (inputBeam !== undefined) {
      if (fromUnit !== undefined && toUnit !== undefined) {
        const qtyString = inputBeam + fromUnit;
        const qty = new Qty(qtyString);
        const resultNum = qty.to(toUnit);

        const { scalar } = resultNum;
        updateInputBeam(Math.round(scalar));
        updateBoatModel();
      }
    }
  };

  const updateBoatModel = () => {
    if (currentUnit === 'meter' && inputLength !== undefined) {
      props.newBoatModel.length = inputLength;
      props.setNewBoatModel({ ...props.newBoatModel });
    }
    if (currentUnit === 'meter' && inputBeam !== undefined) {
      props.newBoatModel.beam = inputBeam;
      props.setNewBoatModel({ ...props.newBoatModel });
    }
    if (currentUnit === 'foot' && inputLength !== undefined) {
      const qtyString = inputLength + fromUnit;
      const qty = new Qty(qtyString);
      const resultNum = qty.to(toUnit);
      const { scalar } = resultNum;
      props.newBoatModel.length = scalar;
      props.setNewBoatModel({ ...props.newBoatModel });
    }
    if (currentUnit === 'foot' && inputBeam !== undefined) {
      const qtyString = inputBeam + fromUnit;
      const qty = new Qty(qtyString);
      const resultNum = qty.to(toUnit);
      const { scalar } = resultNum;
      props.newBoatModel.beam = scalar;
      props.setNewBoatModel({ ...props.newBoatModel });
    }
  };

  useEffect(() => {
    updateBoatModel();
  }, [inputLength, inputBeam]);

  return (
    <div className='border-b border-gray-10 pb-6'>
      <span className='prose-heading5'>Dimensions</span>
      <div className='flex flex-wrap gap-20 mt-2'>
        <div className='w-[11rem]'>
          <Dropdown
            optionsData={[
              { id: 'meter', value: 'Meter' },
              { id: 'foot', value: 'Foot' },
            ]}
            label='Unit of meassure'
            placeholder='Meter'
            value={currentUnit.charAt(0).toUpperCase() + currentUnit.slice(1).toLowerCase()}
            onValueChosen={(id: string) => setCurrentUnit(id)}
            size='Medium'
          />
        </div>
        <div className=' w-[5rem]'>
          <Input
            type='number'
            label='Length'
            unitLabel={currentUnit === 'meter' ? 'm' : 'ft'}
            value={inputLength}
            onChange={(input: number) => updateInputLength(input)}
            size='Small'
          />
        </div>
        <div className=' w-[5rem]'>
          <Input
            type='number'
            label='Beam'
            unitLabel={currentUnit === 'meter' ? 'm' : 'ft'}
            value={inputBeam}
            onChange={(input: number) => updateInputBeam(input)}
            size='Small'
          />
        </div>
      </div>
    </div>
  );
}

export default Dimensions;
