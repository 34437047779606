import { useMsal } from '@azure/msal-react';
import { useContext } from 'react';
import { AppContext } from '../contexts/AppContext';
interface Props {
  roles: string[];
  children?: any;
  rejected?: any;
  permissionException?: boolean;
}

export const ROLES = {
  editor: 'editor',
  admin: 'admin',
  softwareManager: 'softwareManager',
  oemEditor: 'oemEditor',
  oemAdmin: 'oemAdmin',
  presetCertified: 'presetCertified',
};

export default function PermissionsGate(props: Props) {
  const { isHumphreeUser } = useContext(AppContext);
  const { accounts } = useMsal();
  const account = accounts[0];
  const roles: string = account?.idTokenClaims?.extension_userRoles as string;

  switch (true) {
    case roles?.includes('admin') && props.roles?.includes('admin') && isHumphreeUser:
    case roles?.includes('editor') && props.roles?.includes('editor') && isHumphreeUser:
    case roles?.includes('admin') && props.roles?.includes('oemAdmin') && !isHumphreeUser:
    case roles?.includes('editor') && props.roles?.includes('oemEditor') && !isHumphreeUser:
    case roles?.includes('softwareManager') && props.roles?.includes('softwareManager'):
    case roles?.includes('presetCertified') && props.roles?.includes('presetCertified'):
    case props.permissionException:
      return <>{props.children}</>;
    default:
      return <>{props.rejected}</>;
  }
}
