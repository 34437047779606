import { faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, Input, Tooltip } from 'component-library';
import { useEffect, useMemo, useState } from 'react';
import { ConversionSpeed } from '../../../../helpers/Custom';
import { ValidPositiveNumber } from '../../../../helpers/Validation';
import { BoatModel } from '../../../../models/ApiModels';

type Props = {
  boatModel: BoatModel;
  editMode: boolean;
  setUpdateBoatModel: (boatModel: BoatModel) => void;
  updateBoatModel: BoatModel;
  isKnots: boolean;
  setIsKnots: (value: boolean) => void;
  setIsValid: (value: boolean) => void;
};
function TopSpeed(props: Props) {
  const [topSpeed, setTopSpeed] = useState<number>(props.boatModel.topSpeed || 0);

  const validTopSpeed = useMemo(
    () => ValidPositiveNumber(props.updateBoatModel.topSpeed) || props.updateBoatModel.topSpeed === 0,
    [props.updateBoatModel.topSpeed]
  );
  function CheckIsKnots(id: string) {
    if (id === 'kn') {
      props.setIsKnots(true);
      return props.isKnots;
    } else if (id === 'mph') {
      props.setIsKnots(false);
      return props.isKnots;
    }
  }
  useEffect(() => {
    if (props.updateBoatModel.topSpeed !== undefined)
      setTopSpeed(ConversionSpeed(props.updateBoatModel.topSpeed, props.isKnots));
  }, [props.updateBoatModel.topSpeed, props.isKnots]);

  useEffect(() => {
    if (validTopSpeed) props.setIsValid(true);
    else props.setIsValid(false);
  }, [validTopSpeed]);

  return (
    <div className='flex justify-between py-5 items-center'>
      <span className='flex gap-1'>
        Top speed <Tooltip description='Average maximum speed of the boat model.' darkmode />
      </span>
      {props.editMode ? (
        <div className='flex gap-2'>
          <Input
            size='Small'
            type='number'
            value={topSpeed === 0 ? '' : topSpeed}
            onChange={(input: number) => setTopSpeed(input)}
            onBlur={(input: number) => {
              props.updateBoatModel.topSpeed = ConversionSpeed(input, props.isKnots, true);
              props.setUpdateBoatModel({ ...props.updateBoatModel });
            }}
          />
          <Dropdown
            size='Medium'
            label=''
            optionsData={[
              { id: 'kn', value: 'Kn' },
              { id: 'mph', value: 'Mph' },
            ]}
            placeholder='Kn'
            value={props.isKnots ? 'Kn' : 'Mph'}
            onValueChosen={(id: string) => CheckIsKnots(id)}
          />
        </div>
      ) : (
        <span className={`${!props.boatModel.topSpeed && 'italic'}`}>
          {props.boatModel.topSpeed ? (
            `${props.boatModel.topSpeed.toString()} knots`
          ) : (
            <div className='text-warning-100'>
              <FontAwesomeIcon icon={faTriangleExclamation} /> <span className='text-gray-40'>Not added</span>
            </div>
          )}
        </span>
      )}
    </div>
  );
}

export default TopSpeed;
