import { useNavigate, useOutletContext } from 'react-router-dom';
import { GetPresetsListData, presetsListColumns } from '../../../../helpers/PresetsListSetup';
import PageNotFound from '../../../../routes/PageNotFound';
import useWindowSize from '../../../../utils/UseWindowSize';
import Table from '../../../Table/Table';

function BoatModelDetailsPresets() {
  const [
    boatModel,
    setBoatModel,
    oldBoatModel,
    GetBoatModelDetails,
    loading,
    hasChanged,
    leavingPage,
    hasError,
    setLoading,
    setOpenPopup,
    openPopup,
    presets,
  ] = useOutletContext<any[]>();
  const navigate = useNavigate();
  const size = useWindowSize();

  function goToPresetDetails(value: any, event: any) {
    if (event.ctrlKey || event.metaKey || event.which === 2) {
      window.open('/models/' + boatModel.id + '/presets/' + value.preset.id, '_blank');
    } else navigate('/models/' + boatModel.id + '/presets/' + value.preset.id);
  }

  return (
    <div>
      {loading ? (
        <div className='flex items-center flex-col gap-4 h-96 justify-center'>
          <span className='text-primary-400 prose-heading5'>Loading presets...</span>
          <div className='w-12 h-12 border-l-2 border-primary-400 rounded-full animate-spin'></div>
        </div>
      ) : !hasError ? (
        <>
          <div className='mt-10 border border-gray-10 p-4 flex justify-between items-center'>
            <h4 className='prose-heading4'>Presets</h4>
          </div>
          <Table
            data={GetPresetsListData(presets)}
            columns={presetsListColumns}
            setColumnVisibility={{
              status: size?.width > 1000 || size?.width < 640,
            }}
            onClick={(value: any, event?: any) => goToPresetDetails(value, event)}
            type='presets'
          />
        </>
      ) : (
        <PageNotFound />
      )}
    </div>
  );
}

export default BoatModelDetailsPresets;
