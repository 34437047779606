import React, { ChangeEvent, useEffect, useState } from 'react';
import { SystemComment } from '../../../models/ApiModels';

interface Props {
  newComment: SystemComment;
  setNewComment: (comment: SystemComment) => void;
}

function CreateProjectComments(props: Props) {
  const [commentText, setCommentText] = useState('');

  useEffect(() => {
    props.newComment.text = commentText;
    props.setNewComment({ ...props.newComment });
  }, [commentText]);

  return (
    <div className='w-full flex flex-col pb-16'>
      <span className='border-b w-full prose-heading4 border-gray-10 pb-5'>Comments</span>
      <textarea
        className='bg-gray-3 max-w-[460px] min-h-[110px] resize-none mt-5 p-2 prose-paragraphSmaller focus:ring-1 focus:ring-primary-100 focus:border-transparent outline-none'
        placeholder='Add comment...'
        value={commentText}
        onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
          setCommentText(event.target.value);
        }}
      ></textarea>
    </div>
  );
}

export default CreateProjectComments;
