import { faStar } from '@fortawesome/pro-regular-svg-icons';
import { faStar as faStarSolid } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  active?: boolean;
  name: string;
  setAsFavourite: () => void;
  selectOem: () => void;
}

export function OemSelectionItem(props: Props) {
  return (
    <div
      onClick={() => props.selectOem()}
      className='p-4 border-b border-gray-10 w-full cursor-pointer hover:shadow-dropdown hover:bg-gray-10 flex justify-between items-center'
    >
      <span className='text-primary-400 prose-heading5'>{props.name}</span>
      <div
        onClick={(e: any) => {
          e.stopPropagation();
          props.setAsFavourite();
        }}
      >
        {props.active ? (
          <FontAwesomeIcon className='text-primary-100' icon={faStarSolid} title='Favorite' />
        ) : (
          <FontAwesomeIcon className='text-gray-40' icon={faStar} title='Favorite' />
        )}
      </div>
    </div>
  );
}
