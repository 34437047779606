import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InvertButton, Popup, PrimaryButton } from 'component-library';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { System, SystemComment } from '../../../models/ApiModels';
import { AddSystem } from '../../../services/SystemService';
import { ActionType } from '../../../store/actionTypes';
import CreateSystemComments from './CreateSystemComments';
import CreateSystemHardware from './CreateSystemHardware';
import CreateSystemInformation from './CreateSystemInformation';
import CreateSystemSoftware from './CreateSystemSoftware';

function CreateSystem() {
  const dispatch = useDispatch();
  const [hasChanges, setHasChanges] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();
  const [newComment, setNewComment] = useState<SystemComment>({
    text: '',
    createdDate: undefined,
  });

  const initSystem: System = {
    oemId: '',
    boatModelId: '',
    packageId: '',
    presetId: '',
    presetStatus: '',
    presetInstallationDate: undefined,
    controlUnitSerialNumber: 0,
    confirmedLicenseRevision: 0,
    externalReference: '',
    softwareConfiguredDate: undefined,
    softwareInstalledDate: undefined,
    createdDate: undefined,
    modifiedDate: undefined,
    addonFeatureIds: [],
    eventLog: [],
    comments: [],
    status: 'Created',
    hullNumber: '',
  };

  const [newSystem, setNewSystem] = useState<System>(initSystem);

  useEffect(() => {
    if (_.isEqual(initSystem, newSystem)) setHasChanges(false);
    else {
      setHasChanges(true);
    }
  }, [newSystem]);

  function GoBack() {
    if (hasChanges) {
      setShowPopup(true);
    } else {
      setTimeout(() => {
        navigate(-1);
      }, 1);
    }
  }

  const saveSystem = useCallback((newSystem: System) => {
    setLoading(true);
    AddSystem(newSystem)
      .then((response: any) => {
        setLoading(false);
        dispatch({
          type: ActionType.SET_SNACKBAR,
          payload: { heading: 'You created a new system!', status: 'success' },
        });
        setTimeout(() => {
          navigate(`/systems/${response.id}`);
        }, 1);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  //Check for string
  const validOemInput = useMemo(() => {
    return newSystem.oemId !== '';
  }, [newSystem.oemId]);

  //Check for valid
  const validSystemCreate = useMemo(() => {
    return validOemInput && newSystem.controlUnitSerialNumber && newSystem.controlUnitSerialNumber > 0;
  }, [newSystem]);

  return (
    <div className='flex flex-col w-full'>
      <div className='flex gap-2 prose-heading3 text-primary-400 items-center pb-16'>
        <span className='cursor-pointer' onClick={() => GoBack()}>
          Systems
        </span>
        <FontAwesomeIcon icon={faChevronRight} size='sm' />
        <span>Create new system</span>
      </div>
      <CreateSystemInformation setNewSystem={(system: System) => setNewSystem({ ...system })} newSystem={newSystem} />
      <CreateSystemSoftware setNewSystem={(system: System) => setNewSystem({ ...system })} newSystem={newSystem} />
      <CreateSystemHardware setNewSystem={(system: System) => setNewSystem({ ...system })} newSystem={newSystem} />
      <CreateSystemComments
        newComment={newComment}
        setNewComment={(comment: SystemComment) => setNewComment({ ...comment })}
      />
      <div className='flex gap-2 justify-end pt-10'>
        <InvertButton
          label='Cancel'
          disabled={loading}
          onClick={() => {
            if (hasChanges) setShowPopup(true);
            else
              setTimeout(() => {
                navigate('/');
              }, 1);
          }}
        />
        <PrimaryButton
          label='Save system'
          disabled={!validSystemCreate || loading}
          onClick={() => {
            if (newComment.text && newComment.text.length > 0) newSystem.comments?.push({ ...newComment });
            setNewSystem({ ...newSystem });
            saveSystem(newSystem);
          }}
        />
      </div>
      {loading && (
        <div className='w-full flex justify-center items-center h-full'>
          <div className='w-12 h-12 border-l-2 border-primary-400 rounded-full animate-spin'></div>
        </div>
      )}
      {showPopup && (
        <Popup
          close={() => setShowPopup(false)}
          confirm={() =>
            setTimeout(() => {
              navigate('/');
            }, 1)
          }
          heading='You have unsaved changes.'
          paragraph="Do you want to discard any changes you've made?"
          status='error'
          cancelText='Go back'
          confirmText='Discard'
        />
      )}
    </div>
  );
}

export default CreateSystem;
