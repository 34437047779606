import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../../../contexts/AppContext';

enum Type {
  BoatModelDetails = 'Details',
  Presets = 'Presets',
  Patches = 'Patches',
}

interface Props {
  currentTab: string;
  changeTab: (value: string) => void;
}

function BoatModelDetailsTabs(props: Props) {
  const { isHumphreeUser } = useContext(AppContext);
  return (
    <div className='flex items-center w-full border-b border-gray-10 gap-8 mt-10'>
      <Link
        to='details'
        className={`py-4 cursor-pointer w-max ${
          !location.pathname.includes('presets') &&
          !location.pathname.includes('patches') &&
          'border-b-2 border-gray-100'
        }`}
        onClick={() => props.changeTab(Type.BoatModelDetails)}
      >
        <span
          className={`${
            !location.pathname.includes('presets') && !location.pathname.includes('patches')
              ? 'font-bold'
              : 'font-normal'
          } text-primary-400 prose-paragraphBase`}
        >
          Details
        </span>
      </Link>
      <Link
        to='presets'
        className={`py-4 cursor-pointer w-max ${location.pathname.includes('presets') && 'border-b-2 border-gray-100'}`}
        onClick={() => props.changeTab(Type.Presets)}
      >
        <span
          className={`${
            location.pathname.includes('presets') ? 'font-bold' : 'font-normal'
          } text-primary-400 prose-paragraphBase`}
        >
          Presets
        </span>
      </Link>
      {isHumphreeUser && (
        <Link
          to='patches'
          className={`py-4 cursor-pointer w-max ${
            location.pathname.includes('patches') && 'border-b-2 border-gray-100'
          }`}
          onClick={() => props.changeTab(Type.Patches)}
        >
          <span
            className={`${
              location.pathname.includes('patches') ? 'font-bold' : 'font-normal'
            } text-primary-400 prose-paragraphBase`}
          >
            Data patches
          </span>
        </Link>
      )}
    </div>
  );
}

export default BoatModelDetailsTabs;
