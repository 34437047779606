import { Checkbox } from 'component-library';
import listImg from '../../../../assets/lcpIcons/autolisttest-01.png';
import autoTrimImg from '../../../../assets/lcpIcons/autotrimtest-01.png';
import coTurnImg from '../../../../assets/lcpIcons/coordinatedturn-new-01.png';
import functionSetupImg from '../../../../assets/lcpIcons/functionsetup-new-01.png';
import pitchImg from '../../../../assets/lcpIcons/waves-01.png';
import { Preset } from '../../../../models/ApiModels';
interface Props {
  autoTrimData: { id: string; name: string; value: number; unit: string }[];
  listRollData: { id: string; name: string; value: number; unit: string }[];
  coTurnData: { id: string; name: string; value: number; unit: string }[];
  pitchData: { id: string; name: string; value: number; unit: string }[];
  selectBoxes?: boolean;
  preset?: Preset;
  setPreset?: (preset: Preset) => void;
  isPreset?: boolean;
}

export function ConfigurationFunctionSetupSettings(props: Props) {
  function handleEnabledValues(clickedName: any, clickedValue: any, clickedId: string) {
    if (
      clickedName.includes('Theme') ||
      clickedName.includes('Orientation') ||
      clickedName.includes('Allow nofix') ||
      clickedName.includes('Prioritize waterspeed')
    ) {
      if (props.preset && props.setPreset) {
        const newPreset = { ...props.preset };
        if (Object.prototype.hasOwnProperty.call(newPreset.lcpMetadata, clickedId)) {
          //check if key exists
          delete newPreset.lcpMetadata[clickedId];
        } else {
          newPreset.lcpMetadata[clickedId] = clickedValue;
        }
        props.setPreset(newPreset);
      }
    } else {
      if (props.preset && props.setPreset) {
        const newPreset = { ...props.preset };
        if (Object.prototype.hasOwnProperty.call(newPreset.lcuMetadata, clickedId)) {
          //check if key exists
          delete newPreset.lcuMetadata[clickedId];
        } else {
          newPreset.lcuMetadata[clickedId] = clickedValue;
        }
        props.setPreset(newPreset);
      }
    }
  }

  return (
    <div>
      <h4 className='prose-heading4 pb-5 mb-5 border-b border-gray-10'>
        <img src={functionSetupImg} className='inline mr-1 object-contain' />
        Function setup settings
      </h4>
      <div className='grid grid-cols-[repeat(auto-fit,minmax(16rem,auto))] gap-10 border-b border-gray-10 pb-5'>
        {/* Auto trim */}
        <div className='flex flex-col gap-4'>
          <h5 className='prose-heading5'>
            <img src={autoTrimImg} className='inline mr-1 object-contain' />
            Auto trim
          </h5>
          {props.autoTrimData.map(({ name, value, id, unit }) => (
            <div key={id} className='flex gap-2'>
              {props.selectBoxes && (
                <Checkbox
                  disabled={value === null || value === undefined}
                  active={
                    Object.keys(props.preset?.lcpMetadata as object).includes(id) ||
                    Object.keys(props.preset?.lcuMetadata as object).includes(id)
                  }
                  onClick={() => handleEnabledValues(name, value, id)}
                />
              )}
              <p
                className={`${
                  (value === null && props.isPreset) || (value === undefined && props.isPreset) ? 'text-gray-40' : ''
                }`}
              >
                {name}
              </p>
              {value === null || value === undefined ? (
                <p className='text-gray-40 italic ml-auto'>{props.isPreset ? '-' : 'n/a'}</p>
              ) : (
                <p className='font-code ml-auto'>{value + unit}</p>
              )}
            </div>
          ))}
        </div>
        {/* List & Roll */}
        <div className='flex flex-col gap-4'>
          <h5 className='prose-heading5'>
            <img src={listImg} className='inline mr-1 object-contain' />
            List & Roll
          </h5>
          {props.listRollData.map(({ name, value, id, unit }) => (
            <div key={id} className='flex gap-2'>
              {props.selectBoxes && (
                <Checkbox
                  disabled={value === null || value === undefined}
                  active={
                    Object.keys(props.preset?.lcpMetadata as object).includes(id) ||
                    Object.keys(props.preset?.lcuMetadata as object).includes(id)
                  }
                  onClick={() => handleEnabledValues(name, value, id)}
                />
              )}
              <p
                className={`${
                  (value === null && props.isPreset) || (value === undefined && props.isPreset) ? 'text-gray-40' : ''
                }`}
              >
                {name}
              </p>
              {value === null || value === undefined ? (
                <p className='text-gray-40 italic ml-auto'>{props.isPreset ? '-' : 'n/a'}</p>
              ) : (
                <p className='font-code ml-auto'>{value + unit}</p>
              )}
            </div>
          ))}
        </div>
        {/* Coordinated turn */}
        <div className='flex flex-col gap-4'>
          <h5 className='prose-heading5'>
            <img src={coTurnImg} className='inline mr-1 object-contain' />
            Coordinated turn
          </h5>
          {props.coTurnData.map(({ name, value, id, unit }) => (
            <div key={id} className='flex gap-2'>
              {props.selectBoxes && (
                <Checkbox
                  disabled={value === null || value === undefined}
                  active={
                    Object.keys(props.preset?.lcpMetadata as object).includes(id) ||
                    Object.keys(props.preset?.lcuMetadata as object).includes(id)
                  }
                  onClick={() => handleEnabledValues(name, value, id)}
                />
              )}
              <p
                className={`${
                  (value === null && props.isPreset) || (value === undefined && props.isPreset) ? 'text-gray-40' : ''
                }`}
              >
                {name}
              </p>
              {value === null || value === undefined ? (
                <p className='text-gray-40 italic ml-auto'>{props.isPreset ? '-' : 'n/a'}</p>
              ) : (
                <p className='font-code ml-auto'>{value + unit}</p>
              )}
            </div>
          ))}
        </div>
        {/* Pitch control */}
        <div className='flex flex-col gap-4'>
          <h5 className='prose-heading5'>
            <img src={pitchImg} className='inline mr-1 object-contain' />
            Pitch control
          </h5>
          {props.pitchData.map(({ name, value, id, unit }) => (
            <div key={id} className='flex gap-2'>
              {props.selectBoxes && (
                <Checkbox
                  disabled={value === null || value === undefined}
                  active={
                    Object.keys(props.preset?.lcpMetadata as object).includes(id) ||
                    Object.keys(props.preset?.lcuMetadata as object).includes(id)
                  }
                  onClick={() => handleEnabledValues(name, value, id)}
                />
              )}
              <p
                className={`${
                  (value === null && props.isPreset) || (value === undefined && props.isPreset) ? 'text-gray-40' : ''
                }`}
              >
                {name}
              </p>
              {value === null || value === undefined ? (
                <p className='text-gray-40 italic ml-auto'>{props.isPreset ? '-' : 'n/a'}</p>
              ) : (
                <p className='font-code ml-auto'>{value + unit}</p>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
