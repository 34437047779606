import ServiceHelper from '../helpers/ServiceHelper';
import { SoftwareGroup, SoftwareUpdate } from '../models/ApiModels';

export async function GetSoftwareUpdate() {
  return new Promise<SoftwareUpdate[]>((resolve, reject) => {
    ServiceHelper({
      method: 'GET',
      url: 'v1/files/firmwares',
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export async function GetSoftwareGroups() {
  return new Promise<SoftwareGroup[]>((resolve, reject) => {
    ServiceHelper({
      method: 'GET',
      url: 'v1/files/firmwares/groups',
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function AddSoftwareGroup(group: SoftwareGroup) {
  return new Promise<SoftwareGroup>((resolve, reject) => {
    ServiceHelper({
      method: 'POST',
      url: 'v1/files/firmwares/groups/',
      data: group,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}

export async function GetSoftwareUpdateInfo(id: string) {
  return new Promise<SoftwareUpdate>((resolve, reject) => {
    ServiceHelper({
      method: 'GET',
      url: `v1/files/firmwares/${id}/info`,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export async function GetSoftwareGroupsInfo(id: string) {
  return new Promise<SoftwareGroup>((resolve, reject) => {
    ServiceHelper({
      method: 'GET',
      url: `v1/files/firmwares/groups/${id}`,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function AddSoftwareUpdateFile(software: any) {
  return new Promise<void>((resolve, reject) => {
    ServiceHelper({
      method: 'POST',
      url: 'v1/files/firmwares/upload',
      data: software,
      headers: {
        'content-type': 'multipart/form-data',
        'content-length': 'content-length',
      },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export async function UpdateSoftwareUpdateFile(software: SoftwareUpdate) {
  return new Promise<void>((resolve, reject) => {
    ServiceHelper({
      method: 'PUT',
      url: `v1/files/firmwares/${software.id}`,
      data: software,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}

export async function DeleteSoftwareUpdateFile(software: SoftwareUpdate) {
  return new Promise<void>((resolve, reject) => {
    ServiceHelper({
      method: 'DELETE',
      url: `v1/files/firmwares/${software.id}`,
      data: software,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}

export async function UpdateGroupsInfo(group: SoftwareGroup) {
  return new Promise<void>((resolve, reject) => {
    ServiceHelper({
      method: 'PUT',
      url: `v1/files/firmwares/groups/${group.id}`,
      data: group,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}
export async function DeleteSoftwareGroupsInfo(group: SoftwareGroup) {
  return new Promise<void>((resolve, reject) => {
    ServiceHelper({
      method: 'DELETE',
      url: `v1/files/firmwares/groups/${group.id}`,
      data: group,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}
