import { faPenToSquare } from '@fortawesome/pro-regular-svg-icons';
import { Banner, Checkbox, InvertButton, PrimaryButton } from 'component-library';
import { useState } from 'react';
import { SoftwareGroup } from '../../../models/ApiModels';

interface Props {
  group: SoftwareGroup;
  setGroup: (group: SoftwareGroup) => void;
  editMode: boolean;
  setEditMode: () => void;
  updateGroup?: SoftwareGroup;
  setUpdateGroup: (group: SoftwareGroup) => void;
  openPopup: boolean;
  setOpenPopup: () => void;
  hasChanges: boolean;
  UpdateGroup: () => void;
}

function SoftwareUpdateGroupDetailsInfo(props: Props) {
  const [showBanner, setShowBanner] = useState(true);

  return (
    <div className='flex flex-col'>
      <h1 className='prose-heading4 border-b border-gray-10 pb-5'>Group information</h1>
      <div className='flex gap-2 ml-auto w-max /sticky -mt-12 mb-12 top-0 right-0 z-10'>
        {props.editMode && (
          <div className='bg-white'>
            <InvertButton
              label={'Leave edit'}
              onClick={() => {
                if (!props.hasChanges) {
                  props.setEditMode();
                } else props.setOpenPopup();
              }}
            />
          </div>
        )}
        {!props.editMode ? (
          <PrimaryButton label={'Edit Group'} icon={faPenToSquare} onClick={() => props.setEditMode()} />
        ) : (
          <PrimaryButton
            label={'Save group'}
            disabled={!props.hasChanges}
            onClick={() => {
              props.setGroup({ ...props.group });
              props.UpdateGroup();
            }}
          />
        )}
      </div>
      <div className='flex flex-row justify-between border-b border-gray-10 py-5 items-center'>
        <h2 className='prose-paragraphBase mb-1 flex w-full flex-col'>
          <span className='flex gap-2'>System type</span>
        </h2>
        <p className='flex items-center prose-paragraphBase whitespace-nowrap'>{props.group.assignedSystemType}</p>
      </div>
      <div className='flex flex-row justify-between border-b border-gray-10 py-5 items-center'>
        <h2 className='prose-paragraphBase mb-1 flex w-full flex-col'>
          <span className='flex gap-2'>Group name</span>
        </h2>
        <p className='flex items-center prose-paragraphBase whitespace-nowrap'>{props.group.groupName}</p>
      </div>
      <div className='flex flex-row justify-between border-b border-gray-10 py-5 items-center'>
        <h2 className='prose-paragraphBase mb-1 flex w-full flex-col'>
          <span className='flex gap-2'>Force install versions</span>
        </h2>
        <span className='flex min-w-max gap-1 items-center prose-paragraphBase'>
          Version will {!props.updateGroup?.isForceDownload && 'not'} be forced installed{' '}
        </span>

        {props.editMode && (
          <div className=''>
            <div className='ml-2'>
              <Checkbox
                active={props.updateGroup?.isForceDownload === true}
                onClick={() => {
                  if (props.updateGroup !== undefined) {
                    props.updateGroup.isForceDownload = !props.updateGroup.isForceDownload;
                    props.setUpdateGroup({ ...props.updateGroup });
                  }
                }}
              />
            </div>
          </div>
        )}
      </div>
      {props.editMode && showBanner && (
        <div className='mt-5 w-full'>
          <Banner
            icon={true}
            warning={false}
            text={
              'REMEMBER Force install does not work on versions below 2.0.0. Failure to comply could initiate an escalating LCSCON level event. Chances of a random LCSON event is VERY LOW, but NEVER ZERO. STAY SAFE. STAY VIGILANT.'
            }
            color={'Super Light Blue'}
            dismissBanner={() => setShowBanner(!showBanner)}
          />
        </div>
      )}
    </div>
  );
}

export default SoftwareUpdateGroupDetailsInfo;
