import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { useContext } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import App from './App';
import { msalConfig } from './authConfig';
import BoatModelDetailsPresetDetails from './components/BoatModels/BoatDetails/BoatDetailsPresets/BoatModelDetailsPresetDetails';
import BoatModelDetailsPresets from './components/BoatModels/BoatDetails/BoatDetailsPresets/BoatModelDetailsPresets';
import BoatModelDetails from './components/BoatModels/BoatDetails/BoatModelDetails';
import BoatModelDetailsPatches from './components/BoatModels/BoatDetails/BoatModelDetailsPatches';
import BoatModelDetailsLayout from './components/BoatModels/BoatModelDetailsLayout';
import BoatModels from './components/BoatModels/BoatModels';
import CreateBoatModel from './components/BoatModels/CreateBoatModel/CreateBoatModel';
import DashboardHome from './components/Dashboard/DashboardHome';
import HardwareUnits from './components/HardwareUnits/HardwareUnits';
import HardwareUnitsDetailsLCP from './components/HardwareUnits/HardwareUnitsDetails/HardwareUnitsDetailsLCP';
import HardwareUnitsDetailsLCU from './components/HardwareUnits/HardwareUnitsDetails/HardwareUnitsDetailsLCU';
import HardwareUnitsDetailsLHS from './components/HardwareUnits/HardwareUnitsDetails/HardwareUnitsDetailsLHS';
import HardwareUnitsDetailsLSU from './components/HardwareUnits/HardwareUnitsDetails/HardwareUnitsDetailsLSU';
import { CreateIndirectCustomer } from './components/IndirectCustomer/CreateIndirectCustomer';
import Inventory from './components/Inventory/Inventory';
import Layout from './components/Layout/Layout';
import LogoutUrl from './components/Layout/Logout';
import Measurements from './components/Measurements/Measurements';
import { Settings } from './components/Settings/Settings';
import { SettingAdministration } from './components/Settings/SettingsAdministration';
import { SettingsCustomers } from './components/Settings/SettingsCustomers';
import { SettingsOrder } from './components/Settings/SettingsOrder';
import { SettingsPrices } from './components/Settings/SettingsPrices';
import { SoftwareUpdate } from './components/SoftwareUpdate/SoftwareUpdate';
import SoftwareUpdateCreate from './components/SoftwareUpdate/SoftwareUpdateCreate';
import SoftwareUpdateDetails from './components/SoftwareUpdate/SoftwareUpdateDetails/SoftwareUpdateDetails';
import SoftwareUpdateGroupDetails from './components/SoftwareUpdate/SoftwareUpdateGroupDetails.tsx/SoftwareUpdateGroupDetails';
import { SoftwareUpdateGroups } from './components/SoftwareUpdate/SoftwareUpdateGroups';
import SoftwareUpdateUpload from './components/SoftwareUpdate/SoftwareUpdateUpload';
import { SoftwareUpdateVersions } from './components/SoftwareUpdate/SoftwareUpdateVersions';
import CreateSystem from './components/Systems/CreateSystem/CreateSystem';
import SystemDetails from './components/Systems/SystemDetails/SystemDetails';
import SystemDetailsConfigurationCreatePreset from './components/Systems/SystemDetails/SystemDetailsConfiguration/CreatePreset/SystemDetailsConfigurationCreatePreset';
import SystemDetailsConfiguration from './components/Systems/SystemDetails/SystemDetailsConfiguration/SystemDetailsConfiguration';
import SystemDetailsEventLog from './components/Systems/SystemDetails/SystemDetailsEventLogs/SystemDetailsEventLogs';
import { SystemDetailsLayout } from './components/Systems/SystemDetails/SystemDetailsLayout';
import Systems from './components/Systems/Systems';
import WarrantyForm from './components/WarrantyForm/WarrantyForm';
import AppProvider, { AppContext } from './contexts/AppContext';
import { WithAxios } from './helpers/ServiceHelper';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Error from './routes/PageNotFound';
import { store } from './store/store';

export const msalInstance = new PublicClientApplication(msalConfig);

const ProtectedRoute = () => {
  const { isHumphreeUser } = useContext(AppContext);
  if (!isHumphreeUser) {
    return <Navigate to={'/systems'} replace />;
  }
  return <Outlet />;
};
const BusinessRoute = () => {
  const { currentOemTenantId, isBusinessCustomer } = useContext(AppContext);
  if (currentOemTenantId !== '12345678-1234-1234-1234-123456789000' && isBusinessCustomer) {
    return <Outlet />;
  }
  return <Navigate to={'/settings'} replace />;
};
const IndirectCustomerRoute = () => {
  const { currentOemTenantId, isBusinessCustomer } = useContext(AppContext);
  if (currentOemTenantId !== '12345678-1234-1234-1234-123456789000' && !isBusinessCustomer) {
    return <Outlet />;
  }
  return <Navigate to={'/settings'} replace />;
};

ReactDOM.render(
  <MsalProvider instance={msalInstance}>
    <Provider store={store}>
      <AppProvider>
        <WithAxios>
          <BrowserRouter>
            <Routes>
              <Route element={<App />}>
                <Route element={<Layout />}>
                  <Route element={<ProtectedRoute />}>
                    <Route path='/dashboard' element={<DashboardHome />} />
                    <Route path='/units' element={<HardwareUnits />} />
                    <Route path='/units/lhs/:serial' element={<HardwareUnitsDetailsLHS />} />
                    <Route path='/units/lcu/:serial' element={<HardwareUnitsDetailsLCU />} />
                    <Route path='/units/lcp/:serial' element={<HardwareUnitsDetailsLCP />} />
                    <Route path='/units/lsu/:serial' element={<HardwareUnitsDetailsLSU />} />
                    <Route path='/create' element={<CreateSystem />} />
                    <Route path='/measurements' element={<Measurements />} />
                    <Route path='/software' element={<SoftwareUpdate />}>
                      <Route index element={<SoftwareUpdateVersions />} />
                      <Route path='version' element={<SoftwareUpdateVersions />} />
                      <Route path='group' element={<SoftwareUpdateGroups />} />
                    </Route>
                    <Route path='software/upload' element={<SoftwareUpdateUpload />} />
                    <Route path='software/create-group' element={<SoftwareUpdateCreate />} />
                    <Route path='software/version/:id' element={<SoftwareUpdateDetails />} />
                    <Route path='software/group/:id' element={<SoftwareUpdateGroupDetails />} />
                  </Route>
                  <Route path='/' element={<Navigate to='/systems' replace />} />
                  <Route path='/systems' element={<Systems />} />
                  <Route path='/create/customer' element={<CreateIndirectCustomer />} />

                  <Route path='/systems/:id' element={<SystemDetailsLayout />}>
                    <Route index element={<SystemDetails />} />
                    <Route path='details' element={<SystemDetails />} />
                    <Route path='configuration' element={<SystemDetailsConfiguration />} />
                    <Route path='createPreset' element={<SystemDetailsConfigurationCreatePreset />} />
                    <Route path='eventlog' element={<SystemDetailsEventLog />} />
                  </Route>
                  <Route path='/models' element={<BoatModels />} />
                  <Route path='/models/:id' element={<BoatModelDetailsLayout />}>
                    <Route index element={<BoatModelDetails />} />
                    <Route path='details' element={<BoatModelDetails />} />
                    <Route path='presets' element={<BoatModelDetailsPresets />} />
                    <Route path='presets/:presetName' element={<BoatModelDetailsPresetDetails />} />
                    <Route path='patches' element={<BoatModelDetailsPatches />} />
                  </Route>
                  <Route path='/createModel' element={<CreateBoatModel />} />
                  <Route path='/inventory' element={<Inventory />} />
                  <Route path='/settings' element={<Settings />}>
                    <Route index element={<SettingAdministration />} />
                    <Route path='admin' element={<SettingAdministration />} />
                    <Route element={<BusinessRoute />}>
                      <Route path='order' element={<SettingsOrder />} />
                      <Route path='customers' element={<SettingsCustomers />} />
                    </Route>
                    <Route element={<IndirectCustomerRoute />}>
                      <Route path='prices' element={<SettingsPrices />} />
                    </Route>
                  </Route>
                  <Route path='*' element={<Error />} />
                </Route>
              </Route>
              <Route path='/warrantyform' element={<WarrantyForm />} />
              <Route path='/logout' element={<LogoutUrl />} />
            </Routes>
          </BrowserRouter>
        </WithAxios>
      </AppProvider>
    </Provider>
  </MsalProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
