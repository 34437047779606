import _ from 'lodash';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { useSelector } from 'react-redux';
import { AppContext } from '../../../contexts/AppContext';
import { BoatModel } from '../../../models/ApiModels';
import { RootState } from '../../../store/reducers/combine';
interface BoatMakeProps {
  boatModel: BoatModel;
  setBoatModel: (boatModel: BoatModel) => void;
}
function BoatModelMakeInput(props: BoatMakeProps) {
  const { currentOemTenantId } = useContext(AppContext);
  const boatModels = useSelector((state: RootState) => state.boatModels.boatModels);
  const [searchValueMake, setSearchValueMake] = useState<string>('');
  const [showResultMake, setShowResultMake] = useState(false);
  const [searchResultMake, setSearchResultMake] = useState<BoatModel[]>([]);
  const [characterCountMake, setCharacterCountMake] = useState<string>(props.boatModel.make);

  const refMake = useDetectClickOutside({
    onTriggered: () => {
      setShowResultMake(false);
    },
  });

  function FreewordMakeFilter(filteredList: BoatModel[]) {
    const oemList = filteredList.filter((oem: BoatModel) => oem.oemTenantId === currentOemTenantId);

    filteredList = filteredList.filter((boat: BoatModel) => {
      const makeValue = boat.make;
      const flatObject = Object.values(boat);
      return flatObject.some(
        () => makeValue && makeValue.toString().toLowerCase().includes(searchValueMake.toLowerCase())
      );
    });

    if (currentOemTenantId !== '12345678-1234-1234-1234-123456789000') {
      setSearchResultMake(oemList);
    }
    setSearchResultMake(filteredList);
  }

  useEffect(() => {
    FreewordMakeFilter(boatModels);
  }, [searchValueMake]);

  return (
    <div className='relative w-full md:max-w-[256px]'>
      <span className={`prose-paragraphTiny ${characterCountMake.length > 29 && 'text-warning-100'}`}>
        ({characterCountMake.length}/30)
      </span>
      <input
        maxLength={30}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          setSearchValueMake(e.target.value);
          setShowResultMake(true);
          setCharacterCountMake(e.target.value);
          props.boatModel.make = e.target.value;
          props.setBoatModel({ ...props.boatModel });
        }}
        placeholder='Search for boat brand...'
        className={`w-full truncate bg-gray-3 prose-paragraphSmall ${
          characterCountMake.length > 29 && 'border border-warning-100'
        } px-3 h-10`}
        value={props.boatModel.make}
      />

      {searchValueMake.length >= 0 && showResultMake && (
        <div
          ref={refMake}
          className='bg-white border scrollbar shadow-popup border-gray-10 mt-1 p-2 max-h-[250px] overflow-auto absolute top-16 z-[100] w-full'
        >
          {_.uniqBy<BoatModel>(searchResultMake, 'make').map((result: BoatModel) => {
            return (
              <div
                key={result.id}
                onClick={() => {
                  props.boatModel.make = result.make;
                  setSearchValueMake(result.make);
                  props.setBoatModel({ ...props.boatModel });
                  setShowResultMake(false);
                }}
                className='px-3 py-3 hover:bg-gray-5 cursor-pointer'
              >
                {result.make}
              </div>
            );
          })}
          {searchResultMake.length === 0 && (
            <div onClick={() => setShowResultMake(false)} className='px-3 py-3 hover:bg-gray-5 cursor-pointer'>
              No Brand was found. Adding <strong>{searchValueMake}</strong>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default BoatModelMakeInput;
