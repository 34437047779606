import { faCalendar, faChevronLeft, faChevronRight, faPenToSquare } from '@fortawesome/pro-regular-svg-icons';
import axios from 'axios';
import { Input, PrimaryButton, StatusPill } from 'component-library';
import moment from 'moment';
import { useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Config } from '../../config';
import { ContainsOnlyNumbers } from '../../helpers/Validation';
import { Warranty } from '../../models/ApiModels';

interface Props {
  warrantyForm: Warranty;
  setNewWarranty: (newWarranty: Warranty) => void;
  page: number;
  setPage: (value: number) => void;
  errorMessage?: string;
}

interface ErrorProps {
  showError: boolean;
  setShowError: (value: boolean) => void;
  errorMessage: string;
}

function Error(props: ErrorProps) {
  return (
    <>
      <div>
        <img src='/humphree-logo-black.png' className='m-auto mb-4' />
        <span className='prose-heading3 text-primary-400'>Lightning Control System warranty form</span>
      </div>
      <div className='flex flex-col justify-center items-center gap-5'>
        {props.errorMessage === 'LCU_NOT_FOUND' && (
          <>
            <StatusPill color={'Light Red'} text={'Error'} />
            No LCU number was found
            <br />
            <br />
            You can find the numbers in the control panel under the system information field.
          </>
        )}
        {props.errorMessage === 'LHS_MISMATCH' && (
          <>
            <StatusPill color={'Light Red'} text={'Error'} />
            It looks like the serial number for the control panel and the control unit do not match.
            <br />
            <br />
            Please check if you entered the right numbers. You can find the numbers in the control panel under the
            system information field.
          </>
        )}
        {props.errorMessage === 'NOT_CONNECTED' && (
          <>
            <StatusPill color={'Light Red'} text={'Error'} />
            It looks like this system has not been connected to the internet, please repeat the connection process and
            submit the form again.
            <br />
            <br />
            If the problem persists contact support.
          </>
        )}
        {props.errorMessage === 'SYSTEM_NOT_CREATED' && (
          <>
            <StatusPill color={'Light Red'} text={'Error'} />
            It looks lite this system has not been created yet.
          </>
        )}
        {props.errorMessage === 'ALREADY_SUBMITTED' && (
          <>
            <StatusPill color={'Light Red'} text={'Error'} />
            It looks like this system already has a valid warranty submitted.
          </>
        )}
        {props.errorMessage !== 'ALREADY_SUBMITTED' && (
          <PrimaryButton onClick={() => props.setShowError(false)} icon={faChevronLeft} label='Go back to form' />
        )}
      </div>
    </>
  );
}

function WarrantyFormSystemInfo(props: Props) {
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  async function AddWarrantyServiceValidate() {
    const data = props.warrantyForm;
    setShowError(false);
    setLoading(true);
    return axios
      .post(`${Config.api_url}v1/warranty/lightning/validate`, data)
      .then((response) => {
        setLoading(false);
        props.setPage(props.page + 1);
        return response.data;
      })
      .catch((error) => {
        if (error.response.data) {
          setLoading(false);
          setShowError(true);
          setErrorMessage(error.response.data.message);
        }
      });
  }

  const [startDate, setStartDate] = useState();
  const WarrantyDate = () => {
    function onChangeDateHandler(value: any) {
      const newDate = moment(value).format('YYYY-MM-DD');
      setStartDate(value);
      props.warrantyForm.boatDeliveryDate = newDate;
      props.setNewWarranty({ ...props.warrantyForm });
    }

    return (
      <div>
        <DatePicker
          popperPlacement='top'
          dateFormat='dd/MM/yyyy'
          placeholderText='Enter date...'
          className='bg-gray-3 w-full prose-paragraphSmaller'
          selected={startDate}
          onChange={onChangeDateHandler}
          customInput={
            <Input
              value={props.warrantyForm.boatDeliveryDate}
              icon={faCalendar}
              centeredLabel
              label='boat delivery date'
            />
          }
        />
      </div>
    );
  };

  const validLCU = useMemo(() => {
    if (props.warrantyForm.controlUnitSerialNumber !== undefined) {
      return (
        props.warrantyForm.controlUnitSerialNumber === '' ||
        ContainsOnlyNumbers(props.warrantyForm.controlUnitSerialNumber)
      );
    }
  }, [props.warrantyForm.controlUnitSerialNumber]);

  const validLCP = useMemo(() => {
    if (props.warrantyForm.controlPanelSerialNumber !== undefined) {
      return (
        props.warrantyForm.controlPanelSerialNumber === '' ||
        ContainsOnlyNumbers(props.warrantyForm.controlPanelSerialNumber)
      );
    }
  }, [props.warrantyForm.controlPanelSerialNumber]);

  const configValid = useMemo(() => {
    if (
      validLCU &&
      validLCP &&
      props.warrantyForm.hullNumber !== '' &&
      props.warrantyForm.boatDeliveryDate !== undefined
    ) {
      return true;
    } else false;
  }, [props.warrantyForm]);

  return (
    <>
      {loading ? (
        <div className='fixed top-0 left-0 right-0 bottom-0 z-10 flex justify-center items-center'>
          <span className='font-bold flex flex-col gap-4 justify-center items-center'>
            Loading...
            <div className='w-12 h-12 border-l-2 border-primary-400 rounded-full animate-spin'></div>
          </span>
        </div>
      ) : (
        <>
          {showError ? (
            <Error
              showError={showError}
              setShowError={(value: boolean) => setShowError(value)}
              errorMessage={errorMessage}
            />
          ) : (
            <div className='flex flex-col gap-3 items-center w-full'>
              <div className='bg-primary-200 text-white prose-heading3 rounded-full p-2 flex justify-center items-center w-14'>
                3
              </div>
              <div className='prose-paragraphBase mb-5'>System information</div>
              <div className='flex flex-col gap-5 w-full'>
                <Input
                  value={props.warrantyForm.controlUnitSerialNumber}
                  onChange={(value: string) => {
                    props.warrantyForm.controlUnitSerialNumber = value;
                    props.setNewWarranty({ ...props.warrantyForm });
                  }}
                  placeholder='Enter text input...'
                  error={!validLCU}
                  icon={faPenToSquare}
                  centeredLabel
                  label='LCU serial number'
                />
                <hr className='border-gray-10' />
                <Input
                  value={props.warrantyForm.controlPanelSerialNumber}
                  onChange={(value: string) => {
                    props.warrantyForm.controlPanelSerialNumber = value;
                    props.setNewWarranty({ ...props.warrantyForm });
                  }}
                  placeholder='Enter text input...'
                  error={!validLCP}
                  icon={faPenToSquare}
                  centeredLabel
                  label='LCP serial number'
                />
                <hr className='border-gray-10' />
                <Input
                  value={props.warrantyForm.hullNumber}
                  onChange={(value: string) => {
                    props.warrantyForm.hullNumber = value;
                    props.setNewWarranty({ ...props.warrantyForm });
                  }}
                  placeholder='Enter text input...'
                  icon={faPenToSquare}
                  centeredLabel
                  label='Boat hull number'
                />
                <hr className='border-gray-10' />
                <WarrantyDate />
                <hr className='border-gray-10' />
                <div className='flex justify-between mt-10 z-20'>
                  <PrimaryButton
                    onClick={() => props.setPage(props.page - 1)}
                    disabled={props.page === 0}
                    icon={faChevronLeft}
                    label='Go back'
                  />
                  <PrimaryButton
                    disabled={!configValid}
                    onClick={() => {
                      AddWarrantyServiceValidate();
                    }}
                    iconAfter
                    icon={faChevronRight}
                    label='Continue'
                  />
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}
export default WarrantyFormSystemInfo;
