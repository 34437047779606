import 'moment/locale/en-gb';
import { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { EventLog } from '../../../../models/ApiModels';
import { GetUserNames } from '../../../../services/UserService';
import LogDataChanged from './LogDataChanged';
import LogHardwareChanged from './LogHardwareChanged';
import LogLicenseFeatureChanged from './LogLicenseFeatureChanged';
import LogLicenseInstalled from './LogLicenseInstalled';
import LogLicenseManualOverride from './LogLicenseManualOverride';
import LogStatusChanged from './LogStatusChanged';
import LogSystemCreated from './LogSystemCreated';
import LogSystemRetired from './LogSystemRetired';
import LogWarrantyAdded from './LogWarrantyAdded';

function SystemDetailsEventLog() {
  const [users, setUsers] = useState<any>([]);
  const [isUsersLoading, setIsUsersLoading] = useState(false);
  const [system] = useOutletContext<any>();

  useEffect(() => {
    const logIds: string[] = [];
    system.eventLog?.forEach((log: EventLog) => {
      if (log.userId) {
        logIds.push(log.userId);
      }
    });
    if (logIds.length > 0) {
      setIsUsersLoading(true);
      GetUserNames(logIds)
        .then((response) => setUsers(response))
        .finally(() => setIsUsersLoading(false));
    }
  }, []);

  return (
    <div className='pt-10'>
      <div className='border-b border-gray-10'>
        <div className='py-3'>
          <h1 className='prose-heading4'>System event log</h1>
          <span className='prose-paragraphSmaller text-gray-60'>
            A timeline of all the changes made to this system.
          </span>
        </div>
      </div>
      {[...(system.eventLog || [])].reverse().map((event: EventLog, index: number) => {
        const user = users.find((user: any) => user.userId === event.userId);
        const humphreeUser = user?.oemTenantId === '12345678-1234-1234-1234-123456789000';
        switch (event.eventCode) {
          case 1:
            return (
              <LogStatusChanged
                key={index}
                user={user?.userName}
                system={system}
                event={event}
                isUsersLoading={isUsersLoading}
                isHumphreeUser={humphreeUser}
              />
            );
          case 1000:
            return (
              <LogSystemCreated
                key={index}
                user={user?.userName}
                system={system}
                event={event}
                isUsersLoading={isUsersLoading}
                isHumphreeUser={humphreeUser}
              />
            );
          case 1001:
            return (
              <LogSystemRetired
                key={index}
                user={user?.userName}
                system={system}
                event={event}
                isUsersLoading={isUsersLoading}
                isHumphreeUser={humphreeUser}
              />
            );
          case 2100:
            return (
              <LogLicenseInstalled
                key={index}
                user={user?.userName}
                system={system}
                event={event}
                isUsersLoading={isUsersLoading}
                isHumphreeUser={humphreeUser}
              />
            );
          case 2200:
            return (
              <LogLicenseFeatureChanged
                key={index}
                user={user?.userName}
                system={system}
                event={event}
                isUsersLoading={isUsersLoading}
                isHumphreeUser={humphreeUser}
              />
            );
          case 2201:
            return (
              <LogLicenseManualOverride
                key={index}
                user={user?.userName}
                system={system}
                event={event}
                isUsersLoading={isUsersLoading}
                isHumphreeUser={humphreeUser}
              />
            );
          case 2000:
            return (
              <LogDataChanged
                key={index}
                user={user?.userName}
                system={system}
                event={event}
                isUsersLoading={isUsersLoading}
                isHumphreeUser={humphreeUser}
              />
            );
          case 4000:
            return (
              <LogWarrantyAdded
                key={index}
                user={user?.userName}
                system={system}
                event={event}
                isUsersLoading={isUsersLoading}
                isHumphreeUser={humphreeUser}
              />
            );
          case 5001:
            return (
              <LogHardwareChanged
                key={index}
                user={user?.userName}
                system={system}
                event={event}
                isUsersLoading={isUsersLoading}
                isHumphreeUser={humphreeUser}
              />
            );
          default:
            break;
        }
      })}
    </div>
  );
}

export default SystemDetailsEventLog;
