import { BoatModel } from '../../models/ApiModels';
import { Action, ActionType } from '../actionTypes';

interface State {
  boatModels: BoatModel[];
  hasError: boolean;
}

const initialState: State = {
  boatModels: [],
  hasError: false,
};

const boatModelsReducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case ActionType.GET_BOATMODELS:
      return {
        ...state,
        boatModels: action.payload,
      };
      case ActionType.ADD_BOATMODELS: {
        const newArray = [...state.boatModels, action.payload]; //Copying state array
        return {
          ...state,
        boatModels: newArray,
        };
      }
    case ActionType.UPDATE_BOATMODEL: {
      const updatedArray = state.boatModels.map((boatModel) => {
        return boatModel.id === action.payload.id ? { ...boatModel, ...action.payload } : boatModel;
      });
      return {
        ...state,
        boatModels: updatedArray,
      };
    }
    case ActionType.DELETE_BOATMODEL: {
      const deleteArray = state.boatModels.filter((boatModel) => boatModel !== action.payload); //Copying state array
      return {
        ...state,
        boatModels: deleteArray,
      };
    }
    case ActionType.SET_BOATMODELSERROR: {
      return {
        ...state,
        hasError: action.payload,
      };
    }
    default:
      return state;
  }
};

export default boatModelsReducer;
