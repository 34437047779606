import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  close: () => void;
}

export default function WarrantyFormPrivacy(props: Props) {
  return (
    <div className='fixed left-0 top-0 right-0 bottom-0 w-screen flex justify-center overflow-y-auto z-10'>
      <div className='fixed w-screen h-screen bottom-0 bg-gray-100 opacity-50 overlay'></div>
      <div className='prose-paragraphSmall bg-white opacity-100 z-10 h-max p-4 justify-start relative shadow-popup popup flex flex-col text-left md:min-h-[70%] md:max-w-2xl md:mt-20 md:mb-10 md:p-16'>
        <div className='w-10 fixed bg-error-200'></div>
        <div
          onClick={() => props.close()}
          className='fixed top-3 right-3 flex w-9 h-9 justify-center items-center bg-primary-100 rounded-full text-white md:w-11 md:h-11 md:absolute md:-right-3 md:-top-3 cursor-pointer shadow-close'
        >
          <FontAwesomeIcon icon={faXmark} title='Close' />
        </div>
        <img src='/humphree-logo-black.png' className='m-auto mb-4 mt-10 md:mt-0' />
        <span className='text-primary-400 prose-heading3 mb-10 text-center'>
          Warranty form
          <br /> privacy notice
        </span>
        <p>
          We are committed to protecting your personal data and respecting your privacy. This privacy notice explains
          how we collect, use, store, and share your personal information when you provide us with your email address in
          the digital warranty form.
        </p>
        <br />
        <p>
          <span className='font-bold'>Data Controller:</span>
          <br /> The data controller responsible for the processing of your personal data is Humphree AB.
        </p>
        <br />
        <p>
          <span className='font-bold'>Purpose of Processing:</span>
          <br /> We will process your email address for the purpose of providing you with information about your
          warranty.
        </p>
        <br />
        <p>
          <span className='font-bold'>Legal Basis for Processing:</span>
          <br /> We process your personal data based on your consent, which you provide by voluntarily submitting your
          email address in the digital warranty form.
        </p>
        <br />
        <p>
          <span className='font-bold'> Data Retention:</span>
          <br /> We will retain your personal data for as long as necessary to fulfill the purposes for which it was
          collected, unless a longer retention period is required or permitted by law.
        </p>
        <br />
        <p>
          <span className='font-bold'>Data Sharing:</span>
          <br /> We may share your email adress with service centers and affiliates who assist us in processing the
          warranty.
        </p>
        <br />
        <p>
          <span className='font-bold'>Your rights:</span>
          <br /> You have the right to correct or delete your personal data, as well as the right to object to or
          restrict our processing of your personal data. You also have the right to withdraw your consent at any time by
          contacting us at hello@humphree.com. Additionally, you have the right to lodge a complaint with the
          supervisory authority if you believe that your personal data has been processed in violation of applicable
          data protection laws. <br />
          <br /> If you have any questions or concerns about our processing of your personal data, please contact us at
          hello@humphree.com. <br />
          <br /> By submitting your email address in the digital warranty form, you confirm that you have read and
          understood this privacy notice, and that you consent to our processing of your personal data as described
          herein.
        </p>
      </div>
    </div>
  );
}
