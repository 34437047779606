import ServiceHelper from '../helpers/ServiceHelper';
import { Inventory } from '../models/ApiModels';

export async function GetInventory() {
  return new Promise<Inventory[]>((resolve, reject) => {
    ServiceHelper({
      method: 'GET',
      url: 'v1/inventory',
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function AddInventory(inventory: Inventory) {
  return new Promise<void>((resolve, reject) => {
    ServiceHelper({
      method: 'POST',
      url: 'v1/inventory',
      data: inventory,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

