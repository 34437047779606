import { Dropdown } from 'component-library';
import { Link, useNavigate } from 'react-router-dom';
import { SoftwareGroup, SoftwareUpdate } from '../../../models/ApiModels';

interface Props {
  group: SoftwareGroup;
  updateGroup: SoftwareGroup;
  setUpdateGroup: (group: SoftwareGroup) => void;
  editMode: boolean;
  softwareUpdate: SoftwareUpdate[];
  openPopup: boolean;
  setOpenPopup: () => void;
}

function SoftwareUpdateGroupDetailsVersions(props: Props) {
  const navigate = useNavigate();

  const LCUSoftwares = props.softwareUpdate.filter((version: SoftwareUpdate) => version.unit === 'LCU');
  const LCPSoftwares = props.softwareUpdate.filter((version: SoftwareUpdate) => version.unit === 'LCP');
  const LSUSoftwares = props.softwareUpdate.filter((version: SoftwareUpdate) => version.unit === 'LSU');

  const assignedLCUVersion = props.softwareUpdate.find(
    (software: SoftwareUpdate) => software.id === props.group.lcuFirmwareId
  )?.version;
  const assignedLCPVersion = props.softwareUpdate.find(
    (software: SoftwareUpdate) => software.id === props.group.lcpFirmwareId
  )?.version;
  const assignedLSUVersion = props.softwareUpdate.find(
    (software: SoftwareUpdate) => software.id === props.group.lsuFirmwareId
  )?.version;
  const noVersionInDropdown = { id: '0', value: 'None' };

  return (
    <div className='flex flex-col'>
      <h1 className='prose-heading4 border-b border-gray-10 pb-5'>Software versions</h1>
      <div className='flex flex-row justify-between border-b border-gray-10 py-5 items-center'>
        <h2 className='prose-paragraphBase mb-1 flex w-full flex-col'>
          <span className='flex gap-2'>LCU</span>
        </h2>
        {!props.editMode ? (
          <>
            {assignedLCUVersion && assignedLCUVersion?.length > 0 ? (
              <Link
                to={'/software/version/' + props.group.lcuFirmwareId}
                className='whitespace-nowrap cursor-pointer underline text-primary-100 hover:text-primary-200'
              >
                {assignedLCUVersion}
              </Link>
            ) : (
              <div className='whitespace-nowrap italic text-gray-40 prose-paragraphBase'>Not assigned</div>
            )}
          </>
        ) : (
          <Dropdown
            size='Medium'
            label={''}
            value={assignedLCUVersion}
            optionsData={[
              noVersionInDropdown,
              ...LCUSoftwares.map((s: SoftwareUpdate) => {
                return { id: s.id, value: s.version };
              }),
            ]}
            onValueChosen={(value: any) => {
              if (value === '0') {
                props.updateGroup.lcuFirmwareId = null;
                props.setUpdateGroup(props.updateGroup);
              } else props.updateGroup.lcuFirmwareId = value;
              props.setUpdateGroup(props.updateGroup);
            }}
            placeholder={'Add version'}
          />
        )}
      </div>

      <div className='flex flex-row justify-between border-b border-gray-10 py-5 items-center'>
        <h2 className='prose-paragraphBase mb-1 flex w-full flex-col'>
          <span className='flex gap-2'>LCP</span>
        </h2>
        {!props.editMode ? (
          <>
            {assignedLCPVersion && assignedLCPVersion?.length > 0 ? (
              <Link
                to={'/software/version/' + props.group.lcpFirmwareId}
                className='whitespace-nowrap cursor-pointer underline text-primary-100 hover:text-primary-200'
              >
                {assignedLCPVersion}
              </Link>
            ) : (
              <div className='whitespace-nowrap italic text-gray-40 prose-paragraphBase'>Not assigned</div>
            )}
          </>
        ) : (
          <Dropdown
            size='Medium'
            label={''}
            value={assignedLCPVersion}
            optionsData={[
              noVersionInDropdown,
              ...LCPSoftwares.map((s: SoftwareUpdate) => {
                return { id: s.id, value: s.version };
              }),
            ]}
            onValueChosen={(value: any) => {
              if (value === '0') {
                props.updateGroup.lcpFirmwareId = null;
                props.setUpdateGroup(props.updateGroup);
              } else props.updateGroup.lcpFirmwareId = value;
              props.setUpdateGroup(props.updateGroup);
            }}
            placeholder={'Add version'}
          />
        )}
      </div>

      <div className='flex flex-row justify-between border-b border-gray-10 py-5 items-center'>
        <h2 className='prose-paragraphBase mb-1 flex w-full flex-col'>
          <span className='flex gap-2'>LSU</span>
        </h2>

        {!props.editMode ? (
          <>
            {assignedLSUVersion && assignedLSUVersion?.length > 0 ? (
              <Link
                to={'/software/version/' + props.group.lsuFirmwareId}
                className='whitespace-nowrap cursor-pointer underline text-primary-100 hover:text-primary-200'
              >
                {assignedLSUVersion}
              </Link>
            ) : (
              <div className='whitespace-nowrap italic text-gray-40 prose-paragraphBase'>Not assigned</div>
            )}
          </>
        ) : (
          <Dropdown
            size='Medium'
            label={''}
            value={assignedLSUVersion}
            optionsData={[
              noVersionInDropdown,
              ...LSUSoftwares.map((s: SoftwareUpdate) => {
                return { id: s.id, value: s.version };
              }),
            ]}
            onValueChosen={(value: any) => {
              if (value === '0') {
                props.updateGroup.lsuFirmwareId = null;
                props.setUpdateGroup(props.updateGroup);
              } else props.updateGroup.lsuFirmwareId = value;
              props.setUpdateGroup(props.updateGroup);
            }}
            placeholder={'Add version'}
          />
        )}
      </div>
    </div>
  );
}

export default SoftwareUpdateGroupDetailsVersions;
