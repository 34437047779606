import { Snackbar } from 'component-library';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../contexts/AppContext';
import { GetInventory } from '../../services/InventoryService';
import useRequest from '../../utils/net/useRequest';
import AddPackageToInventory from './AddFunctionPackage/AddFunctionPackage';

function Inventory() {
  const { currentOem } = useContext(AppContext);
  const [addPackageIsOpen, setAddPackageIsOpen] = useState(false);
  const [fetchCounter, setFetchCounter] = useState(0);
  const [visibleSnackbar, setVisibleSnackbar] = useState(false);
  const [inventory = [], isInventoryLoading, inventoryGetError] = useRequest(() => GetInventory(), [fetchCounter]);

  useEffect(() => {
    const popup = setTimeout(() => {
      setVisibleSnackbar(false);
    }, 9000);
    return () => clearTimeout(popup);
  }, [visibleSnackbar]);

  return (
    <div className='flex px-20 py-11 flex-col'>
      {addPackageIsOpen && (
        <AddPackageToInventory
          close={() => setAddPackageIsOpen(false)}
          addOrderInventory={() => {
            setFetchCounter(fetchCounter + 1);
            setAddPackageIsOpen(false);
          }}
        />
      )}
      <div className='flex flex-row w-full justify-between items-center mb-14'>
        <span className='prose-heading3 text-primary-400'>
          Inventory{' '}
          <span className='prose-paragraphBase text-gray-60'>
            {' '}
            <span className='text-primary-400'>|</span> {currentOem?.name}
          </span>
        </span>
      </div>
      {isInventoryLoading && (
        <div className='flex items-center flex-col gap-4 h-96 justify-center'>
          <span className='text-primary-400 prose-heading5'>Loading inventory...</span>
          <div className='w-12 h-12 border-l-2 border-primary-400 rounded-full animate-spin'></div>
        </div>
      )}
      <div className='self-center w-2/5 mt-10'>
        {visibleSnackbar && (
          <Snackbar
            heading='Your package order has been saved!'
            paragraph='High five!'
            status='success'
            setVisibleSnackbar={setVisibleSnackbar}
            onClick={() => setVisibleSnackbar(false)}
          />
        )}
      </div>
    </div>
  );
}

export default Inventory;
