import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Banner, StatusPill } from 'component-library';
import _ from 'lodash';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { AppContext } from '../../contexts/AppContext';
import { BoatModel, PresetList } from '../../models/ApiModels';
import { GetBoatModel } from '../../services/BoatModelsService';
import { GetBoatModelPresets } from '../../services/PresetsServices';
import BoatModelDetailsTabs from './BoatDetails/BoatModelDetailsTabs';

export type OutletContextTypeBoatDetails = BoatModel | any;

function BoatModelDetailsLayout() {
  const { currentOemTenantId } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const { presetName } = useParams();
  const [boatModel, setBoatModel] = useState<BoatModel>();
  const [presets, setPresets] = useState<PresetList[]>([]);
  const [oldBoatModel, setOldBoatModel] = useState<BoatModel>();
  const [hasError, setHasError] = useState(false);
  const [currentTab, setCurrentTab] = useState(!location.pathname.includes('details') ? '' : 'Details');
  const { id } = useParams();
  const [leavingPage, setLeavingPage] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const navigate = useNavigate();
  const componentMounted = useRef(true);

  useEffect(() => {
    if (id) {
      GetBoatModelDetails();
    }
    return () => {
      componentMounted.current = false;
    };
  }, [id]);

  function GetBoatModelDetails() {
    setLoading(true);
    if (id !== undefined) {
      GetBoatModel(id)
        .then((boat: BoatModel) => {
          if (componentMounted.current) {
            setBoatModel({ ...boat });
            setOldBoatModel(_.cloneDeep(boat));
          }
          GetBoatModelPresets(boat.id, currentOemTenantId)
            .then((presets: PresetList[]) => {
              setPresets([...presets]);
            })
            .catch(() => {
              setHasError(true);
              console.log('Something went wrong!');
            });
        })
        .catch(() => {
          setHasError(true);
          console.log('Something went wrong!');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }

  const selectedPreset = presets.find((preset: PresetList) => preset.id === presetName);

  const hasChanged = useMemo(() => {
    return !_.isEqual(boatModel, oldBoatModel);
  }, [boatModel, oldBoatModel]);

  const missingSpecificationFields = useMemo(() => {
    if (
      boatModel?.length === 0 ||
      boatModel?.beam === 0 ||
      boatModel?.dryWeight === 0 ||
      boatModel?.topSpeed === 0 ||
      boatModel?.cruisingSpeed === 0 ||
      boatModel?.propulsionSystemIds.length === 0
    )
      return true;
    else return false;
  }, [boatModel]);

  function GoBack() {
    if (hasChanged) {
      setOpenPopup(true);
    } else {
      setTimeout(() => {
        navigate('/models');
      }, 1);
    }
  }

  function handlePresetStatusColor(preset: string) {
    switch (preset) {
      case 'Created':
        return 'Light Gray';
      case 'Active':
        return 'Green';
      default:
        return 'Light Red';
    }
  }

  return (
    <div>
      {loading ? (
        <div className='flex items-center flex-col gap-4 h-96 justify-center'>
          <span className='text-primary-400 prose-heading5'>Loading boat model...</span>
          <div className='w-12 h-12 border-l-2 border-primary-400 rounded-full animate-spin'></div>
        </div>
      ) : (
        !hasError &&
        boatModel && (
          <>
            <div>
              {missingSpecificationFields && currentTab === 'Details' && (
                <div className='mb-12'>
                  <Banner
                    icon={true}
                    warning={true}
                    text={'There is data missing on this boat model'}
                    color={'Super Light Orange'}
                  />
                </div>
              )}

              {currentTab !== 'Details' && (
                <div className='flex justify-between items-center mb-10'>
                  <h1 className='prose-heading6 lg:prose-heading5 2xl:prose-heading3 items-center gap-1'>
                    <span
                      className='cursor-pointer'
                      onClick={() => {
                        setLeavingPage(true);
                        GoBack();
                      }}
                    >
                      Boat models
                    </span>
                    <FontAwesomeIcon icon={faChevronRight} className='mx-2' size='xs' />
                    {boatModel?.make}
                    <FontAwesomeIcon icon={faChevronRight} className='mx-2' size='xs' />
                    {boatModel.name}
                    {selectedPreset && (
                      <>
                        <FontAwesomeIcon icon={faChevronRight} className='mx-2' size='xs' />
                        {selectedPreset.name}
                      </>
                    )}
                  </h1>
                  {selectedPreset && (
                    <StatusPill
                      color={handlePresetStatusColor(selectedPreset.status)}
                      text={selectedPreset.status.toString()}
                    />
                  )}
                </div>
              )}
              <BoatModelDetailsTabs currentTab={currentTab} changeTab={(value: string) => setCurrentTab(value)} />
            </div>
            <Outlet
              context={[
                boatModel,
                setBoatModel,
                oldBoatModel,
                GetBoatModelDetails,
                loading,
                hasChanged,
                leavingPage,
                hasError,
                setLoading,
                setOpenPopup,
                openPopup,
                presets,
              ]}
            />
          </>
        )
      )}
    </div>
  );
}

export default BoatModelDetailsLayout;
