import { Link, useLocation } from 'react-router-dom';

enum Type {
  Versions = 'Versions',
  Groups = 'Groups',
}
interface Props {
  currentTab: string;
  changeTab: (value: string) => void;
}

function SoftwareUpdateTabs(props: Props) {
  const location = useLocation();
  return (
    <div className='flex items-center w-full border-b border-gray-10 gap-8 mt-10'>
      <Link
        to='version'
        className={`py-4 cursor-pointer w-max ${
          location.pathname === '/software/version' ||
          (location.pathname === '/software' && 'border-b-2 border-gray-100')
        }`}
        onClick={() => props.changeTab(Type.Versions)}
      >
        <span
          className={`${
            location.pathname.includes('version') || location.pathname === '/software' ? 'font-bold' : 'font-normal'
          } text-primary-400 prose-paragraphBase`}
        >
          Versions
        </span>
      </Link>
      <Link
        to='group'
        className={`py-4 cursor-pointer w-max ${
          location.pathname === '/software/group' && 'border-b-2 border-gray-100'
        }`}
        onClick={() => props.changeTab(Type.Groups)}
      >
        <span
          className={`${
            location.pathname === '/software/group' ? 'font-bold' : 'font-normal'
          } text-primary-400 prose-paragraphBase`}
        >
          Groups
        </span>
      </Link>
    </div>
  );
}

export default SoftwareUpdateTabs;
