function handleOrientation(value) {
  switch (value) {
    case 0:
      return 'Portrait';
    case 1:
      return 'Landscape';
    default:
      return 'Reverse Landscape';
  }
}

function handleTheme(value) {
  switch (value) {
    case 1:
      return 'Dark';
    case 2:
      return 'Light';
    case 3:
      return 'Red';
    case 4:
      return 'Green';
    default:
      return 'Pink';
  }
}

function handleLCUHeadingAngleUnit(value) {
  if (value === 0) return '°';
  else if (value > 0) return '° to port';
  else return '° to starboard';
}

const sensorDataPreset = (systemMetaData) => [
  {
    id: 'axisConfig',
    name: 'LCU orientation',
    value: systemMetaData.lcuMetadata.axisConfig,
    unit: '',
    hasTooltip: true,
  },
  {
    id: 'srt_TurnAng',
    name: 'LCU heading angle',
    value: systemMetaData.lcuMetadata.srt_TurnAng,
    unit: handleLCUHeadingAngleUnit(systemMetaData.lcuMetadata.srt_TurnAng),
    hasTooltip: false,
  },
];
const rudderDataPreset = (systemMetaData) => [
  {
    id: 're_PortVal',
    name: 'Port value',
    value: systemMetaData.lcuMetadata.re_PortVal,
    unit: '',
  },
  {
    id: 're_CenterVal',
    name: 'Center value',
    value: systemMetaData.lcuMetadata.re_CenterVal,
    unit: '',
  },
  {
    id: 're_StbdVal',
    name: 'Starboard value',
    value: systemMetaData.lcuMetadata.re_StbdVal,
    unit: '',
  },
];
const interceptorSetupDataPreset = (systemMetaData) => [
  {
    id: 'servo0_Offset',
    name: 'Port offset',
    value: systemMetaData.lcuMetadata.servo0_Offset,
    unit: '%',
  },
  {
    id: 'servo1_Offset',
    name: 'Starboard offset',
    value: systemMetaData.lcuMetadata.servo1_Offset,
    unit: '%',
  },
];
const speedSourceDataPreset = (systemMetaData) => [
  {
    id: 'prioritizeWaterSpeed',
    name: 'Prioritize waterspeed',
    value: systemMetaData.lcpMetadata.prioritizeWaterSpeed,
    unit: '',
  },
  {
    id: 'allowNoFixGPS',
    name: 'Allow nofix',
    value: systemMetaData.lcpMetadata.allowNoFixGPS,
    unit: '',
  },
];
const autoTrimDataPreset = (systemMetaData) => [
  {
    id: 'at_HumpSpeed',
    name: 'Hump speed',
    value: systemMetaData.lcuMetadata.at_HumpSpeed,
    unit: 'kn',
  },
  {
    id: 'at_Amplitude',
    name: 'Hump speed extension',
    value: systemMetaData.lcuMetadata.at_Amplitude,
    unit: '%',
  },
  {
    id: 'at_EndSpeed',
    name: 'Trim max speed',
    value: systemMetaData.lcuMetadata.at_EndSpeed,
    unit: 'kn',
  },
  {
    id: 'at_EndAmplitude',
    name: 'Trim speed extension',
    value: systemMetaData.lcuMetadata.at_EndAmplitude,
    unit: '%',
  },
];
const listRollDataPreset = (systemMetaData) => [
  {
    id: 'alSensitivity',
    name: 'List sensitivity',
    value: systemMetaData.lcuMetadata.alSensitivity,
    unit: '',
  },
  {
    id: 'OnSpeed',
    name: 'Activation speed',
    value: systemMetaData.lcuMetadata.onSpeed,
    unit: 'kn',
  },
  {
    id: 'OffSpeed',
    name: 'Deactivation speed',
    value: systemMetaData.lcuMetadata.offSpeed,
    unit: 'kn',
  },
  {
    id: 'td_TurnDetectActivationLimit',
    name: 'Turn detect limit',
    value: systemMetaData.lcuMetadata.td_TurnDetectActivationLimit,
    unit: '°/s',
  },
  {
    id: 'td_TurnDetectDeactivationLimit',
    name: 'Turn exit limit',
    value: systemMetaData.lcuMetadata.td_TurnDetectDeactivationLimit,
    unit: '°/s',
  },
  {
    id: 'al_TrueTurnOffset',
    name: 'True turn compensation',
    value: systemMetaData.lcuMetadata.al_TrueTurnOffset,
    unit: '',
  },
  {
    id: 'ar_Sensitivity',
    name: 'Roll sensitivity',
    value: systemMetaData.lcuMetadata.ar_Sensitivity,
    unit: '',
  },
];
const coTurnDataPreset = (systemMetaData) => [
  {
    id: 'ct_RudderDeadBand',
    name: 'Rudder deadband',
    value: systemMetaData.lcuMetadata.ct_RudderDeadBand,
    unit: '%',
  },
  {
    id: 'ct_LowSpeedGain',
    name: 'Low speed gain',
    value: systemMetaData.lcuMetadata.ct_LowSpeedGain,
    unit: '%',
  },
  {
    id: 'ct_TransitionSpeed',
    name: 'Transition speed',
    value: systemMetaData.lcuMetadata.ct_TransitionSpeed,
    unit: 'kn',
  },
  {
    id: 'ct_HighSpeedGain',
    name: 'High speed gain',
    value: systemMetaData.lcuMetadata.ct_HighSpeedGain,
    unit: '%',
  },
];
const pitchDataPreset = (systemMetaData) => [
  {
    id: 'ap_Sensitivity',
    name: 'Pitch sensitivity',
    value: systemMetaData.lcuMetadata.ap_Sensitivity,
    unit: '',
  },
  {
    id: 'ap_PitchAccMaxBowDownStroke',
    name: 'Max bow down stroke',
    value: systemMetaData.lcuMetadata.ap_PitchAccMaxBowDownStroke,
    unit: '%',
  },
  {
    id: 'ap_PitchAccBowDownThreshold',
    name: 'Bow down threshold',
    value: systemMetaData.lcuMetadata.ap_PitchAccBowDownThreshold,
    unit: '',
  },
];
const displayDataPreset = (systemMetaData) => [
  {
    id: 'theme',
    name: 'Theme',
    value: systemMetaData.lcpMetadata.theme,
    displayValue: handleTheme(systemMetaData.lcpMetadata.theme),
    unit: '',
  },
  {
    id: 'orientation',
    name: 'Orientation',
    value: systemMetaData.lcpMetadata.orientation,
    displayValue: handleOrientation(systemMetaData.lcpMetadata.orientation),
    unit: '',
  },
];
export {
  sensorDataPreset,
  rudderDataPreset,
  displayDataPreset,
  interceptorSetupDataPreset,
  speedSourceDataPreset,
  autoTrimDataPreset,
  pitchDataPreset,
  coTurnDataPreset,
  listRollDataPreset,
};
