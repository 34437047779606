import { faClose } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input, InvertButton, Popup, PrimaryButton } from 'component-library';
import _ from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Required } from '../../../helpers/Validation';
import { Inventory } from '../../../models/ApiModels';
import { AddInventory } from '../../../services/InventoryService';

interface Props {
  close: () => void;
  addOrderInventory: () => void;
}

function AddPackageToInventory(props: Props) {
  const [loading, setLoading] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const navigate = useNavigate();

  const initOrder: Inventory = {
    businessCentralOrderNumber: '',
    comfortQuantity: 0,
    comfortPlusQuantity: 0,
    dynamicQuantity: 0,
    dynamicPlusQuantity: 0,
    underWaterLightsQuantity: 0,
  };

  const [newOrder, setNewOrder] = useState<Inventory>(initOrder);

  const saveOrderToInventory = useCallback((newOrder: Inventory) => {
    setLoading(true);
    AddInventory(newOrder)
      .then(() => {
        setLoading(false);
        if (props.addOrderInventory !== undefined) props.addOrderInventory();
      })
      .catch(() => {
        setLoading(false);
        console.log('Something went wrong...');
      });
  }, []);

  function ValidNumber(number?: number) {
    if (number !== undefined && number >= 0) return true;
    return false;
  }

  const validComfortQuantity = useMemo(
    () =>
      newOrder.comfortQuantity === undefined ||
      ValidNumber(newOrder.comfortQuantity),
    [newOrder.comfortQuantity]
  );

  const validComfortPlusQuantity = useMemo(
    () =>
      newOrder.comfortQuantity === undefined ||
      ValidNumber(newOrder.comfortPlusQuantity),
    [newOrder.comfortPlusQuantity]
  );

  const validDynamiqQuantity = useMemo(
    () =>
      newOrder.comfortQuantity === undefined ||
      ValidNumber(newOrder.dynamicQuantity),
    [newOrder.dynamicQuantity]
  );

  const validDynamiqPlusQuantity = useMemo(
    () =>
      newOrder.comfortQuantity === undefined ||
      ValidNumber(newOrder.dynamicPlusQuantity),
    [newOrder.dynamicPlusQuantity]
  );

  const validUnderWaterLightsQuantity = useMemo(
    () =>
      newOrder.comfortQuantity === undefined ||
      ValidNumber(newOrder.underWaterLightsQuantity),
    [newOrder.underWaterLightsQuantity]
  );

  const validQuantity = useMemo(() => {
    return (
      validComfortQuantity &&
      validComfortPlusQuantity &&
      validDynamiqQuantity &&
      validDynamiqPlusQuantity &&
      validUnderWaterLightsQuantity &&
      Required(newOrder.businessCentralOrderNumber)
    );
  }, [newOrder]);

  return (
    <div className='fixed left-0 top-0 right-0 bottom-0 w-screen flex justify-center overflow-y-auto z-50'>
      <div className='fixed w-screen h-screen bottom-0 bg-gray-100 opacity-50 overlay'></div>
      <div className='bg-white pb-10 pt-7 px-16 mt-20 mb-10 opacity-100 z-10 create-system relative shadow-popup popup flex flex-col items-center'>
        <div className='flex h-full w-full flex-col overflow-auto'>
          <span className='prose-heading3 text-primary-400 py-8 text-center'>
            Add package
          </span>
          <div className='w-1/3 mb-10'>
            <Input
              value={newOrder.businessCentralOrderNumber}
              label='Order number'
              onChange={(value: string) => {
                newOrder.businessCentralOrderNumber = value;
                setNewOrder({ ...newOrder });
              }}
            />
          </div>
          <div className=''>
            <div className='flex justify-between pb-4'>
              <span className='prose-heading4'>Functions</span>
              <span className='prose-heading4 w-1/4'>Quantity</span>
            </div>
            <div className='flex flex-col'>
              <div className='flex justify-between items-center border-b border-gray-10 pb-2 pt-2'>
                <span className='prose-paragraphBig'>Comfort</span>
                <div className='w-1/4'>
                  <Input
                    type='number'
                    error={!validComfortQuantity}
                    value={newOrder.comfortQuantity}
                    explanation='Not a valid amount'
                    onChange={(value: number) => {
                      newOrder.comfortQuantity = value;
                      setNewOrder({ ...newOrder });
                    }}
                  />
                </div>
              </div>
              <div className='flex justify-between items-center border-b border-gray-10 pb-2 pt-2'>
                <span className='prose-paragraphBig'>Comfort +</span>
                <div className='w-1/4'>
                  <Input
                    type='number'
                    error={!validComfortPlusQuantity}
                    explanation='Not a valid amount'
                    value={newOrder.comfortPlusQuantity}
                    onChange={(value: number) => {
                      newOrder.comfortPlusQuantity = value;
                      setNewOrder({ ...newOrder });
                    }}
                  />
                </div>
              </div>
              <div className='flex justify-between items-center border-b border-gray-10 pb-2 pt-2'>
                <span className='prose-paragraphBig'>Dynamic</span>
                <div className='w-1/4'>
                  <Input
                    type='number'
                    value={newOrder.dynamicQuantity}
                    error={!validDynamiqQuantity}
                    explanation='Not a valid amount'
                    onChange={(value: number) => {
                      newOrder.dynamicQuantity = value;
                      setNewOrder({ ...newOrder });
                    }}
                  />
                </div>
              </div>
              <div className='flex justify-between items-center border-b border-gray-10 pb-2 pt-2'>
                <span className='prose-paragraphBig'>Dynamic +</span>
                <div className='w-1/4'>
                  <Input
                    type='number'
                    value={newOrder.dynamicPlusQuantity}
                    error={!validDynamiqPlusQuantity}
                    explanation='Not a valid amount'
                    onChange={(value: number) => {
                      newOrder.dynamicPlusQuantity = value;
                      setNewOrder({ ...newOrder });
                    }}
                  />
                </div>
              </div>
              <div className='flex justify-between items-center pb-2 pt-2'>
                <span className='prose-paragraphBig'>Underwater lights</span>
                <div className='w-1/4'>
                  <Input
                    type='number'
                    value={newOrder.underWaterLightsQuantity}
                    error={!validUnderWaterLightsQuantity}
                    explanation='Not a valid amount'
                    onChange={(value: number) => {
                      newOrder.underWaterLightsQuantity = value;
                      setNewOrder({ ...newOrder });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          {!loading && (
            <div className='w-full flex justify-between items-end h-full pt-10'>
              <InvertButton onClick={() => props.close()} label='Discard' />
              <PrimaryButton
                disabled={!validQuantity}
                onClick={() => {
                  saveOrderToInventory(newOrder);
                  null;
                }}
                label='Create order'
                size='small'
              />
            </div>
          )}
          {!loading && (
            <div
              onClick={() => props.close()}
              className='flex justify-center items-center w-11 h-11 bg-primary-100 rounded-full text-white absolute -right-3 -top-3 cursor-pointer shadow-close'
            >
              <FontAwesomeIcon icon={faClose} title='Close' />
            </div>
          )}
          {loading && (
            <div className='w-full flex justify-center items-center h-full'>
              <div className='w-12 h-12 border-l-2 border-primary-400 rounded-full animate-spin'></div>
            </div>
          )}
        </div>
      </div>
      {openPopup && (
        <Popup
          confirm={() => navigate('/')}
          close={() => setOpenPopup(false)}
          heading='You have unsaved changes.'
          paragraph="Do you want to discard any changes you've made?"
          status='error'
          cancelText='Go back'
          confirmText='Discard'
        />
      )}
    </div>
  );
}
export default AddPackageToInventory;
