import { faBandAid } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StatusPill } from 'component-library';
import { useOutletContext } from 'react-router-dom';
import { GetStatusColor } from '../../../../helpers/Custom';
import { BoatModel } from '../../../../models/ApiModels';
interface Props {
  boatModel: BoatModel;
}
export default function ConfigurationPatch(props: Props) {
  const [system] = useOutletContext<any>();
  function handlePatchStatus(activeText: string) {
    function hasMatch(arr1: any, arr2: any) {
      if (!arr1 || !arr2) return false;
      return arr1.some((obj1: any) => arr2.some((obj2: any) => obj1.id === obj2.id));
    }
    const result = hasMatch(props.boatModel.patches, system.patches);
    if (result === true) return activeText;
    else return 'Pending';
  }

  return (
    <div>
      <div className='border-b border-gray-10'>
        <h4 className='prose-heading4 pb-4'>
          <FontAwesomeIcon icon={faBandAid} className='mr-1' />
          Patch
        </h4>
      </div>
      <div className='flex flex-row justify-between border-b border-gray-10 py-5 items-center w-full whitespace-nowrap'>
        <div className='prose-paragraphBase mb-1 flex flex-row w-full whitespace-pre-wrap gap-2'>
          <span>Patch status</span>
        </div>
        <span>
          <StatusPill color={GetStatusColor(handlePatchStatus('Active'))} text={handlePatchStatus('Installed')} />
        </span>
      </div>
    </div>
  );
}
