import { faBars } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';

function Layout() {
  const [showMenu, setShowMenu] = useState(window.innerWidth >= 768 ? true : false);

  return (
    <div className='bg-white h-screen flex flex-grow'>
      <Sidebar showMenu={showMenu} setShowMenu={() => setShowMenu(!showMenu)} />
      <div className='relative flex flex-col md:px-2 lg:px-20 md:py-2 lg:py-14 w-full overflow-y-auto'>
        {/* Hamburger */}
        <div className='sticky flex top-0 w-full z-50 pr-4 bg-gray-5 shadow-popup md:hidden'>
          <div
            className={`transition duration-500 ${showMenu ? 'text-black bg-white' : 'bg-black text-white'} p-4`}
            onClick={() => setShowMenu(!showMenu)}
          >
            <FontAwesomeIcon icon={faBars} size='2x' />
          </div>
          <div className='flex ml-auto'>
            <img src='/humphree-logo-black.png' className=' object-contain' />
          </div>
        </div>
        <div className='m-6'>
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default Layout;
