import { Tooltip } from 'component-library';
import { ChangeEvent, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppContext } from '../../../../contexts/AppContext';
import { Oem, PaymentTerm } from '../../../../models/ApiModels';

interface Props {
  orderReference: string;
  setOrderReference: (value: string) => void;
  oemId: string;
}

function OrderDetails(props: Props) {
  const { currentOem } = useContext(AppContext);
  const paymentTerms = useSelector((state: any) => state.configurations.paymentTerms);
  const oems = useSelector((state: any) => state.oems.data);

  const [characterCount, setCharacterCount] = useState<number>(props.orderReference.length);

  function GetPaymentTerm() {
    if (currentOem !== undefined) {
      const paymentTerm: PaymentTerm = paymentTerms.find(
        (paymentTerm: PaymentTerm) => paymentTerm.id === currentOem?.businessCentralPaymentTermsId
      );
      return paymentTerm?.name;
    } else {
      const oem: Oem = oems.find((oem: Oem) => oem.id === props.oemId);

      const paymentTerm: PaymentTerm = paymentTerms.find(
        (paymentTerm: PaymentTerm) => paymentTerm.id === oem?.businessCentralPaymentTermsId
      );
      return paymentTerm?.name;
    }
  }

  return (
    <div>
      <h1 className='prose-heading4 border-b border-gray-10 pb-5 pt-14'>Order details</h1>
      <div className='border-b border-gray-10 pb-6'>
        <div className='max-w-[700px] pt-12 flex flex-col gap-3 prose-paragraphSmaller'>
          <span>
            When the order has been placed, an order confirmation will be sent by email and the license upgrade will get
            reserved to the system instantly. The reservation will be shown as “Pending License” in the System status.
          </span>
          <span>
            To install the new upgrade, the Lightning control panel must be connected to internet. When the control
            panel is connected to a Wi-Fi netwrook with internet, it will automatically install the new license.
          </span>
        </div>
      </div>

      <div className='flex justify-between pt-12 items-center'>
        <span className='flex gap-2'>
          Your order reference{' '}
          <Tooltip
            description='If you as a Humphree customer have an internal order reference that you would like to use for this license upgrade, it can be added in this free text field. It will default to the Control Unit serial number.'
            darkmode
          />
        </span>
        <div className='w-[250px]'>
          <input
            maxLength={18}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setCharacterCount(e.target.value.length);
              props.setOrderReference(e.target.value);
            }}
            placeholder='Enter your order reference...'
            className={`w-full max-w-[400px] bg-gray-3 prose-paragraphSmall ${
              characterCount === 18 && 'border border-warning-100'
            } px-3 h-10`}
            value={props.orderReference}
          />
        </div>
      </div>
      <p
        className={`prose-paragraphTiny text-right pt-1 pb-3 border-b border-gray-10 ${
          characterCount === 18 ? 'text-warning-100' : 'text-black'
        }`}
      >
        Max characters ({characterCount}/18)
      </p>
      <div className='flex justify-between pt-12 border-b border-gray-10 pb-5'>
        <span className='flex gap-2'>
          Payment terms <Tooltip description='The payment terms are pre-defined for license upgrade orders.' darkmode />
        </span>
        <div>{GetPaymentTerm()}</div>
      </div>
    </div>
  );
}

export default OrderDetails;
