import { faChevronRight, faPenToSquare, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InvertButton, Popup, PrimaryButton, StatusButton } from 'component-library';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { SoftwareUpdate } from '../../../models/ApiModels';
import {
  DeleteSoftwareUpdateFile,
  GetSoftwareGroups,
  GetSoftwareUpdateInfo,
  UpdateSoftwareUpdateFile,
} from '../../../services/SoftwareUpdateService';
import { ActionType } from '../../../store/actionTypes';
import useRequest from '../../../utils/net/useRequest';
import SoftwareUpdateDetailsDescription from './SoftwareUpdateDetailsDescription';
import SoftwareUpdateDetailsFile from './SoftwareUpdateDetailsFile';
import SoftwareUpdateDetailsGroups from './SoftwareUpdateDetailsGroups';
import SoftwareUpdateDetailsInfo from './SoftwareUpdateDetailsInfo';

function SoftwareUpdateDetails() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [edit, setEdit] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [software, setSoftware] = useState<SoftwareUpdate>();
  const [softwareGroups = [], isSoftwareGroupsLoading] = useRequest(() => GetSoftwareGroups(), []);
  const [updateSoftware, setUpdateSoftware] = useState<SoftwareUpdate>();
  const componentMounted = useRef(true);
  const navigate = useNavigate();

  useEffect(() => {
    GetSoftwareInfo();
    return () => {
      componentMounted.current = false;
    };
  }, [id]);

  useEffect(() => {
    GetSoftwareInfo();
  }, [id]);

  const hasChanges = useMemo(() => {
    return !_.isEqual(software, updateSoftware);
  }, [updateSoftware]);

  function GetSoftwareInfo() {
    setLoading(true);
    if (id !== undefined) {
      GetSoftwareUpdateInfo(id).then((software: SoftwareUpdate) => {
        if (componentMounted.current) {
          setSoftware({ ...software });
          setUpdateSoftware(_.cloneDeep(software));
        }
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }

  const UpdateSoftwareInfo = useCallback(() => {
    setLoading(true);
    if (updateSoftware !== undefined) {
      UpdateSoftwareUpdateFile(updateSoftware)
        .then(() => {
          dispatch({
            type: ActionType.SET_SNACKBAR,
            payload: { heading: 'Successfully updated software!', status: 'success' },
          });
          setTimeout(() => {
            setEdit(false);
            GetSoftwareInfo();
          }, 1);
        })
        .catch(() => {
          console.log('Something went wrong!');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [updateSoftware]);

  const DeleteSoftware = useCallback(() => {
    setOpenDeletePopup(false);
    setLoading(true);
    if (software !== undefined) {
      DeleteSoftwareUpdateFile(software)
        .then(() => {
          dispatch({
            type: ActionType.SET_SNACKBAR,
            payload: { heading: 'Software version successfully deleted', status: 'success' },
          });
          navigate('/software');
        })
        .catch((error) => {
          dispatch({
            type: ActionType.SET_SNACKBAR,
            payload: { heading: error.message, status: 'error' },
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [software]);

  function GoBack() {
    if (hasChanges) {
      setOpenPopup(true);
    } else {
      navigate('/software');
    }
  }

  function DiscardChanges() {
    if (!_.isEqual(software, updateSoftware) && software) {
      setUpdateSoftware({ ...software });
    }
    setOpenPopup(false);
    setEdit(false);
  }
  return (
    <div>
      <div>
        {loading ? (
          <div className='flex items-center flex-col gap-4 h-96 justify-center'>
            <span className='text-primary-400 prose-heading5'>Loading software details...</span>
            <div className='w-12 h-12 border-l-2 border-primary-400 rounded-full animate-spin'></div>
          </div>
        ) : software ? (
          <>
            <div className='flex justify-between items-center mb-10'>
              <h1 className='md:prose-heading4 2xl:prose-heading3 items-center gap-1'>
                <span className='cursor-pointer' onClick={() => GoBack()}>
                  Version
                </span>
                <>
                  <FontAwesomeIcon icon={faChevronRight} className='mx-2' size='xs' />
                  {software.systemType}
                </>
                <>
                  <FontAwesomeIcon icon={faChevronRight} className='mx-2' size='xs' />
                  {software.unit}
                </>
                <FontAwesomeIcon icon={faChevronRight} className='mx-2' size='xs' />
                {software.version}
              </h1>
            </div>
            <div className='w-full lg:ml-auto lg:w-fit flex flex-col gap-2 flex-wrap md:flex-nowrap md:flex-row lg:sticky top-0 right-0 z-10 my-6 lg:mt-11 lg:-mb-11'>
              {!edit ? (
                <PrimaryButton label={'Edit software'} icon={faPenToSquare} onClick={() => setEdit(true)} fullWidth />
              ) : (
                <>
                  <div className='w-full bg-white'>
                    <InvertButton
                      label={'Leave edit'}
                      onClick={() => {
                        if (!hasChanges) {
                          setEdit(false);
                        } else setOpenPopup(true);
                      }}
                      fullWidth
                    />
                  </div>
                  <PrimaryButton
                    label={'Save software'}
                    disabled={!hasChanges}
                    onClick={() => {
                      setSoftware({ ...software });
                      UpdateSoftwareInfo();
                    }}
                    fullWidth
                  />
                </>
              )}
            </div>
            <div className='flex flex-col gap-16 pt-12 md:pt-2'>
              <SoftwareUpdateDetailsInfo
                software={software}
                editMode={edit}
                updateSoftware={updateSoftware}
                setUpdateSoftware={(updateSoftware: SoftwareUpdate) => setUpdateSoftware({ ...updateSoftware })}
              />
              <SoftwareUpdateDetailsDescription
                software={software}
                editMode={edit}
                updateSoftware={updateSoftware}
                setUpdateSoftware={(updateSoftware: SoftwareUpdate) => setUpdateSoftware({ ...updateSoftware })}
              />
              <SoftwareUpdateDetailsFile software={software} />
              <SoftwareUpdateDetailsGroups software={software} groups={softwareGroups} />
              <div className='flex items-center flex-col w-full lg:w-fit lg:flex-row gap-4 mt-4 ml-auto'>
                <span className='prose-buttonStandard /lg:w-max'>
                  Software versions needs to be unnassigned from groups before deletion
                </span>
                <div className='w-full'>
                  <StatusButton
                    onClick={() => setOpenDeletePopup(true)}
                    icon={faTrash}
                    label='Delete software version'
                    status={'error'}
                    fullWidth
                  />
                </div>
              </div>
            </div>
            {openPopup && (
              <Popup
                close={() => setOpenPopup(false)}
                confirm={() => DiscardChanges()}
                heading='Discard software changes?'
                paragraph='You will lose all the changes made to this software.'
                status='error'
                cancelText='Keep editing'
                confirmText='Discard changes'
              />
            )}
            {openDeletePopup && (
              <Popup
                close={() => setOpenDeletePopup(false)}
                confirm={() => DeleteSoftware()}
                heading='Delete software version?'
                paragraph='Yeah, there’s probably a new and more fancy version out anyway. This will wipe it from the database, but you can always upload it again.'
                status='error'
                cancelText='Cancel'
                confirmText='Delete software version'
              />
            )}
          </>
        ) : (
          <>error</>
        )}
      </div>
    </div>
  );
}

export default SoftwareUpdateDetails;
