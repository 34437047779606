import { Dispatch } from 'redux';
import { BoatModel, Configurations, PaymentTerm, System } from '../models/ApiModels';
import { GetBoatModels } from '../services/BoatModelsService';
import { GetPaymentTerms } from '../services/BusinessCentralService';
import { GetConfigurations } from '../services/ConfigurationService';
import { GetSystemsAsync } from '../services/SystemService';
import { ActionType } from './actionTypes';
import { RootState } from './reducers/combine';

async function StoreInit(dispatch: Dispatch, state: RootState) {
  const isOemSpecific = false;
  await GetSystemsAsync()
    .then((system: System[]) => {
      dispatch({
        type: ActionType.GET_SYSTEMS,
        payload: system,
      });
    })
    .catch(() => {
      console.log('error');
    });
  await GetBoatModels(isOemSpecific)
    .then((boatModels: BoatModel[]) => {
      dispatch({
        type: ActionType.GET_BOATMODELS,
        payload: boatModels,
      });
    })
    .catch(() => {
      console.log('error');
    });

  if (state.propulsionSystems.propulsionSystems.length === 0) {
    await GetConfigurations()
      .then((configurations: Configurations) => {
        dispatch({
          type: ActionType.GET_PROPULSIONSYSTEMS,
          payload: configurations.propulsionSystems,
        });
        dispatch({
          type: ActionType.ADD_PACKAGES,
          payload: configurations.packages,
        });
        dispatch({
          type: ActionType.ADD_FEATURES,
          payload: configurations.features,
        });
        dispatch({
          type: ActionType.ADD_ITEMS,
          payload: configurations.items,
        });
        dispatch({
          type: ActionType.ADD_UPGRADES,
          payload: configurations.upgrades,
        });
      })
      .catch(() => {
        console.log('error');
      });
  }
  if (state.configurations.paymentTerms.length === 0) {
    await GetPaymentTerms()
      .then((paymentTerms: PaymentTerm[]) => {
        dispatch({
          type: ActionType.ADD_PAYMENT_TERMS,
          payload: paymentTerms,
        });
      })
      .catch(() => {
        console.log('error');
      });
  }
}

export default StoreInit;
