import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StatusPill } from 'component-library';
import { useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Dashboard } from '../../models/ApiModels';
import PieChart from './PieChart';

interface Props {
  dashboardData: Dashboard;
  compare: boolean;
}

function DashboardDataSystems(props: Props) {
  const navigate = useNavigate();

  const today = new Date();
  const lastConnectedSystems = props.dashboardData.lastConnectedSystems.systemConnections.map((item) => {
    const isToday = (date: Date) => {
      return (
        date.getDate() == today.getDate() &&
        date.getMonth() == today.getMonth() &&
        date.getFullYear() == today.getFullYear()
      );
    };
    const lastConnected = useMemo(() => {
      if (item.connectedAt) {
        const latestConnectionDate = new Date(item.connectedAt);
        if (isToday(latestConnectionDate))
          return (
            'Today at ' +
            latestConnectionDate.getHours() +
            ':' +
            (latestConnectionDate.getMinutes() < 10 ? '0' : '') +
            latestConnectionDate.getMinutes()
          );
        else {
          const difference = today.getTime() - latestConnectionDate.getTime();
          const totalDays = Math.ceil(difference / (1000 * 3600 * 24));
          return totalDays.toString() + (totalDays == 1 ? ' day ago' : ' days ago');
        }
      }
    }, [item.connectedAt]);

    const lastConnectedStatusColor = useMemo(() => {
      if (item.connectedAt) {
        const latestConnectionDate = new Date(item.connectedAt);
        if (isToday(latestConnectionDate)) {
          return 'Light Blue';
        } else {
          const difference = today.getTime() - latestConnectionDate.getTime();
          const totalDays = Math.ceil(difference / (1000 * 3600 * 24));
          if (totalDays > 249 && totalDays < 500) return 'Warning';
          else if (totalDays > 499) return 'Light Red';
          else return 'Green';
        }
      }

      return 'Green';
    }, [item.connectedAt]);

    return (
      <div key={item.systemId} className='flex justify-center items-center w-full gap-4 mb-4'>
        <div className='prose-labelStandard w-20'>{item.systemId}</div>
        {lastConnected && <StatusPill fixedSize color={lastConnectedStatusColor} text={lastConnected} />}
        <Link to={`../systems/${item.systemId}`}>
          <FontAwesomeIcon icon={faArrowRight} size='lg' className='cursor-pointer' />
        </Link>
      </div>
    );
  });

  return (
    <>
      <div className='prose-heading4 mb-5 mt-10'>Systems</div>
      <div className='md:flex md:flex-wrap gap-4'>
        <div className='bg-gray-3 pt-4 rounded-lg mb-4 md:mb-0 flex-1'>
          <div className='prose-heading5 text-center mt-4'>System statuses</div>
          {props.dashboardData && (
            <PieChart
              compare={props.compare}
              series={[
                props.dashboardData.systems.statusCreated,
                props.dashboardData.systems.statusConfigured,
                props.dashboardData.systems.statusInstalling,
                props.dashboardData.systems.statusActive,
                props.dashboardData.systems.statusPendingLicense,
              ]}
              colors={['#646868', '#E6E8E8', '#9BE6EE', '#BFE5A7', '#FEB1B1']}
              labels={['Created', 'Configured', 'Installing', 'Active', 'Pending License']}
              showMarkers={false}
            />
          )}
        </div>
        <div className='bg-gray-3 pt-4 rounded-lg mb-4 md:mb-0 min-w-[410px] flex-1'>
          <div className='prose-heading5 text-center mt-4 mb-4'>Last connected</div>
          <div>{lastConnectedSystems}</div>
        </div>
      </div>
    </>
  );
}

export default DashboardDataSystems;
