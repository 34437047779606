import { faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'component-library';
import advancedMenusImg from '../../../../assets/lcpIcons/advanced.png';
import listImg from '../../../../assets/lcpIcons/autolisttest-01.png';
import autoTrimImg from '../../../../assets/lcpIcons/autotrimtest-01.png';
import sensitivityImg from '../../../../assets/lcpIcons/baseline_tune_black_24dp.png';
import coTurnImg from '../../../../assets/lcpIcons/coordinatedturn-new-01.png';
import extBusImg from '../../../../assets/lcpIcons/extbus.png';
import functionSetupImg from '../../../../assets/lcpIcons/functionsetup-new-01.png';
import gpsSimImg from '../../../../assets/lcpIcons/gpsselection-new-01.png';
import interceptorImg from '../../../../assets/lcpIcons/interceptorsetup-01.png';
import nmeaSimImg from '../../../../assets/lcpIcons/NMEA-R-Blue-293-x-214-compressed 2.png';
import systemSetupImg from '../../../../assets/lcpIcons/panelsettings.png';
import rudderImg from '../../../../assets/lcpIcons/ruddersignalnew-01.png';
import sensorImg from '../../../../assets/lcpIcons/sensorsetup-01.png';
import speedImg from '../../../../assets/lcpIcons/speedsource-01.png';
import pitchImg from '../../../../assets/lcpIcons/waves-01.png';
import { MenuVisibility } from '../../../../models/ApiModels';
import {
  presetVisibilityAdvancedData,
  presetVisibilityFunctionData,
  presetVisibilitySystemData,
} from '../../../DataExports/presetVisibilityData';

interface Props {
  data: MenuVisibility;
}

export default function ConfigurationVisibilitySettings(props: Props) {
  const visibilitySystem = presetVisibilitySystemData(props.data as any, {
    sensorImg: sensorImg,
    signalImg: rudderImg,
    setupImg: interceptorImg,
    speedImg: speedImg,
    extBusImg: extBusImg,
  });

  const visibilityFunction = presetVisibilityFunctionData(props.data as any, {
    sensitivityImg: sensitivityImg,
    autoTrimImg: autoTrimImg,
    listImg: listImg,
    coTurnImg: coTurnImg,
    pitchImg: pitchImg,
  });

  const visibilityAdvanced = presetVisibilityAdvancedData(props.data as any, {
    nmeaSimImg: nmeaSimImg,
    gpsSimImg: gpsSimImg,
  });

  return (
    <div className='flex-1'>
      <h4 className='prose-heading4 pb-6 mb-5 border-b border-gray-10'>Setting visibility</h4>
      <div className='flex justify-between border-b border-gray-10 py-4'>
        <div className='flex gap-1'>
          Setting unlock password
          <Tooltip
            heading='Setting unlock password'
            description='Use this password in the Advanced > Login menu in the LCP, to enable every setting to be set again. When the system restarts the settings will be hidden again.'
            darkmode
          />
        </div>
        <p>{props.data && props.data.password}</p>
      </div>
      <div className='flex flex-wrap gap-20 mt-10 pb-10 border-b border-gray-10'>
        <div>
          <h5 className='prose-heading5 pb-4'>
            <img src={systemSetupImg} className='h-6 inline mr-1 object-contain' />
            System visibility
          </h5>
          {props.data !== undefined &&
            visibilitySystem.map((item) => {
              return (
                <div className='flex items-center gap-2 my-2' key={item.key}>
                  <img src={item.img} className={`${!item.value ? 'opacity-100' : 'opacity-40'}`} />
                  <h6 className={`prose-heading6 flex-1 ${!item.value ? 'text-black' : 'text-gray-40'}`}>{item.key}</h6>
                  {!item.value ? (
                    <FontAwesomeIcon icon={faEye} className='w-8' />
                  ) : (
                    <FontAwesomeIcon icon={faEyeSlash} className='w-8 text-gray-40' />
                  )}
                </div>
              );
            })}
        </div>
        <div>
          <h5 className='prose-heading5 pb-4'>
            <img src={functionSetupImg} className='h-6 inline mr-1 /object-contain' />
            Function visibility
          </h5>
          {props.data !== undefined &&
            visibilityFunction.map((item) => {
              return (
                <div className='flex items-center gap-2 my-2' key={item.key}>
                  <img src={item.img} className={`${!item.value ? 'opacity-100' : 'opacity-40'}`} />
                  <h6 className={`prose-heading6 flex-1 ${!item.value ? 'text-black' : 'text-gray-40'}`}>{item.key}</h6>
                  {!item.value ? (
                    <FontAwesomeIcon icon={faEye} className='w-8' />
                  ) : (
                    <FontAwesomeIcon icon={faEyeSlash} className='w-8 text-gray-40' />
                  )}
                </div>
              );
            })}
        </div>
        <div>
          <h5 className='prose-heading5 pb-4'>
            <img src={advancedMenusImg} className='h-6 inline mr-1 /object-contain' />
            Advanced menus
          </h5>
          {props.data !== undefined &&
            visibilityAdvanced.map((item) => {
              return (
                <div className='flex items-center gap-2 my-2' key={item.key}>
                  <img src={item.img} className={`${!item.value ? 'opacity-100' : 'opacity-40'}`} />
                  <h6 className={`prose-heading6 flex-1 ${!item.value ? 'text-black' : 'text-gray-40'}`}>{item.key}</h6>
                  {!item.value ? (
                    <FontAwesomeIcon icon={faEye} className='w-8' />
                  ) : (
                    <FontAwesomeIcon icon={faEyeSlash} className='w-8 text-gray-40' />
                  )}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}
