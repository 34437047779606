import { Input, InvertButton, PrimaryButton } from 'component-library';
import { ChangeEvent } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Preset } from '../../../../../models/ApiModels';
import { RootState } from '../../../../../store/reducers/combine';
import { ConfigurationFunctionSetupSettings } from '../ConfigurationFunctionSetupSettings';
import { ConfigurationOtherSettings } from '../ConfigurationOtherSettings';
import { ConfigurationSystemSetupSettings } from '../ConfigurationSystemSetupSettings';

interface CreatePresetProps {
  preset: Preset;
  setPreset: (preset: Preset) => void;
  setPage: () => void;
  sensorData: any;
  rudderData: any;
  interceptorData: any;
  speedData: any;
  autoTrimData: any;
  listData: any;
  coTurnData: any;
  pitchData: any;
  displayData: any;
}

export default function CreatePresetPartOne(props: CreatePresetProps) {
  const boats = useSelector((state: RootState) => state.boatModels.boatModels);
  const navigate = useNavigate();

  return (
    <div className='flex flex-col gap-16'>
      <div className='flex flex-col'>
        <h4 className='prose-heading4'>Part 1 of 2 - Preset data</h4>
      </div>
      <div>
        <h4 className='prose-heading4'>Boat model</h4>
        <p className='prose-paragraphBase py-2'>
          Verify that this is the boat model you want to create the preset for. If it’s not, change the boat model of
          the system.
        </p>
        <div className='flex justify-between items-center border-y border-gray-10'>
          <span className='prose-paragraphBase py-4'>Assigned boat model</span>
          <span>{boats.find((boat) => boat.id === props.preset.boatModelId)?.name}</span>
        </div>
      </div>
      <div>
        <h4 className='prose-heading4 border-b border-gray-10 pb-4'>Preset information</h4>
        <div className='flex flex-col py-4 gap-4 border-b border-gray-10'>
          <Input
            size='Large'
            label='Preset Name'
            placeholder='Enter text input...'
            mandatory
            value={props.preset.name}
            onChange={(value: string) => {
              props.preset.name = value;
              props.setPreset({ ...props.preset });
            }}
          />
          <p>Description</p>
          <textarea
            className='bg-gray-3 max-w-[460px] min-h-[110px] resize-none -mt-2 p-2 prose-paragraphSmaller outline-none'
            placeholder='Add description...'
            value={props.preset.description}
            onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
              props.preset.description = event.target.value;
              props.setPreset({ ...props.preset });
            }}
          ></textarea>
        </div>
      </div>
      <div>
        <h4 className='prose-heading4'>Select settings</h4>
        <p className='prose-paragraphBase py-2 border-b border-gray-10'>
          Select what settings that should be included into the preset. Settings not selected will use system default.
        </p>
      </div>
      <ConfigurationSystemSetupSettings
        sensorData={props.sensorData}
        rudderData={props.rudderData}
        interceptorSetupData={props.interceptorData}
        speedSourceData={props.speedData}
        selectBoxes
        preset={props.preset}
        setPreset={(preset: Preset) => props.setPreset({ ...preset })}
      />
      <ConfigurationFunctionSetupSettings
        autoTrimData={props.autoTrimData}
        listRollData={props.listData}
        coTurnData={props.coTurnData}
        pitchData={props.pitchData}
        selectBoxes
        preset={props.preset}
        setPreset={(preset: Preset) => props.setPreset({ ...preset })}
      />
      <ConfigurationOtherSettings
        displayData={props.displayData}
        selectBoxes
        preset={props.preset}
        setPreset={(preset: Preset) => props.setPreset({ ...preset })}
      />
      <div className='flex gap-2 ml-auto'>
        <InvertButton label={'Cancel'} onClick={() => navigate(-1)} />
        <PrimaryButton label={'Continue '} onClick={() => props.setPage()} disabled={props.preset.name === ''} />
      </div>
    </div>
  );
}
