import ServiceHelper from '../helpers/ServiceHelper';
import { Dashboard } from '../models/ApiModels';

export async function GetDashboard() {
  return new Promise<Dashboard>((resolve, reject) => {
    ServiceHelper({
      method: 'GET',
      url: 'v1/dashboard',
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function GetDashboardCompare(date: string, dateComparison: string) {
  return new Promise<Dashboard>((resolve, reject) => {
    ServiceHelper({
      method: 'GET',
      url: `v1/dashboard/compare?date=${date}&comparisonDate=${dateComparison}`,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
