import { createColumnHelper } from '@tanstack/react-table';
import { HardwareSystem } from '../../models/ApiModels';
import RemoveGroup from './SoftwareUpdateGroupDetails.tsx/RemoveConnectedGroup';

type SystemsList = {
  connectedSystemId?: string;
  serialNumber?: number;
  system: HardwareSystem;
};

export function SoftwareUpdateGroupsConnectedSystemsListSetup(systems: HardwareSystem[]) {
  const systemList: SystemsList[] = systems.map((system: HardwareSystem) => {
    return {
      connectedSystemId: system.connectedSystemId,
      serialNumber: system.controlUnitSerial,
      system: system,
    };
  });

  return systemList;
}

const columnHelper = createColumnHelper<SystemsList>();

export const connectedSystemsListColumns = [
  columnHelper.accessor('serialNumber', {
    header: 'S/N',
    cell: (info) => {
      return <div className='truncate font-code prose-dataBaseDataLabel'>{info.getValue()}</div>;
    },
    size: 1500,
  }),
  columnHelper.accessor('connectedSystemId', {
    header: 'Humphree-ID',
    cell: (info) => info.getValue(),
    size: 1500,
  }),
  columnHelper.accessor('system', {
    header: 'Remove from group',
    cell: (info) => <RemoveGroup system={info.getValue()} />,
  }),
];
