import { Link, useLocation } from 'react-router-dom';

enum Type {
  SystemDetails = 'System Details',
  Configuration = 'Configuration',
  EventLog = 'Event Log',
}

interface Props {
  currentTab: string;
  changeTab: (value: string) => void;
}

function SystemDetailsTabs(props: Props) {
  const location = useLocation();

  return (
    <div className='flex items-center w-full border-b border-gray-10 gap-8 mt-10'>
      <Link
        to='details'
        className={`py-4 cursor-pointer w-max ${
          !location.pathname.includes('eventlog') &&
          !location.pathname.includes('configuration') &&
          'border-b-2 border-gray-100'
        }`}
        onClick={() => props.changeTab(Type.SystemDetails)}
      >
        <span
          className={`${
            !location.pathname.includes('eventlog') && !location.pathname.includes('configuration')
              ? 'font-bold'
              : 'font-normal'
          } text-primary-400 prose-paragraphBase`}
        >
          System details
        </span>
      </Link>
      <Link
        to='configuration'
        className={`py-4 cursor-pointer w-max ${
          location.pathname.includes('configuration') && 'border-b-2 border-gray-100'
        }`}
        onClick={() => props.changeTab(Type.Configuration)}
      >
        <span
          className={`${
            location.pathname.includes('configuration') ? 'font-bold' : 'font-normal'
          } text-primary-400 prose-paragraphBase`}
        >
          Configuration
        </span>
      </Link>
      <Link
        to='eventlog'
        className={`py-4 cursor-pointer w-max ${
          location.pathname.includes('eventlog') && 'border-b-2 border-gray-100'
        }`}
        onClick={() => props.changeTab(Type.EventLog)}
      >
        <span
          className={`${
            location.pathname.includes('eventlog') ? 'font-bold' : 'font-normal'
          } text-primary-400 prose-paragraphBase`}
        >
          Log
        </span>
      </Link>
    </div>
  );
}

export default SystemDetailsTabs;
