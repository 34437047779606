import { useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Preset } from '../../../../../models/ApiModels';
import {
  autoTrimData,
  coTurnData,
  displayData,
  interceptorSetupData,
  listRollData,
  pitchData,
  rudderData,
  sensorData,
  speedSourceData,
} from '../../../../DataExports/dataSetsData';
import CreatePresetPartOne from './CreatePresetPartOne';
import CreatePresetPartTwo from './CreatePresetPartTwo';

export default function SystemDetailsConfigurationCreatePreset() {
  const formatDate = (date: Date | string) => date.toString().slice(0, 10);
  const [system] = useOutletContext<any>();

  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [preset, setPreset] = useState<Preset>({
    id: '',
    name: '',
    boatModelId: system.boatModelId,
    systemId: system.id,
    description: '',
    status: 0,
    createdDate: new Date(),
    lcpMetadata: {
      fileName: '',
      createdDate: new Date(),
    },
    lcuMetadata: {
      fileName: '',
      createdDate: new Date(),
      menuVisibility: {
        sensor: false,
        rudder: false,
        interceptorOffset: false,
        speedSource: false,
        extbus: false,
        sensitivity: false,
        autoTrim: false,
        listAndRollControl: false,
        coordinatedTurn: false,
        pitchControl: false,
        nmea2000Sim: false,
        gpsSim: false,
        password: '',
      },
    },
  });

  const importedSensorData = sensorData(system);
  const ImportedRudderData = rudderData(system);
  const importedInterceptorSetupData = interceptorSetupData(system);
  const importedSpeedSourceData = speedSourceData(system);
  const importedAutoTrimData = autoTrimData(system);
  const importedListRollData = listRollData(system);
  const importedCoTurnData = coTurnData(system);
  const importedPitchData = pitchData(system);
  const importedDisplayData = displayData(system);

  return (
    <div>
      {loading ? (
        <div className='w-full flex justify-center items-center h-full'>
          <div className='w-12 h-12 border-l-2 border-primary-400 rounded-full animate-spin'></div>
        </div>
      ) : (
        <>
          {page === 1 ? (
            <CreatePresetPartOne
              sensorData={importedSensorData}
              preset={preset}
              setPreset={(preset: any) => setPreset({ ...preset })}
              setPage={() => setPage(page + 1)}
              rudderData={ImportedRudderData}
              interceptorData={importedInterceptorSetupData}
              speedData={importedSpeedSourceData}
              autoTrimData={importedAutoTrimData}
              listData={importedListRollData}
              coTurnData={importedCoTurnData}
              pitchData={importedPitchData}
              displayData={importedDisplayData}
            />
          ) : (
            <CreatePresetPartTwo
              preset={preset}
              setPreset={(pres: any) => setPreset({ ...pres })}
              setPage={() => setPage(page - 1)}
            />
          )}
        </>
      )}
    </div>
  );
}
