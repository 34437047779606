import { faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { PrimaryButton } from 'component-library';
import { useState } from 'react';
import LCUImg from '../../assets/LCU.svg';
import { Warranty } from '../../models/ApiModels';
import WarrantyFormConfigInfo from './WarrantyFormConfigInfo';
import WarrantyFormSubmit from './WarrantyFormSubmit';
import WarrantyFormSystemInfo from './WarrantyFormSystemInfo';

function WarrantyForm() {
  const initWarrantyForm: Warranty = {
    controlUnitSerialNumber: '',
    controlPanelSerialNumber: '',
    rudderSignal: false,
    hullNumber: '',
    submissionerEmail: '',
    systemVoltage: '',
    boatDeliveryDate: undefined,
  };
  const initCheckList = {
    isServoCalibrated: false,
    isFuseSize16: false,
    hasGPSSignal: false,
    isTrimAndListCalibrated: false,
    isOrientationCorrect: false,
    hasConsent: false,
    hasCorrectDetails: false,
  };

  const [checkList, setCheckList] = useState(initCheckList);
  const [newWarranty, setNewWarranty] = useState<Warranty>(initWarrantyForm);
  const [page, setPage] = useState(0);

  return (
    <div className='font-montserrat flex gap-10 px-4 py-11 flex-col items-center text-center max-w-[400px] m-auto sm:my-10'>
      {page === 0 && (
        <>
          <div>
            <img src='/humphree-logo-black.png' className='m-auto mb-4' />
            <span className='prose-heading3 text-primary-400'>Lightning Control System warranty form</span>
          </div>
          <div className='prose-paragraphBase'>
            By submitting this form, you confirm that the setup for the Lightning system has been successfully performed
            and that the system is entitled to the extended 24-month warranty from the date of boat delivery.
          </div>
          <div className='-mb-7 bg-primary-200 text-white prose-heading3 rounded-full p-2 flex justify-center items-center w-14'>
            1
          </div>
          <div className='text-left'>
            <span className='font-bold'>
              Prior to starting the system, make sure that the power polarity is correct
            </span>
            <br /> - The red cable with a black stripe should be connected to the MINUS (negative terminal-)
            <br /> - The solid red cable should be connected to the PLUS (positive terminal+)
          </div>
          <div className='-mb-7 bg-primary-200 text-white prose-heading3 rounded-full p-2 flex justify-center items-center w-14'>
            2
          </div>
          <div>
            Verify that each interceptor is connected to the corresponding Control Unit (LCU) connector named STB
            (Starboard) and PORT (Port side).
            <div className='h-40 flex my-2'>
              <img src={LCUImg} alt='' />
            </div>
          </div>
          <div className='w-full flex justify-between'>
            <PrimaryButton
              onClick={() => setPage(page - 1)}
              disabled={page === 0}
              icon={faChevronLeft}
              label='Go back'
            />
            <PrimaryButton onClick={() => setPage(page + 1)} iconAfter icon={faChevronRight} label='Continue' />
          </div>
        </>
      )}
      {page === 1 && (
        <WarrantyFormSystemInfo
          key={page}
          page={page}
          setPage={(value: number) => setPage(value)}
          warrantyForm={newWarranty}
          setNewWarranty={(warranty: Warranty) => setNewWarranty({ ...warranty })}
        />
      )}
      {page === 2 && (
        <WarrantyFormConfigInfo
          key={page}
          page={page}
          setPage={(value: number) => setPage(value)}
          warrantyForm={newWarranty}
          checkList={checkList}
          setCheckList={(optional) => setCheckList({ ...optional })}
          setNewWarranty={(warranty: Warranty) => setNewWarranty({ ...warranty })}
        />
      )}
      {page === 3 && (
        <WarrantyFormSubmit
          key={page}
          page={page}
          setPage={(value: number) => setPage(value)}
          warrantyForm={newWarranty}
          checkList={checkList}
          setCheckList={(optional) => setCheckList({ ...optional })}
          setNewWarranty={(warranty: Warranty) => setNewWarranty({ ...warranty })}
        />
      )}
    </div>
  );
}

export default WarrantyForm;
