import { faArrowRight, faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Options } from 'component-library/lib/esm/models/ComponentModels';
import { useEffect, useState } from 'react';
import { useDetectClickOutside } from 'react-detect-click-outside';

interface DropDownProps {
  value?: string;
  firstData: Options[];
  secondData: Options[];
  placeholder: string;
  onValueChosen: (id?: string) => void;
}

export default function SelectBoatMakeDropdown(props: DropDownProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [originalData, setOriginalData] = useState(props.firstData);
  const [newData, setNewData] = useState(props.secondData);
  const [data, setData] = useState(originalData);
  const [isOriginalData, setIsOriginalData] = useState(true);
  const [selectedValue, setSelectedValue] = useState(props.value ? props.value : undefined);
  useEffect(() => {
    if (!props.value) {
      setSelectedValue(props.value);
    }
  }, [props.value, selectedValue]);

  const ref = useDetectClickOutside({
    onTriggered: () => setIsOpen(false),
  });

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const toggleData = () => {
    if (isOriginalData) {
      setData(newData);
    } else {
      setData(originalData);
    }
    setIsOriginalData(!isOriginalData);
  };

  const handleValueSelect = (value: any) => {
    setSelectedValue(value);
    toggleDropdown();
  };

  return (
    <div ref={ref}>
      <div className='relative'>
        <button
          className='w-full h-10 px-4 py-2 bg-gray-3 prose-paragraphSmaller outline-0 gap-2 whitespace-nowrap overflow-hidden flex justify-between items-center'
          onClick={toggleDropdown}
        >
          <span className={`${!selectedValue && 'text-gray-40'}`}>
            {selectedValue ? selectedValue : props.placeholder}
          </span>
          <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} />
        </button>
        {isOpen && (
          <div className='absolute bg-white py-1 mt-2 m-0 w-full z-10 shadow-dropdown border border-gray-10 max-h-[200px] overflow-auto'>
            <ul>
              {data.length > 0 ? (
                data.map((opt) => (
                  <li
                    className='py-2 px-4 flex w-full text-gray-100 prose-paragraphSmaller hover:bg-gray-5 cursor-pointer'
                    key={opt.id}
                    onClick={() => {
                      props.onValueChosen && props.onValueChosen(opt.id);
                      handleValueSelect(opt.value);
                    }}
                  >
                    {opt.value}
                  </li>
                ))
              ) : (
                <div className='py-2 px-4 flex w-full prose-paragraphSmaller italic text-gray-40 cursor-default'>
                  Nothing here
                </div>
              )}
              <li
                onClick={toggleData}
                className='py-2 px-4 flex w-full text-gray-100 prose-paragraphSmaller hover:bg-gray-5 cursor-pointer'
              >
                <button>
                  <span className='font-bold mr-2'>
                    Select from {data.length === props.secondData.length ? 'own' : 'all'} brands
                  </span>
                  <FontAwesomeIcon icon={faArrowRight} />
                </button>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}
