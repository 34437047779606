import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { ControlUnit } from '../../../models/ApiModels';
import { GetLCUSerialNumbeAsync } from '../../../services/UnitService';
import useRequest from '../../../utils/net/useRequest';
import HardwareUnitsDetailsLog from './HardwareUnitsDetailsLog';

function HardwareUnitsDetailsLCU() {
  const navigate = useNavigate();
  const { serial } = useParams();
  const [lcu, isLoading, getError] = useRequest<ControlUnit>(() => {
    if (serial !== undefined) return GetLCUSerialNumbeAsync(serial);
  });

  useEffect(() => {
    if (getError) navigate(-1);
  }, [getError]);

  return (
    <div className='max-w-[2000px]'>
      {!getError ? (
        <div>
          {isLoading ? (
            <div className='flex items-center flex-col gap-4 h-96 justify-center'>
              <span className='text-primary-400 prose-heading5'>Loading unit...</span>
              <div className='w-12 h-12 border-l-2 border-primary-400 rounded-full animate-spin'></div>
            </div>
          ) : (
            <div>
              <div className='md:prose-heading4 2xl:prose-heading3 items-center gap-1 mb-14'>
                Lightning Control Unit
                <FontAwesomeIcon icon={faChevronRight} className='mx-2' size='xs' />
                {serial}
              </div>
              <div className='prose-heading4 pb-5 border-b border-gray-10'>General information</div>
              <div className='flex py-5 justify-between border-b border-gray-10'>
                <div>Part number</div>
                <div>{lcu?.articleNumber}</div>
              </div>
              <div className='flex py-5 justify-between border-b border-gray-10'>
                <div>Software version</div>
                <div>{lcu?.firmwareVersion}</div>
              </div>
              <HardwareUnitsDetailsLog lcu={lcu} />
            </div>
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default HardwareUnitsDetailsLCU;
