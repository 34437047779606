import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { PrimaryButton } from 'component-library';
import { useNavigate } from 'react-router-dom';

export const SettingsCustomers = () => {
  const navigate = useNavigate();

  return (
    <div className='flex flex-col pt-16'>
      <h4 className='prose-heading4 pb-2'>Customers</h4>
      <p className='prose-paragraphBase text-gray-60 max-w-[52ch] mb-3'>
        Add your customers to the portal.
        <br />
        <br />
        This feature enables you to add one or more customers to the portal. They will be created as a separate OEM that
        you can manage, assign systems and boat models to, invite users, and buy upgrades to system they own. When you
        have created your first customer, a drop down will be available in the side menu.
        <br />
        <br />
        For more support, contact your Humphree representative if you are interested in how this feature works and wants
        to set it up.
      </p>
      <PrimaryButton
        icon={faPlus}
        label='Add customer'
        onClick={() => {
          navigate('/create/customer/');
        }}
      />
    </div>
  );
};
