import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  close: () => void;
}

export default function OrderTermsAndConditions(props: Props) {
  return (
    <div className='fixed left-0 top-0 right-0 bottom-0 w-screen flex justify-center overflow-y-auto z-10'>
      <div className='fixed w-screen h-screen bottom-0 bg-gray-100 opacity-50 overlay'></div>
      <div className='bg-white pb-16 pt-16 opacity-100 z-10 h-max min-h-[70%] xl:max-w-[50%] justify-start relative shadow-popup popup flex flex-col items-center 2xl:mt-28 mt-20 mb-10 px-16'>
        <span className='text-primary-400 prose-heading3 mb-10'>Payment terms</span>
        <span className='text-primary-400 prose-paragraphSmall mb-3'>
          Payment for purchases made through the portal will be invoiced under the same payment terms as the customer
          has agreed upon with Humphree during their purchase of the hardware system.
        </span>
        <ol className='text-primary-400 prose-paragraphSmall px-4'>
          <li className='mb-1'>
            Pricing for the services offered through the portal will be based on the customer&apos;s agreed-upon
            discount on the items. The current pricing will be displayed in the portal before an order is confirmed. If
            there are any discrepancies in the pricing, the customer is advised to contact their Humphree sales
            representative for assistance.
          </li>
          <li className='mb-1'>
            Invoices for purchases made through the portal will follow the same due dates and late payment fees as the
            customer&apos;s initial Humphree payment terms.
          </li>
          <li className='mb-1'>
            The refund policy for purchases made through the portal is handled by the Humphree sales representative. The
            customer is responsible for any purchases made by themselves or any users they have granted access to within
            the portal.
          </li>
          <li className='mb-1'>
            There are no additional fees or charges associated with using the portal&apos;s order placement features.
          </li>
          <li className='mb-1'>
            The portal&apos;s order placement features follow the same policies as the customer&apos;s initial Humphree
            payment terms.
          </li>
          <li className='mb-1'>
            Please note that purchases made through the portal are immediately available and processed once an order is
            completed. Upgrades to a Humphree system, which include new licenses with new tiers of function packages
            and/or additional features, are immediately available for download to the customer&apos;s Humphree system.
          </li>
        </ol>
        <span className='text-primary-400 prose-paragraphSmall mt-3'>
          If you have any further questions or concerns regarding the payment terms for the portal, please contact us at
          hello@humphree.com.
        </span>
        <div
          onClick={() => props.close()}
          className='flex justify-center items-center w-11 h-11 bg-primary-100 rounded-full text-white absolute -right-3 -top-3 cursor-pointer shadow-close'
        >
          <FontAwesomeIcon icon={faXmark} title='Close' />
        </div>
      </div>
    </div>
  );
}
