import { OemIndirect } from '../../models/ApiModels';
import FunctionPackagesPrices from './FunctionPackagesPrices';
type PriceProps = {
  newIndirectOem: OemIndirect;
  handleChange: (e: any) => void;
  handlePriceChange: (item: any, index: any) => void;
};
export const CreateIndirectCustomerPricing = ({ newIndirectOem, handleChange, handlePriceChange }: PriceProps) => {
  return (
    <div className='grid gap-5'>
      <h4 className='prose-heading4'>Upgrade prices</h4>
      <p className='prose-paragraphBase pb-3  max-w-[52ch]'>
        Input prices and currency displayed for the customer when purchasing function package upgrades for the portal.
        You will still be invoiced your agreed upon price from Humphree.
        <br />
        <br />
        If a customer upgrades from one package to another, the difference will be displayed.
      </p>
      <FunctionPackagesPrices
        newIndirectOem={newIndirectOem}
        handlePriceChange={handlePriceChange}
        handleChange={handleChange}
      />
    </div>
  );
};
