import { Tooltip } from 'component-library';
import { ChangeEvent, useEffect, useState } from 'react';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { System } from '../../../models/ApiModels';
import { GetSerialNumbersAsync } from '../../../services/UnitService';

interface Props {
  setNewSystem: (newSystem: System) => void;
  newSystem: System;
}

interface SerialNumber {
  value: number;
  label: number;
}

function CreateSystemHardware(props: Props) {
  const [serialNumbers, setSerialNumbers] = useState<SerialNumber[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [showResult, setShowResult] = useState(false);
  const [searchResult, setSearchResult] = useState<SerialNumber[]>([]);

  const ref = useDetectClickOutside({
    onTriggered: () => {
      setSearchValue('');
      setShowResult(false);
    },
  });

  function FreewordFilter(filteredList: SerialNumber[]) {
    filteredList = filteredList.filter((serialNumber) => {
      return serialNumber.label.toString().startsWith(searchValue);
    });
    setSearchResult(filteredList);
  }

  useEffect(() => {
    FreewordFilter(serialNumbers);
  }, [searchValue]);

  useEffect(() => {
    let isMounted = true;
    GetSerialNumbersAsync().then((response: number[]) => {
      const numbers = response.map((number: number, index: number) => {
        return { value: index, label: number };
      });
      if (isMounted) setSerialNumbers(numbers);
    });
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div className='w-full flex flex-col pb-16'>
      <span className='border-b w-full prose-heading4 border-gray-10 pb-5'>Humphree hardware</span>
      <div className='pt-4 flex flex-col gap-6 relative'>
        <div>
          <div className='flex gap-2'>
            <span className='prose-paragraphBase mb-2'>
              LCU serial number <span className=' text-error-100'>*</span>
            </span>
            <Tooltip
              description='The serial number of a Lightning control unit is the identifier for Humphree hardware systems. The number contain max seven digits, e.g., 1234567.'
              darkmode
            />
          </div>
          <input
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setSearchValue(e.target.value);
              setShowResult(true);
              props.newSystem.controlUnitSerialNumber = 0;
              props.setNewSystem({ ...props.newSystem });
            }}
            placeholder='Enter serial number...'
            className='bg-gray-3 prose-paragraphSmaller outline-0 px-3 h-10 w-1/3'
            value={
              serialNumbers.find(
                (serialNumber: SerialNumber) => serialNumber.label === props.newSystem.controlUnitSerialNumber
              )?.label || searchValue
            }
          />
          {searchValue.length > 0 && showResult && (
            <div
              ref={ref}
              className='bg-white border scrollbar shadow-popup border-gray-10 mt-1 p-2 max-h-[250px] overflow-auto absolute z-[100] w-full'
            >
              {searchResult?.map((result: SerialNumber) => {
                return (
                  <div
                    key={result.value}
                    onClick={() => {
                      props.newSystem.controlUnitSerialNumber = result.label;
                      props.setNewSystem({ ...props.newSystem });
                      setShowResult(false);
                    }}
                    className='px-3 py-3 hover:bg-gray-5 cursor-pointer'
                  >
                    {result.label}
                  </div>
                );
              })}
              {searchResult.length === 0 && 'Serial number does not exist.'}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CreateSystemHardware;
