import { StatusPill } from 'component-library';
import { EventLog, System } from '../../../../models/ApiModels';
import LogEventTimeData from './EventLogsTimeUserData';

interface Props {
  system?: System;
  event: EventLog;
  isUsersLoading: boolean;
  user: string;
  isHumphreeUser: boolean;
}

export default function LogLicenseFeatureChanged(props: Props) {
  return (
    <div className='flex lg:flex-nowrap flex-wrap justify-between items-center border-b border-gray-10'>
      <div className='py-8 flex flex-wrap flex-row items-center gap-2'>
        <span className=' flex prose-paragraphBase text-gray-100'>License upgraded from </span>
        {props.event.arguments[0].split(',').map((argument: string, index: number) => {
          if (argument) return <StatusPill key={index} color='Light Red' text={argument} />;
          else return <StatusPill key={index} color='Light Red' text='Manual' />;
        })}
        <span className=' flex prose-paragraphBase text-gray-100'> to </span>
        {props.event.arguments[1].split(',').map((argument: string, index: number) => {
          if (argument) return <StatusPill key={index} color='Green' text={argument} />;
          else return <StatusPill key={index} color='Green' text='Manual' />;
        })}
      </div>
      <LogEventTimeData
        event={props.event}
        isUsersLoading={props.isUsersLoading}
        user={props.user}
        isHumphreeUser={props.isHumphreeUser}
      />
    </div>
  );
}
