import { System } from '../../models/ApiModels';
import { Action, ActionType } from '../actionTypes';

interface State {
  systems: System[];
}

const initialState: State = {
  systems: [],
};

const systemsReducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case ActionType.GET_SYSTEMS:
      return {
        systems: action.payload,
      };
    case ActionType.ADD_SYSTEM: {
      const newArray = [...state.systems, action.payload]; //Copying state array
      return {
        systems: newArray,
      };
    }
    case ActionType.UPDATE_SYSTEM: {
      const updatedArray = state.systems.map((system) => {
        return system.id === action.payload.id ? { ...system, ...action.payload } : system;
      });
      return {
        systems: updatedArray,
      };
    }
    case ActionType.DELETE_SYSTEM: {
      const deleteArray = state.systems.filter((system: System) => system.id !== action.payload); //Copying state array
      return {
        systems: deleteArray,
      };
    }

    default:
      return state;
  }
};

export default systemsReducer;
