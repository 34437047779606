import { useState } from 'react';
import { BoatModel } from '../../../models/ApiModels';
import Beam from './BoatModelDetailsSpecifications/Beam';
import CruisingSpeed from './BoatModelDetailsSpecifications/CruisingSpeed';
import Flybridge from './BoatModelDetailsSpecifications/Flybridge';
import Length from './BoatModelDetailsSpecifications/Length';
import PropulsionSystems from './BoatModelDetailsSpecifications/PropulsionSystems';
import TopSpeed from './BoatModelDetailsSpecifications/TopSpeed';
import Weight from './BoatModelDetailsSpecifications/Weight';

type Props = {
  boatModel: BoatModel;
  editMode: boolean;
  setUpdateBoatModel: (boatModel: BoatModel) => void;
  updateBoatModel: BoatModel;
  setIsValid: (value: boolean) => void;
};

function BoatModelDetailsSpecifications(props: Props) {
  const [isMetric, setIsMetric] = useState(true);
  const [isKnots, setIsKnots] = useState(true);

  return (
    <div>
      <span className='prose-heading4'>Specifications</span>
      <Length
        boatModel={props.boatModel}
        editMode={props.editMode}
        setUpdateBoatModel={(updateBoatModel: BoatModel) => props.setUpdateBoatModel(updateBoatModel)}
        updateBoatModel={props.updateBoatModel}
        isMetric={isMetric}
        setIsMetric={(value: boolean) => setIsMetric(value)}
        setIsValid={(value: boolean) => props.setIsValid(value)}
      />
      <Beam
        boatModel={props.boatModel}
        editMode={props.editMode}
        setUpdateBoatModel={(updateBoatModel: BoatModel) => props.setUpdateBoatModel(updateBoatModel)}
        updateBoatModel={props.updateBoatModel}
        isMetric={isMetric}
        setIsMetric={(value: boolean) => setIsMetric(value)}
        setIsValid={(value: boolean) => props.setIsValid(value)}
      />
      <Weight
        boatModel={props.boatModel}
        editMode={props.editMode}
        setUpdateBoatModel={(updateBoatModel: BoatModel) => props.setUpdateBoatModel(updateBoatModel)}
        updateBoatModel={props.updateBoatModel}
        isMetric={isMetric}
        setIsMetric={(value: boolean) => setIsMetric(value)}
        setIsValid={(value: boolean) => props.setIsValid(value)}
      />
      <TopSpeed
        boatModel={props.boatModel}
        editMode={props.editMode}
        setUpdateBoatModel={(updateBoatModel: BoatModel) => props.setUpdateBoatModel(updateBoatModel)}
        isKnots={isKnots}
        setIsKnots={(value: boolean) => setIsKnots(value)}
        updateBoatModel={props.updateBoatModel}
        setIsValid={(value: boolean) => props.setIsValid(value)}
      />
      <CruisingSpeed
        boatModel={props.boatModel}
        editMode={props.editMode}
        setUpdateBoatModel={(updateBoatModel: BoatModel) => props.setUpdateBoatModel(updateBoatModel)}
        isKnots={isKnots}
        setIsKnots={(value: boolean) => setIsKnots(value)}
        updateBoatModel={props.updateBoatModel}
        setIsValid={(value: boolean) => props.setIsValid(value)}
      />
      <PropulsionSystems
        boatModel={props.boatModel}
        editMode={props.editMode}
        setUpdateBoatModel={(updateBoatModel: BoatModel) => props.setUpdateBoatModel(updateBoatModel)}
        updateBoatModel={props.updateBoatModel}
      />
      <Flybridge
        boatModel={props.boatModel}
        editMode={props.editMode}
        setUpdateBoatModel={(updateBoatModel: BoatModel) => props.setUpdateBoatModel(updateBoatModel)}
        updateBoatModel={props.updateBoatModel}
      />
    </div>
  );
}

export default BoatModelDetailsSpecifications;
