import { Snack } from '../../models/AppModels';
import { Action, ActionType } from '../actionTypes';

interface State {
  snackbar: Snack;
}

const initialState: State = {
  snackbar: {
    heading: '',
    status: '',
  },
};

const snackbarReducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case ActionType.SET_SNACKBAR:
      return {
        snackbar: action.payload,
      };

    default:
      return state;
  }
};

export default snackbarReducer;
