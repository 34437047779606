import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import SoftwareUpdateTabs from './SoftwareUpdateTabs';

export function SoftwareUpdate() {
  const [currentTab, setCurrentTab] = useState('Versions');

  return (
    <div className='max-w-[2000px]'>
      <div className='prose-heading3 mb-10'>Software update</div>
      <>
        <SoftwareUpdateTabs currentTab={currentTab} changeTab={(value: string) => setCurrentTab(value)} />
        <Outlet />
      </>
    </div>
  );
}
