import { InvertButton, PrimaryButton, Tooltip } from 'component-library';
import _ from 'lodash';
import { ChangeEvent, useContext, useEffect, useMemo, useState } from 'react';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { useSelector } from 'react-redux';
import { AppContext } from '../../../contexts/AppContext';
import { Required } from '../../../helpers/Validation';
import { BoatModel, Oem } from '../../../models/ApiModels';

interface Props {
  newBoatModel: BoatModel;
  setNewBoatModel: (boatModel: BoatModel) => void;
  boatModels: BoatModel[];
  page: number;
  setPage: (value: number) => void;
  GoBack: () => void;
}

function CreateBoatModelInformation(props: Props) {
  const { currentOemTenantId } = useContext(AppContext);

  const [searchValueOEM, setSearchValueOEM] = useState<string>('');
  const [showResultOEM, setShowResultOEM] = useState(false);
  const [searchResultOem, setSearchResultOEM] = useState<Oem[]>([]);
  const [searchValueMake, setSearchValueMake] = useState<string>('');
  const [showResultMake, setShowResultMake] = useState(false);
  const [searchResultMake, setSearchResultMake] = useState<BoatModel[]>([]);
  const oems = useSelector((state: any) => state.oems.data);
  const [characterCountMake, setCharacterCountMake] = useState<string>('');
  const [characterCountName, setCharacterCountName] = useState<string>('');

  const nonValidBoatName = useMemo(() => {
    return props.boatModels
      .filter((model: BoatModel) => model.oemTenantId === props.newBoatModel.oemTenantId)
      .some((model: BoatModel) => model.name.toLowerCase() === props.newBoatModel.name.toLowerCase());
  }, [props.newBoatModel.name, props.newBoatModel.oemTenantId]);

  const validBoatModel = useMemo(() => {
    return (
      Required(props.newBoatModel.name) &&
      Required(props.newBoatModel.make) &&
      Required(props.newBoatModel.oemTenantId) &&
      !nonValidBoatName
    );
  }, [props.newBoatModel, nonValidBoatName]);

  const refOEM = useDetectClickOutside({
    onTriggered: () => {
      setSearchValueOEM('');
      setShowResultOEM(false);
    },
  });
  const refMake = useDetectClickOutside({
    onTriggered: () => {
      setSearchValueMake('');
      setCharacterCountMake('');
      setCharacterCountName('');
      setShowResultMake(false);
    },
  });

  function FreewordOEMFilter(filteredList: Oem[]) {
    filteredList = filteredList.filter((oem) => {
      const flatObject = Object.values(oem);
      return flatObject.some(
        (object: object) => object && object.toString().toLowerCase().includes(searchValueOEM.toLowerCase())
      );
    });
    setSearchResultOEM(filteredList);
  }
  function FreewordMakeFilter(filteredList: BoatModel[]) {
    const oemList = filteredList.filter((oem: BoatModel) => oem.oemTenantId === currentOemTenantId);

    filteredList = filteredList.filter((boat: BoatModel) => {
      const makeValue = boat.make;
      const flatObject = Object.values(boat);
      return flatObject.some(
        () => makeValue && makeValue.toString().toLowerCase().includes(searchValueMake.toLowerCase())
      );
    });

    if (currentOemTenantId !== '12345678-1234-1234-1234-123456789000') {
      setSearchResultMake(oemList);
    }
    setSearchResultMake(filteredList);
  }

  useEffect(() => {
    FreewordOEMFilter(oems);
  }, [searchValueOEM]);
  useEffect(() => {
    FreewordMakeFilter(props.boatModels);
  }, [searchValueMake]);

  return (
    <div className='w-full flex flex-col pb-16'>
      <div className='flex flex-col mb-14'>
        <span className='prose-heading4 text-primary-400 py-3'>Step 1 of 2 - Information</span>
        <span className='prose-paragraphBig border-b border-gray-10 py-3'>Mandatory fields for boat information</span>
      </div>
      <div className='flex flex-col'>
        <div className='flex flex-col gap-4 border-b border-gray-10 pb-6 pt-6'>
          {currentOemTenantId === '12345678-1234-1234-1234-123456789000' && (
            <div className=''>
              <div className='flex gap-2'>
                <span className='prose-paragraphBase mb-2'>
                  OEM <span className=' text-error-100'>*</span>
                </span>
              </div>
              <input
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setSearchValueOEM(e.target.value);
                  setShowResultOEM(true);
                  props.newBoatModel.oemTenantId = '';
                  props.setNewBoatModel({ ...props.newBoatModel });
                }}
                placeholder='Search for OEM...'
                className='w-full md:max-w-[400px] bg-gray-3 prose-paragraphSmall outline-0 px-3 h-10'
                value={oems.find((oem: Oem) => oem.id === props.newBoatModel.oemTenantId)?.name || searchValueOEM}
              />
              {searchValueOEM.length > 0 && showResultOEM && (
                <div
                  ref={refOEM}
                  className='bg-white border scrollbar shadow-popup border-gray-10 mt-1 p-2 max-h-[250px] overflow-auto absolute z-[100] w-full max-w-[400px]'
                >
                  {searchResultOem?.map((result: Oem) => {
                    return (
                      <div
                        key={result.id}
                        onClick={() => {
                          props.newBoatModel.oemTenantId = result.id;
                          props.setNewBoatModel({ ...props.newBoatModel });
                          setShowResultOEM(false);
                        }}
                        className='px-3 py-3 hover:bg-gray-5 cursor-pointer'
                      >
                        {result.name}
                      </div>
                    );
                  })}
                  {searchResultOem.length === 0 && 'No OEM was found.'}
                </div>
              )}
            </div>
          )}
          <div>
            <div className='flex gap-2'>
              <span className='prose-paragraphBase mb-2'>
                Brand / Make name <span className=' text-error-100'>*</span>
              </span>
              <Tooltip
                darkmode
                description='The brand or make of the boat. You will get the option to add model name afterwards'
              />
            </div>
            <input
              maxLength={30}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setSearchValueMake(e.target.value);
                setShowResultMake(true);
                setCharacterCountMake(e.target.value);
                props.newBoatModel.make = e.target.value;
                props.setNewBoatModel({ ...props.newBoatModel });
              }}
              placeholder='Search for boat brand...'
              className={`w-full md:max-w-[400px] bg-gray-3 prose-paragraphSmall ${
                characterCountMake.length > 29 && 'border border-warning-100'
              } px-3 h-10`}
              value={searchValueMake}
            />
            <span className={`prose-paragraphTiny md:ml-2 ${characterCountMake.length > 29 && 'text-warning-100'}`}>
              ({characterCountMake.length}/30)
            </span>
            {searchValueMake.length > 0 && showResultMake && (
              <div
                ref={refMake}
                className='bg-white border scrollbar shadow-popup border-gray-10 mt-1 p-2 max-h-[250px] overflow-auto absolute z-[100] w-full max-w-[400px]'
              >
                {_.uniqBy<BoatModel>(searchResultMake, 'make').map((result: BoatModel) => {
                  return (
                    <div
                      key={result.id}
                      onClick={() => {
                        props.newBoatModel.make = result.make;
                        setSearchValueMake(result.make);
                        props.setNewBoatModel({ ...props.newBoatModel });
                        setShowResultMake(false);
                      }}
                      className='px-3 py-3 hover:bg-gray-5 cursor-pointer'
                    >
                      {result.make}
                    </div>
                  );
                })}
                {searchResultMake.length === 0 && (
                  <div onClick={() => setShowResultMake(false)} className='px-3 py-3 hover:bg-gray-5 cursor-pointer'>
                    No Brand was found. Adding <strong>{searchValueMake}</strong>
                  </div>
                )}
              </div>
            )}
          </div>
          <div>
            <div className='flex gap-2'>
              <span className='prose-paragraphBase mb-2'>
                Model name <span className=' text-error-100'>*</span>
              </span>
              <Tooltip darkmode description='The actual model name of the boat, without the brand name.' />
            </div>
            <input
              maxLength={30}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                props.newBoatModel.name = e.target.value;
                props.setNewBoatModel({ ...props.newBoatModel });
                setCharacterCountName(e.target.value);
              }}
              placeholder='Enter boat model name...'
              className={`w-full md:max-w-[400px] bg-gray-3 prose-paragraphSmall ${
                characterCountMake.length > 29 && 'border border-warning-100'
              } px-3 h-10`}
              value={props.newBoatModel.name}
            />
            <span className={`prose-paragraphTiny md:ml-2 ${characterCountName.length > 29 && 'text-warning-100'}`}>
              ({characterCountName.length}/30)
            </span>
          </div>
        </div>
      </div>
      <div className='flex flex-col items-center mx-auto sm:flex-row sm:justify-end sm:items-start sm:mx-0 gap-2 pt-10'>
        <InvertButton onClick={() => props.GoBack()} label='Cancel' />
        <PrimaryButton
          disabled={!validBoatModel}
          onClick={() => props.setPage(props.page + 1)}
          label='Save and continue'
        />
      </div>
    </div>
  );
}

export default CreateBoatModelInformation;
