import {
  BoatModel,
  Feature,
  HardwareSystem,
  Inventory,
  Item,
  Oem,
  Package,
  PackageUpgrade,
  PaymentTerm,
  PropulsionSystem,
  System,
  Template,
} from '../../models/ApiModels';
import { Snack } from '../../models/AppModels';

export enum ActionType {
  GET_BOATMODELS = 'GET_BOATMODELS',
  GET_PROPULSIONSYSTEMS = 'GET_PROPULSIONSYSTEMS',
  ADD_BOATMODELS = 'ADD_BOATMODELS',
  UPDATE_BOATMODEL = 'UPDATE_BOATMODEL',
  DELETE_BOATMODEL = 'DELETE_BOATMODEL',
  SET_BOATMODELSERROR = 'SET_BOATMODELSERROR',
  ADD_PACKAGES = 'ADD_PACKAGES',
  ADD_FEATURES = 'ADD_FEATURES',
  ADD_ITEMS = 'ADD_ITEMS',
  GET_SYSTEMS = 'GET_SYSTEMS',
  ADD_SYSTEM = 'ADD_SYSTEM',
  UPDATE_SYSTEM = 'UPDATE_SYSTEM',
  DELETE_SYSTEM = 'DELETE_SYSTEM',
  GET_TEMPLATES = 'GET_TEMPLATES',
  ADD_TEMPLATE = 'ADD_TEMPLATE',
  UPDATE_TEMPLATE = 'UPDATE_TEMPLATE',
  DELETE_TEMPLATE = 'DELETE_TEMPLATE',
  ADD_OEMS = 'ADD_OEMS',
  GET_INVENTORY = 'GET_INVENTORY',
  ADD_INVENTORY = 'ADD_INVENTORY',
  ADD_PAYMENT_TERMS = 'ADD_PAYMENT_TERMS',
  ADD_UPGRADES = 'ADD_UPGRADES',
  GET_HARDWARESYSTEM = 'GET_HARDWARESYSTEM',
  SET_SNACKBAR = 'SET_SNACKBAR',
}

interface actionGetBoatModels {
  type: ActionType.GET_BOATMODELS;
  payload: BoatModel[];
}

interface actionGetPropulsionSystems {
  type: ActionType.GET_PROPULSIONSYSTEMS;
  payload: PropulsionSystem[];
}

interface actionAddBoatModels {
  type: ActionType.ADD_BOATMODELS;
  payload: BoatModel;
}

interface actionUpdateBoatModel {
  type: ActionType.UPDATE_BOATMODEL;
  payload: BoatModel;
}

interface actionDeleteBoatModel {
  type: ActionType.DELETE_BOATMODEL;
  payload: BoatModel;
}

interface actionBoatModelsHasError {
  type: ActionType.SET_BOATMODELSERROR;
  payload: boolean;
}

interface actionGetPropulsionSystems {
  type: ActionType.GET_PROPULSIONSYSTEMS;
  payload: PropulsionSystem[];
}

interface actionAddPackages {
  type: ActionType.ADD_PACKAGES;
  payload: Package[];
}

interface actionAddFeatures {
  type: ActionType.ADD_FEATURES;
  payload: Feature[];
}
interface actionAddItems {
  type: ActionType.ADD_ITEMS;
  payload: Item[];
}

interface actionAddPaymentTerms {
  type: ActionType.ADD_PAYMENT_TERMS;
  payload: PaymentTerm[];
}

interface actionGetSystems {
  type: ActionType.GET_SYSTEMS;
  payload: System[];
}

interface actionAddSystem {
  type: ActionType.ADD_SYSTEM;
  payload: System;
}

interface actionUpdateSystem {
  type: ActionType.UPDATE_SYSTEM;
  payload: System;
}

interface actionDeleteSystem {
  type: ActionType.DELETE_SYSTEM;
  payload: string;
}

interface actionGetTemplates {
  type: ActionType.GET_TEMPLATES;
  payload: Template[];
}

interface actionAddTemplate {
  type: ActionType.ADD_TEMPLATE;
  payload: Template;
}

interface actionUpdateTemplate {
  type: ActionType.UPDATE_TEMPLATE;
  payload: Template;
}

interface actionDeleteTemplate {
  type: ActionType.DELETE_TEMPLATE;
  payload: string;
}

interface actionAddOems {
  type: ActionType.ADD_OEMS;
  payload: Oem[];
}

interface actionGetInventory {
  type: ActionType.GET_INVENTORY;
  payload: Inventory[];
}

interface actionAddInventory {
  type: ActionType.ADD_INVENTORY;
  payload: Inventory;
}

interface actionAddUpgrades {
  type: ActionType.ADD_UPGRADES;
  payload: PackageUpgrade[];
}
interface actionGetHardwareSystem {
  type: ActionType.GET_HARDWARESYSTEM;
  payload: HardwareSystem[];
}

interface actionSetSnackbar {
  type: ActionType.SET_SNACKBAR;
  payload: Snack;
}

export type Action =
  | actionAddBoatModels
  | actionGetBoatModels
  | actionGetPropulsionSystems
  | actionUpdateBoatModel
  | actionDeleteBoatModel
  | actionBoatModelsHasError
  | actionAddFeatures
  | actionAddPackages
  | actionAddItems
  | actionGetSystems
  | actionAddSystem
  | actionUpdateSystem
  | actionDeleteSystem
  | actionGetTemplates
  | actionAddTemplate
  | actionUpdateTemplate
  | actionDeleteTemplate
  | actionAddOems
  | actionGetInventory
  | actionAddInventory
  | actionAddPaymentTerms
  | actionAddUpgrades
  | actionGetHardwareSystem
  | actionSetSnackbar;
