import { BrowserUtils, EventType } from '@azure/msal-browser';
import { loginRequest } from '../authConfig';
import { msalInstance } from '../index';

export async function GetToken() {
  return msalInstance
    .acquireTokenSilent(loginRequest)
    .then((response: any) => {
      return response;
    })
    .catch(async () => {
      return Promise.reject();
    });
}

export function Login() {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  // Set active acccount on page load
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
  }

  msalInstance.addEventCallback((event: any) => {
    // set active account after redirect
    if (
      event.eventType === EventType.LOGIN_SUCCESS &&
      event.payload &&
      event.payload.account &&
      event.payload.account
    ) {
      const account = event.payload.account;
      msalInstance.setActiveAccount(account);
    }
  });

  // handle auth redired/do all initial setup for msal
  msalInstance
    .handleRedirectPromise()
    .then(() => {
      // Check if user signed in
      const account = msalInstance.getActiveAccount();

      if (!account) {
        // redirect anonymous user to login page
        msalInstance
          .loginRedirect(loginRequest)
          .then((response: any) => {
            console.log(response);
          })
          .catch((err: any) => {
            console.log(err);
          });
      } else {
        console.log(account);
      }
    })
    .catch(async (err: any) => {
      await Logout();
    });
}

export function Logout() {
  const homeAccountId = msalInstance.getActiveAccount()?.homeAccountId;

  if (homeAccountId) {
    const currentAccount = msalInstance.getAccountByHomeId(homeAccountId);

    const logoutRequest = {
      account: currentAccount,
      onRedirectNavigate: () => !BrowserUtils.isInIframe(),
    };

    msalInstance.logoutRedirect(logoutRequest).catch((error: any) => {
      console.error(error);
    });
  } else {
    msalInstance.logoutRedirect().catch((error: any) => {
      console.error(error);
    });
  }
}
