import { combineReducers } from 'redux';
import boatModelsReducer from './BoatModelsReducer';
import configurationsReducer from './ConfigurationReducer';
import inventoryReducer from './InventoryReducer';
import oemsReducer from './OemReducer';
import propulsionSystemsReducer from './PropulsionSystemReducer';
import snackbarReducer from './SnackbarReducer';
import systemsReducer from './SystemReducer';

const reducers = combineReducers({
  boatModels: boatModelsReducer,
  configurations: configurationsReducer,
  systems: systemsReducer,
  oems: oemsReducer,
  propulsionSystems: propulsionSystemsReducer,
  inventory: inventoryReducer,
  snackbar: snackbarReducer,
});

export default reducers;
export type RootState = ReturnType<typeof reducers>;
