import { useState } from 'react';
import ServiceHelper from '../helpers/ServiceHelper';
import { BoatModel, PropulsionSystem } from '../models/ApiModels';

export async function GetBoatModels(isOem: boolean) {
  return new Promise<BoatModel[]>((resolve, reject) => {
    ServiceHelper({
      method: 'GET',
      url: `v1/boats/models${'?isOemSpecific=' + isOem}`,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export async function GetBoatModel(id: string) {
  return new Promise<BoatModel>((resolve, reject) => {
    ServiceHelper({
      method: 'GET',
      url: `v1/boats/models/${id}`,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export async function GetBoatModelImage(id: string) {
  return new Promise<any>((resolve, reject) => {
    ServiceHelper({
      method: 'GET',
      url: `v1/boats/models/image/${id}`,
      responseType: 'blob',
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function DeleteBoatModelImage(id: string) {
  return new Promise<BoatModel>((resolve, reject) => {
    ServiceHelper({
      method: 'DELETE',
      url: `v1/boats/models/image/${id}`,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function GetPropulsionSystems() {
  return new Promise<PropulsionSystem[]>((resolve, reject) => {
    ServiceHelper({
      method: 'GET',
      url: 'v1/boats/models/propulsionsystems',
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function UpdateBoatModels(boatModel: any) {
  return new Promise<void>((resolve, reject) => {
    ServiceHelper({
      method: 'PUT',
      url: `v1/boats/models/${boatModel.id}`,
      data: boatModel,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}

export async function AddBoatModel(boatModel: BoatModel) {
  return new Promise<void>((resolve, reject) => {
    ServiceHelper({
      method: 'POST',
      url: 'v1/boats/models',
      data: boatModel,
      headers: {
        OemTenantId: boatModel.oemTenantId,
      },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function DeleteBoatModel(id: string) {
  return new Promise<void>((resolve, reject) => {
    ServiceHelper({
      method: 'DELETE',
      url: `v1/boats/models/${id}`,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export const useUploadFormImage = () => {
  const [abortController, setAbortController] = useState<AbortController>();
  const [fileResponse, setFileResponse] = useState<any>();
  const [progress, setProgress] = useState(0);
  const [loaded, setLoaded] = useState(0);
  const [percent, setPercent] = useState(0);

  const uploadForm = async (formData: FormData, id?: string) => {
    const abortController = new AbortController();
    setAbortController(abortController);
    setFileResponse(undefined);

    return new Promise<any>((resolve, reject) => {
      ServiceHelper({
        method: 'POST',
        url: `/v1/boats/models/image/${id}`,
        timeout: 0,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data; boundary=------WebKitFormBoundary7MA4YWxkTrZu0gW',
        },
        signal: abortController.signal,
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          const kb = Math.floor(loaded / 1024);
          const mb = Math.floor(kb / 1024);
          const percent = Math.floor((loaded * 100) / total);
          setProgress(progress);
          setLoaded(mb);
          setPercent(percent);
        },
      })
        .then((response) => {
          setFileResponse(response.data);
          resolve(response.data);
        })
        .catch((error) => {
          setFileResponse(error);
          reject(error);
        });
    });
  };

  return { uploadForm, fileResponse, progress, loaded, percent, abortController };
};
