import { Tooltip } from 'component-library';
import { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AppContext } from '../../../contexts/AppContext';
import { SystemDetail } from '../../../models/ApiModels';

interface Props {
  system: SystemDetail;
}

export default function SystemDetailsHardwareControlPanel(props: Props) {
  const { isHumphreeUser } = useContext(AppContext);

  const navigate = useNavigate();
  return (
    <div className='border-b border-gray-10'>
      <div className='prose-heading5 flex gap-2 my-3'>
        Lightning control panel(s)
        <Tooltip
          description='The control panel is used to let boaters utilize the features in their Lightning system. There can be more than one control pannel in a Lightning system.'
          darkmode
        />
      </div>
      <div className='xl:flex xl:justify-between my-5 items-center'>
        <div className='xl:font-normal font-semibold w-48'>Primary</div>
        <div className='2xl:gap-16 xl:flex gap-4'>
          <div className='w-32 flex justify-between items-center truncate'></div>
          {props.system?.lightningHardwareSystem?.primaryControlPanel?.articleNumber && (
            <div className='xl:w-42 flex justify-between items-center'>
              Part no.
              <span className='font-code prose-dataBaseDataLabel ml-3'>
                {props.system?.lightningHardwareSystem?.primaryControlPanel?.articleNumber}
              </span>
            </div>
          )}
          {props.system?.lightningHardwareSystem?.primaryControlPanel?.serialNumber && (
            <div className='xl:w-32 flex justify-between items-center'>
              Serial no.
              <span className='font-code prose-dataBaseDataLabel ml-3'>
                {isHumphreeUser ? (
                  <Link
                    to={'/units/lcp/' + props.system.lightningHardwareSystem?.primaryControlPanel?.serialNumber}
                    className='whitespace-nowrap cursor-pointer underline text-primary-100 hover:text-primary-200'
                  >
                    {props.system?.lightningHardwareSystem?.primaryControlPanel?.serialNumber?.toString()}
                  </Link>
                ) : (
                  props.system?.lightningHardwareSystem?.primaryControlPanel?.serialNumber?.toString()
                )}
              </span>
            </div>
          )}
          {props.system?.lightningHardwareSystem?.primaryControlPanel?.firmwareVersion && (
            <div className='font-code prose-labelPill max-w-[10rem] bg-success-50 px-2.5 py-1.5 shadow-dropdown text-success-200 truncate'>
              Software {props.system.lightningHardwareSystem?.primaryControlPanel?.firmwareVersion}
            </div>
          )}
        </div>
      </div>
      {props.system?.lightningHardwareSystem?.secondaryControlPanel && (
        <div className='xl:flex xl:justify-between my-5 items-center'>
          <div className='xl:font-normal font-semibold w-48'>Secondary</div>
          <div className='2xl:gap-16 xl:flex gap-4'>
            {props.system?.lightningHardwareSystem?.secondaryControlPanel?.articleNumber && (
              <div className='xl:w-42 flex justify-between'>
                Part no.
                <span className='font-code prose-dataBaseDataLabel ml-3'>
                  {props.system?.lightningHardwareSystem?.secondaryControlPanel?.articleNumber}
                </span>
              </div>
            )}
            {props.system?.lightningHardwareSystem?.secondaryControlPanel?.serialNumber && (
              <div className='xl:w-32 flex justify-between'>
                Serial no.
                <span className='font-code prose-dataBaseDataLabel ml-3'>
                  {isHumphreeUser ? (
                    <Link
                      to={'/units/lcp/' + props.system.lightningHardwareSystem?.secondaryControlPanel?.serialNumber}
                      className='whitespace-nowrap cursor-pointer underline text-primary-100 hover:text-primary-200'
                    >
                      {props.system?.lightningHardwareSystem?.secondaryControlPanel?.serialNumber?.toString()}
                    </Link>
                  ) : (
                    props.system?.lightningHardwareSystem?.secondaryControlPanel?.serialNumber?.toString()
                  )}
                </span>
              </div>
            )}
            {props.system?.lightningHardwareSystem?.secondaryControlPanel?.firmwareVersion && (
              <div className='font-code prose-labelPill max-w-[10rem] bg-success-50 px-2.5 py-1.5 shadow-dropdown text-success-200 truncate'>
                Software {props.system.lightningHardwareSystem?.secondaryControlPanel?.firmwareVersion}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
