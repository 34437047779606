import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input, InvertButton, PrimaryButton } from 'component-library';
import { useState } from 'react';
import { Oem } from '../../models/ApiModels';

interface Props {
  addCustomer: () => void;
  customer: Oem;
}

export function OemAddCustomerItem(props: Props) {
  const [addEmail, setAddEmail] = useState('');
  const [togglePopup, setTogglePopup] = useState(false);

  return (
    <>
      <div className='p-4 border-b border-gray-10 w-full  flex justify-between items-center'>
        <span className='text-primary-400 prose-heading5'>{props.customer.name}</span>
        <div
          onClick={(e: any) => {
            e.stopPropagation();
            setTogglePopup(!togglePopup);
          }}
        >
          <FontAwesomeIcon
            className='text-gray-40 hover:text-gray-60 text-lg cursor-pointer'
            icon={faPlus}
            title='Add'
          />
        </div>
      </div>
      {togglePopup && (
        <div className='flex flex-col p-7 w-full bg-white border-b border-gray-10'>
          <div className='//prose-heading3 prose-heading4'>
            Do you want to add <span className='text-primary-100'>{props.customer.name}</span> as a portal customer?
          </div>
          <div className='//prose-paragraphBig my-3'>
            To add this portal customer, provide an order confirmation email to the customer.
          </div>
          <Input
            label='Default order confirmation email:'
            placeholder='Enter email...'
            value={addEmail}
            onChange={(value: string) => {
              setAddEmail(value);
              props.customer.settings.orderConfirmationEmail = value;
            }}
          />
          <div className='mt-5 justify-end flex gap-5'>
            <InvertButton label={'Cancel'} onClick={() => setTogglePopup(!togglePopup)} />
            <PrimaryButton
              label={'Add customer'}
              onClick={() => props.addCustomer()}
              disabled={addEmail === '' || !addEmail.includes('@')} //just a small check for email and extension
            />
          </div>
        </div>
      )}
    </>
  );
}
