import { Tooltip } from 'component-library';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../../../contexts/AppContext';
import { SystemDetail } from '../../../models/ApiModels';

interface Props {
  system: SystemDetail;
}

export default function SystemDetailsHardwareControlUnit(props: Props) {
  const { isHumphreeUser } = useContext(AppContext);

  return (
    <div className='border-b border-gray-10'>
      <div className='prose-heading5 flex gap-2 my-3'>
        Lightning control unit
        <Tooltip
          description='The Lightning control unit controls the interceptors and houses the license file and the software for all the functions.'
          darkmode
        />
      </div>
      <div className='xl:flex xl:justify-between my-5 items-center'>
        <div className='xl:block hidden w-48'>Lightning control unit</div>
        <div className='2xl:gap-16 xl:flex gap-4'>
          <div className='w-32 flex justify-between items-center truncate'></div>
          {props.system.lightningHardwareSystem?.controlUnit?.articleNumber && (
            <div className='text-left w-42 flex justify-between items-center'>
              Part no.
              <span className='font-code prose-dataBaseDataLabel ml-3'>
                {props.system.lightningHardwareSystem?.controlUnit?.articleNumber?.toString()}
              </span>
            </div>
          )}
          {props.system.lightningHardwareSystem?.controlUnitSerial && (
            <div className='text-left xl:w-32 flex justify-between items-center'>
              Serial no.
              <span className='font-code prose-dataBaseDataLabel ml-3'>
                {isHumphreeUser ? (
                  <Link
                    to={'/units/lhs/' + props.system.lightningHardwareSystem?.controlUnitSerial}
                    className='whitespace-nowrap cursor-pointer underline text-primary-100 hover:text-primary-200'
                  >
                    {props.system.lightningHardwareSystem?.controlUnitSerial?.toString()}
                  </Link>
                ) : (
                  props.system.lightningHardwareSystem?.controlUnitSerial?.toString()
                )}
              </span>
            </div>
          )}
          <div className='font-code prose-labelPill max-w-[10rem] bg-success-50 px-2.5 py-1.5 shadow-dropdown text-success-200 truncate'>
            Software {props.system.lightningHardwareSystem?.controlUnit?.firmwareVersion}
          </div>
        </div>
      </div>
    </div>
  );
}
