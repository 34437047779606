import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SoftwareUpdate } from '../../models/ApiModels';
import { DeleteSoftwareUpdateFile } from '../../services/SoftwareUpdateService';
import { ActionType } from '../../store/actionTypes';

interface Props {
  software: SoftwareUpdate;
}

export default function QuickDeleteSoftware(props: Props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const DeleteSoftware = useCallback(() => {
    if (props.software !== undefined) {
      DeleteSoftwareUpdateFile(props.software)
        .then(() => {
          dispatch({
            type: ActionType.SET_SNACKBAR,
            payload: { heading: 'Software version successfully deleted', status: 'success' },
          });
        })
        .catch((error) => {
          dispatch({
            type: ActionType.SET_SNACKBAR,
            payload: { heading: error.message, status: 'error' },
          });
        })
        .finally(() => {
          navigate('/software');
        });
    }
  }, [props.software]);
  return <FontAwesomeIcon onClick={() => DeleteSoftware()} icon={faTrash} size='lg' color='red' />;
}
