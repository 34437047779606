import ServiceHelper from '../helpers/ServiceHelper';
import { Preset, PresetList } from '../models/ApiModels';

export async function AddPreset(preset: Preset, OemTenantId: string) {
  return new Promise<Preset>((resolve, reject) => {
    ServiceHelper({
      method: 'POST',
      url: 'v1/boats/presets',
      data: preset,
      headers: {
        OemTenantId: OemTenantId,
      },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function GetBoatModelPresets(id: string, OemTenantId: string) {
  return new Promise<PresetList[]>((resolve, reject) => {
    ServiceHelper({
      method: 'GET',
      url: `v1/boats/${id}/presets`,
      headers: {
        OemTenantId: OemTenantId,
      },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function GetAllPresets(OemTenantId: string) {
  return new Promise<PresetList[]>((resolve, reject) => {
    ServiceHelper({
      method: 'GET',
      url: 'v1/boats/presets',
      headers: {
        OemTenantId: OemTenantId,
      },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function GetPresetDetails(id: string) {
  return new Promise<Preset>((resolve, reject) => {
    ServiceHelper({
      method: 'GET',
      url: `v1/boats/presets/${id}`,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function UpdatePresetDetails(preset: Preset, id: string, oemTenantId: string) {
  return new Promise<Preset>((resolve, reject) => {
    ServiceHelper({
      method: 'PUT',
      url: `v1/boats/presets/${id}`,
      data: preset,
      headers: { OemTenantId: oemTenantId },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function DeletePreset(id: string, OemTenantId: string) {
  return new Promise<void>((resolve, reject) => {
    ServiceHelper({
      method: 'DELETE',
      url: `v1/boats/presets/${id}`,
      headers: { OemTenantId: OemTenantId },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function GetPresetFile(id: string, unitType: string, OemTenantId: string) {
  return new Promise<any>((resolve, reject) => {
    ServiceHelper({
      method: 'GET',
      url: `v1/boats/presets/${unitType}/file/${id}`,
      responseType: 'blob',
      headers: {
        OemTenantId: OemTenantId,
      },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function GetPatchFile(id: string, OemTenantId: string) {
  return new Promise<any>((resolve, reject) => {
    ServiceHelper({
      method: 'GET',
      url: `v1/boats/models/patches/file/${id}`,
      headers: {
        'Content-Type': 'application/xml; charset=utf-8',
        OemTenantId: OemTenantId,
      },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function GetPatchFileDownload(id: string, OemTenantId: string) {
  return new Promise<any>((resolve, reject) => {
    ServiceHelper({
      method: 'GET',
      url: `v1/boats/models/patches/download/file/${id}`,
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/xml; charset=utf-8',
        OemTenantId: OemTenantId,
      },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function DeletePatchFile(boatModelId: string, unitType: string, OemTenantId: string) {
  return new Promise<any>((resolve, reject) => {
    ServiceHelper({
      method: 'DELETE',
      url: `v1/boats/models/${boatModelId}/patches/${unitType}`,
      headers: {
        OemTenantId: OemTenantId,
      },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
