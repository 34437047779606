import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popup } from 'component-library';
import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../../contexts/AppContext';
import { BoatModel } from '../../../models/ApiModels';

import PermissionsGate, { ROLES } from '../../../utils/PermissionGate';
import CreateBoatModelInformation from './CreateBoatModelInformation';
import CreateBoatModelSpecifications from './CreateBoatModelSpecifications';

function BoatModelCreate() {
  const [loading, setLoading] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);

  const { currentOemTenantId } = useContext(AppContext);
  const boatModels = useSelector((state: any) => state.boatModels.boatModels);
  const navigate = useNavigate();
  const [showHasChangesPopup, setShowHasChangesPopup] = useState(false);
  const [page, setPage] = useState(0);

  const initBoatModel: BoatModel = {
    flyBridgeOption: false,
    id: '',
    name: '',
    make: '',
    oemTenantId: currentOemTenantId,
    length: undefined,
    beam: undefined,
    dryWeight: undefined,
    topSpeed: undefined,
    cruisingSpeed: undefined,
    propulsionSystemIds: [],
    status: 'Public',
    createdDate: undefined,
    availableConfigurationIds: [],
  };

  const [newBoatModel, setNewBoatModel] = useState<BoatModel>(initBoatModel);

  const stepsComponentList = [
    <CreateBoatModelInformation
      key={page}
      newBoatModel={newBoatModel}
      setNewBoatModel={(boatModel: BoatModel) => setNewBoatModel({ ...boatModel })}
      boatModels={boatModels}
      page={page}
      setPage={(value: number) => setPage(value)}
      GoBack={() => GoBack()}
    />,
    <CreateBoatModelSpecifications
      key={page}
      newBoatModel={newBoatModel}
      setNewBoatModel={(boatModel: BoatModel) => setNewBoatModel({ ...boatModel })}
      page={page}
      setPage={(value: number) => setPage(value)}
      GoBack={() => GoBack()}
    />,
  ];

  useEffect(() => {
    if (_.isEqual(initBoatModel, newBoatModel)) setHasChanges(false);
    else {
      setHasChanges(true);
    }
  }, [newBoatModel]);

  function GoBack() {
    if (hasChanges) {
      setShowHasChangesPopup(true);
    } else {
      navigate(-1);
    }
  }

  return (
    <PermissionsGate
      roles={[ROLES.admin, ROLES.editor, ROLES.oemAdmin, ROLES.oemEditor]}
      rejected={() =>
        setTimeout(() => {
          navigate('/models');
        }, 100)
      }
    >
      <div className='h-full flex flex-col'>
        <div className='flex gap-2 prose-heading3 items-center pb-14'>
          <div className='hidden md:flex cursor-pointer' onClick={() => GoBack()}>
            Boat models
          </div>
          <FontAwesomeIcon className='hidden md:flex' icon={faChevronRight} size='sm' />
          <div>Add new boat model</div>
        </div>
        <div className='h-full'>
          {stepsComponentList[page]}
          {loading && (
            <div className='w-full flex justify-center items-center h-full'>
              <div className='w-12 h-12 border-l-2 border-primary-400 rounded-full animate-spin'></div>
            </div>
          )}
        </div>
        {showHasChangesPopup && (
          <Popup
            confirm={() => navigate('/models')}
            close={() => setShowHasChangesPopup(false)}
            heading='Discard boat model?'
            paragraph='You will lose all the information entered about this boat model.'
            status='error'
            cancelText='Keep it'
            confirmText='Discard boat model'
          />
        )}
      </div>
    </PermissionsGate>
  );
}

export default BoatModelCreate;
