import { Checkbox } from 'component-library';
import displayImg from '../../../../assets/lcpIcons/displaysettings-01.png';
import otherSettingsImg from '../../../../assets/lcpIcons/generic.png';
import { Preset } from '../../../../models/ApiModels';
interface Props {
  displayData: { id: string; name: string; value: number; displayValue: string; unit: string }[];
  selectBoxes?: boolean;
  preset?: Preset;
  setPreset?: (preset: Preset) => void;
  isPreset?: boolean;
}
export function ConfigurationOtherSettings(props: Props) {
  function handleEnabledValues(clickedName: any, clickedValue: any, clickedId: string) {
    if (
      clickedName.includes('Theme') ||
      clickedName.includes('Orientation') ||
      clickedName.includes('Allow nofix') ||
      clickedName.includes('Prioritize waterspeed')
    ) {
      if (props.preset && props.setPreset) {
        const newPreset = { ...props.preset };
        if (Object.prototype.hasOwnProperty.call(newPreset.lcpMetadata, clickedId)) {
          //check if key exists
          delete newPreset.lcpMetadata[clickedId];
        } else {
          newPreset.lcpMetadata[clickedId] = clickedValue;
        }
        props.setPreset(newPreset);
      }
    } else {
      if (props.preset && props.setPreset) {
        const newPreset = { ...props.preset };
        if (Object.prototype.hasOwnProperty.call(newPreset.lcuMetadata, clickedId)) {
          //check if key exists
          delete newPreset.lcuMetadata[clickedId];
        } else {
          newPreset.lcuMetadata[clickedId] = clickedValue;
        }
        props.setPreset(newPreset);
      }
    }
  }

  return (
    <div>
      <h4 className='prose-heading4 pb-5 mb-5 border-b border-gray-10'>
        <img src={otherSettingsImg} className='inline mr-1 object-contain' />
        Other settings
      </h4>
      <div className='grid grid-cols-[repeat(auto-fill,minmax(16rem,auto))] gap-10 border-b border-gray-10 pb-5'>
        {/* Display settings */}
        <div className='flex flex-col gap-4'>
          <h5 className='prose-heading5'>
            <img src={displayImg} className='inline mr-1 object-contain' />
            Display settings
          </h5>
          {props.displayData.map(({ name, value, id, displayValue }) => (
            <div key={id} className='flex gap-2'>
              {props.selectBoxes && props.preset?.lcuMetadata && (
                <Checkbox
                  disabled={value === null || value === undefined}
                  active={
                    Object.keys(props.preset?.lcpMetadata).includes(id) ||
                    Object.keys(props.preset?.lcuMetadata).includes(id)
                  }
                  onClick={() => handleEnabledValues(name, value, id)}
                />
              )}
              <p
                className={`${
                  (value === null && props.isPreset) || (value === undefined && props.isPreset) ? 'text-gray-40' : ''
                }`}
              >
                {name}
              </p>
              {value === null || value === undefined ? (
                <p className='text-gray-40 italic ml-auto'>{props.isPreset ? '-' : 'n/a'}</p>
              ) : (
                <p className='font-code ml-auto'>{displayValue}</p>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
