import { useMsal } from '@azure/msal-react';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { Popup, PrimaryButton } from 'component-library';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import { GetUserListData, userListColumns } from '../../../helpers/UsersListSetup';
import { User } from '../../../models/ApiModels';
import { DeleteUser, GetUsersAsync } from '../../../services/UserService';
import useRequest from '../../../utils/net/useRequest';

import PermissionGate, { ROLES } from '../../../utils/PermissionGate';
import useWindowSize from '../../../utils/UseWindowSize';
import Table from '../../Table/Table';
import { SettingsCreateUser } from './SettingsCreateUser/SettingsCreateUser';

export function SettingsUsers() {
  const size = useWindowSize();
  const { accounts } = useMsal();
  const account = accounts[0];
  const { currentOemTenantId, isHumphreeHome, currentOem } = useContext(AppContext);
  const [fetchCount, setFetchCount] = useState(0);
  const [openAddUser, setOpenAddUser] = useState(false);
  const [users = [], isUsersLoading, usersGetError] = useRequest(
    () => GetUsersAsync(),
    [currentOemTenantId, fetchCount]
  );
  const [editUser, setEditUser] = useState<User>();
  const [openPopup, setOpenPopup] = useState(false);

  useEffect(() => {
    GetUsersAsync();
  }, [users, fetchCount]);

  async function DeleteUserAsync(id: string) {
    DeleteUser(id)
      .then(() => {
        setOpenPopup(false);
        setEditUser(undefined);
        setOpenAddUser(false);
        setFetchCount(fetchCount + 1);
      })
      .catch(() => {
        setOpenPopup(false);
      });
  }

  return (
    <div className='relative mt-2'>
      {openAddUser && (
        <SettingsCreateUser
          users={users}
          edit={editUser}
          close={() => {
            setEditUser(undefined);
            setOpenAddUser(false);
          }}
          addUser={() => {
            setEditUser(undefined);
            setFetchCount(fetchCount + 1);
            setOpenAddUser(false);
          }}
          deleteUser={() => setOpenPopup(true)}
          currentOem={currentOem}
        />
      )}
      <div>
        <div className='flex justify-between p-5 border border-gray-10 items-center'>
          <span className='prose-heading4 text-primary-400'>Users</span>
          <PermissionGate
            roles={isHumphreeHome ? [ROLES.admin] : [ROLES.admin, ROLES.editor, ROLES.oemAdmin]}
            rejected={undefined}
          >
            <PrimaryButton label='Add new user' icon={faPlus} onClick={() => setOpenAddUser(true)} />
          </PermissionGate>
        </div>
        {!isUsersLoading && !usersGetError && (
          <Table
            data={GetUserListData(users)}
            columns={userListColumns}
            onClick={(value: any) => {
              const roles: string = account?.idTokenClaims?.extension_userRoles as string;
              if (
                roles.includes('admin') ||
                roles.includes('editor') ||
                roles.includes('oemAdmin') ||
                roles.includes('oemEditor')
              ) {
                setEditUser(value);
                setOpenAddUser(true);
              }
            }}
            type='users'
            removeItem={(id: string) => DeleteUserAsync(id)}
            setColumnVisibility={{
              active: size?.width > 1100 || size?.width < 640,
              email: size?.width > 1000 || size?.width < 640,
            }}
          />
        )}
      </div>
      {isUsersLoading && (
        <div className='flex items-center flex-col gap-4 pb-7 justify-center absolute top-40 w-full'>
          <span className='text-primary-400 prose-heading5'>Loading users...</span>
          <div className='w-12 h-12 border-l-2 border-primary-400 rounded-full animate-spin'></div>
        </div>
      )}
      {usersGetError && !isUsersLoading && (
        <div className='w-full flex justify-between bg-error-100 px-6 py-4 text-white prose-paragraphSmall italic'>
          <p>Couldn&apos;t load users.</p>
          <p onClick={() => setFetchCount(fetchCount + 1)} className='cursor-pointer underline'>
            Try again
          </p>
        </div>
      )}
      {openPopup && (
        <Popup
          close={() => setOpenPopup(false)}
          confirm={async () => editUser?.id && (await DeleteUserAsync(editUser.id))}
          heading='Are you sure you want to delete this user?'
          paragraph='All information about this user will be permanently deleted.'
          status='error'
          cancelText='Discard'
          confirmText='Delete'
        />
      )}
    </div>
  );
}
