import ServiceHelper from '../helpers/ServiceHelper';
import { User } from '../models/ApiModels';

export async function GetUsersAsync() {
  return new Promise<User[]>((resolve, reject) => {
    ServiceHelper({
      method: 'GET',
      url: 'v1/users',
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function GetUser(id: string) {
  return new Promise<User>((resolve, reject) => {
    ServiceHelper({
      method: 'GET',
      url: `v1/users/${id}`,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function DeleteUser(id: string) {
  return new Promise<void>((resolve, reject) => {
    ServiceHelper({
      method: 'DELETE',
      url: 'v1/users/' + id,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function UpdateUser(user: User) {
  return new Promise<void>((resolve, reject) => {
    ServiceHelper({
      method: 'PUT',
      url: 'v1/users/' + user.id,
      data: user,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function GetUserNames(id: string[]) {
  return new Promise<any>((resolve, reject) => {
    ServiceHelper({
      method: 'POST',
      url: 'v1/users/names',
      data: id,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
