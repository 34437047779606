import ServiceHelper from '../helpers/ServiceHelper';
import { System, SystemComment, SystemDetail } from '../models/ApiModels';

export async function GetSystemsAsync() {
  return new Promise<System[]>((resolve, reject) => {
    ServiceHelper({
      method: 'GET',
      url: 'v1/systems',
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function GetSystemAsync(id: string) {
  return new Promise<SystemDetail>((resolve, reject) => {
    ServiceHelper({
      method: 'GET',
      url: `v1/systems/${id}`,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function AddSystem(system: System) {
  return new Promise<void>((resolve, reject) => {
    ServiceHelper({
      method: 'POST',
      url: 'v1/systems',
      data: system,
      headers: {
        OemTenantId: system.oemId,
      },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function UpdateSystem(updatedSystem: System) {
  return new Promise<System>((resolve, reject) => {
    ServiceHelper({
      method: 'PUT',
      url: `v1/systems/${updatedSystem.id}`,
      data: updatedSystem,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function DeleteSystem(id: string) {
  return new Promise<void>((resolve, reject) => {
    ServiceHelper({
      method: 'DELETE',
      url: `v1/systems/${id}`,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function AddComment(system: System, comment: string) {
  return new Promise<System>((resolve, reject) => {
    ServiceHelper({
      method: 'POST',
      url: `v1/systems/${system.id}/comments`,
      data: `${comment}`,
      headers: {
        OemTenantId: system.oemId,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function DeleteComment(system: System, id?: number) {
  return new Promise<System>((resolve, reject) => {
    ServiceHelper({
      method: 'DELETE',
      url: `v1/systems/${system.id}/comments/${id}`,
      headers: {
        OemTenantId: system.oemId,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function UpdateCommentAsync(system: System, comment: SystemComment) {
  return new Promise<System>((resolve, reject) => {
    ServiceHelper({
      method: 'PUT',
      url: `v1/systems/${system.id}/comments/${comment.id}`,
      data: comment.text,
      headers: {
        OemTenantId: system.oemId,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function UpgradeLicense(
  id: string,
  oemId: string,
  packageId: string,
  externalOrderReference: string,
  additionalFeatures: string[]
) {
  return new Promise<void>((resolve, reject) => {
    ServiceHelper({
      method: 'POST',
      url: `v1/systems/${id}/upgrade`,
      headers: {
        OemTenantId: oemId,
      },
      data: {
        upgradePackageId: packageId,
        externalOrderReference: externalOrderReference,
        additionalFeatures: additionalFeatures,
      },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function GetSystemDatasetFile(id: string) {
  return new Promise<any>((resolve, reject) => {
    ServiceHelper({
      method: 'GET',
      url: `v1/systems/dataset/file/${id}`,
      responseType: 'blob',
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
