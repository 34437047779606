import { faChevronLeft, faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InvertButton, PrimaryButton, Tooltip } from 'component-library';
import { ChangeEvent, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useOutletContext } from 'react-router-dom';
import listImg from '../../../../../assets/lcpIcons/autolisttest-01.png';
import autoTrimImg from '../../../../../assets/lcpIcons/autotrimtest-01.png';
import sensitivityImg from '../../../../../assets/lcpIcons/baseline_tune_black_24dp.png';
import coTurnImg from '../../../../../assets/lcpIcons/coordinatedturn-new-01.png';
import extBusImg from '../../../../../assets/lcpIcons/extbus.png';
import functionSetupImg from '../../../../../assets/lcpIcons/functionsetup-new-01.png';
import interceptorImg from '../../../../../assets/lcpIcons/interceptorsetup-01.png';
import systemSetupImg from '../../../../../assets/lcpIcons/panelsettings.png';
import rudderImg from '../../../../../assets/lcpIcons/ruddersignalnew-01.png';
import sensorImg from '../../../../../assets/lcpIcons/sensorsetup-01.png';
import speedImg from '../../../../../assets/lcpIcons/speedsource-01.png';
import pitchImg from '../../../../../assets/lcpIcons/waves-01.png';
import { Preset } from '../../../../../models/ApiModels';
import { AddPreset } from '../../../../../services/PresetsServices';
import { ActionType } from '../../../../../store/actionTypes';
import { presetVisibilityFunctionData, presetVisibilitySystemData } from '../../../../DataExports/presetVisibilityData';

interface CreatePresetProps {
  preset: Preset;
  setPreset: (preset: Preset) => void;
  setPage: () => void;
}

export default function CreatePresetPartTwo(props: CreatePresetProps) {
  const [system] = useOutletContext<any>();
  const [characterCount, setCharacterCount] = useState<number | undefined>(
    props.preset.lcuMetadata.menuVisibility.password?.length
  );
  const [isValidPassword, setIsValidPassword] = useState(true);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const visibilitySystem = presetVisibilitySystemData(props.preset.lcuMetadata.menuVisibility as any, {
    sensorImg: sensorImg,
    signalImg: rudderImg,
    setupImg: interceptorImg,
    speedImg: speedImg,
    extBusImg: extBusImg,
  });

  const visibilityFunction = presetVisibilityFunctionData(props.preset.lcuMetadata.menuVisibility as any, {
    sensitivityImg: sensitivityImg,
    autoTrimImg: autoTrimImg,
    listImg: listImg,
    coTurnImg: coTurnImg,
    pitchImg: pitchImg,
  });

  function handleVisibilityToggle(item: any) {
    if (props.preset.lcuMetadata) {
      switch (item.key) {
        case 'Sensor':
          props.preset.lcuMetadata.menuVisibility.sensor = !item.value;
          break;
        case 'Rudder signal':
          props.preset.lcuMetadata.menuVisibility.rudder = !item.value;
          break;
        case 'Interceptor setup':
          props.preset.lcuMetadata.menuVisibility.interceptorOffset = !item.value;
          break;
        case 'Speed source':
          props.preset.lcuMetadata.menuVisibility.speedSource = !item.value;
          break;
        case 'Ext bus':
          props.preset.lcuMetadata.menuVisibility.extbus = !item.value;
          break;
        case 'Sensitivity':
          props.preset.lcuMetadata.menuVisibility.sensitivity = !item.value;
          break;
        case 'Auto trim':
          props.preset.lcuMetadata.menuVisibility.autoTrim = !item.value;
          break;
        case 'List & Roll control':
          props.preset.lcuMetadata.menuVisibility.listAndRollControl = !item.value;
          break;
        case 'Coordinated turn':
          props.preset.lcuMetadata.menuVisibility.coordinatedTurn = !item.value;
          break;
        case 'Pitch control':
          props.preset.lcuMetadata.menuVisibility.pitchControl = !item.value;
          break;
        default:
          break;
      }
    }
    props.setPreset({ ...props.preset });
  }

  const savePreset = useCallback((newPreset: Preset) => {
    setLoading(true);
    AddPreset(newPreset, system.oemId)
      .then((response: any) => {
        setLoading(false);
        dispatch({
          type: ActionType.SET_SNACKBAR,
          payload: { heading: 'You created a new preset!', status: 'success' },
        });
        setTimeout(() => {
          navigate('/models/' + props.preset.boatModelId + '/presets/' + response.id);
        }, 1);
      })
      .catch(() => {
        setLoading(false);
        console.log('Something went wrong...');
      });
  }, []);

  return (
    <div className='flex flex-col gap-16'>
      <div className='flex flex-col'>
        <h4 className='prose-heading4 mb-4'>Part 2 of 2 - Hide menus (optional)</h4>
        <p>
          Select if you want settings to be visible to the user or not. This can help making the menus a little easier
          and hide away settings to prevent users from changing, preferably settings included in the preset. If menus
          are hidden, you must set a password to use when unlocking all settings temporarily. Password is visible in the
          portal.
        </p>
      </div>
      <h4 className='prose-heading4 pb-2 border-b border-gray-10'>Setting visibility</h4>
      <div className='flex flex-wrap gap-20'>
        <div className='flex gap-20'>
          <div>
            <h5 className='prose-heading5 pb-4 border-b border-gray-10'>
              <img src={systemSetupImg} className='h-6 inline mr-1 object-contain' />
              System setup settings
            </h5>
            {visibilitySystem.map((item) => {
              return (
                <div
                  className='flex items-center gap-2 my-2 cursor-pointer'
                  key={item.key}
                  onClick={() => handleVisibilityToggle(item)}
                >
                  {!item.value ? (
                    <FontAwesomeIcon icon={faEye} className='w-8 text-primary-100' />
                  ) : (
                    <FontAwesomeIcon icon={faEyeSlash} className='w-8 text-primary-40' />
                  )}
                  <img src={item.img} className={`${!item.value ? 'opacity-100' : 'opacity-40'}`} />
                  <h6 className={`prose-heading6 ${!item.value ? 'text-black' : 'text-gray-40'}`}>{item.key}</h6>
                </div>
              );
            })}
            <div className='border-b border-gray-10'></div>
          </div>
          <div>
            <h5 className='prose-heading5 pb-4 border-b border-gray-10'>
              <img src={functionSetupImg} className='h-6 inline mr-1 /object-contain' />
              Function setup settings
            </h5>
            {visibilityFunction.map((item) => {
              return (
                <div
                  className='flex items-center gap-2 my-2 cursor-pointer'
                  key={item.key}
                  onClick={() => handleVisibilityToggle(item)}
                >
                  {!item.value ? (
                    <FontAwesomeIcon icon={faEye} className='w-8 text-primary-100' />
                  ) : (
                    <FontAwesomeIcon icon={faEyeSlash} className='w-8 text-primary-40' />
                  )}
                  <img src={item.img} className={`${!item.value ? 'opacity-100' : 'opacity-40'}`} />
                  <h6 className={`prose-heading6 ${!item.value ? 'text-black' : 'text-gray-40'}`}>{item.key}</h6>
                </div>
              );
            })}
            <div className='border-b border-gray-10'></div>
          </div>
        </div>
      </div>
      {Object.values(props.preset.lcuMetadata.menuVisibility).includes(true) && (
        <div className='flex flex-wrap justify-between border-b border-gray-10'>
          <div className='flex'>
            Setting unlock password<span className='text-error-100 mr-1'>*</span>
            <Tooltip
              heading='Setting unlock password'
              description='Use this password in the Advanced > Login menu in the LCP, to enable every setting to be set again. When the system restarts the settings will be hidden again.'
              darkmode
            />
          </div>
          <div>
            <input
              pattern='[\x00-\x7F]+' //ASCII RegExp pattern
              minLength={5}
              maxLength={15}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setCharacterCount(e.target.value.length);
                props.preset.lcuMetadata.menuVisibility.password = e.target.value;
                props.setPreset({ ...props.preset });
                setIsValidPassword(e.target.checkValidity());
              }}
              placeholder='Enter your password...'
              className={`w-full max-w-[400px] min-w-[250px] bg-gray-3 invalid:border invalid:border-error-100 prose-paragraphSmall ${
                characterCount === 15 && 'border border-warning-100'
              } px-3 h-10`}
              value={props.preset.lcuMetadata.menuVisibility.password}
            />
            <p
              className={`prose-paragraphTiny text-right pt-1 pb-3 ${
                characterCount === 18 ? 'text-warning-100' : 'text-black'
              }`}
            >
              Min characters 5, max characters ({characterCount}/15)
            </p>
          </div>
        </div>
      )}
      <div className='flex flex-wrap gap-2 ml-auto'>
        <InvertButton label={'Cancel'} onClick={() => navigate(-1)} />
        <InvertButton icon={faChevronLeft} label={'Go back to previous page'} onClick={() => props.setPage()} />
        <PrimaryButton
          loading={loading}
          label={'Create new preset '}
          onClick={() => {
            props.setPreset({ ...props.preset });
            savePreset(props.preset);
          }}
          disabled={
            !isValidPassword ||
            (Object.values(props.preset.lcuMetadata.menuVisibility).includes(true) &&
              props.preset.lcuMetadata.menuVisibility.password === '')
          }
        />
      </div>
    </div>
  );
}
