// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import axios from 'axios';
import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { Config } from '../config';
import { AppContext } from '../contexts/AppContext';
import { GetToken, Logout } from '../services/AuthService';
import { ActionType } from '../store/actionTypes';

const axiosInstance = axios.create({
  baseURL: Config.api_url,
  timeout: 30000,
  headers: {
    'Access-Control-Allow-Origin': '*',
  },
});

export const WithAxios = ({ children }) => {
  const dispatch = useDispatch();
  const { currentOemTenantId } = useContext(AppContext);

  axiosInstance.interceptors.request.use(
    async (config) => {
      const response = await GetToken().catch(async () => {
        await Logout();
      });

      if (response && response.accessToken && response.idTokenClaims.extension_oemTenantId) {
        config.headers['Authorization'] = 'Bearer ' + response.accessToken; // for Spring Boot back-end
        if (config.headers['OemTenantId'] === undefined)
          config.headers['OemTenantId'] = currentOemTenantId
            ? currentOemTenantId
            : response.idTokenClaims.extension_oemTenantId;
        config.headers['LoggedInOemTenantId'] = response.idTokenClaims.extension_oemTenantId;
      }

      return config;
    },
    (error) => {
      // Continue with default error handler
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err: any) => {
      const originalConfig = err.config;
      if (err.response) {
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;
          try {
            await GetToken();
          } catch (_error) {
            await Logout();
          }
        }
      }
      if (axios.isCancel(err)) {
        // here you check if this is a cancelled request to drop it silently (without error)
        return Promise.reject();
      }
      if (err?.response?.data?.message) {
        dispatch({
          type: ActionType.SET_SNACKBAR,
          payload: { heading: err?.response?.data?.message, status: 'error' },
        });
      } else {
        dispatch({
          type: ActionType.SET_SNACKBAR,
          payload: { heading: 'Something went wrong!', status: 'error' },
        });
      }

      return Promise.reject(err);
    }
  );
  return children;
};

export default axiosInstance;
