import moment from 'moment';
import { EventLog } from '../../../../models/ApiModels';

interface Props {
  event: EventLog;
  isUsersLoading: boolean;
  user: string;
  isHumphreeUser: boolean;
}

export default function LogEventTimeData(props: Props) {
  return (
    <div className='flex flex-1 flex-col items-end sm:whitespace-nowrap'>
      {props.isUsersLoading ? (
        <div className='w-4 h-4 border-l-2 border-primary-400 rounded-full animate-spin'></div>
      ) : (
        <>
          <span className='prose-paragraphBase'>{moment(props.event.createdDate).format('MMMM D, LT')}</span>
          <span className='prose-paragraphSmaller text-gray-60'>
            <span className='prose-mainMenuL2ItemActive text-primary-100'>
              {props.user ? props.user : 'System'} {props.isHumphreeUser && '(Humphree)'}
            </span>
          </span>
        </>
      )}
    </div>
  );
}
