import ServiceHelper from '../helpers/ServiceHelper';
import { Configurations, Feature, PaymentTerm } from '../models/ApiModels';

export async function GetConfigurations() {
  return new Promise<Configurations>((resolve, reject) => {
    ServiceHelper({
      method: 'GET',
      url: 'v1/configuration/static',
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
