import { faCalendar, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { Input, InvertButton, PrimaryButton, RadioButton, StatusPill } from 'component-library';
import moment from 'moment';
import { useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Config } from '../../../config';
import { SystemDetail, User, Warranty } from '../../../models/ApiModels';

interface Props {
  system: SystemDetail;
  user: User;
  close: () => void;
}

export default function ExtendWarrantyModal(props: Props) {
  const initWarrantyForm: Warranty = {
    controlUnitSerialNumber: props.system.controlUnitSerialNumber?.toString(),
    controlPanelSerialNumber: props.system.lightningHardwareSystem?.primaryControlPanel?.serialNumber.toString(),
    rudderSignal: undefined,
    hullNumber: props.system.hullNumber,
    submissionerEmail: props.user.email,
    systemVoltage: undefined,
    boatDeliveryDate: undefined,
  };
  const [warrantyForm, setWarrantyForm] = useState(initWarrantyForm);

  const [startDate, setStartDate] = useState();
  const WarrantyDate = () => {
    function onChangeDateHandler(value: any) {
      const newDate = moment(value).format('YYYY-MM-DD');
      setStartDate(value);
      warrantyForm.boatDeliveryDate = newDate;
      setWarrantyForm({ ...warrantyForm });
    }

    return (
      <div>
        <DatePicker
          popperPlacement='top'
          dateFormat='dd/MM/yyyy'
          placeholderText='Enter date...'
          className='bg-gray-3 w-full prose-paragraphSmaller'
          selected={startDate}
          onChange={onChangeDateHandler}
          customInput={<Input value={warrantyForm.boatDeliveryDate} icon={faCalendar} size='Large' />}
        />
      </div>
    );
  };

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  async function AddWarranty() {
    const data = warrantyForm;
    setLoading(true);
    return axios
      .post(`${Config.api_url}v1/warranty/lightning`, data)
      .then((response) => {
        setLoading(false);
        setSuccess(true);
        return response.data;
      })
      .catch((error) => {
        if (error.response.data) {
          setLoading(false);
          console.log('Something went wrong');
        }
      });
  }
  const warrantyvalid = useMemo(() => {
    if (
      warrantyForm.boatDeliveryDate !== undefined &&
      warrantyForm.rudderSignal !== undefined &&
      warrantyForm.systemVoltage !== undefined &&
      warrantyForm.hullNumber !== ''
    )
      return true;
    return false;
  }, [warrantyForm]);

  return (
    <div className='fixed z-50 top-0 left-0 bg-black bg-opacity-50 w-full h-full'>
      <div className='flex items-center justify-center h-full'>
        <div className='relative m-2 p-4 md:p-8 bg-white w-full md:w-fit shadow-popup popup'>
          <div
            onClick={() => props.close()}
            className='hidden md:flex justify-center items-center w-11 h-11 bg-primary-100 rounded-full text-white absolute -right-3 -top-3 cursor-pointer shadow-close'
          >
            <FontAwesomeIcon icon={faXmark} title='Close' />
          </div>
          {!success ? (
            <div className='flex flex-col gap-8'>
              <div className='w-full text-center'>
                <h3 className='prose-heading3 mb-2'>Extend system warranty</h3>
                <p className='prose-paragraphBase'>
                  After submitting, a confirmation email will be sent to your email.
                </p>
              </div>
              <div className='flex flex-col gap-2'>
                <h4 className='prose-heading4'>System polarity</h4>
                <span className='flex gap-1 items-center'>
                  <RadioButton
                    active={warrantyForm.systemVoltage === '12 Volt'}
                    onClick={() => {
                      warrantyForm.systemVoltage = '12 Volt';
                      setWarrantyForm({ ...warrantyForm });
                    }}
                  />
                  12 Volt
                </span>
                <span className='flex gap-1 items-center'>
                  <RadioButton
                    active={warrantyForm.systemVoltage === '24 Volt'}
                    onClick={() => {
                      warrantyForm.systemVoltage = '24 Volt';
                      setWarrantyForm({ ...warrantyForm });
                    }}
                  />
                  24 Volt
                </span>
              </div>
              <div className='flex flex-col gap-2'>
                <h4 className='prose-heading4'>Rudder signal</h4>
                <span className='flex gap-1 items-center'>
                  <RadioButton
                    active={warrantyForm.rudderSignal === true}
                    onClick={() => {
                      warrantyForm.rudderSignal = true;
                      setWarrantyForm({ ...warrantyForm });
                    }}
                  />
                  Yes
                </span>
                <span className='flex gap-1 items-center'>
                  <RadioButton
                    active={warrantyForm.rudderSignal === false}
                    onClick={() => {
                      warrantyForm.rudderSignal = false;
                      setWarrantyForm({ ...warrantyForm });
                    }}
                  />
                  No
                </span>
              </div>
              <div className='flex flex-col gap-2'>
                <h4 className='prose-heading4'>Boat delivery date</h4>
                <WarrantyDate />
              </div>
              {props.system.hullNumber === '' && (
                <div className='flex flex-col gap-2'>
                  <h4 className='prose-heading4'>Boat hull number</h4>
                  <p className=''>The system appears to not have a hull number</p>
                  <Input
                    size='Large'
                    placeholder='Enter hull number...'
                    onChange={(input: string) => {
                      warrantyForm.hullNumber = input;
                      setWarrantyForm({ ...warrantyForm });
                    }}
                  />
                </div>
              )}
              <p className='prose-paragraphBase max-w-xl'>
                By using the Humphree Portal to extend warranty, you herby confirm that the installation was done
                according to the Lightning Manuals and that the new warranty will start on the boat delivery date and be
                valid for 24 months from that date.
              </p>
              <div className='flex justify-between flex-wrap'>
                <InvertButton
                  label='Close'
                  onClick={() => {
                    props.close();
                  }}
                />
                <PrimaryButton
                  label='Extend warranty'
                  loading={loading}
                  onClick={() => AddWarranty()}
                  disabled={!warrantyvalid}
                />
              </div>
            </div>
          ) : (
            <div className='flex flex-col gap-8'>
              <div className='flex flex-col gap-4 w-full text-center'>
                <h3 className='prose-heading3'>Lightning Control System warranty form</h3>
                <div className='flex justify-center'>
                  <StatusPill color='Green' text='Active' />
                </div>
                <p className='prose-paragraphBase max-w-xl'>
                  Submission was sent successfully! You will soon receive an email with the warranty information.
                </p>
                <p className='prose-paragraphBase max-w-xl'>
                  Thank you for helping us realise this boats full potential. We hope you will enjoy your extended
                  warranty!
                </p>
                <div className='flex justify-end pt-2'>
                  <PrimaryButton label='Ok' onClick={() => window.location.reload()} />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
