import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popup } from 'component-library';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { HardwareSystem } from '../../../models/ApiModels';
import { UpdateHardwareSystemAsync } from '../../../services/UnitService';
import { ActionType } from '../../../store/actionTypes';

interface Props {
  system: HardwareSystem;
}

export default function RemoveGroup(props: Props) {
  const dispatch = useDispatch();
  const [removeGroupLoading, setRemoveGroupLoading] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);

  const UpdateHardwareSystems = useCallback(() => {
    if (props.system) {
      setRemoveGroupLoading(true);
      props.system.groupId = null;
      UpdateHardwareSystemAsync(props.system)
        .then(() => {
          dispatch({
            type: ActionType.SET_SNACKBAR,
            payload: { heading: 'Successfully removed group from system!', status: 'success' },
          });
        })
        .catch(() => {
          console.log('Something went wrong!');
        })
        .finally(() => {
          setRemoveGroupLoading(false);
          setOpenPopup(false);
        });
    }
  }, [props.system]);

  return (
    <>
      {openPopup && (
        <Popup
          close={() => {
            setOpenPopup(false);
          }}
          confirm={() => UpdateHardwareSystems()}
          heading={'Remove system from group?'}
          paragraph='If you delete the system from the group, it will receive official software versions again. (List update might be acting weird, reload the page in that case)'
          status={'error'}
          confirmText={'Remove from group'}
          cancelText={'Cancel'}
        />
      )}
      <div
        className='sm:ml-auto p-1 px-2 transition-colors mr-4 hover:bg-error-100 hover:text-white'
        onClick={() => {
          props.system.groupId = null;
          setOpenPopup(true);
        }}
      >
        <FontAwesomeIcon icon={faXmark} size='lg' />
      </div>
    </>
  );
}
