import { ChangeEvent } from 'react';
import { SoftwareUpdate } from '../../../models/ApiModels';

interface Props {
  software: SoftwareUpdate;
  editMode: boolean;
  updateSoftware?: SoftwareUpdate;
  setUpdateSoftware: (software: SoftwareUpdate) => void;
}

function SoftwareUpdateDetailsDescription(props: Props) {
  return (
    <div className='flex flex-col'>
      <h1 className='prose-heading4 border-b border-gray-10 pb-3 flex gap-2'>
        <span className='mr-auto'>Description</span>{' '}
      </h1>

      <div className='flex flex-row justify-between border-b border-gray-10 py-5 items-center'>
        <h2 className='prose-paragraphBase mb-1 flex w-full flex-col'>
          {!props.editMode ? (
            <>
              {props.updateSoftware?.description === null || props.updateSoftware?.description === '' ? (
                <div className='italic text-gray-40'>No description</div>
              ) : (
                props.updateSoftware?.description
              )}
            </>
          ) : (
            <textarea
              className='bg-gray-3 max-w-[460px] min-h-[110px] resize-none mt-5 p-2 prose-paragraphSmaller focus:ring-1 focus:ring-primary-100 focus:border-transparent outline-none'
              placeholder='Edit description...'
              value={props.updateSoftware?.description}
              onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                if (props.updateSoftware !== undefined) {
                  props.updateSoftware.description = event.target.value;
                  props.setUpdateSoftware({ ...props.updateSoftware });
                }
              }}
            ></textarea>
          )}
        </h2>
      </div>
    </div>
  );
}

export default SoftwareUpdateDetailsDescription;
