import { faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, Input } from 'component-library';
import { useEffect, useMemo, useState } from 'react';
import { ConversionMeter } from '../../../../helpers/Custom';
import { ValidPositiveNumber } from '../../../../helpers/Validation';
import { BoatModel } from '../../../../models/ApiModels';

type Props = {
  boatModel: BoatModel;
  editMode: boolean;
  setUpdateBoatModel: (boatModel: BoatModel) => void;
  updateBoatModel: BoatModel;
  isMetric: boolean;
  setIsMetric: (value: boolean) => void;
  setIsValid: (value: boolean) => void;
};

function Length(props: Props) {
  const [inputLength, setInputLength] = useState<number>(props.boatModel.length ? props.boatModel.length : 0);

  function CheckIsMetric(id: string) {
    if (id === 'meter') {
      props.setIsMetric(true);
      return props.isMetric;
    } else if (id === 'foot') {
      props.setIsMetric(false);
      return props.isMetric;
    }
  }

  useEffect(() => {
    if (props.updateBoatModel.length !== undefined) {
      setInputLength(ConversionMeter(props.updateBoatModel.length, props.isMetric));
    }
  }, [props.updateBoatModel.length, props.isMetric]);

  const validLength = useMemo(
    () => ValidPositiveNumber(props.updateBoatModel.length) || props.updateBoatModel.length === 0,
    [props.updateBoatModel.length]
  );

  useEffect(() => {
    if (validLength) props.setIsValid(true);
    else props.setIsValid(false);
  }, [validLength]);

  return (
    <div className='border-t border-gray-10 flex justify-between py-5 mt-4 items-center'>
      <span>Length</span>
      {props.editMode ? (
        <div className='flex gap-2'>
          <Input
            size='Small'
            type='number'
            value={inputLength === 0 ? '' : inputLength}
            onChange={(input: number) => setInputLength(input)}
            onBlur={(input: number) => {
              props.updateBoatModel.length = ConversionMeter(input, props.isMetric, true);
              props.setUpdateBoatModel({ ...props.updateBoatModel });
            }}
          />
          <Dropdown
            size='Medium'
            label=''
            optionsData={[
              { id: 'meter', value: 'Meter' },
              { id: 'foot', value: 'Foot' },
            ]}
            placeholder='Meter'
            value={props.isMetric ? 'Meter' : 'Foot'}
            onValueChosen={(id: string) => CheckIsMetric(id)}
          />
        </div>
      ) : (
        <span className={`${!props.boatModel.length && 'italic'}`}>
          {props.boatModel.length ? (
            `${props.boatModel.length.toString()} meter`
          ) : (
            <div className='text-warning-100'>
              <FontAwesomeIcon icon={faTriangleExclamation} /> <span className='text-gray-40'>Not added</span>
            </div>
          )}
        </span>
      )}
    </div>
  );
}

export default Length;
