import { faArrowDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Measurement } from '../../models/ApiModels';
import { GetMeasurementFile } from '../../services/MeasurementsService';

interface Props {
  measurement: Measurement;
}

export default function MeasurementFileDownload(props: Props) {
  async function GetFile() {
    GetMeasurementFile(props.measurement.fileName)
      .then((res: any) => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(res);
        link.download = `${props.measurement.actualFileName}`;
        link.click();
      })
      .catch((err: any) => {
        console.log(err);
      });
  }
  return <FontAwesomeIcon onClick={async () => await GetFile()} icon={faArrowDown} size='lg' />;
}
