import { useContext } from 'react';
import { AppContext } from '../../contexts/AppContext';
import { SettingsUsers } from './SettingsUsers/SettingsUsers';

export function SettingAdministration() {
  const { isHumphreeUser } = useContext(AppContext);
  return (
    <div className='flex flex-col pt-16'>
      <span className='prose-heading4 pb-2'>User administration</span>
      <span className='prose-paragraphSmall text-gray-60 pb-2'>
        Add or change users and permissions to this {!isHumphreeUser && 'OEM'} portal.{' '}
      </span>
      <SettingsUsers />
    </div>
  );
}
