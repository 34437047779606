import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';
import { Input, PrimaryButton } from 'component-library';
import { useMemo, useState } from 'react';
import { Oem } from '../../models/ApiModels';
import { GetCustomers } from '../../services/BusinessCentralService';
import { AddOem } from '../../services/OemService';
import useRequest from '../../utils/net/useRequest';
import { OemAddCustomerItem } from './OemAddCustomerItem';

interface Props {
  close: () => void;
  customerAdded: (oem: Oem) => void;
}

export default function OemAddCustomerModal(props: Props) {
  const [search, setSearch] = useState('');
  const [oems = [], loadingOems] = useRequest<Oem[]>(() => GetCustomers(), []);
  const [addCustomerLoading, setAddCustomerLoading] = useState(false);
  const [customerAdded, setCustomerAdded] = useState<string[]>([]);

  const customerList = useMemo(() => {
    return oems.filter((oem: Oem) => {
      return (
        oem.name.toLowerCase().includes(search.toLowerCase()) &&
        !customerAdded.some((id: string) => id === oem.businessCentralId)
      );
    });
  }, [search, oems, customerAdded]);

  async function AddCustomer(addOem: Oem) {
    setAddCustomerLoading(true);

    AddOem(addOem)
      .then((response: any) => {
        setCustomerAdded([...customerAdded, addOem.businessCentralId]);
        props.customerAdded(response);
      })
      .catch(() => {
        console.log('Something went wrong!');
      })
      .finally(() => {
        setAddCustomerLoading(false);
        props.close(); //closes after adding the oem and email
      });
  }

  return (
    <>
      <div className='py-20 md:py-8 flex flex-col justify-between w-screen h-screen md:h-full md:w-full bg-white'>
        <div className='overflow-y-auto px-8'>
          <div className='flex flex-row w-full justify-between mb-16'>
            <span className='text-primary-400 prose-heading2'>Add customer</span>
            <div>
              <Input
                icon={faMagnifyingGlass}
                iconbefore
                placeholder='Search for OEM...'
                onChange={(value: string) => setSearch(value)}
              />
            </div>
          </div>
          {loadingOems || addCustomerLoading ? (
            <div className='flex items-center justify-center '>
              <div className='w-12 h-12 border-l-2 border-primary-400 rounded-full animate-spin'></div>
            </div>
          ) : (
            <div className='flex flex-col'>
              <span className='text-primary-400 prose-heading3 mb-7'>All customers from Business Central</span>
              <div className='border border-gray-10 border-b-0 mb-14'>
                {customerList.map((oem: Oem) => {
                  return (
                    <OemAddCustomerItem
                      key={oem.businessCentralId}
                      customer={oem}
                      addCustomer={async () => await AddCustomer(oem)}
                    />
                  );
                })}
              </div>
            </div>
          )}
        </div>
        <div className='flex justify-end flex-row w-full mt-8 items-center px-8'>
          <PrimaryButton label='Back' size='small' onClick={() => props.close()} />
        </div>
      </div>
    </>
  );
}
