import { useOutletContext } from 'react-router-dom';

export default function ConfigurationWarranty() {
  const [system] = useOutletContext<any>();
  const formatDate = (date: Date | string) => date.toString().slice(0, 10);
  return (
    <div>
      <div className='border-b border-gray-10'>
        <div className='py-3'>
          <h1 className='prose-heading4'>Warranty form data</h1>
        </div>
      </div>
      <div className='flex flex-row justify-between border-b border-gray-10 py-5 items-center w-full whitespace-nowrap'>
        <div className='prose-paragraphBase mb-1 flex flex-row w-full whitespace-pre-wrap gap-2'>
          <span>System voltage</span>
        </div>
        <span>{system.systemVoltage}</span>
      </div>
      <div className='flex flex-row justify-between border-b border-gray-10 py-5 items-center w-full whitespace-nowrap'>
        <div className='prose-paragraphBase mb-1 flex flex-row w-full whitespace-pre-wrap gap-2'>
          <span>Rudder signal</span>
        </div>
        <span>{system.rudderSignal ? 'Yes' : 'No'}</span>
      </div>
      <div className='flex flex-row justify-between border-b border-gray-10 py-5 items-center w-full whitespace-nowrap'>
        <div className='prose-paragraphBase mb-1 flex flex-row w-full whitespace-pre-wrap gap-2'>
          <span>Form submission date</span>
        </div>
        <span>{system.warrantySubmissionDate && formatDate(system.warrantySubmissionDate)}</span>
      </div>
    </div>
  );
}
