type LoadingSpinnerProps = {
  text?: string;
};

export const LoadingSpinner = ({ text }: LoadingSpinnerProps) => {
  return (
    <div className='grid place-items-center gap-4'>
      <span className='text-primary-400 prose-heading4'>{text ? text : 'Loading...'}</span>
      <div className='w-12 h-12 border-l-2 border-primary-400 rounded-full animate-spin'></div>
    </div>
  );
};
