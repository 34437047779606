import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useDetectClickOutside } from 'react-detect-click-outside';

interface Props {
  value: string;
  options: any[];
  setDate: (date: any) => void;
}

function DateComparisonDropdown(props: Props) {
  const [openDropdown, setOpenDropdown] = useState(false);
  const ref = useDetectClickOutside({
    onTriggered: () => {
      setOpenDropdown(!openDropdown);
    },
  });

  return (
    <div
      className='text-primary-100'
      onClick={() => {
        setOpenDropdown(!openDropdown);
      }}
    >
      <span className='cursor-pointer'>
        {props.value} <FontAwesomeIcon icon={faChevronDown} />
      </span>
      {openDropdown && (
        <div ref={ref} className='absolute p-2 bg-gray-3 rounded-lg shadow-popup'>
          {props.options.map((item) => {
            return (
              <div
                className='p-2 hover:bg-gray-5'
                key={item.value}
                onClick={() => {
                  props.setDate(item);
                }}
              >
                {item.value}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default DateComparisonDropdown;
