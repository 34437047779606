import { Inventory } from '../../models/ApiModels';
import { Action, ActionType } from '../actionTypes';

interface State {
  inventory: Inventory[];
}

const initialState: State = {
  inventory: [],
};

const inventoryReducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case ActionType.GET_INVENTORY:
      return {
        inventory: action.payload,
      };
    case ActionType.ADD_INVENTORY: {
      const newArray = [...state.inventory, action.payload]; //Copying state array
      return {
        inventory: newArray,
      };
    }
    default:
      return state;
  }
};

export default inventoryReducer;
