import { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AppContext } from '../../contexts/AppContext';

enum Type {
  Administration = 'Administration',
  Order = 'Order',
  Customers = 'Customers',
  Prices = 'Prices',
}

interface Props {
  currentTab: string;
  changeTab: (value: string) => void;
}
export function SettingsTabs(props: Props) {
  const location = useLocation();
  const { currentOemTenantId, currentOem, isBusinessCustomer } = useContext(AppContext);

  return (
    <div className='flex items-center w-full border-b border-gray-10 gap-8 mt-10'>
      <Link
        to='admin'
        className={`py-4 cursor-pointer w-max ${
          !location.pathname.includes('order') &&
          !location.pathname.includes('customers') &&
          !location.pathname.includes('prices') &&
          'border-b-2 border-gray-100'
        }`}
        onClick={() => props.changeTab(Type.Administration)}
      >
        <span
          className={`${
            !location.pathname.includes('order') &&
            !location.pathname.includes('customers') &&
            !location.pathname.includes('prices')
              ? 'font-bold'
              : 'font-normal'
          } text-primary-400 prose-paragraphBase`}
        >
          Administration
        </span>
      </Link>

      {/* Business customer view */}
      {currentOemTenantId !== '12345678-1234-1234-1234-123456789000' && isBusinessCustomer && (
        <>
          <Link
            to='order'
            className={`py-4 cursor-pointer w-max ${
              location.pathname.includes('order') && 'border-b-2 border-gray-100'
            }`}
            onClick={() => props.changeTab(Type.Order)}
          >
            <span
              className={`${
                location.pathname.includes('order') ? 'font-bold' : 'font-normal'
              } text-primary-400 prose-paragraphBase`}
            >
              Order
            </span>
          </Link>
          <Link
            to='customers'
            className={`py-4 cursor-pointer w-max ${
              location.pathname.includes('customers') && 'border-b-2 border-gray-100'
            }`}
            onClick={() => props.changeTab(Type.Customers)}
          >
            <span
              className={`${
                location.pathname.includes('customers') ? 'font-bold' : 'font-normal'
              } text-primary-400 prose-paragraphBase`}
            >
              Customers
            </span>
          </Link>
        </>
      )}
      {/* Indirect customer view */}
      {currentOemTenantId !== '12345678-1234-1234-1234-123456789000' && !isBusinessCustomer && (
        <Link
          to='prices'
          className={`py-4 cursor-pointer w-max ${
            location.pathname.includes('prices') && 'border-b-2 border-gray-100'
          }`}
          onClick={() => props.changeTab(Type.Prices)}
        >
          <span
            className={`${
              location.pathname.includes('prices') ? 'font-bold' : 'font-normal'
            } text-primary-400 prose-paragraphBase`}
          >
            Prices
          </span>
        </Link>
      )}
    </div>
  );
}
