import { PrimaryButton } from 'component-library';
import { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import { System, SystemComment, User } from '../../../models/ApiModels';
import { DeleteComment, UpdateCommentAsync } from '../../../services/SystemService';
import { GetUserNames, GetUsersAsync } from '../../../services/UserService';
import useRequest from '../../../utils/net/useRequest';
import SystemDetailsCommentItem from './SystemDetailsCommentItem';

interface Props {
  system: System;
  updateSystemWithComments: (comment: string) => void;
  savingComment: boolean;
  editMode: boolean;
  setSystem: (system: System) => void;
}

function SystemDetailsComments(props: Props) {
  const { isHumphreeUser } = useContext(AppContext);
  const [users = [], isUsersLoading, usersGetError] = useRequest(() => GetUsersAsync(), []);
  const [commentText, setCommentText] = useState('');
  const [humphreeUsers, setHumphreeUsers] = useState<any>([]);
  const componentMounted = useRef(true);

  useEffect(() => {
    GetUsers();

    return () => {
      // This code runs when component is unmounted
      componentMounted.current = false; // (4) set it to false when we leave the page
    };
  }, [props.system.comments]);

  function GetUsers() {
    const commentsIdsArray: string[] = [];
    props.system?.comments?.forEach((comment: SystemComment) => {
      if (comment.userId !== undefined) {
        commentsIdsArray.push(comment.userId);
      }
    });
    if (commentsIdsArray.length > 0) {
      GetUserNames(commentsIdsArray).then((response) => setHumphreeUsers(response));
    }
  }

  const deleteComment = (commentId?: number) => {
    props.system.comments = props.system.comments?.filter((item) => item.id !== commentId);
    DeleteComment(props.system, commentId).then(() => {
      props.setSystem({ ...props.system });
    });
  };

  const UpdateComment = (comment: SystemComment) => {
    UpdateCommentAsync(props.system, comment).then(() => {
      props.setSystem({ ...props.system });
    });
  };

  return (
    <div className='flex flex-col'>
      <div className='border-b border-gray-10 pb-5'>
        <h1 className='prose-heading4'>Comments</h1>
      </div>
      <div>
        {props.system?.comments?.map((comment: SystemComment, index) => {
          const user: User = users.find((user: User) => user.id === comment.userId);
          const humphreeUser = humphreeUsers.find((user: any) => user.userId === comment.userId)?.userName;

          return (
            <SystemDetailsCommentItem
              key={index}
              comment={comment}
              deleteComment={() => deleteComment(comment?.id)}
              updateComment={(updatedComment: SystemComment) => {
                if (props.system.comments && props.system.comments[index]) {
                  props.system.comments[index] = updatedComment;
                  UpdateComment(updatedComment);
                }
              }}
              user={user}
              humphreeUser={humphreeUser}
              isHumphreeUser={isHumphreeUser}
              editMode={props.editMode}
            />
          );
        })}
        {props.savingComment && (
          <div className='flex flex-row justify-start w-full items-center pr-8 gap-6 py-4'>
            <span className='prose-paragraphSmaller flex flex-col max-w-lg'>
              <span className=' prose-paragraphBase italic text-gray-100'>Adding comment...</span>
            </span>
            <div className='w-4 h-4 border-l-2 border-gray-100 rounded-full animate-spin'></div>
          </div>
        )}
      </div>
      <textarea
        className='bg-gray-3 max-w-[460px] min-h-[110px] resize-none mt-5 p-2 prose-paragraphSmaller focus:ring-1 focus:ring-primary-100 focus:border-transparent outline-none'
        placeholder='Add comment...'
        value={commentText}
        onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
          setCommentText(event.target.value);
        }}
      ></textarea>
      <div className='mt-5'>
        <PrimaryButton
          label={props.savingComment ? 'Publishing...' : 'Publish'}
          loading={props.savingComment}
          disabled={commentText.length <= 0 || commentText.trim() === '' || props.savingComment}
          onClick={() => {
            props.updateSystemWithComments(commentText);
          }}
        />
      </div>
    </div>
  );
}

export default SystemDetailsComments;
