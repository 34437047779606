import { useMsal } from '@azure/msal-react';
import { faClone } from '@fortawesome/pro-regular-svg-icons';
import { PrimaryButton, StatusButton } from 'component-library';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { Dashboard } from '../../models/ApiModels';
import { GetDashboard, GetDashboardCompare } from '../../services/DashboardService';
import useRequest from '../../utils/net/useRequest';
import DashBoardDataLicenses from './DashBoardDataLicenses';
import DashBoardDataOverview from './DashBoardDataOverview';
import DashboardDataProduction from './DashboardDataProduction';
import DashboardDataSoftware from './DashboardDataSoftware';
import DashboardDataSystems from './DashboardDataSystems';
import DateComparisonDropDown from './DateComparisonDropDown';

function DashboardHome() {
  const dateOptionsOne = useMemo(
    () => [
      {
        value: 'today',
        date: moment().format('YYYY-MM-DD'),
      },
      {
        value: 'yesterday',
        date: moment().subtract(1, 'days').format('YYYY-MM-DD'),
      },
      {
        value: 'last week',
        date: moment().subtract(6, 'days').format('YYYY-MM-DD'),
      },
      {
        value: 'last month',
        date: moment().subtract(1, 'months').format('YYYY-MM-DD'),
      },
    ],
    []
  );

  const dateOptionsTwo = useMemo(
    () => [
      {
        value: 'yesterday',
        date: moment().subtract(1, 'days').format('YYYY-MM-DD'),
      },
      {
        value: 'last week',
        date: moment().subtract(6, 'days').format('YYYY-MM-DD'),
      },
      {
        value: 'last month',
        date: moment().subtract(1, 'months').format('YYYY-MM-DD'),
      },
    ],
    []
  );

  const [startDate, setStartDate] = useState(dateOptionsOne[0]);
  const [endDate, setEndDate] = useState(dateOptionsTwo[0]);
  const { accounts } = useMsal();
  const account = accounts[0];
  const [compare, setCompare] = useState(false);
  const [dashboardData, isDashboardDataLoading] = useRequest<Dashboard>(() => GetDashboard(), []);
  const [dashboardDataCompare, isDashboardDataCompareLoading] = useRequest<Dashboard>(
    () =>
      compare &&
      GetDashboardCompare(moment(startDate.date).format('YYYY-MM-DD'), moment(endDate.date).format('YYYY-MM-DD')),
    [startDate, endDate, compare]
  );

  return (
    <div className='max-w-[2000px]'>
      {isDashboardDataLoading || isDashboardDataCompareLoading ? (
        <div className='flex items-center flex-col gap-4 h-96 justify-center'>
          <span className='text-primary-400 prose-heading5'>Loading dashboard...</span>
          <div className='w-12 h-12 border-l-2 border-primary-400 rounded-full animate-spin'></div>
        </div>
      ) : (
        <>
          <div className='header mb-10'>
            <div className='prose-heading3 flex'>
              <div>
                Welcome, <span className='text-primary-100'>{account?.name}</span>
              </div>
              <div className='ml-auto'>
                {!compare ? (
                  <PrimaryButton icon={faClone} label={'Compare'} onClick={() => setCompare(!compare)} />
                ) : (
                  <StatusButton
                    icon={faClone}
                    label={'Stop comparing'}
                    onClick={() => setCompare(!compare)}
                    status={'error'}
                  />
                )}
              </div>
            </div>
            {compare && (
              <>
                <div className='flex relative prose-heading4 gap-2'>
                  Total data
                  <DateComparisonDropDown
                    value={startDate.value}
                    options={dateOptionsOne}
                    setDate={(date) => setStartDate(date)}
                  />
                  compared with
                  <DateComparisonDropDown
                    value={endDate.value}
                    options={dateOptionsTwo}
                    setDate={(date) => setEndDate(date)}
                  />
                </div>
              </>
            )}
            <span className='prose-paragraphBase'>The dashboard is updated daily for you.</span>
          </div>
          {dashboardData && !compare && (
            <div>
              <DashBoardDataOverview dashboardData={dashboardData} compare={compare} />
              <DashboardDataSystems dashboardData={dashboardData} compare={compare} />
              <DashBoardDataLicenses dashboardData={dashboardData} compare={compare} />
              <DashboardDataSoftware dashboardData={dashboardData} compare={compare} />
              <DashboardDataProduction dashboardData={dashboardData} compare={compare} />
            </div>
          )}
          {dashboardDataCompare && compare && (
            <div>
              <DashBoardDataOverview dashboardData={dashboardDataCompare} compare={compare} />
              <DashboardDataSystems dashboardData={dashboardDataCompare} compare={compare} />
              <DashBoardDataLicenses dashboardData={dashboardDataCompare} compare={compare} />
              <DashboardDataSoftware dashboardData={dashboardDataCompare} compare={compare} />
              <DashboardDataProduction dashboardData={dashboardDataCompare} compare={compare} />
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default DashboardHome;
