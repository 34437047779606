import { faChevronLeft, faChevronRight, faUser } from '@fortawesome/pro-regular-svg-icons';
import axios from 'axios';
import { Checkbox, Input, PrimaryButton, StatusPill } from 'component-library';
import { useMemo, useState } from 'react';
import { Config } from '../../config';
import { Warranty } from '../../models/ApiModels';
import WarrantyFormPrivacy from './WarrantyFormPrivacy';

interface Props {
  warrantyForm: Warranty;
  setNewWarranty: (newWarranty: Warranty) => void;
  page: number;
  setPage: (value: number) => void;
  checkList: any;
  setCheckList: (value: any) => void;
}

function WarrantyFormSubmit(props: Props) {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  async function AddWarranty() {
    const data = props.warrantyForm;
    setLoading(true);
    return axios
      .post(`${Config.api_url}v1/warranty/lightning`, data)
      .then((response) => {
        setLoading(false);
        setSuccess(true);
        return response.data;
      })
      .catch((error) => {
        if (error.response.data) {
          setLoading(false);
          console.log('Something went wrong');
        }
      });
  }

  const [showPrivacyNotice, setShowPrivacyNotice] = useState(false);
  const submitValid = useMemo(() => {
    if (
      props.checkList.hasConsent &&
      props.checkList.hasCorrectDetails &&
      props.warrantyForm.submissionerEmail !== ''
    ) {
      return true;
    } else false;
  }, [props.checkList, props.warrantyForm]);

  return (
    <>
      {loading ? (
        <div className='fixed top-0 left-0 right-0 bottom-0 z-10 flex justify-center items-center'>
          <span className='font-bold flex flex-col gap-4 justify-center items-center'>
            Loading...
            <div className='w-12 h-12 border-l-2 border-primary-400 rounded-full animate-spin'></div>
          </span>
        </div>
      ) : (
        <>
          {success ? (
            <>
              <div>
                <img src='/humphree-logo-black.png' className='m-auto mb-4' />
                <span className='prose-heading3 text-primary-400'>Lightning Control System warranty form</span>
              </div>
              <StatusPill color={'Green'} text={'Success'} />
              Submission was sent successfully! You will soon receive an email with the warranty information.
              <br />
              <br />
              Thank you for helping us realise this boats full potential. We hope you will enjoy your extended warranty!
            </>
          ) : (
            <div className='flex flex-col gap-3 items-center w-full'>
              <div className='bg-primary-200 text-white prose-heading3 rounded-full p-2 flex justify-center items-center w-14'>
                5
              </div>
              <div className='prose-paragraphBase mb-5'>Submit</div>
              <div className='flex items-center justify-center gap-3 w-full pb-5 border-b border-gray-10'>
                <Input
                  value={props.warrantyForm.submissionerEmail}
                  onChange={(value: string) => {
                    props.warrantyForm.submissionerEmail = value;
                    props.setNewWarranty({ ...props.warrantyForm });
                  }}
                  label='Your email'
                  placeholder='Enter text input...'
                  icon={faUser}
                />
              </div>
              <div className='text-left'>
                <div className='prose-buttonStandard mb-3'>
                  The details in this form will be used to register the system&apos;s warranty. You will receive a
                  confirmation email at the address you entered.
                  <br />
                  <br /> Humphree AB and its partners can always check the warranty status of this system in case you
                  need any help.
                  <br />
                  <br /> If the information posted in this form is incorrect, the warranty will be invalid, and no
                  warranty claims can be made during the extended warranty period. We won&apos;t send any other emails
                  to your address or share it with third parties. The details in this form will be used to register the
                  systems warranty. You will receive a confirmation email to the address you entered.
                </div>
                <div className='prose-labelPill flex flex-col gap-3 border-b pb-5 border-gray-10'>
                  <div className='flex justify-between'>
                    <div>
                      I consent to Humphree AB storing my email<span className='text-error-100'>*</span>
                      <br />
                      <span
                        onClick={() => setShowPrivacyNotice(true)}
                        className='cursor-pointer text-primary-100 underline'
                      >
                        Privacy notice
                      </span>
                    </div>
                    <Checkbox
                      active={props.checkList.hasConsent === true}
                      onClick={() => {
                        props.checkList.hasConsent = !props.checkList.hasConsent;
                        props.setCheckList({ ...props.checkList });
                      }}
                    />
                  </div>
                  <div className='flex items-center justify-between'>
                    <div>
                      I confirm that the details entered is correct<span className='text-error-100'>*</span>
                    </div>
                    <Checkbox
                      active={props.checkList.hasCorrectDetails}
                      onClick={() => {
                        props.checkList.hasCorrectDetails = !props.checkList.hasCorrectDetails;
                        props.setCheckList({ ...props.checkList });
                      }}
                    />
                  </div>
                </div>
                <div className='flex justify-between mt-10'>
                  <PrimaryButton
                    onClick={() => props.setPage(props.page - 1)}
                    disabled={props.page === 0}
                    icon={faChevronLeft}
                    label='Go back'
                  />
                  <PrimaryButton
                    disabled={!submitValid}
                    onClick={() => {
                      AddWarranty();
                    }}
                    iconAfter
                    icon={faChevronRight}
                    label='Submit form'
                  />
                </div>
              </div>
              {showPrivacyNotice && <WarrantyFormPrivacy close={() => setShowPrivacyNotice(false)} />}
            </div>
          )}
        </>
      )}
    </>
  );
}
export default WarrantyFormSubmit;
