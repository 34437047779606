import { faChevronRight, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popup, StatusButton } from 'component-library';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { SoftwareGroup } from '../../../models/ApiModels';
import {
  DeleteSoftwareGroupsInfo,
  GetSoftwareGroupsInfo,
  GetSoftwareUpdate,
  UpdateGroupsInfo,
} from '../../../services/SoftwareUpdateService';
import { ActionType } from '../../../store/actionTypes';
import useRequest from '../../../utils/net/useRequest';
import SoftwareUpdateGroupDetailsInfo from './SoftwareUpdateGroupDetailsInfo';
import SoftwareUpdateGroupDetailsSystems from './SoftwareUpdateGroupDetailsSystems';
import SoftwareUpdateGroupDetailsVersions from './SoftwareUpdateGroupDetailsVersions';

function SoftwareUpdateGroupDetails() {
  const initUpdateGroup: SoftwareGroup = {
    id: '',
    groupName: '',
    groupDescription: '',
    assignedSystemType: '',
    lcpFirmwareId: '',
    lcuFirmwareId: '',
    lsuFirmwareId: '',
    isForceDownload: false,
  };

  const [softwareUpdate = [], isSoftwareUpdateLoading] = useRequest(() => GetSoftwareUpdate(), []);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [edit, setEdit] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [group, setGroup] = useState<SoftwareGroup>();
  const [updateGroup, setUpdateGroup] = useState<SoftwareGroup>(initUpdateGroup);
  const componentMounted = useRef(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const hasChanges = useMemo(() => {
    return !_.isEqual(group, updateGroup);
  }, [updateGroup]);

  useEffect(() => {
    GetSoftwareInfo();
    return () => {
      componentMounted.current = false;
    };
  }, [id]);

  useEffect(() => {
    GetSoftwareInfo();
  }, [id]);

  function GetSoftwareInfo() {
    setLoading(true);
    if (id !== undefined) {
      GetSoftwareGroupsInfo(id).then((group: SoftwareGroup) => {
        if (componentMounted.current) {
          setGroup({ ...group });
          setUpdateGroup({ ...group });
        }
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }

  const UpdateGroup = useCallback(() => {
    setLoading(true);
    if (updateGroup !== undefined) {
      UpdateGroupsInfo(updateGroup)
        .then(() => {
          dispatch({
            type: ActionType.SET_SNACKBAR,
            payload: { heading: 'Successfully updated software!', status: 'success' },
          });
          setTimeout(() => {
            setEdit(false);
            GetSoftwareInfo();
          }, 1);
        })
        .catch(() => {
          console.log('Something went wrong!');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [updateGroup]);

  const DeleteGroup = useCallback(() => {
    setOpenDeletePopup(false);
    setLoading(true);
    if (group !== undefined) {
      DeleteSoftwareGroupsInfo(group)
        .then(() => {
          dispatch({
            type: ActionType.SET_SNACKBAR,
            payload: { heading: 'Successfully deleted group!', status: 'success' },
          });
          navigate('/software');
        })
        .catch((error) => {
          dispatch({
            type: ActionType.SET_SNACKBAR,
            payload: { heading: error.message, status: 'error' },
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [group]);

  function GoBack() {
    if (hasChanges) {
      setOpenPopup(true);
    } else {
      navigate('/software');
    }
  }

  function DiscardChanges() {
    if (!_.isEqual(group, updateGroup) && group) {
      setUpdateGroup({ ...group });
    }
    setOpenPopup(false);
    setEdit(false);
  }

  return (
    <div>
      <div>
        {loading && isSoftwareUpdateLoading ? (
          <div className='flex items-center flex-col gap-4 h-96 justify-center'>
            <span className='text-primary-400 prose-heading5'>Loading software group details...</span>
            <div className='w-12 h-12 border-l-2 border-primary-400 rounded-full animate-spin'></div>
          </div>
        ) : group && softwareUpdate ? (
          <>
            <div className='flex justify-between items-center mb-10'>
              <h1 className='md:prose-heading4 2xl:prose-heading3 items-center gap-1'>
                <span className='cursor-pointer' onClick={() => GoBack()}>
                  Group
                </span>
                <>
                  <FontAwesomeIcon icon={faChevronRight} className='mx-2' size='xs' />
                  {group.assignedSystemType}
                </>
                <>
                  <FontAwesomeIcon icon={faChevronRight} className='mx-2' size='xs' />
                  {group.groupName}
                </>
              </h1>
            </div>

            <div className='flex flex-col gap-16 pt-16'>
              <SoftwareUpdateGroupDetailsInfo
                group={group}
                setGroup={(group: SoftwareGroup) => setGroup({ ...group })}
                editMode={edit}
                setEditMode={() => setEdit(!edit)}
                hasChanges={hasChanges}
                openPopup={openPopup}
                setOpenPopup={() => setOpenPopup(!openPopup)}
                UpdateGroup={() => UpdateGroup()}
                updateGroup={updateGroup}
                setUpdateGroup={(updateGroup: SoftwareGroup) => setUpdateGroup({ ...updateGroup })}
              />
              <SoftwareUpdateGroupDetailsVersions
                group={group}
                updateGroup={updateGroup}
                setUpdateGroup={(newUpdateGroup: SoftwareGroup) => {
                  setUpdateGroup({ ...newUpdateGroup });
                }}
                softwareUpdate={softwareUpdate}
                editMode={edit}
                openPopup={openPopup}
                setOpenPopup={() => setOpenPopup(!openPopup)}
              />
              <SoftwareUpdateGroupDetailsSystems group={group} />
              <div className='flex justify-between items-center'>
                <span className='prose-buttonStandard'>Groups needs to be empty before deletion</span>
                <StatusButton
                  onClick={() => setOpenDeletePopup(true)}
                  icon={faTrash}
                  label='Delete software group'
                  status={'error'}
                />
              </div>
            </div>
            {openPopup && (
              <Popup
                close={() => setOpenPopup(false)}
                confirm={() => DiscardChanges()}
                heading='Discard software changes?'
                paragraph='You will lose all the changes made to this software.'
                status='error'
                cancelText='Keep editing'
                confirmText='Discard changes'
              />
            )}
            {openDeletePopup && (
              <Popup
                close={() => setOpenDeletePopup(false)}
                confirm={() => DeleteGroup()}
                heading='Delete software group?'
                paragraph='Well, it’s empty and of no use. I understand. If you really want it back you can always create another one.'
                status='error'
                cancelText='Cancel'
                confirmText='Delete group'
              />
            )}
          </>
        ) : (
          <>error</>
        )}
      </div>
    </div>
  );
}

export default SoftwareUpdateGroupDetails;
