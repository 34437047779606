import { InvertButton, PrimaryButton } from 'component-library';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BoatModel } from '../../../models/ApiModels';
import { AddBoatModel } from '../../../services/BoatModelsService';
import { ActionType } from '../../../store/actionTypes';
import Dimensions from './CreateBoatModelSpecifications/Dimensions';
import Flybridge from './CreateBoatModelSpecifications/Flybridge';
import Propulsion from './CreateBoatModelSpecifications/Propulsion';
import Speed from './CreateBoatModelSpecifications/Speed';
import Status from './CreateBoatModelSpecifications/Status';
import Weight from './CreateBoatModelSpecifications/Weight';

interface Props {
  newBoatModel: BoatModel;
  setNewBoatModel: (boatModel: BoatModel) => void;
  page: number;
  setPage: (value: number) => void;
  GoBack: () => void;
}

function CreateBoatModelSpecifications(props: Props) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const saveBoatModel = useCallback((newBoatModel: BoatModel) => {
    setLoading(true);
    AddBoatModel(newBoatModel)
      .then((response: any) => {
        setLoading(false);
        dispatch({
          type: ActionType.SET_SNACKBAR,
          payload: { heading: 'You created a new boat model!', status: 'success' },
        });
        dispatch({
          type: ActionType.ADD_BOATMODELS,
          payload: response,
        });
        setTimeout(() => {
          navigate(`/models/${response.id}`);
        }, 1);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div className='w-full flex flex-col pb-16'>
      <div className='flex flex-col mb-14'>
        <span className='prose-heading4 text-primary-400 py-3'>Step 2 of 2 - Specifications</span>
        <span className='prose-paragraphBig border-b border-gray-10 py-3'>
          Optional data about the boat. Can be filled in later. No other partner can access this data, regardless of
          status choice.
        </span>
      </div>
      <div className='flex flex-col gap-4'>
        <Dimensions
          newBoatModel={props.newBoatModel}
          setNewBoatModel={(boatModel: BoatModel) => props.setNewBoatModel({ ...boatModel })}
        />
        <Weight
          newBoatModel={props.newBoatModel}
          setNewBoatModel={(boatModel: BoatModel) => props.setNewBoatModel({ ...boatModel })}
        />
        <Speed
          newBoatModel={props.newBoatModel}
          setNewBoatModel={(boatModel: BoatModel) => props.setNewBoatModel({ ...boatModel })}
        />
        <Propulsion
          newBoatModel={props.newBoatModel}
          setNewBoatModel={(boatModel: BoatModel) => props.setNewBoatModel({ ...boatModel })}
        />
        <Flybridge
          newBoatModel={props.newBoatModel}
          setNewBoatModel={(boatModel: BoatModel) => props.setNewBoatModel({ ...boatModel })}
        />
        <Status
          newBoatModel={props.newBoatModel}
          setNewBoatModel={(boatModel: BoatModel) => props.setNewBoatModel({ ...boatModel })}
        />
      </div>
      <div className='flex justify-end gap-2 pt-10'>
        <InvertButton onClick={() => props.GoBack()} label='Cancel' disabled={loading} />
        <PrimaryButton
          disabled={loading}
          onClick={() => {
            props.setNewBoatModel({ ...props.newBoatModel });
            saveBoatModel(props.newBoatModel);
          }}
          label='Save boat model'
        />
      </div>
    </div>
  );
}

export default CreateBoatModelSpecifications;
