import { Checkbox, StatusPill } from 'component-library';
import { BoatModel } from '../../../../models/ApiModels';

type Props = {
  boatModel: BoatModel;
  editMode: boolean;
  setUpdateBoatModel: (boatModel: BoatModel) => void;
  updateBoatModel: BoatModel;
};
function Flybridge(props: Props) {
  return (
    <div className='border-b border-gray-10 flex justify-between items-center py-5'>
      <span className='flex gap-1'>Flybridge</span>
      {props.editMode && props.updateBoatModel ? (
        <span className='flex gap-2'>
          Boat model has a flybridge
          <Checkbox
            active={props.updateBoatModel.flyBridgeOption}
            onClick={() => {
              props.updateBoatModel.flyBridgeOption = !props.updateBoatModel.flyBridgeOption;
              props.setUpdateBoatModel(props.updateBoatModel);
            }}
          />
        </span>
      ) : (
        <>
          {props.boatModel.flyBridgeOption ? (
            <StatusPill color='Dark Gray' text='With flybridge' />
          ) : (
            <StatusPill color='Light Gray' text='No flybridge' />
          )}
        </>
      )}
    </div>
  );
}

export default Flybridge;
