import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Banner, Checkbox, Dropdown, Tooltip } from 'component-library';
import { useContext, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppContext } from '../../../contexts/AppContext';
import { Feature, Package, System, SystemDetail } from '../../../models/ApiModels';

import PermissionGate, { ROLES } from '../../../utils/PermissionGate';

type Props = {
  system: System;
  setSystem: (system: SystemDetail) => void;
  editMode?: boolean;
  showUpgradeLicense?: boolean;
  setShowUpgradeLicense: (value: boolean) => void;
  topRef: any;
};

const SystemDetailsSoftware = (props: Props) => {
  const { isHumphreeUser, isBusinessCustomer } = useContext(AppContext);
  const [showBanner, setShowBanner] = useState(true);
  const packageTable = useSelector((state: any) => state.configurations.packages);
  const addonFeatures: Feature[] = useSelector((state: any) => state.configurations.features);
  const manualPackageOptionInDropdown = { id: '', value: 'Manual' };

  const hasUpgrades = useMemo(() => {
    const currentPackage: Package = packageTable.find((pack: Package) => pack.id === props.system?.packageId);

    return (
      currentPackage?.availableUpgrades.length > 0 ||
      !currentPackage ||
      !props.system.addonFeatureIds.includes('FEAT-7-1')
    );
  }, [props.system.packageId, props.system.addonFeatureIds]);

  const goToShowUpgrades = () => {
    props.setShowUpgradeLicense(true);
    scrollToTop();
  };

  const scrollToTop = () => {
    props.topRef?.current?.scrollIntoView({ behavior: 'auto' });
  };

  function GetPackageName() {
    return packageTable.find((p: Package) => p.id === props.system.packageId)?.displayName;
  }

  return (
    <div className='flex flex-col'>
      <div className='border-b border-gray-10 pb-5'>
        <h1 className='prose-heading4 flex justify-between'>Humphree license</h1>
      </div>
      <PermissionGate roles={[ROLES.admin]} rejected={undefined}>
        {props.editMode && showBanner && (
          <div className='mt-5 w-full'>
            <Banner
              icon={true}
              warning={false}
              text={
                'Changing the license will manually override the current license. The customer will not receive an invoice. Use the upgrade license function to upgrade system.'
              }
              color={'Super Light Blue'}
              dismissBanner={() => setShowBanner(!showBanner)}
            />
          </div>
        )}
      </PermissionGate>
      <div>
        <div className='flex justify-between items-center border-b border-gray-10 py-5'>
          <h4 className='prose-paragraphBase flex gap-2 w-full'>
            {!props.system?.packageId ? 'No license added' : 'Function package'}
            <Tooltip
              description={
                !props.system?.packageId
                  ? 'No license added'
                  : 'Function packages includes automatic functions that can be selected to get the preferred level of automation to a boat. The available function packages are Comfort, Comfort Plus, Dynamic and Dynamic Plus.'
              }
              darkmode
            />
          </h4>
          <span className='w-[250px] text-right'>
            {props.editMode ? (
              <div className='w-[250px]'>
                <PermissionGate roles={[ROLES.admin]} rejected={undefined}>
                  <Dropdown
                    label={''}
                    optionsData={[
                      ...packageTable.map((p: Package) => {
                        return { id: p.id, value: p.displayName };
                      }),
                      manualPackageOptionInDropdown,
                    ]}
                    onValueChosen={(value: string) => {
                      props.system.packageId = value;
                      props.setSystem(props.system);
                    }}
                    placeholder={''}
                    disabled={!isHumphreeUser}
                    value={`${
                      props.system?.packageId
                        ? packageTable.find((packages: Package) => packages.id === props.system?.packageId)?.displayName
                        : 'Manual'
                    }`}
                  />
                </PermissionGate>
              </div>
            ) : (
              <div>
                {props.system?.packageId
                  ? packageTable.find((packages: Package) => packages.id === props.system?.packageId)?.displayName
                  : 'Manual'}
              </div>
            )}
          </span>
        </div>
        <div className='flex justify-between items-center border-b border-gray-10 py-5 w-full'>
          <h4 className='prose-paragraphBase flex gap-2 w-full'>
            Additional features
            <Tooltip
              description='Features that can be additionally selected to a license, such as underwater lights.'
              darkmode
            />
          </h4>
          {(props.editMode || props.system.addonFeatureIds.length > 0) && (
            <div className='flex flex-row w-full gap-5 justify-end'>
              {addonFeatures.map((addonFeature: Feature) => {
                return (
                  addonFeature.addon && (
                    <div key={addonFeature.id} className='flex flex-row items-center gap-4'>
                      {props.editMode && (
                        <PermissionGate roles={[ROLES.admin]} rejected={undefined}>
                          <Checkbox
                            active={props.system.addonFeatureIds.some((id: string) => id === addonFeature.id)}
                            disabled={!isHumphreeUser}
                            onClick={() => {
                              const index = props.system.addonFeatureIds.findIndex(
                                (id: string) => id === addonFeature.id
                              );
                              if (index > -1) {
                                props.system.addonFeatureIds.splice(index, 1);
                              } else {
                                props.system.addonFeatureIds.push(addonFeature.id);
                              }
                              props.setSystem(props.system);
                            }}
                          />
                        </PermissionGate>
                      )}
                      <h2 className='prose-paragraphBase'>{addonFeature.displayName}</h2>
                    </div>
                  )
                );
              })}
            </div>
          )}
        </div>
      </div>
      {hasUpgrades && (
        <PermissionGate roles={[ROLES.admin, ROLES.editor, ROLES.oemAdmin]} rejected={undefined}>
          <div className='flex justify-end pt-5 items-center prose-buttonStandard'>
            <span className='cursor-pointer' onClick={() => goToShowUpgrades()}>
              Upgrades available
            </span>
            <FontAwesomeIcon icon={faArrowRight} className='mx-2' size='lg' />
          </div>
        </PermissionGate>
      )}
    </div>
  );
};

export default SystemDetailsSoftware;
