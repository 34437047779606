import { Input, Tooltip } from 'component-library';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { useSelector } from 'react-redux';
import { Oem, System } from '../../../models/ApiModels';

interface Props {
  setNewSystem: (newSystem: System) => void;
  newSystem: System;
}

function CreateSystemInformation(props: Props) {
  const oems = useSelector((state: any) => state.oems.data);
  const [searchValue, setSearchValue] = useState<string>('');
  const [showResult, setShowResult] = useState(false);
  const [searchResult, setSearchResult] = useState<Oem[]>([]);

  const ref = useDetectClickOutside({
    onTriggered: () => {
      setSearchValue('');
      setShowResult(false);
    },
  });

  function FreewordFilter(filteredList: Oem[]) {
    filteredList = filteredList.filter((oem) => {
      const flatObject = Object.values(oem);
      return flatObject.some(
        (object: object) => object && object.toString().toLowerCase().includes(searchValue.toLowerCase())
      );
    });
    setSearchResult(filteredList);
  }

  useEffect(() => {
    FreewordFilter(oems);
  }, [searchValue]);

  return (
    <div className='w-full flex flex-col pb-16'>
      <span className='border-b w-full prose-heading4 border-gray-10 pb-5'>System Information</span>
      <div className='pt-4 flex flex-col gap-6 w-1/3 relative'>
        <div>
          <div className='flex gap-2'>
            <span className='prose-paragraphBase mb-2'>
              Add OEM <span className=' text-error-100'>*</span>
            </span>
            <Tooltip
              description='OEM’s are boat builders who purchase and install Humphree systems.'
              darkmode
            />
          </div>
          <input
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setSearchValue(e.target.value);
              setShowResult(true);
              props.newSystem.oemId = '';
              props.setNewSystem({ ...props.newSystem });
            }}
            placeholder='Search for OEM...'
            className='w-full bg-gray-3 prose-paragraphSmall outline-0 px-3 h-10'
            value={oems.find((oem: Oem) => oem.id === props.newSystem.oemId)?.name || searchValue}
          />
          {searchValue.length > 0 && showResult && (
            <div
              ref={ref}
              className='bg-white border scrollbar shadow-popup border-gray-10 mt-1 p-2 max-h-[250px] overflow-auto absolute z-[100] w-full'
            >
              {searchResult?.map((result: Oem) => {
                return (
                  <div
                    key={result.id}
                    onClick={() => {
                      props.newSystem.oemId = result.id;
                      props.setNewSystem({ ...props.newSystem });
                      setShowResult(false);
                    }}
                    className='px-3 py-3 hover:bg-gray-5 cursor-pointer'
                  >
                    {result.name}
                  </div>
                );
              })}
              {searchResult.length === 0 && 'No OEM was found.'}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CreateSystemInformation;
