import { Tooltip } from 'component-library';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { AppContext } from '../../../contexts/AppContext';
import { Item, SystemDetail } from '../../../models/ApiModels';

interface Props {
  system: SystemDetail;
}

export default function SystemDetailsHardwareInterceptors(props: Props) {
  const { isHumphreeUser } = useContext(AppContext);
  const navigate = useNavigate();
  const items: Item[] = useSelector((state: any) => state.configurations.items);
  return (
    <div className='border-b border-gray-10'>
      <div className='prose-heading5 flex gap-2 my-3'>
        Lightning interceptors
        <Tooltip
          description='The interceptors are installed on the transom of the boat to optimize the boating experience with the selected functionalities.'
          darkmode
        />
      </div>
      <div className='xl:flex xl:justify-between my-5 items-center'>
        <div className='xl:font-normal font-semibold w-48'>Port</div>
        <div className='2xl:gap-16 xl:flex gap-4'>
          <div className='w-32 flex justify-between items-center truncate'>
            {
              items.find(
                (item: Item) =>
                  props.system.lightningHardwareSystem?.portServo?.articleNumber.slice(0, 6) === item.articleNumber
              )?.displayName
            }
          </div>
          {props.system.lightningHardwareSystem?.portServo?.articleNumber && (
            <div className='xl:w-42 flex justify-between items-center'>
              Part no.
              <span className='font-code prose-dataBaseDataLabel ml-3'>
                {props.system.lightningHardwareSystem?.portServo?.articleNumber}
              </span>
            </div>
          )}
          {props.system.lightningHardwareSystem?.portServo?.serialNumber && (
            <div className='xl:w-32 flex justify-between items-center'>
              Serial no.
              <span className='font-code prose-dataBaseDataLabel ml-3'>
                {isHumphreeUser ? (
                  <Link
                    to={'/units/lsu/' + props.system.lightningHardwareSystem?.portServo?.serialNumber}
                    className='whitespace-nowrap cursor-pointer underline text-primary-100 hover:text-primary-200'
                  >
                    {props.system.lightningHardwareSystem?.portServo?.serialNumber?.toString()}
                  </Link>
                ) : (
                  props.system.lightningHardwareSystem?.portServo?.serialNumber?.toString()
                )}
              </span>
            </div>
          )}
          {props.system.lightningHardwareSystem?.portServo?.firmwareVersion && (
            <div className='font-code prose-labelPill max-w-[10rem] bg-success-50 px-2.5 py-1.5 shadow-dropdown text-success-200 truncate'>
              Software {props.system.lightningHardwareSystem?.portServo?.firmwareVersion}
            </div>
          )}
        </div>
      </div>
      <div className='xl:flex xl:justify-between my-5 items-center'>
        <div className='xl:font-normal font-semibold w-48'>Starboard</div>
        <div className='2xl:gap-16 xl:flex gap-4'>
          <div className='flex w-32 justify-between items-center truncate'>
            {
              items.find(
                (item: Item) =>
                  props.system.lightningHardwareSystem?.starboardServo?.articleNumber.slice(0, 6) === item.articleNumber
              )?.displayName
            }
          </div>
          {props.system.lightningHardwareSystem?.starboardServo?.articleNumber && (
            <div className='xl:w-42 flex justify-between items-center'>
              Part no.
              <span className='font-code prose-dataBaseDataLabel ml-3'>
                {props.system.lightningHardwareSystem?.starboardServo?.articleNumber}
              </span>
            </div>
          )}
          {props.system.lightningHardwareSystem?.starboardServo?.serialNumber && (
            <div className='xl:w-32 flex justify-between items-center'>
              Serial no.
              <span className='font-code prose-dataBaseDataLabel ml-3'>
                {isHumphreeUser ? (
                  <Link
                    to={'/units/lsu/' + props.system.lightningHardwareSystem?.starboardServo?.serialNumber}
                    className='whitespace-nowrap cursor-pointer underline text-primary-100 hover:text-primary-200'
                  >
                    {props.system.lightningHardwareSystem?.starboardServo?.serialNumber?.toString()}
                  </Link>
                ) : (
                  props.system.lightningHardwareSystem?.starboardServo?.serialNumber?.toString()
                )}
              </span>
            </div>
          )}
          <div>
            {props.system.lightningHardwareSystem?.starboardServo?.firmwareVersion && (
              <div className='font-code prose-labelPill max-w-[10rem] bg-success-50 px-2.5 py-1.5 shadow-dropdown text-success-200 truncate'>
                Software {props.system.lightningHardwareSystem?.starboardServo?.firmwareVersion}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
