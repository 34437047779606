import ServiceHelper from '../helpers/ServiceHelper';
import { Measurement } from '../models/ApiModels';

export async function GetMeasurements() {
  return new Promise<Measurement[]>((resolve, reject) => {
    ServiceHelper({
      method: 'GET',
      url: 'v1/files/measurements',
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function GetMeasurementFile(fileName: string) {
  return new Promise<any>((resolve, reject) => {
    ServiceHelper({
      method: 'GET',
      url: `v1/files/measurements/${fileName}`,
      responseType: 'blob',
      headers: {
        'content-type': 'application/octet-stream',
      },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
