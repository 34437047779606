import { SoftwareUpdate } from '../../../models/ApiModels';

interface Props {
  software: SoftwareUpdate;
}

function SoftwareUpdateDetailsFile(props: Props) {
  const fileSizeInKb = props.software.fileSize / 1000;

  return (
    <div className='flex flex-col'>
      <h1 className='prose-heading4 border-b border-gray-10 pb-5'>File</h1>

      <div className='flex flex-row justify-between border-b border-gray-10 py-5 items-center'>
        <h2 className='prose-paragraphBase mb-1 flex w-full flex-col'>
          <span className='flex gap-2'>ID</span>
        </h2>
        <p className='flex items-center prose-paragraphBase whitespace-nowrap'>{props.software.id}</p>
      </div>
      <div className='flex flex-row justify-between border-b border-gray-10 py-5 items-center'>
        <h2 className='prose-paragraphBase mb-1 flex w-full flex-col'>
          <span className='flex gap-2'>File name</span>
        </h2>
        <p className='flex items-center prose-paragraphBase whitespace-nowrap'>{props.software.originalFileName}</p>
      </div>
      <div className='flex flex-row justify-between border-b border-gray-10 py-5 items-center'>
        <h2 className='prose-paragraphBase mb-1 flex w-full flex-col'>
          <span className='flex gap-2'>File size</span>
        </h2>
        <span className='flex font-code'>
          <p className='flex items-center prose-paragraphBase whitespace-nowrap'>{fileSizeInKb.toFixed()}</p>kb
        </span>
      </div>
    </div>
  );
}

export default SoftwareUpdateDetailsFile;
