import { Dropdown, Input } from 'component-library';
import Qty from 'js-quantities';
import { useEffect, useState } from 'react';
import { BoatModel } from '../../../../models/ApiModels';

interface Props {
  newBoatModel: BoatModel;
  setNewBoatModel: (newBoatModel: BoatModel) => void;
}

function Speed(props: Props) {
  const [speed, setSpeed] = useState<number>();
  const [cruisingSpeed, setCruisingSpeed] = useState<number>();
  const [currentUnit, setCurrentUnit] = useState<string>('kn');
  const [fromUnit, setFromUnit] = useState('kn');
  const [toUnit, setToUnit] = useState('');

  useEffect(() => {
    if (currentUnit === 'kn') {
      setFromUnit('kn');
      setToUnit('mph');
    } else if (currentUnit === 'mph') {
      setFromUnit('mph');
      setToUnit('kn');
    }
    if (speed !== undefined || cruisingSpeed !== undefined) {
      conversion();
    }
  }, [currentUnit]);

  const conversion = () => {
    if (speed !== undefined) {
      if (fromUnit !== undefined && toUnit !== undefined) {
        const qtyString = speed + fromUnit;
        const qty = new Qty(qtyString);
        const resultNum = qty.to(toUnit);
        const { scalar } = resultNum;
        setSpeed(Math.round(scalar));
        updateBoatModel();
      }
    }
    if (cruisingSpeed !== undefined) {
      if (fromUnit !== undefined && toUnit !== undefined) {
        const qtyString = cruisingSpeed + fromUnit;
        const qty = new Qty(qtyString);
        const resultNum = qty.to(toUnit);
        const { scalar } = resultNum;
        setCruisingSpeed(Math.round(scalar));
        updateBoatModel();
      }
    }
  };

  const updateBoatModel = () => {
    if (currentUnit === 'kn' && speed !== undefined) {
      props.newBoatModel.topSpeed = speed;
      props.setNewBoatModel({ ...props.newBoatModel });
    }
    if (currentUnit === 'mph' && speed !== undefined) {
      const qtyString = speed + fromUnit;
      const qty = new Qty(qtyString);
      const resultNum = qty.to(toUnit);
      const { scalar } = resultNum;
      props.newBoatModel.topSpeed = scalar;
      props.setNewBoatModel({ ...props.newBoatModel });
    }
  };

  return (
    <div className='border-b border-gray-10 pb-6'>
      <span className='prose-heading5'>Speed</span>
      <div className='flex flex-wrap gap-20 mt-2'>
        <div className='w-[11rem]'>
          <Dropdown
            optionsData={[
              { id: 'kn', value: 'Kn' },
              { id: 'mph', value: 'Mph' },
            ]}
            label='Unit of meassure'
            placeholder='Kn'
            value={currentUnit.charAt(0).toUpperCase() + currentUnit.slice(1).toLowerCase()}
            onValueChosen={(id: string) => setCurrentUnit(id)}
            size='Medium'
          />
        </div>
        <div className='w-[11rem]'>
          <Input
            type='number'
            label='Top speed'
            unitLabel={currentUnit === 'kn' ? 'kn' : 'mph'}
            value={speed}
            onChange={(value: number) => {
              props.newBoatModel.topSpeed = value;
              setSpeed(value);
              props.setNewBoatModel({ ...props.newBoatModel });
            }}
            tooltipDesc='Average maximum speed of the boat model.'
            tooltipDarkmode
          />
        </div>
        <div className='w-[11rem]'>
          <Input
            type='number'
            label='Cruising speed'
            unitLabel={currentUnit === 'kn' ? 'kn' : 'mph'}
            value={cruisingSpeed}
            tooltipDesc='A speed that is usually somewhat below maximum, that is comfortable and economical for longer distances.'
            tooltipDarkmode
            onChange={(value: number) => {
              props.newBoatModel.cruisingSpeed = value;
              setCruisingSpeed(value);
              props.setNewBoatModel({ ...props.newBoatModel });
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Speed;
