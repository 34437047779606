import { useMsal } from '@azure/msal-react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';
import { Input, PrimaryButton } from 'component-library';
import { useContext, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../contexts/AppContext';
import { Oem } from '../../models/ApiModels';
import { ActionType } from '../../store/actionTypes';
import OemAddCustomerModal from './OemAddCustomerModal';
import { OemSelectionItem } from './OemSelectionItem';

interface Props {
  loading: boolean;
  close: () => void;
  oemFavourites: string[];
  addFavourite: (id: string) => void;
}

export function OemSelectionModal(props: Props) {
  const navigate = useNavigate();
  const { setCurrentOemTenantId } = useContext(AppContext);
  const { accounts } = useMsal();
  const account: any = accounts[0];
  const [search, setSearch] = useState('');
  const oems = useSelector((state: any) => state.oems.data);
  const dispatch = useDispatch();
  const [showAddCutomer, setShowAddCustomer] = useState(false);

  const oemFavouriteList = useMemo(() => {
    const list = oems.filter((oem: Oem) => {
      return oem.name.toLowerCase()?.includes(search.toLowerCase()) && props.oemFavourites?.includes(oem.id);
    });

    return list.map((oem: Oem) => {
      return (
        <OemSelectionItem
          key={oem.businessCentralId}
          name={oem.name}
          active={props.oemFavourites?.some((favourite: string) => favourite === oem.id)}
          selectOem={() => SelectOem(oem.id)}
          setAsFavourite={() => props.addFavourite(oem.id)}
        />
      );
    });
  }, [search, oems, props.oemFavourites]);

  const oemList = useMemo(() => {
    const list = oems.filter((oem: Oem) => {
      return oem.name.toLowerCase().includes(search.toLowerCase()) && !props.oemFavourites?.includes(oem.id);
    });

    return list.map((oem: Oem) => {
      return (
        <OemSelectionItem
          key={oem.businessCentralId}
          name={oem.name}
          active={props.oemFavourites?.some((favourite: string) => favourite === oem.id)}
          selectOem={() => SelectOem(oem.id)}
          setAsFavourite={() => props.addFavourite(oem.id)}
        />
      );
    });
  }, [search, oems, props.oemFavourites]);

  function SelectOem(id: string) {
    setCurrentOemTenantId(id);
    navigate('/');
    props.close();
  }

  return showAddCutomer ? (
    <OemAddCustomerModal
      close={() => setShowAddCustomer(false)}
      customerAdded={(oem: Oem) => {
        dispatch({
          type: ActionType.ADD_OEMS,
          payload: [...oems, oem],
        });
      }}
    />
  ) : (
    <>
      <div className='py-20 md:py-8 flex flex-col justify-between w-screen h-screen md:h-full md:w-full bg-white'>
        <div className='overflow-y-auto px-8'>
          <div className='flex flex-row w-full justify-between mb-16'>
            <span className='text-primary-400 prose-heading2'>Select customer</span>
            <div>
              <Input
                icon={faMagnifyingGlass}
                iconbefore
                placeholder='Search for OEM...'
                onChange={(value: string) => setSearch(value)}
              />
            </div>
          </div>
          {props.loading ? (
            <div className='flex items-center justify-center '>
              <div className='w-12 h-12 border-l-2 border-primary-400 rounded-full animate-spin'></div>
            </div>
          ) : (
            <div className='flex flex-col'>
              <span className='text-primary-400 prose-heading3 mb-7'>Your favorites</span>
              <div className='border border-gray-10 border-b-0 mb-14'>{oemFavouriteList}</div>
              <span className='text-primary-400 prose-heading3 mb-7'>All customers</span>
              <div className='border border-gray-10 border-b-0'>{oemList}</div>
            </div>
          )}
        </div>
        {account && (
          <div className='flex justify-between flex-row w-full mt-8 items-center px-8'>
            <PrimaryButton icon={faPlus} label='Add customer' size='small' onClick={() => setShowAddCustomer(true)} />
            <PrimaryButton label='Close' size='small' onClick={() => props.close()} />
          </div>
        )}
      </div>
    </>
  );
}
