import { faArrowUpFromBracket, faTrash, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { PrimaryButton, SecondaryButton, StatusButton } from 'component-library';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import porty from '../../../assets/porty.svg';
import { DeleteBoatModelImage, GetBoatModelImage, useUploadFormImage } from '../../../services/BoatModelsService';
import { ActionType } from '../../../store/actionTypes';

interface Props {
  edit: boolean;
}

function BoatDetailsImage(props: Props) {
  const dispatch = useDispatch();
  const { uploadForm, fileResponse, percent, loaded, abortController } = useUploadFormImage();
  const [uploadPopup, setUploadPopup] = useState(false);
  const [exceedsSizeLimit, setExceedsSizeLimit] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [dragActive, setDragActive] = useState(false);
  const { id } = useParams();
  const [file, setFile] = useState<File>();
  const [selectedImage, setSelectedImage] = useState(null);
  const fileInputRef = useRef<any>();
  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    setLoading(true);
    GetBoatModelImage(id as string).then((imageData: any) => {
      if (imageData && (imageData.type.startsWith('image/') || imageData.type === 'application/octet-stream')) {
        const blobData = new Blob([imageData], { type: 'image/*' });
        const imageUrl: any = URL.createObjectURL(blobData);
        setImageSrc(imageUrl);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
    return () => {
      if (imageSrc) {
        URL.revokeObjectURL(imageSrc);
      }
    };
  }, []);

  function handleUploadClick() {
    if (!file) {
      return;
    }
    const formData = new FormData();
    if (file) {
      setUploadPopup(true);
      formData.append('boatImageFile', file);
      uploadForm(formData, id);
      removeImage();
    }
  }

  const [isInputDisabled, setIsInputDisabled] = useState(false);
  function handleDeleteClick() {
    if (imageSrc) {
      setIsInputDisabled(true);
      setLoading(true);
      DeleteBoatModelImage(id as string)
        .catch(() => {
          console.log('error');
        })
        .finally(() => {
          window.location.reload();
          setLoading(false);
          setIsInputDisabled(false);
        });
    }
  }

  function handleFileSizeLimit(file: File) {
    const maxSize = 20 * 1024 * 1024; // 20MB

    if (file.size > maxSize) {
      setExceedsSizeLimit('File size exceeds 20MB limit.');
    } else {
      setExceedsSizeLimit(undefined);
    }
  }

  function handleImageChange(e: any) {
    const file = e.target.files[0];
    e.preventDefault();
    setDragActive(false);
    if (e.target.files && e.target.files[0]) {
      const newFile = e.target.files[0];
      if (newFile) {
        setFile(newFile);
      }
    }
    if (file) {
      handleFileSizeLimit(file);
      const reader: any = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  }

  function handleDrop(e: React.DragEvent<HTMLElement>) {
    e.preventDefault();
    const newFile = e.dataTransfer.files[0];
    setExceedsSizeLimit(undefined);
    if (newFile) {
      handleFileSizeLimit(newFile);
      setFile(newFile);
      const reader: any = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(newFile);
    }
  }

  function removeImage() {
    setFile(undefined);
    setSelectedImage(null);
  }

  useEffect(() => {
    if (percent === 100 && axios.isAxiosError(fileResponse)) {
      setUploadPopup(false);
    } else if (percent === 100 && fileResponse?.id) {
      dispatch({
        type: ActionType.SET_SNACKBAR,
        payload: { heading: 'File successfully uploaded!', status: 'success' },
      });
      setUploadPopup(false);
      window.location.reload();
      GetBoatModelImage(id as string);
    }
  }, [fileResponse, percent, imageSrc]);

  return (
    <>
      {uploadPopup && (
        <div className='flex justify-center items-center w-screen h-screen fixed top-0 left-0 z-40'>
          <div className='fixed w-screen h-screen bottom-0 bg-gray-100 opacity-50 overlay'></div>
          <div className='absolute flex flex-col gap-8 mx-4 md:left-1/2 md:top-1/2 md:-translate-x-1/2 md:-translate-y-1/2 z-50 bg-white shadow-popup p-7'>
            <h3 className='prose-heading3'>Uploading boat model image...</h3>
            <p className='prose-paragraphBig'>Please wait until upload is finished.</p>
            <div className='flex flex-wrap items-center gap-4 justify-between'>
              <StatusButton
                icon={faXmark}
                label={'Cancel upload'}
                status={'error'}
                onClick={() => {
                  abortController?.abort('Upload cancelled');
                  dispatch({
                    type: ActionType.SET_SNACKBAR,
                    payload: { heading: 'File upload cancelled!', status: 'error' },
                  });
                  setUploadPopup(false);
                }}
              />
              {percent}% or {loaded}mb
              <div className='w-12 h-12 border-l-2 border-primary-400 rounded-full animate-spin'></div>
            </div>
          </div>
        </div>
      )}
      {props.edit ? (
        <>
          <form
            className='relative w-full text-center'
            onDragEnter={() => setDragActive(true)}
            onDragLeave={() => setDragActive(false)}
            onSubmit={(e) => e.preventDefault()}
          >
            {/* Image preview */}
            {selectedImage && (
              <>
                <div
                  className='absolute top-0 left-0 z-30 h-[31rem] w-full flex flex-col gap-2'
                  onDragOver={(e) => e.preventDefault()}
                  onDrop={handleDrop}
                >
                  <div className='absolute z-20 rounded-xl bg-white w-full h-full opacity-50'></div>
                  <div className='absolute left-1/2 top-1/2 z-40 -translate-x-1/2 -translate-y-1/2 flex w-full justify-center gap-4 flex-wrap 2xl:flex-nowrap'>
                    <SecondaryButton label='Remove selected image' onClick={() => removeImage()} />
                    <PrimaryButton
                      disabled={exceedsSizeLimit !== undefined}
                      label='Upload selected image'
                      onClick={() => handleUploadClick()}
                    />
                  </div>
                  <img
                    src={selectedImage}
                    className='h-full w-full object-cover rounded-xl'
                    alt='selected image for image upload'
                  />
                </div>
              </>
            )}
            <label
              className={`relative flex flex-col h-[31rem] justify-around rounded-xl bg-gray-3 cursor-pointer hover:bg-gray-10 ${
                dragActive ? 'bg-gray-10' : 'bg-gray-3'
              }`}
              onDragOver={(e) => e.preventDefault()}
              onDrop={handleDrop}
            >
              <input
                disabled={isInputDisabled}
                ref={fileInputRef}
                className='absolute top-0 bottom-0 left-0 right-0 opacity-0'
                type='file'
                multiple={true}
                onChange={handleImageChange}
                onDrop={handleDrop}
              />
              {imageSrc && (
                <p className='absolute top-10 left-1/2 -translate-x-1/2 prose-labelStandard uppercase'>
                  Drop image here or select a new image
                </p>
              )}
              <div className='flex flex-col gap-4 h-[31rem]'>
                {!imageSrc ? (
                  <div className='h-full flex flex-col place-content-center'>
                    <FontAwesomeIcon icon={faArrowUpFromBracket} />
                    <p className='prose-labelStandard uppercase'>Drop image here or select an image</p>
                    <p className='prose-labelStandard uppercase text-gray-60'>Must be a .jpg or .png file</p>
                  </div>
                ) : (
                  <>
                    {imageSrc && (
                      <img
                        className='h-full w-full object-cover rounded-xl opacity-20'
                        src={imageSrc}
                        alt='boat model image'
                      />
                    )}
                    <div
                      className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-20 text-error-100 hover:text-black transition-colors'
                      onClick={() => handleDeleteClick()}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                      <p className='prose-labelStandard uppercase'>Delete image</p>
                    </div>
                  </>
                )}
              </div>
            </label>
          </form>
          {exceedsSizeLimit && <div className='text-error-100 py-2'>{exceedsSizeLimit}</div>}
        </>
      ) : loading ? (
        <div className='flex items-center flex-col gap-4 h-96 justify-center'>
          <span className='text-primary-400 prose-heading5'>Loading boat image...</span>
          <div className='w-12 h-12 border-l-2 border-primary-400 rounded-full animate-spin'></div>
        </div>
      ) : (
        <div className='relative h-[31rem] lg:flex lg:flex-col lg:items-center lg:justify-center'>
          {imageSrc ? (
            <img
              className='h-full w-full object-cover rounded-xl /h-[31rem] /object-cover '
              src={imageSrc}
              alt='boat model image'
            />
          ) : (
            <>
              <img
                className='rounded-xl h-[31rem] object-contain max-w-[18rem] m-auto'
                src={porty}
                alt='no image porty sad'
              />
              <p className='absolute left-1/2 top-3/4 -translate-x-1/2 prose-labelStandard uppercase text-center text-gray-80'>
                No boat model image uploaded
              </p>
            </>
          )}
        </div>
      )}
    </>
  );
}

export default BoatDetailsImage;
