import { useSelector } from 'react-redux';
import { Feature, Package, PackageUpgrade, PriceListItem, SoftwarePrice, System } from '../../../../models/ApiModels';
import FunctionPackageAddon from './FunctionPackageAddon';
import FunctionPackageItem from './FunctionPackageItem';

type Props = {
  system: System;
  setNewPackage: (id: string) => void;
  newPackage: string;
  additionalFeatures: string[];
  setAdditionalFeatures: (value: string) => void;
  prices?: SoftwarePrice;
  listPrices?: SoftwarePrice;
};

function FunctionPackages(props: Props) {
  const packages = useSelector((state: any) => state.configurations.packages);
  const addonFeatures = useSelector((state: any) => state.configurations.features);
  const upgrades: PackageUpgrade[] = useSelector((state: any) => state.configurations.upgrades);

  const currentPackage: Package = packages.find((pack: Package) => pack.id === props.system?.packageId);

  function GetPackagePrice(pack: Package) {
    const upgrade = upgrades.find(
      (u: PackageUpgrade) =>
        u.upgradeBasePackageId === pack.id && u.id && currentPackage?.availableUpgrades?.includes(u.id)
    );

    if (upgrade && currentPackage?.availableUpgrades?.some((p: string) => p === upgrade.id)) {
      return props.prices?.priceList.find((p: PriceListItem) => p.internalId === upgrade.id);
    } else {
      return props.prices?.priceList.find((p: PriceListItem) => p.internalId === pack.id);
    }
  }
  function GetPackageListPrice(pack: Package) {
    const upgrade = upgrades.find(
      (u: PackageUpgrade) =>
        u.upgradeBasePackageId === pack.id && u.id && currentPackage?.availableUpgrades?.includes(u.id)
    );

    if (upgrade && currentPackage?.availableUpgrades?.some((p: string) => p === upgrade.id)) {
      return props.listPrices?.priceList.find((p: PriceListItem) => p.internalId === upgrade.id);
    } else {
      return props.listPrices?.priceList.find((p: PriceListItem) => p.internalId === pack.id);
    }
  }

  return (
    <div>
      <h1 className='prose-heading4 border-b border-gray-10 pb-5 pt-6 mb-6'>Select upgrade package</h1>
      <div className='grid grid-cols-9'>
        <div className='col-span-9 grid grid-cols-9 px-5 mb-2'>
          <span className='uppercase prose-labelTableColumn col-span-2'></span>
          <span className='uppercase prose-labelTableColumn col-span-1 flex justify-center'>Auto trim</span>
          <span className='uppercase prose-labelTableColumn col-span-1 flex justify-center'>Auto list</span>
          <span className='uppercase prose-labelTableColumn col-span-1 flex justify-center'>Pitch control</span>
          <span className='uppercase prose-labelTableColumn col-span-1 flex justify-center text-center'>
            Coordinated turn
          </span>
          <span className='uppercase prose-labelTableColumn col-span-1 flex justify-center text-center'>
            Roll stabilization
          </span>
          <span className='uppercase prose-labelTableColumn col-span-2 flex justify-end'>Add to license</span>
        </div>
        <div className='bg-gray-5 py-2 px-5 mb-1 prose-mainMenuL2Default col-span-9'>Manual</div>
        {packages.map((packageItem: Package) => {
          return (
            <FunctionPackageItem
              key={packageItem.id}
              newPackage={
                packages.find((pack: Package) => pack.id === props.newPackage)?.id ||
                packages.find(
                  (pack: Package) =>
                    pack.id === upgrades.find((u: PackageUpgrade) => u.id === props.newPackage)?.upgradeBasePackageId
                )?.id
              }
              setNewPackage={(id: string) => {
                if (props.system.packageId !== '' && id !== '') {
                  const upgradeId = upgrades.find(
                    (p: PackageUpgrade) =>
                      p.upgradeBasePackageId === packageItem.id &&
                      p?.id &&
                      currentPackage?.availableUpgrades.includes(p.id)
                  )?.id;

                  if (upgradeId) props.setNewPackage(upgradeId);
                } else {
                  props.setNewPackage(id);
                }
              }}
              package={packageItem}
              currentPackage={currentPackage}
              customerPriceListItem={GetPackagePrice(packageItem)}
              priceListItem={GetPackageListPrice(packageItem)}
            />
          );
        })}
      </div>
      <div className='prose-labelPill text-gray-60 text-right mt-8'>All prices are excluding VAT</div>
      <h1 className='prose-heading4 border-b border-gray-10 pb-5 pt-14'>Additional functions</h1>
      <div className='flex flex-col'>
        <span className='prose-labelTableColumn uppercase self-end py-4 px-5'>Add to license</span>

        {addonFeatures.map(
          (addonFeature: Feature) =>
            addonFeature.addon && (
              <FunctionPackageAddon
                key={addonFeature.id}
                addonFeature={addonFeature}
                addAddon={(id: string) => {
                  props.setAdditionalFeatures(id);
                }}
                added={props.additionalFeatures.includes(addonFeature.id)}
                active={props.system.addonFeatureIds.includes(addonFeature.id)}
                customerPriceListItem={props.prices?.priceList.find(
                  (price: any) => price.internalId === addonFeature.id
                )}
                priceListItem={props.listPrices?.priceList.find((price: any) => price.internalId === addonFeature.id)}
              />
            )
        )}
      </div>
      <div className='prose-labelPill text-gray-60 text-right mt-8'>All prices are excluding VAT</div>
    </div>
  );
}

export default FunctionPackages;
