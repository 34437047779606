import { Checkbox } from 'component-library';
import { useState } from 'react';
import { BoatModel } from '../../../../models/ApiModels';

type BoatModelProps = {
  newBoatModel: BoatModel;
  setNewBoatModel: (newBoatModel: BoatModel) => void;
};

function Flybridge(props: BoatModelProps) {
  const [hasFlybridge, setHasFlybridge] = useState(false);

  return (
    <div className='flex flex-col gap-2 border-b border-gray-10 pb-6 pt-6'>
      <span className='prose-heading5 flex items-center gap-1'>Flybridge</span>
      <div className='flex flex-row gap-2'>
        <Checkbox
          active={hasFlybridge}
          onClick={() => {
            if (!hasFlybridge) {
              setHasFlybridge(true);
              props.newBoatModel.flyBridgeOption = true;
              props.setNewBoatModel({ ...props.newBoatModel });
            } else {
              setHasFlybridge(false);
              props.newBoatModel.flyBridgeOption = false;
              props.setNewBoatModel({ ...props.newBoatModel });
            }
          }}
        />
        <span>Boat model has a flybridge</span>
      </div>
    </div>
  );
}

export default Flybridge;
