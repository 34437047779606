import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { ControlPanel } from '../../../models/ApiModels';
import { GetLCPSerialNumberAsync } from '../../../services/UnitService';
import useRequest from '../../../utils/net/useRequest';
import HardwareUnitsDetailsLog from './HardwareUnitsDetailsLog';

function HardwareUnitsDetailsLCP() {
  const navigate = useNavigate();
  const { serial } = useParams();
  const [lcp, isLoading, getError] = useRequest<ControlPanel>(() => {
    if (serial !== undefined) return GetLCPSerialNumberAsync(serial);
  });

  useEffect(() => {
    if (getError) navigate(-1);
  }, [getError]);

  return (
    <div className='max-w-[2000px]'>
      {!getError ? (
        <div>
          {isLoading ? (
            <div className='flex items-center flex-col gap-4 h-96 justify-center'>
              <span className='text-primary-400 prose-heading5'>Loading units...</span>
              <div className='w-12 h-12 border-l-2 border-primary-400 rounded-full animate-spin'></div>
            </div>
          ) : (
            <div>
              <div className='md:prose-heading4 2xl:prose-heading3 items-center gap-1 mb-14'>
                Lightning Control Panel
                <FontAwesomeIcon icon={faChevronRight} className='mx-2' size='xs' />
                {serial}
              </div>
              <div className='prose-heading4 pb-5 border-b border-gray-10'>General information</div>
              <div className='flex py-5 justify-between border-b border-gray-10'>
                <div>Part number</div>
                <div>{lcp?.articleNumber}</div>
              </div>
              <div className='flex py-5 justify-between border-b border-gray-10'>
                <div>Software version</div>
                <div>{lcp?.firmwareVersion}</div>
              </div>
              { lcp?.auxFirmwareVersion &&
              <div className='flex py-5 justify-between border-b border-gray-10'>
                <div>MCU version</div>
                <div>{lcp?.auxFirmwareVersion}</div>
              </div>
              }
              { lcp?.auxTouchVersion &&
              <div className='flex py-5 justify-between border-b border-gray-10'>
                <div>Touch version</div>
                <div>{lcp?.auxTouchVersion}</div>
              </div>
              }
              <HardwareUnitsDetailsLog lcp={lcp} />
            </div>
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default HardwareUnitsDetailsLCP;
