import { createColumnHelper } from '@tanstack/react-table';
import { StatusPill } from 'component-library';
import QuickDeleteSoftware from '../components/SoftwareUpdate/QuickDeleteSoftware';
import { SoftwareUpdate, User } from '../models/ApiModels';

type SoftwareUpdateLíst = {
  id: string;
  uploadedBy?: string;
  systemType: string;
  unit: string;
  version: string;
  originalFileName?: string;
  officialRelease: boolean;
  isActive: boolean;
  description: string;
  fileSize?: number;
  softwareUpdate: SoftwareUpdate;
};

export function GetSoftwareUpdateListData(softwareUpdate: SoftwareUpdate[], users: User[]) {
  const softwareUpdateList: SoftwareUpdateLíst[] = softwareUpdate.map((softwareUpdate: SoftwareUpdate) => {
    const uploadedBy = users.find((user) => user.id === softwareUpdate.userId)?.displayName;

    return {
      id: softwareUpdate.id,
      uploadedBy: uploadedBy,
      systemType: softwareUpdate.systemType,
      unit: softwareUpdate.unit,
      version: softwareUpdate.version,
      originalFileName: softwareUpdate.originalFileName,
      officialRelease: softwareUpdate.officialRelease,
      isActive: softwareUpdate.isActive,
      description: softwareUpdate.description,
      fileSize: softwareUpdate.fileSize,
      softwareUpdate: softwareUpdate,
    };
  });

  return softwareUpdateList;
}

const columnHelper = createColumnHelper<SoftwareUpdateLíst>();

export const softwareUpdateListColumns = [
  columnHelper.accessor('systemType', {
    header: 'System Type',
    cell: (info) => <div className='truncate'>{info.getValue()}</div>,
  }),
  columnHelper.accessor('unit', {
    header: 'Unit',
    cell: (info) => <div className='truncate'>{info.getValue()}</div>,
  }),
  columnHelper.accessor('version', {
    header: 'Version',
    cell: (info) => <div className='truncate'>{info.getValue()}</div>,
    maxSize: 90,
  }),

  columnHelper.accessor('originalFileName', {
    header: 'File name',
    cell: (info) => <div className='truncate'>{info.getValue()}</div>,
  }),

  columnHelper.accessor('officialRelease', {
    header: 'Version status',
    cell: (info) =>
      info.getValue() === true ? (
        <StatusPill color='Green' text='Official Release' fixedSize />
      ) : (
        <StatusPill color='Dark Gray' text='Development' fixedSize />
      ),
    maxSize: 130,
  }),
  columnHelper.accessor('uploadedBy', {
    header: 'Uploaded by',
    cell: (info) => (
      <div className={`${!info.getValue() && 'text-gray-40'}`}>{info.getValue() ? info.getValue() : 'Unknown'}</div>
    ),
  }),
  columnHelper.accessor('softwareUpdate', {
    header: 'Delete',
    cell: (info) => <QuickDeleteSoftware software={info.getValue()} />,
  }),
];
