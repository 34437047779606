import { PrimaryButton } from 'component-library';
import { Oem } from '../../../models/ApiModels';

interface Props {
  showOrderConfirmation: boolean;
  setShowOrderConfirmation: () => void;
  currentOem: Oem;
}

export default function OrderConfirmation(props: Props) {
  return (
    <>
      {props.showOrderConfirmation && (
        <div className='fixed left-0 top-0 right-0 bottom-0 flex justify-center overflow-y-auto z-50'>
          <div className='fixed w-full h-full bottom-0 bg-gray-100 opacity-50 overlay'></div>
          <div className='bg-white p-8 opacity-100 absolute flex flex-col gap-3 max-w-[550px] h-full md:h-auto md:mt-60'>
            <span className='prose-heading3 text-gray-100'>Thank you for your order!</span>
            <span className='prose-paragraphBig text-gray-100'>
              The system license have been upgraded with its new functions and a confirmation will be sent on email to
              <span className='text-primary-100'> {props.currentOem?.settings?.orderConfirmationEmail || ''}</span>.
            </span>
            <span className='prose-paragraphBig text-gray-100'>
              To install the new license, the Lightning control panel needs internet connection.{' '}
            </span>
            <div className='flex w-full justify-end'>
              <PrimaryButton label='OK' onClick={() => props.setShowOrderConfirmation()} />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
