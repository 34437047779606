import { useSelector } from 'react-redux';
import { Dashboard, Item, KeyCountWrapper } from '../../models/ApiModels';

interface Props {
  dashboardData: Dashboard;
  compare: boolean;
}

function DashboardDataProduction(props: Props) {
  const items: Item[] = useSelector((state: any) => state.configurations.items);
  const checkPositive = (value: number) => {
    if (value > 0) {
      return '+';
    } else return '';
  };

  const comparisonColor = (color: number) => {
    if (color > 0) {
      return 'text-success-100';
    } else if (color < 0) {
      return 'text-error-100';
    } else return 'text-gray-20';
  };

  return (
    <>
      <div className='prose-heading4 mb-5 mt-10'>Production</div>
      <div className='flex flex-wrap my-10 gap-2'>
        <div className='text-center p-8'>
          <div className='prose-heading5'>LCP</div>
          <div className='prose-heading2 mb-2 text-warning-100'>
            {props.dashboardData.hardwareUnits.totalAmountOfLCP.count}
            <div
              className={`${comparisonColor(props.dashboardData.hardwareUnits.totalAmountOfLCP.diff)} prose-heading5`}
            >
              {props.compare &&
                props.dashboardData &&
                checkPositive(props.dashboardData.hardwareUnits.totalAmountOfLCP.diff) +
                  props.dashboardData.hardwareUnits.totalAmountOfLCP.diff}
            </div>
          </div>
          <div className='prose-heading5'>LCU</div>
          <div className='prose-heading2 mb-2 text-warning-100'>
            {props.dashboardData.hardwareUnits.totalAmountOfLCU.count}
            <div
              className={`${comparisonColor(props.dashboardData.hardwareUnits.totalAmountOfLCU.diff)} prose-heading5`}
            >
              {props.compare &&
                props.dashboardData &&
                checkPositive(props.dashboardData.hardwareUnits.totalAmountOfLCU.diff) +
                  props.dashboardData.hardwareUnits.totalAmountOfLCU.diff}
            </div>
          </div>
          <div className='prose-heading5'>LSU</div>
          <div className='prose-heading2 mb-2 text-warning-100'>
            {props.dashboardData.hardwareUnits.totalAmountOfLSU.count}
            <div
              className={`${comparisonColor(props.dashboardData.hardwareUnits.totalAmountOfLSU.diff)} prose-heading5`}
            >
              {props.compare &&
                props.dashboardData &&
                checkPositive(props.dashboardData.hardwareUnits.totalAmountOfLSU.diff) +
                  props.dashboardData.hardwareUnits.totalAmountOfLSU.diff}
            </div>
          </div>
        </div>
        <div className='bg-gray-3 rounded-lg p-4 prose-heading1 w-[400px]'>
          <div className='flex flex-col prose-heading5'>
            Total units produced
            <div>
              <div className='prose-labelPill mt-4'>
                <div className='hidden p-2 md:flex justify-between'>
                  <div className='prose-labelPill w-1/2'>Name</div>
                  <div className='prose-labelPill w-1/3'>Part number</div>
                  <div className='prose-labelPill w-1/6'>Amount</div>
                </div>
                {!props.compare &&
                  props.dashboardData &&
                  props.dashboardData.hardwareUnits.articleNumberCounts.map((item: KeyCountWrapper) => {
                    const itemName = items.find(
                      (displayName: Item) => item.key?.slice(0, 6) === displayName.articleNumber
                    )?.displayName;
                    return (
                      <div className=' odd:bg-white p-2 md:flex md:justify-between' key={item.key}>
                        <div className='w-1/2'>{itemName}</div>
                        <div className='w-1/3'>{item.key}</div>
                        <div className='flex w-1/6'>{item.count}</div>
                      </div>
                    );
                  })}
                {props.compare &&
                  props.dashboardData &&
                  props.dashboardData.hardwareUnits.articleNumberCounts.map((item: KeyCountWrapper) => {
                    const itemName = items.find(
                      (displayName: Item) => item.key?.slice(0, 6) === displayName.articleNumber
                    )?.displayName;
                    return (
                      <div className='relative odd:bg-white p-2 md:flex md:justify-between' key={item.key}>
                        <div className='w-1/2'>{itemName}</div>
                        <div className='w-1/3'>{item.key}</div>
                        <div className='flex w-1/6'>{item.count}</div>
                        <div className={`md:absolute md:right-2 ${comparisonColor(item.diff)}`}>
                          {checkPositive(item.diff)}
                          {item.diff}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DashboardDataProduction;
