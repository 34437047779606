import Qty from 'js-quantities';

export function SortList(list: any, type: string, secondType: string, desc: boolean) {
  if (secondType.length > 0) {
    if (desc)
      return [...list].sort((a: any, b: any) =>
        a[type][secondType]?.toString()?.localeCompare(b[type][secondType]?.toString())
      );
    else
      return [...list].sort((a: any, b: any) =>
        b[type][secondType]?.toString()?.localeCompare(a[type][secondType]?.toString())
      );
  } else {
    if (desc) return [...list].sort((a: any, b: any) => a[type]?.toString()?.localeCompare(b[type]?.toString()));
    else return [...list].sort((a: any, b: any) => b[type]?.toString()?.localeCompare(a[type]?.toString()));
  }
}

export function GetStatusColor(status: string) {
  switch (status) {
    case 'Created':
      return 'Light Gray';
      break;
    case 'Configured':
      return 'Light Gray';
      break;
    case 'Private':
      return 'Dark Gray';
      break;
    case 'Installing':
      return 'Light Blue';
      break;
    case 'Active':
      return 'Green';
      break;
    case 'Public':
      return 'Green';
      break;
    case 'PendingLicense':
      return 'Light Red';
      break;
    case 'Deleted':
      return 'Light Red';
      break;
    case 'Retired':
      return 'Light Red';
      break;
    default:
      return 'Light Gray';
      break;
  }
}

export function GetStatusText(status: string) {
  switch (status) {
    case 'Created':
      return 'To be installed';
      break;
    case 'Configured':
      return 'To be installed';
      break;
    case 'Installing':
      return 'Installing';
      break;
    case 'Active':
      return 'Active';
      break;
    case 'PendingLicense':
      return 'Pending license';
      break;
    case 'Retired':
      return 'Retired';
      break;
    case 'Deleted':
      return 'Deleted';
      break;
    default:
      return '';
  }
}

export function ConversionMeter(input: number, isMetric: boolean, forceToMeter?: boolean) {
  const fromUnit = !isMetric ? 'meter' : 'foot';
  const toUnit = fromUnit === 'meter' ? 'foot' : 'meter';

  if (!input) return 0;
  if (isMetric) {
    return Number(input);
  } else if (!isMetric && forceToMeter) {
    const qtyString = input + 'foot';
    const qty = new Qty(qtyString).to('meter');
    const { scalar } = qty;
    const fixed = Number(scalar.toFixed(2));
    return Math.round(scalar);
  } else {
    const qtyString = input + fromUnit;
    const qty = new Qty(qtyString);
    const resultNum = qty.to(toUnit);
    const { scalar } = resultNum;
    const fixed = Number(scalar.toFixed(2));
    return Math.round(scalar);
  }
}
export function ConversionSpeed(input: number, isKnots: boolean, forceToMph?: boolean) {
  const fromUnit = !isKnots ? 'kn' : 'mph';
  const toUnit = fromUnit === 'kn' ? 'mph' : 'kn';

  if (!input) return 0;
  if (isKnots) {
    return Number(input);
  } else if (!isKnots && forceToMph) {
    const qtyString = input + 'mph';
    const qty = new Qty(qtyString).to('kn');
    const { scalar } = qty;
    const fixed = Number(scalar.toFixed(2));
    return Math.round(scalar);
  } else {
    const qtyString = input + fromUnit;
    const qty = new Qty(qtyString);
    const resultNum = qty.to(toUnit);
    const { scalar } = resultNum;
    const fixed = Number(scalar.toFixed(2));
    return Math.round(scalar);
  }
}

export function ConversionKilogram(input: number, isMetric: boolean, forceToKilogram?: boolean) {
  const fromUnit = !isMetric ? 'kg' : 'lbs';
  const toUnit = fromUnit === 'kg' ? 'lbs' : 'kg';

  if (!input) return 0;
  if (isMetric) {
    return Number(input);
  } else if (!isMetric && forceToKilogram) {
    const qtyString = input + 'lbs';
    const qty = new Qty(qtyString).to('kg');
    const { scalar } = qty;
    const fixed = Number(scalar.toFixed(2));
    return Math.round(scalar);
  } else {
    const qtyString = input + fromUnit;
    const qty = new Qty(qtyString);
    const resultNum = qty.to(toUnit);
    const { scalar } = resultNum;
    const fixed = Number(scalar.toFixed(2));
    return Math.round(scalar);
  }
}
