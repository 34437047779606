import { faPlus, faSearch } from '@fortawesome/pro-regular-svg-icons';
import { Input, InvertButton, PrimaryButton } from 'component-library';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { HardwareSystem, SoftwareGroup } from '../../../models/ApiModels';
import { GetLHSAsync, UpdateHardwareSystemAsync } from '../../../services/UnitService';
import useRequest from '../../../utils/net/useRequest';
import Table from '../../Table/Table';
import {
  connectedSystemsListColumns,
  SoftwareUpdateGroupsConnectedSystemsListSetup,
} from '../SoftwareUpdateGroupsConnectedSystemsListSetup';

interface Props {
  group: SoftwareGroup;
}

function SoftwareUpdateGroupDetailsVersions(props: Props) {
  const navigate = useNavigate();
  const [ligtningHardwareSystems = []] = useRequest<HardwareSystem[]>(() => GetLHSAsync(), []);
  const [searchValue, setSearchValue] = useState('');
  const [addSerial, setAddSerial] = useState<any>(undefined);

  const [alreadyAdded, setAlreadyAdded] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [addGroupLoading, setAddGroupLoading] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);

  const connectedLHS = ligtningHardwareSystems.filter((system: HardwareSystem) => system.groupId === props.group.id);

  function goToConnectedSystem(value: any, event: any) {
    if (value.system.groupId !== null) {
      if (event.ctrlKey || event.metaKey || event.which === 2) {
        window.open(
          '/systems/' +
            connectedLHS.find((system: any) => system.connectedSystemId === value.connectedSystemId)?.connectedSystemId,
          '_blank'
        );
      } else navigate('/systems/' + value.connectedSystemId);
    }
  }

  function openPopupAddSystem() {
    setOpenPopup(true);
  }

  function AddSystem(serialNo: number) {
    setAddGroupLoading(true);
    setNotFound(false);
    setAlreadyAdded(false);

    const lhs = ligtningHardwareSystems.find((lhs: HardwareSystem) => lhs.controlUnitSerial == serialNo);
    const existsInGroup = connectedLHS.some((lhs: HardwareSystem) => lhs.controlUnitSerial == serialNo);

    if (!lhs) {
      setNotFound(true);
      setAddGroupLoading(false);
    } else if (existsInGroup) {
      setAlreadyAdded(true);
      setAddGroupLoading(false);
    } else {
      if (props.group.id) lhs.groupId = props.group.id;

      UpdateHardwareSystemAsync(lhs)
        .then((response: any) => {
          setAddSerial(response);
        })
        .catch(() => {
          console.log('Something went wrong!');
        })
        .finally(() => {
          setAddGroupLoading(false);
          setOpenPopup(false);
          setAddSerial(undefined);
        });
    }
  }

  return (
    <div className='flex flex-col'>
      <h1 className='prose-heading4 border-b border-gray-10 pb-5'>Systems</h1>
      <div className='mt-10 border border-gray-10 p-4 flex justify-between items-center'>
        <div className='w-1/3 mb-1'>
          <Input
            placeholder='Search...'
            icon={faSearch}
            iconbefore
            value={searchValue}
            onChange={(value: string) => {
              setSearchValue(value);
            }}
          />
        </div>
        <PrimaryButton onClick={() => openPopupAddSystem()} icon={faPlus} label='Add new system to group' />
      </div>
      <Table
        data={SoftwareUpdateGroupsConnectedSystemsListSetup(connectedLHS)}
        columns={connectedSystemsListColumns}
        onClick={(value: any, event?: any) => goToConnectedSystem(value, event)}
        searchValue={searchValue}
        type='connected groups'
      />
      {openPopup && (
        <div className='fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 max-w-lg flex flex-col p-7 w-full bg-white border-b border-gray-10 shadow-popup'>
          <div className='prose-heading3'>Add system to group</div>
          <div className='my-3'>Enter the systems control unit serial number in order to assign it to this group</div>
          <Input
            label=''
            placeholder='Enter serial number...'
            value={addSerial}
            centeredLabel
            onChange={(value: number) => {
              setAddSerial(value);
            }}
          />
          <div className='mt-5 justify-end flex gap-5'>
            <InvertButton
              label={'Cancel'}
              onClick={() => {
                setAddSerial(undefined);
                setAlreadyAdded(false);
                setNotFound(false);
                setOpenPopup(!openPopup);
              }}
            />
            <PrimaryButton
              disabled={!addSerial}
              label={'Add system'}
              onClick={() => {
                if (addSerial) AddSystem(addSerial);
              }}
            />
          </div>
          {alreadyAdded && <div className='prose-heading6 text-error-100'>Serial number already added</div>}
          {notFound && <div className='prose-heading6 text-error-100'>Serial number not found</div>}
          {addGroupLoading && (
            <div className='w-full flex justify-center items-center h-full mt-4'>
              <div className='w-12 h-12 border-l-2 border-primary-400 rounded-full animate-spin'></div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default SoftwareUpdateGroupDetailsVersions;
