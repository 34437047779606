import { Checkbox, Dropdown, Tooltip } from 'component-library';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { AppContext } from '../../../contexts/AppContext';
import { Feature, Package, System } from '../../../models/ApiModels';

interface Props {
  setNewSystem: (newSystem: System) => void;
  newSystem: System;
}

function CreateSystemSoftware(props: Props) {
  const features = useSelector((state: any) => state.configurations.features);
  const packages = useSelector((state: any) => state.configurations.packages);
  const { isHumphreeUser } = useContext(AppContext);
  const manualPackageOptionInDropdown = { id: '', value: 'Manual' };

  return (
    <div className='w-full flex flex-col pb-16'>
      <span className='border-b w-full prose-heading4 border-gray-10 pb-5'>Humphree license</span>
      <div className='pt-4 flex flex-col gap-6'>
        <div className='w-1/3'>
          <Dropdown
            optionsData={[
              ...packages.map((p: Package) => {
                return { id: p.id, value: p.displayName };
              }),
              manualPackageOptionInDropdown,
            ]}
            label='Select function package'
            disabled={!isHumphreeUser}
            placeholder=''
            required
            onValueChosen={(value: string) => {
              props.newSystem.packageId = value;
              props.setNewSystem(props.newSystem);
            }}
            value={`${
              props.newSystem?.packageId
                ? packages.find((packages: Package) => packages.id === props.newSystem?.packageId)?.displayName
                : ''
            }`}
            tooltipDarkmode
            tooltipDesc='Function packages includes automatic functions that can be selected to get the preferred level of automation to a boat. The available function packages are Comfort, Comfort Plus, Dynamic and Dynamic Plus.'
          />
        </div>
        <div className='flex gap-2 flex-col w-full'>
          <span className='prose-heading5 flex gap-2'>
            Additional features
            <Tooltip
              description='Features that can be additionally selected to a license, such as underwater lights.'
              darkmode
            />
          </span>
          {features.map((feature: Feature) => {
            return (
              feature.addon && (
                <div className='flex gap-4 items-center' key={feature.id}>
                  <Checkbox
                    onClick={() => {
                      const index = props.newSystem.addonFeatureIds.findIndex((id: string) => id === feature.id);
                      if (index > -1) {
                        props.newSystem.addonFeatureIds.splice(index, 1);
                      } else {
                        props.newSystem.addonFeatureIds.push(feature.id);
                      }
                      if (props.newSystem) props.setNewSystem({ ...props.newSystem });
                    }}
                    active={props.newSystem.addonFeatureIds.includes(feature.id)}
                    disabled={!isHumphreeUser}
                  />
                  <span>{feature.displayName} </span>
                </div>
              )
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default CreateSystemSoftware;
