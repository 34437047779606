import { Package, Feature, PaymentTerm, PackageUpgrade, Item } from '../../models/ApiModels';
import { Action, ActionType } from '../actionTypes';

interface State {
  features: Feature[];
  packages: Package[];
  paymentTerms: PaymentTerm[];
  upgrades: PackageUpgrade[];
  items: Item[];
}

const initialState: State = {
  features: [],
  packages: [],
  paymentTerms: [],
  upgrades: [],
  items: [],
};

const configurationsReducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case ActionType.ADD_FEATURES:
      return {
        features: action.payload,
        packages: state.packages,
        paymentTerms: state.paymentTerms,
        upgrades: state.upgrades,
        items: state.items,
      };
    case ActionType.ADD_PACKAGES:
      return {
        features: state.features,
        packages: action.payload,
        paymentTerms: state.paymentTerms,
        upgrades: state.upgrades,
        items: state.items,
      };
    case ActionType.ADD_PAYMENT_TERMS:
      return {
        features: state.features,
        packages: state.packages,
        paymentTerms: action.payload,
        upgrades: state.upgrades,
        items: state.items,
      };
    case ActionType.ADD_UPGRADES:
      return {
        features: state.features,
        packages: state.packages,
        paymentTerms: state.paymentTerms,
        upgrades: action.payload,
        items: state.items,
      };
      case ActionType.ADD_ITEMS:
      return {
        features: state.features,
        packages: state.packages,
        paymentTerms: state.paymentTerms,
        upgrades: state.upgrades,
        items: action.payload,
      };
    default:
      return state;
  }
};

export default configurationsReducer;
