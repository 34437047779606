import { faChevronDown, faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

interface Props {
  active: boolean;
  routeName: string;
  icon?: ReactElement;
  title: string;
  children?: any;
  setShowMenu: () => void;
}

function SidebarButton(props: Props) {
  const [showChildren, setShowChildren] = useState(false);
  const [childActive, setChildActive] = useState(false);

  useEffect(() => {
    if (props.children) {
      setChildActive(props.children?.props?.active);
    }
  }, [props.children]);

  return (
    <div className='flex flex-col gap-2'>
      <Link
        to={props.routeName}
        onClick={() => props.setShowMenu()}
        className='xl:h-8 px-3 xl:py-5 group flex flex-row w-full gap-4 justify-between items-center cursor-pointer hover:bg-white'
      >
        <div className='flex flex-row items-center gap-3'>
          <div
            className={`w-4 flex justify-center ${
              props.active
                ? 'text-gray-100 2xl:prose-mainMenuL1ItemActive prose-mainMenuL2ItemActive'
                : childActive
                ? 'text-gray-100 2xl:prose-mainMenuL1ItemActive prose-mainMenuL2ItemActive'
                : 'text-gray-60 2xl:prose-mainMenuL1ItemActive prose-mainMenuL2ItemActive'
            }`}
          >
            {props.icon}
          </div>
          <span
            className={
              props.active
                ? 'text-gray-100 2xl:prose-mainMenuL1ItemActive prose-mainMenuL2ItemActive'
                : childActive
                ? 'text-gray-100 2xl:prose-mainMenuL1ItemActive prose-mainMenuL2ItemActive'
                : 'text-gray-60 2xl:prose-mainMenuL1Default prose-mainMenuL2Default'
            }
          >
            {props.title}
          </span>
        </div>
        {props.children && (
          <div
            onClick={(e: any) => {
              e.stopPropagation();
              setShowChildren(!showChildren);
            }}
            className='w-10 h-10 flex justify-center items-center -mr-2'
          >
            <FontAwesomeIcon
              className={
                props.active
                  ? 'text-gray-100 2xl:prose-mainMenuL1ItemActive prose-mainMenuL2ItemActive'
                  : childActive
                  ? 'text-gray-100 2xl:prose-mainMenuL1ItemActive prose-mainMenuL2ItemActive'
                  : 'text-gray-60 2xl:prose-mainMenuL1ItemActive prose-mainMenuL2ItemActive'
              }
              icon={showChildren ? faChevronLeft : faChevronDown}
              title='Favorite'
            />
          </div>
        )}
      </Link>
      {props.children && showChildren && props.children}
    </div>
  );
}

export default SidebarButton;
