import ServiceHelper from '../helpers/ServiceHelper';
import { InviteUser, Oem } from '../models/ApiModels';

export async function InviteOemUser(user: InviteUser) {
  return new Promise<Oem[]>((resolve, reject) => {
    ServiceHelper({
      method: 'POST',
      url: 'v1/invite',
      data: user,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
