import { faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { Checkbox, PrimaryButton, RadioButton } from 'component-library';
import { useMemo } from 'react';
import { Warranty } from '../../models/ApiModels';

interface Props {
  warrantyForm: Warranty;
  setNewWarranty: (newWarranty: Warranty) => void;
  page: number;
  setPage: (value: number) => void;
  checkList: any;
  setCheckList: (value: any) => void;
}

function WarrantyFormConfigInfo(props: Props) {
  const configValid = useMemo(() => {
    if (
      props.warrantyForm.systemVoltage &&
      props.checkList.isServoCalibrated &&
      props.checkList.isFuseSize16 &&
      props.checkList.hasGPSSignal &&
      props.checkList.isTrimAndListCalibrated &&
      props.checkList.isOrientationCorrect
    ) {
      return true;
    } else false;
  }, [props.checkList, props.warrantyForm]);

  return (
    <div className='flex flex-col gap-3 items-center w-full'>
      <div className='bg-primary-200 text-white prose-heading3 rounded-full p-2 flex justify-center items-center w-14'>
        4
      </div>
      <div className='prose-paragraphBase mb-5'>Configuration information</div>
      <div className='flex items-center justify-center gap-3 w-full px-10 pb-5 border-b border-gray-10'>
        <RadioButton
          active={props.warrantyForm.systemVoltage === '12 Volt'}
          onClick={() => {
            props.warrantyForm.systemVoltage = '12 Volt';
            props.setNewWarranty({ ...props.warrantyForm });
          }}
        />
        <p className='prose-labelPill'>12 volt</p>
        <p className='prose-labelPill'>/</p>
        <RadioButton
          active={props.warrantyForm.systemVoltage === '24 Volt'}
          onClick={() => {
            props.warrantyForm.systemVoltage = '24 Volt';
            props.setNewWarranty({ ...props.warrantyForm });
          }}
        />
        <p className='prose-labelPill'>24 volt</p>
      </div>
      <div className='text-left'>
        <div className=' prose-labelStandard text-center uppercase my-2'>Setup steps</div>
        <div className='prose-paragraphBase mb-3'>
          Please see the{' '}
          <a
            href='https://humphree.com/lightning/installation-support/installation-manual/'
            target='_blank'
            rel='noreferrer'
            className='cursor-pointer text-primary-100 underline'
          >
            setup manual
          </a>{' '}
          if you encounter any questions regarding the setup steps
        </div>
        <div className='prose-labelPill flex flex-col gap-3 border-b pb-5 border-gray-10'>
          <div className='flex justify-between'>
            <div>
              Servo calibration<span className='text-error-100'>*</span>
            </div>
            <Checkbox
              active={props.checkList.isServoCalibrated === true}
              onClick={() => {
                props.checkList.isServoCalibrated = !props.checkList.isServoCalibrated;
                props.setCheckList({ ...props.checkList });
              }}
            />
          </div>
          <div className='flex items-center justify-between'>
            <div>
              Is fuse size 16A?<span className='text-error-100'>*</span> <br />
              (Recommended for both 12V and 24V systems)
            </div>
            <Checkbox
              active={props.checkList.isFuseSize16 === true}
              onClick={() => {
                props.checkList.isFuseSize16 = !props.checkList.isFuseSize16;
                props.setCheckList({ ...props.checkList });
              }}
            />
          </div>
          <div className='flex items-center justify-between'>
            <div>
              GPS signal<span className='text-error-100'>*</span>
            </div>
            <Checkbox
              active={props.checkList.hasGPSSignal === true}
              onClick={() => {
                props.checkList.hasGPSSignal = !props.checkList.hasGPSSignal;
                props.setCheckList({ ...props.checkList });
              }}
            />
          </div>
          <div className='flex items-center justify-between'>
            <div>Rudder signal</div>
            <Checkbox
              active={props.warrantyForm.rudderSignal === true}
              onClick={() => {
                props.warrantyForm.rudderSignal = !props.warrantyForm.rudderSignal;
                props.setNewWarranty({ ...props.warrantyForm });
              }}
            />
          </div>
          <div className='flex items-center justify-between'>
            <div>
              Calibrated trim & list angles<span className='text-error-100'>*</span>
            </div>
            <Checkbox
              active={props.checkList.isTrimAndListCalibrated === true}
              onClick={() => {
                props.checkList.isTrimAndListCalibrated = !props.checkList.isTrimAndListCalibrated;
                props.setCheckList({ ...props.checkList });
              }}
            />
          </div>
          <div className='flex items-center justify-between'>
            <div>
              Correct LCU orientation<span className='text-error-100'>*</span>
            </div>
            <Checkbox
              active={props.checkList.isOrientationCorrect === true}
              onClick={() => {
                props.checkList.isOrientationCorrect = !props.checkList.isOrientationCorrect;
                props.setCheckList({ ...props.checkList });
              }}
            />
          </div>
        </div>
        <div className='flex justify-between mt-10'>
          <PrimaryButton
            onClick={() => props.setPage(props.page - 1)}
            disabled={props.page === 0}
            icon={faChevronLeft}
            label='Go back'
          />
          <PrimaryButton
            disabled={!configValid}
            onClick={() => props.setPage(props.page + 1)}
            iconAfter
            icon={faChevronRight}
            label='Continue'
          />
        </div>
      </div>
    </div>
  );
}
export default WarrantyFormConfigInfo;
