import { createColumnHelper } from '@tanstack/react-table';
import { PresetList } from '../models/ApiModels';

type PresetsList = {
  name: string;
  desc?: string;
  preset: PresetList;
};

export function GetPresetsListData(presets: PresetList[]) {
  const presetsList: PresetsList[] = presets.map((preset: PresetList) => {
    return {
      name: preset.name,
      desc: preset.description,
      preset: preset,
    };
  });

  return presetsList;
}

const columnHelper = createColumnHelper<PresetsList>();

export const presetsListColumns = [
  columnHelper.accessor('name', {
    header: 'Name',
    cell: (info) => <div className='truncate'>{info.getValue()}</div>,
  }),
  columnHelper.accessor('desc', {
    header: 'Description',
    cell: (info) => <div className='whitespace-normal sm:truncate'>{info.getValue()}</div>,
    size: 300,
  }),
];
