import { faPlus, faSearch } from '@fortawesome/pro-regular-svg-icons';
import { Input, PrimaryButton } from 'component-library';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Config } from '../../config';
import { GetSoftwareUpdateListData, softwareUpdateListColumns } from '../../helpers/SoftwareUpdateListSetup';
import { SoftwareUpdate } from '../../models/ApiModels';
import { GetSoftwareUpdate } from '../../services/SoftwareUpdateService';
import { GetUsersAsync } from '../../services/UserService';
import { LoadingSpinner } from '../../utils/LoadingSpinner';
import useRequest from '../../utils/net/useRequest';
import useWindowSize from '../../utils/UseWindowSize';
import Table from '../Table/Table';

export function SoftwareUpdateVersions() {
  const [softwareUpdate = [], isSoftwareUpdateLoading] = useRequest(() => GetSoftwareUpdate(), []);
  const [users = [], isUsersLoading] = useRequest(() => GetUsersAsync(), []);
  const [searchValue, setSearchValue] = useState('');
  const [searchParams, setSearchParams] = useSearchParams({ searchValue: '' });
  const navigate = useNavigate();
  const size = useWindowSize();

  function uploadSoftware() {
    setTimeout(() => {
      navigate('/software/upload');
    }, 1);
  }
  function goToSoftwareUpdateDetails(value: any, event: any) {
    if (event.ctrlKey || event.metaKey || event.which === 2) {
      window.open(
        'software/version/' + softwareUpdate.find((software: SoftwareUpdate) => software.id === value.id).id,
        '_blank'
      );
    } else navigate('/software/version/' + value.id);
  }

  return (
    <>
      {!isSoftwareUpdateLoading && !isUsersLoading ? (
        <>
          <h2 className='prose-heading4 mt-10 mb-5'>LCS</h2>
          <div className='border border-gray-10 p-4 flex justify-between items-center'>
            <div className='w-1/3 mb-1'>
              <Input
                placeholder='Search...'
                icon={faSearch}
                iconbefore
                value={searchValue}
                onChange={(value: string) => {
                  searchParams.set('searchValue', value);
                  setSearchValue(value);
                  searchParams.set('page', '1');
                  setSearchParams(searchParams);
                }}
              />
            </div>
            <PrimaryButton onClick={() => uploadSoftware()} icon={faPlus} label='Upload new version' />
          </div>
          <Table
            data={GetSoftwareUpdateListData(softwareUpdate, users)}
            columns={softwareUpdateListColumns}
            onClick={(value: any, event?: any) => {
              goToSoftwareUpdateDetails(value, event);
            }}
            searchValue={searchValue}
            type='software version'
            setColumnVisibility={{
              uploadedBy: size?.width > 1500 || size?.width < 640,
              officialRelease: size?.width > 1300 || size?.width < 640,
              originalFileName: size?.width > 1150 || size?.width < 640,
              softwareUpdate: Config.b2c_domain !== 'humphreecustomers' ? true : false, // hides delete column in prod
            }}
          />
        </>
      ) : (
        <div className='mt-40'>
          <LoadingSpinner text='Loading software versions...' />
        </div>
      )}
    </>
  );
}
