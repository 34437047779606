import { Checkbox } from 'component-library';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { BoatModel, PropulsionSystem } from '../../../../models/ApiModels';

interface Props {
  newBoatModel: BoatModel;
  setNewBoatModel: (newBoatModel: BoatModel) => void;
}

function Propulsion(props: Props) {
  const propulsionSystems = useSelector((state: any) => state.propulsionSystems.propulsionSystems);
  const [propulsionsArray, setPropulsionsArray] = useState<string[]>([]);

  function ChosenPropulsions(propulsion: string) {
    if (!propulsionsArray.includes(propulsion)) {
      setPropulsionsArray([...propulsionsArray, propulsion]);
    } else {
      setPropulsionsArray(propulsionsArray.filter((existingPropulsion) => existingPropulsion !== propulsion));
    }
  }

  function ChosenPropulsionSystems(option: string) {
    if (!propulsionsArray.includes(option)) {
      setPropulsionsArray([...propulsionsArray, option]);
    } else {
      setPropulsionsArray(propulsionsArray.filter((existingOption) => existingOption !== option));
    }
  }

  return (
    <div className='border-b border-gray-10 pb-6'>
      <span className='prose-heading5'>Propulsion</span>
      <div className='flex flex-col gap-2 mt-2'>
        <span>Select available propulsion options</span>
        {propulsionSystems.map((propulsion: PropulsionSystem) => {
          return (
            <div key={propulsion.id} className='flex gap-2'>
              <Checkbox
                active={
                  props.newBoatModel !== undefined && props.newBoatModel?.propulsionSystemIds.includes(propulsion.id)
                }
                onClick={() => {
                  const index = props.newBoatModel?.propulsionSystemIds.findIndex((id: string) => id === propulsion.id);

                  if (index !== undefined && index > -1) {
                    props.newBoatModel?.propulsionSystemIds.splice(index, 1);
                  } else {
                    props.newBoatModel?.propulsionSystemIds.push(propulsion.id);
                  }
                  if (props.newBoatModel) props.setNewBoatModel({ ...props.newBoatModel });
                }}
              />
              <span>{propulsion.name}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Propulsion;
