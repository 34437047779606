import { faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, Input, Tooltip } from 'component-library';
import { useEffect, useMemo, useState } from 'react';
import { ConversionKilogram } from '../../../../helpers/Custom';
import { ValidPositiveNumber } from '../../../../helpers/Validation';
import { BoatModel } from '../../../../models/ApiModels';

type Props = {
  boatModel: BoatModel;
  editMode: boolean;
  setUpdateBoatModel: (boatModel: BoatModel) => void;
  updateBoatModel: BoatModel;
  isMetric: boolean;
  setIsMetric: (value: boolean) => void;
  setIsValid: (value: boolean) => void;
};

function Weight(props: Props) {
  const [inputWeight, setInputWeight] = useState<number>(props.boatModel.dryWeight ? props.boatModel.dryWeight : 0);

  function CheckIsMetric(id: string) {
    if (id === 'kg') {
      props.setIsMetric(true);
      return props.isMetric;
    } else if (id === 'lbs') {
      props.setIsMetric(false);
      return props.isMetric;
    }
  }

  useEffect(() => {
    if (props.updateBoatModel.dryWeight !== undefined) {
      setInputWeight(ConversionKilogram(props.updateBoatModel.dryWeight, props.isMetric));
    }
  }, [props.updateBoatModel.dryWeight, props.isMetric]);

  const validWeight = useMemo(
    () => ValidPositiveNumber(props.updateBoatModel.dryWeight) || props.updateBoatModel.dryWeight === 0,
    [props.updateBoatModel.dryWeight]
  );

  useEffect(() => {
    if (validWeight) props.setIsValid(true);
    else props.setIsValid(false);
  }, [validWeight]);

  return (
    <div className='flex justify-between py-5 items-center'>
      <span className='flex gap-1'>
        Dry weight{' '}
        <Tooltip
          description='The weight of the boat without any fluids or accessories added. Engine weight should be excluded as well.'
          darkmode
        />
      </span>
      {props.editMode ? (
        <div className='flex gap-2'>
          <Input
            size='Small'
            type='number'
            value={inputWeight === 0 ? '' : inputWeight}
            onChange={(input: number) => setInputWeight(input)}
            onBlur={(input: number) => {
              props.updateBoatModel.dryWeight = ConversionKilogram(input, props.isMetric, true);
              props.setUpdateBoatModel({ ...props.updateBoatModel });
            }}
          />
          <Dropdown
            size='Medium'
            label=''
            optionsData={[
              { id: 'kg', value: 'Kg' },
              { id: 'lbs', value: 'Lbs' },
            ]}
            placeholder='Kg'
            value={props.isMetric ? 'Kg' : 'Lbs'}
            onValueChosen={(id: string) => CheckIsMetric(id)}
          />
        </div>
      ) : (
        <span className={`${!props.boatModel.dryWeight && 'italic'}`}>
          {props.boatModel.dryWeight ? (
            `${props.boatModel.dryWeight.toString()} kg`
          ) : (
            <div className='text-warning-100'>
              <FontAwesomeIcon icon={faTriangleExclamation} /> <span className='text-gray-40'>Not added</span>
            </div>
          )}
        </span>
      )}
    </div>
  );
}

export default Weight;
