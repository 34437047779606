import { faArrowRight, faClone } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StatusPill } from 'component-library';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AppContext } from '../../../../contexts/AppContext';
import { BoatModel, Preset, SystemDetail } from '../../../../models/ApiModels';
import { GetBoatModelPresets } from '../../../../services/PresetsServices';
import { RootState } from '../../../../store/reducers/combine';
import useRequest from '../../../../utils/net/useRequest';

interface Props {
  system: SystemDetail;
  boatModel?: BoatModel;
}
export default function ConfigurationPresetInfo(props: Props) {
  const { currentOemTenantId } = useContext(AppContext);
  const boatModels = useSelector((state: RootState) => state.boatModels.boatModels);
  const boatModelMake = boatModels.find((model) => model.id === props.system.boatModelId)?.make;
  const boatModelName = boatModels.find((model) => model.id === props.system.boatModelId)?.name;
  const [presets = []] = useRequest(
    () => GetBoatModelPresets(props.system.boatModelId && props.system.boatModelId, props.system.oemId),
    []
  );
  return (
    <div className='flex flex-col'>
      <h4 className='prose-heading4 pb-4'>
        <FontAwesomeIcon icon={faClone} className='mr-1' />
        Preset
      </h4>
      <p className='prose-paragraphBase border-b border-gray-10 pb-4'>
        Perfect settings based on the systems boat model, this clones the settings from a successful sea trial to ensure
        fast and high quality installations.
      </p>
      <div className='flex flex-col divide-y divide-gray-10'>
        <div className='flex justify-between py-5'>
          <p>Boat model</p>
          <p className=''>{boatModelMake + ' ' + boatModelName}</p>
        </div>
        <div className='flex justify-between py-5'>
          <p>Selected preset</p>
          <p>{presets.find((preset: Preset) => preset.id === props.system.presetId)?.name}</p>
        </div>
        <div className='flex justify-between py-5'>
          <p>Preset status</p>
          <StatusPill
            color={props.system.presetStatus !== 'Installed' ? 'Light Red' : 'Green'}
            text={props.system.presetStatus}
          />
        </div>
        {props.boatModel?.oemTenantId === currentOemTenantId && (
          <div className='flex py-5 prose-buttonStandard cursor-pointer'>
            <Link
              to={`/models/${props.system.boatModelId}/presets/${props.system.presetId}`}
              className='cursor-pointer ml-auto prose-buttonStandard'
            >
              Go to preset
              <FontAwesomeIcon icon={faArrowRight} className='ml-1' />
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}
