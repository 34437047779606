import { faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Checkbox, StatusPill } from 'component-library';
import { useSelector } from 'react-redux';
import { BoatModel, PropulsionSystem } from '../../../../models/ApiModels';

type Props = {
  boatModel: BoatModel;
  editMode: boolean;
  setUpdateBoatModel: (boatModel: BoatModel) => void;
  updateBoatModel?: BoatModel;
};

function PropulsionSystems(props: Props) {
  const propulsionSystems = useSelector((state: any) => state.propulsionSystems.propulsionSystems);

  return (
    <div className='flex justify-between py-5'>
      <span className='flex gap-1'>Propulsion options</span>
      {props.editMode ? (
        <div className='flex flex-col items-end gap-2'>
          {propulsionSystems.map((system: PropulsionSystem) => {
            return (
              <div key={system.id} className='flex gap-5 items-center'>
                {system.name}
                <Checkbox
                  active={
                    props.updateBoatModel !== undefined &&
                    props.updateBoatModel?.propulsionSystemIds.includes(system.id)
                  }
                  onClick={() => {
                    const index = props.updateBoatModel?.propulsionSystemIds.findIndex(
                      (id: string) => id === system.id
                    );

                    if (index !== undefined && index > -1) {
                      props.updateBoatModel?.propulsionSystemIds.splice(index, 1);
                    } else {
                      props.updateBoatModel?.propulsionSystemIds.push(system.id);
                    }
                    if (props.updateBoatModel) props.setUpdateBoatModel({ ...props.updateBoatModel });
                  }}
                />
              </div>
            );
          })}
        </div>
      ) : (
        <div className='flex gap-2 flex-wrap justify-end'>
          {props.updateBoatModel?.propulsionSystemIds && props.updateBoatModel?.propulsionSystemIds.length > 0 ? (
            propulsionSystems.map((system: PropulsionSystem) => {
              if (props.updateBoatModel?.propulsionSystemIds.includes(system.id))
                return <StatusPill key={system.id} color={'Dark Gray'} text={system.name} />;
            })
          ) : (
            <div className='text-warning-100'>
              <FontAwesomeIcon icon={faTriangleExclamation} /> <span className='text-gray-40'>Not added</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default PropulsionSystems;
