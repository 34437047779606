export function ValidateEmail(email: string) {
  return new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$').test(email);
}

export function ValidPositiveNumber(number?: number) {
  return number && number > 0;
}

export function Required(value: any) {
  return value !== undefined && value.length > 0;
}

export function ContainsOnlyNumbers(str: string) {
  return /^[0-9]+$/.test(str);
}