import ServiceHelper from '../helpers/ServiceHelper';
import { ControlPanel, ControlUnit, HardwareSystem, ServoUnit } from '../models/ApiModels';

export async function GetSerialNumbersAsync() {
  return new Promise<number[]>((resolve, reject) => {
    ServiceHelper({
      method: 'GET',
      url: 'v1/units/available',
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export async function GetLCUSerialNumbeAsync(serial: string) {
  return new Promise<ControlUnit>((resolve, reject) => {
    ServiceHelper({
      method: 'GET',
      url: `v1/units/lcu/${serial}`,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export async function GetLCPSerialNumberAsync(serial: string) {
  return new Promise<ControlPanel>((resolve, reject) => {
    ServiceHelper({
      method: 'GET',
      url: `v1/units/lcp/${serial}`,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export async function GetLSUSerialNumberAsync(serial: string) {
  return new Promise<ServoUnit>((resolve, reject) => {
    ServiceHelper({
      method: 'GET',
      url: `v1/units/lsu/${serial}`,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export async function GetLHSAsync() {
  return new Promise<HardwareSystem[]>((resolve, reject) => {
    ServiceHelper({
      method: 'GET',
      url: 'v1/units/lhs/',
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export async function GetLHSSerialNumberAsync(serial: string) {
  return new Promise<HardwareSystem>((resolve, reject) => {
    ServiceHelper({
      method: 'GET',
      url: `v1/units/lhs/${serial}`,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function AddToGroup(group: HardwareSystem) {
  return new Promise<HardwareSystem>((resolve, reject) => {
    ServiceHelper({
      method: 'POST',
      url: 'v1/units/lhs/group/',
      data: group,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function UpdateHardwareSystemAsync(hardwareSystem: HardwareSystem) {
  return new Promise<HardwareSystem>((resolve, reject) => {
    ServiceHelper({
      method: 'PUT',
      url: `v1/units/lhs/${hardwareSystem.controlUnitSerial}/group`,
      headers: {
        'content-type': 'application/json',
      },
      data: { groupId: hardwareSystem.groupId },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
