import ServiceHelper from '../helpers/ServiceHelper';
import { Oem, PaymentTerm, SoftwarePrice } from '../models/ApiModels';

export async function GetPaymentTerms() {
  return new Promise<PaymentTerm[]>((resolve, reject) => {
    ServiceHelper({
      method: 'GET',
      url: 'v1/businesscentral/paymentTerms',
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function GetSoftwareCustomerPrices(oemId: string) {
  return new Promise<SoftwarePrice>((resolve, reject) => {
    ServiceHelper({
      method: 'GET',
      url: 'v1/businesscentral/prices/software/customer',
      headers: {
        OemTenantId: oemId,
      },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export async function GetSoftwareRetailPrices(oemId: string) {
  return new Promise<SoftwarePrice>((resolve, reject) => {
    ServiceHelper({
      method: 'GET',
      url: 'v1/businesscentral/prices/software/retail',
      headers: {
        OemTenantId: oemId,
      },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function GetCustomers() {
  return new Promise<Oem[]>((resolve, reject) => {
    ServiceHelper({
      method: 'GET',
      url: 'v1/businesscentral/customers',
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
