import { Dashboard, KeyCountWrapper } from '../../models/ApiModels';
import PieChart from './PieChart';
interface Props {
  dashboardData: Dashboard;
  compare: boolean;
}

function DashBoardDataLicenses(props: Props) {
  const notActivatedLights: KeyCountWrapper = {
    count: props.dashboardData.systems.totalAmountOfSystems.count - props.dashboardData.systems.underwaterLights.count,
    key: 'Not activated',
    diff: props.dashboardData.systems.totalAmountOfSystems.diff - props.dashboardData.systems.underwaterLights.diff,
  };
  return (
    <>
      <div className='prose-heading4 mb-5 mt-10'>Licenses</div>
      <div className='md:flex md:flex-wrap gap-4'>
        <div className='bg-gray-3 pt-4 rounded-lg mb-4 md:mb-0 flex-1'>
          <div className='prose-heading5 text-center mt-4'>Function packages</div>
          <PieChart
            compare={props.compare}
            series={[
              props.dashboardData.systems.manual,
              props.dashboardData.systems.comfort,
              props.dashboardData.systems.comfortPlus,
              props.dashboardData.systems.dynamic,
              props.dashboardData.systems.dynamicPlus,
            ]}
            colors={['#394041', '#053338', '#0A6670', '#06C0D4', '#6AD9E5']}
            labels={['Manual', 'Comfort', 'Comfort Plus', 'Dynamic', 'Dynamic Plus']}
            showMarkers={true}
          />
        </div>
        <div className='bg-gray-3 pt-4 rounded-lg mb-4 md:mb-0 flex-1'>
          <div className='prose-heading5 text-center mt-4'>Underwater lights</div>
          <PieChart
            compare={props.compare}
            series={[props.dashboardData.systems.underwaterLights, notActivatedLights]}
            colors={['#06C0D5', '#646868']}
            labels={['Activated', 'Not activated']}
            showMarkers={true}
          />
        </div>
      </div>
    </>
  );
}

export default DashBoardDataLicenses;
