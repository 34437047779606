import { useEffect, useState } from 'react';
import { Dashboard } from '../../models/ApiModels';
import PieChart from './PieChart';

interface Props {
  dashboardData: Dashboard;
  compare: boolean;
}

function DashboardDataSoftware(props: Props) {
  const [dashboard, setDashboard] = useState<Dashboard>();
  useEffect(() => {
    setDashboard(props.dashboardData);
  }, [props.dashboardData]);

  const colorArray = ['#003142', '#005676', '#008EC2', '#3A9EC2', '#92E8E8', '#07E3E3', '#049697', '#036363'];

  return (
    <>
      <div className='prose-heading4 mb-5 mt-10'>Software</div>
      {dashboard && (
        <div className='md:flex md:flex-wrap gap-4'>
          <div className='bg-gray-3 pt-4 rounded-lg mb-4 md:mb-0 flex-1'>
            <div className='prose-heading5 text-center mt-4'>LCP software versions</div>
            <PieChart
              compare={props.compare}
              series={dashboard?.hardwareUnits?.lcpFirmwareVersions}
              colors={colorArray}
              showMarkers={true}
            />
          </div>
          <div className='bg-gray-3 pt-4 rounded-lg mb-4 md:mb-0 flex-1'>
            <div className='prose-heading5 text-center mt-4'>LCU software versions</div>
            <PieChart
              compare={props.compare}
              series={dashboard?.hardwareUnits.lcuFirmwareVersions}
              colors={colorArray}
              showMarkers={true}
            />
          </div>
          <div className='bg-gray-3 pt-4 rounded-lg mb-4 md:mb-0 flex-1'>
            <div className='prose-heading5 text-center mt-4'>LSU software versions</div>
            <PieChart
              compare={props.compare}
              series={dashboard?.hardwareUnits.lsuFirmwareVersions}
              colors={colorArray}
              showMarkers={true}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default DashboardDataSoftware;
