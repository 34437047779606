import { ChangeEvent, useState } from 'react';
import { BoatModel } from '../../../models/ApiModels';
interface BoatNameProps {
  boatModel: BoatModel;
  setBoatModel: (boatModel: BoatModel) => void;
}
function BoatModelNameInput(props: BoatNameProps) {
  const [characterCountName, setCharacterCountName] = useState<string>(props.boatModel.name);

  return (
    <div className='relative w-full md:max-w-[256px]'>
      <span className={`prose-paragraphTiny ${characterCountName.length > 29 && 'text-warning-100'}`}>
        ({characterCountName.length}/30)
      </span>
      <input
        maxLength={30}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          setCharacterCountName(e.target.value);
          props.boatModel.name = e.target.value;
          props.setBoatModel({ ...props.boatModel });
        }}
        placeholder='Enter boat model name...'
        className={`w-full truncate bg-gray-3 prose-paragraphSmall ${
          characterCountName.length > 29 && 'border border-warning-100'
        } px-3 h-10`}
        value={props.boatModel.name}
      />
    </div>
  );
}

export default BoatModelNameInput;
