import { useMsal } from '@azure/msal-react';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Checkbox, Input, InvertButton, Popup, PrimaryButton, RadioButton, StatusButton } from 'component-library';
import _ from 'lodash';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppContext } from '../../../../contexts/AppContext';
import { InviteUser, Oem, User } from '../../../../models/ApiModels';
import { InviteOemUser } from '../../../../services/InviteService';
import { GetUser, UpdateUser } from '../../../../services/UserService';
import { ActionType } from '../../../../store/actionTypes';
import useRequest from '../../../../utils/net/useRequest';
import PermissionsGate, { ROLES } from '../../../../utils/PermissionGate';
import RolesOverview from '../RolesOverview';

interface Props {
  users: any;
  close: () => void;
  addUser: () => void;
  edit?: User;
  deleteUser: () => void;
  currentOem?: Oem;
}

export function SettingsCreateUser(props: Props) {
  const { currentOemTenantId, isHumphreeHome } = useContext(AppContext);
  const { accounts } = useMsal();
  const account = accounts[0];
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [openAlternateInviteLinkPopup, setOpenAlternateInviteLinkPopup] = useState(false);
  const [alternateInviteLinkChecked, setAlternateInviteLinkChecked] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [user, setUser] = useState<InviteUser>({
    displayName: '',
    userRoles: '',
    email: '',
    alternateEmail: '',
  });

  const [currentUser = {}] = useRequest<User>(
    () => GetUser(account?.idTokenClaims?.extension_oemUserId as string),
    [account]
  );

  const [editUser, setEditUser] = useState<User>({ ...props.edit });

  const isDisabled = useMemo(
    () =>
      (!hasChanges && props.edit !== undefined) ||
      (props.edit === undefined && (user.displayName === '' || user.email === '')),
    [user.displayName, user.email, hasChanges, props.edit]
  );

  useEffect(() => {
    if (props.edit === undefined || _.isEqual(props.edit, editUser)) setHasChanges(false);
    else {
      setHasChanges(true);
    }
  }, [editUser]);

  function AddUser(alternateEmail?: string) {
    setLoading(true);
    if (alternateEmail != null) {
      user.alternateEmail = alternateEmail;

      InviteOemUser(user)
        .then(() => {
          setLoading(false);
          props.addUser();
          dispatch({
            type: ActionType.SET_SNACKBAR,
            payload: {
              heading: 'An invitation was just sent!',
              status: 'success',
            },
          });
        })
        .catch((err: any) => {
          setLoading(false);
        });
    } else {
      InviteOemUser(user)
        .then(() => {
          setLoading(false);
          props.addUser();
          dispatch({
            type: ActionType.SET_SNACKBAR,
            payload: {
              heading: 'An invitation was just sent!',
              status: 'success',
            },
          });
        })
        .catch((err: any) => {
          setLoading(false);
        });
    }
  }

  async function UpdateUserAsync() {
    if (props.edit?.id) {
      setLoading(true);
      UpdateUser(editUser)
        .then(() => {
          setLoading(false);
          props.addUser();
          dispatch({
            type: ActionType.SET_SNACKBAR,
            payload: { heading: 'User has been updated!', status: 'success' },
          });
          props.close();
        })
        .catch(() => setLoading(false));
    }
  }

  const HandleAddon = () => {
    setUser((prevUser) => {
      const newUserRoles = prevUser.userRoles.includes(',presetCertified')
        ? prevUser.userRoles.replace(',presetCertified', '')
        : prevUser.userRoles + ',presetCertified';
      return { ...prevUser, userRoles: newUserRoles };
    });
    if (props.edit) {
      setEditUser((prevUser) => {
        const newUserRoles = prevUser.roles?.includes(',presetCertified')
          ? prevUser.roles.replace(',presetCertified', '')
          : prevUser.roles + ',presetCertified';
        return { ...prevUser, roles: newUserRoles };
      });
    }
  };

  const isAddonActive: any = useMemo(() => {
    if (!props.edit && user.userRoles.includes(',presetCertified')) return true;
    else if (props.edit && editUser.roles?.includes(',presetCertified')) return true;
    else return false;
  }, [user, editUser]);

  return (
    <div className='fixed left-0 top-0 right-0 bottom-0 w-screen flex justify-center overflow-y-auto z-50'>
      <div className='fixed w-screen h-screen bottom-0 bg-gray-100 opacity-50 overlay'></div>
      <div className='bg-white pb-10 pt-7 opacity-100 justify-between relative shadow-popup popup flex flex-col items-center px-7 w-full h-max mx-4 mt-20 md:max-w-[600px] md:min-h-[70%] md:mt-20 md:mb-10 2xl:mt-28'>
        {!openPopup && (
          <div className='flex h-full w-full flex-col'>
            <div className='flex flex-col items-center w-full gap-16'>
              <div className='flex flex-col items-center'>
                <span className='text-primary-400 prose-heading3'>{props.edit ? 'Edit user' : 'Add new user'}</span>
                <span className='prose-heading4 text-primary-100 uppercase mt-2'>
                  {currentOemTenantId === '12345678-1234-1234-1234-123456789000' && !props.edit
                    ? 'Adding humphree user'
                    : ''}
                </span>
                {!props.edit && <span>Invite link will be valid for 2 days.</span>}
              </div>
              <div className='flex flex-wrap gap-10 w-full'>
                <Input
                  label='Name'
                  size='Medium'
                  placeholder='Name'
                  value={editUser ? editUser.displayName : user.displayName}
                  onChange={(value: string) => {
                    editUser.displayName = value;
                    user.displayName = value;
                    setEditUser({ ...editUser });
                  }}
                />
                <Input
                  label='Email'
                  size='Large'
                  placeholder='Email'
                  value={editUser ? editUser.email : user.email}
                  onChange={(value: string) => {
                    editUser.email = value;
                    user.email = value;
                    setEditUser({ ...editUser });
                  }}
                />
              </div>
              <div className='flex flex-row w-full'>
                <div className='flex flex-col min-w-max'>
                  <span className='prose-heading4 text-gray-80 mb-4'>Roles:</span>
                  <div className='flex flex-col gap-2'>
                    <PermissionsGate
                      roles={isHumphreeHome ? [ROLES.admin] : [ROLES.admin, ROLES.editor, ROLES.oemAdmin]}
                      rejected={undefined}
                    >
                      <div className='flex gap-3'>
                        <RadioButton
                          onClick={() => {
                            if (!props.edit) setUser({ ...user, userRoles: 'admin' });
                            else setEditUser({ ...editUser, roles: 'admin' });
                          }}
                          active={!props.edit ? user.userRoles.includes('admin') : editUser.roles?.includes('admin')}
                        />
                        <span className='text-gray-80 prose-paragraphBase'>Admin</span>
                      </div>
                    </PermissionsGate>
                    <div className='flex gap-3'>
                      <RadioButton
                        onClick={() => {
                          if (!props.edit) setUser({ ...user, userRoles: 'editor' });
                          else setEditUser({ ...editUser, roles: 'editor' });
                        }}
                        active={!props.edit ? user.userRoles.includes('editor') : editUser.roles?.includes('editor')}
                      />
                      <span className='text-gray-80 prose-paragraphBase'>Editor</span>
                    </div>
                    <div className='flex gap-3'>
                      <RadioButton
                        onClick={() => {
                          if (!props.edit) setUser({ ...user, userRoles: '' });
                          else setEditUser({ ...editUser, roles: '' });
                        }}
                        active={!props.edit ? user.userRoles.length === 0 : editUser.roles?.length === 0}
                      />
                      <span className='text-gray-80 prose-paragraphBase'>View only</span>
                    </div>
                  </div>
                </div>
                {currentUser.oemTenantId === '12345678-1234-1234-1234-123456789000' && (
                  <PermissionsGate roles={[ROLES.admin]} rejected={undefined}>
                    <div className='flex flex-col'>
                      <span className='prose-heading4 text-gray-80 mb-4'>Add-on:</span>
                      <div className='flex flex-col gap-2'>
                        <div className='flex gap-3'>
                          <Checkbox onClick={() => HandleAddon()} active={isAddonActive} />
                          <span className='text-gray-80 prose-paragraphBase'>Preset Certified</span>
                        </div>
                      </div>
                    </div>
                  </PermissionsGate>
                )}
              </div>
              <RolesOverview />
              {!props.edit && (
                <div className='flex gap-2 w-full items-center'>
                  <Checkbox
                    active={alternateInviteLinkChecked}
                    onClick={() => {
                      setAlternateInviteLinkChecked(!alternateInviteLinkChecked);
                    }}
                  />
                  <span>Send invite link to {currentUser.email} instead</span>
                </div>
              )}
            </div>
          </div>
        )}

        {!loading && (
          <div
            onClick={() => props.close()}
            className='flex justify-center items-center w-11 h-11 bg-primary-100 rounded-full text-white absolute right-3 top-3 md:-right-3 md:-top-3 cursor-pointer shadow-close'
          >
            <FontAwesomeIcon icon={faXmark} title='Close' />
          </div>
        )}
        {!loading && (
          <div className='w-full flex justify-between items-end h-full pt-10'>
            <div className='flex flex-row gap-4 items-center'>
              <InvertButton
                onClick={() => {
                  if (hasChanges) setOpenPopup(true);
                  props.close();
                }}
                label='Close'
                size='small'
              />
              {props.edit && (
                <StatusButton label='Delete user' status='error' size='small' onClick={() => props.deleteUser()} />
              )}
            </div>
            <PrimaryButton
              onClick={async () => {
                if (props.edit) {
                  await UpdateUserAsync();
                } else if (alternateInviteLinkChecked) {
                  setOpenAlternateInviteLinkPopup(true);
                } else AddUser();
              }}
              disabled={isDisabled}
              label={props.edit ? 'Save user' : 'Add user'}
              size='small'
            />
          </div>
        )}
        {openAlternateInviteLinkPopup && (
          <Popup
            close={() => {
              setAlternateInviteLinkChecked(false);
              setOpenAlternateInviteLinkPopup(false);
            }}
            confirm={async () => {
              await AddUser(currentUser.email);
              setOpenAlternateInviteLinkPopup(false);
              setOpenPopup(false);
            }}
            heading='Are you sure you want to send a new invite link to yourself?'
            paragraph='You will have to send the link to the customer manually.'
            status='primary'
            cancelText='Cancel'
            confirmText='Send new invite'
          />
        )}
        {loading && (
          <div className='w-full flex justify-center items-center h-full flex-col gap-3'>
            <span className='prose-heading4 text-primary-400'>
              {props.edit ? 'Updating user...' : 'Adding user...'}
            </span>
            <div className='w-12 h-12 border-l-2 border-primary-400 rounded-full animate-spin'></div>
          </div>
        )}
      </div>
    </div>
  );
}
