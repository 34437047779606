import Chart from 'react-apexcharts';
import { KeyCountWrapper } from '../../models/ApiModels';

interface Props {
  series: KeyCountWrapper[];
  labels?: string[];
  colors: string[];
  width?: any;
  showMarkers?: boolean;
  compare: boolean;
}
function PieChart(props: Props) {
  const pieOptions = {
    chartOptions: {
      options: {
        legend: {
          inverseOrder: false,
        },
      },
      colors: props.colors,
      stroke: { show: false },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: true,
        formatter: function (val: any, opts: any) {
          const statusColor = () => {
            if (val === 'Created') {
              return 'py-1 w-28 bg-info-200 text-info-50';
            }
            if (val === 'Configured') {
              return 'py-1 w-28 bg-info-50 text-info-200';
            }
            if (val === 'Installing') {
              return 'py-1 w-28 bg-primary-40 text-primary-200';
            }
            if (val === 'Active') {
              return 'py-1 w-28 bg-success-50 text-success-200';
            }
            if (val === 'Pending License') {
              return 'py-1 w-28 bg-error-50 text-error-200';
            }
          };
          const comparisonColor = () => {
            if (props.series[opts.seriesIndex].diff > 0) {
              return 'text-success-100';
            } else if (props.series[opts.seriesIndex].diff < 0) {
              return 'text-error-100';
            } else return 'text-gray-20';
          };
          const comparisonPositive = () => {
            if (!props.compare) return '';
            if (props.series[opts.seriesIndex].diff > 0) {
              return '+';
            } else return '';
          };

          return (
            `<div class="flex justify-between items-center gap-1 w-[170px]">
             <span class="${statusColor()} text-center">` +
            val +
            '</span><span class=" prose-labelStandard flex justify-start gap-2">' +
            opts.w.globals.series[opts.seriesIndex] +
            `<div class="w-6 ${comparisonColor()}">` +
            comparisonPositive() +
            (props.compare ? props.series[opts.seriesIndex].diff : '') +
            '</div></span></div>'
          );
        },
        // width: 100,
        fontSize: '12px',
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: 600,
        itemMargin: { horizontal: 0, vertical: 2 },
        markers: {
          width: props.showMarkers ? 12 : 0,
          offsetY: 2,
        },
      },
      tooltip: {
        fillSeriesColor: false,
        fixed: {
          enabled: false,
          position: 'topRight',
          offsetX: 0,
          offsetY: 0,
        },
        theme: 'light',
        enabled: true,
        style: {
          fontSize: '12px',

          fontFamily: 'Montserrat, sans-serif',
        },
      },
      labels:
        props.labels ||
        props.series?.map((serie) => {
          return serie.key;
        }),
    },
    series: props.series?.map((serie) => serie.count),
  };

  return (
    <div className='flex justify-center prose-labelPill py-4'>
      <Chart options={pieOptions.chartOptions} series={pieOptions.series} type='pie' width={410} />
    </div>
  );
}

export default PieChart;
