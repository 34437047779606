import { Checkbox, Tooltip } from 'component-library';
import { useState } from 'react';
import { BoatModel } from '../../../../models/ApiModels';

type BoatModelProps = {
  newBoatModel: BoatModel;
  setNewBoatModel: (newBoatModel: BoatModel) => void;
};

function Status(props: BoatModelProps) {
  const [isPrivate, setIsPrivate] = useState(false);
  return (
    <div className='flex flex-col gap-2 border-b border-gray-10 pb-6 pt-6'>
      <span className='prose-heading5 flex items-center gap-1'>
        Status
        <Tooltip
          darkmode
          description='A private status hides the boat model, so that only the systems you own can be assigned to the boat model. Only recommended for secret projects.'
        />
      </span>
      <div className='flex flex-row gap-2'>
        <Checkbox
          active={isPrivate}
          onClick={() => {
            if (!isPrivate) {
              setIsPrivate(true);
              props.newBoatModel.status = 'Private';
              props.setNewBoatModel({ ...props.newBoatModel });
            } else {
              setIsPrivate(false);
              props.newBoatModel.status = 'Public';
              props.setNewBoatModel({ ...props.newBoatModel });
            }
          }}
        />
        <span>Boat model should be private</span>
      </div>
    </div>
  );
}

export default Status;
