import { faCheck, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Checkbox, Tooltip } from 'component-library';
import interceptorImg from '../../../../assets/lcpIcons/interceptorsetup-01.png';
import systemSetupImg from '../../../../assets/lcpIcons/panelsettings.png';
import rudderImg from '../../../../assets/lcpIcons/ruddersignalnew-01.png';
import sensorImg from '../../../../assets/lcpIcons/sensorsetup-01.png';
import speedImg from '../../../../assets/lcpIcons/speedsource-01.png';
import { Preset } from '../../../../models/ApiModels';

interface Props {
  sensorData: { id: string; name: string; value: number; unit: string; hasTooltip: boolean }[];
  rudderData: { id: string; name: string; value: number; unit: string }[];
  interceptorSetupData: { id: string; name: string; value: number; unit: string }[];
  speedSourceData: { id: string; name: string; value: number; unit: string }[];
  selectBoxes?: boolean;
  preset?: Preset;
  setPreset?: (preset: Preset) => void;
  isPreset?: boolean;
}

export function ConfigurationSystemSetupSettings(props: Props) {
  function handleEnabledValues(clickedName: any, clickedValue: any, clickedId: string) {
    if (
      clickedName.includes('Theme') ||
      clickedName.includes('Orientation') ||
      clickedName.includes('Allow nofix') ||
      clickedName.includes('Prioritize waterspeed')
    ) {
      if (props.preset && props.setPreset) {
        const newPreset = { ...props.preset };
        if (Object.prototype.hasOwnProperty.call(newPreset.lcpMetadata, clickedId)) {
          //check if key exists
          delete newPreset.lcpMetadata[clickedId];
        } else {
          newPreset.lcpMetadata[clickedId] = clickedValue;
        }
        props.setPreset(newPreset);
      }
    } else {
      if (props.preset && props.setPreset) {
        const newPreset = { ...props.preset };
        if (Object.prototype.hasOwnProperty.call(newPreset.lcuMetadata, clickedId)) {
          //check if key exists
          delete newPreset.lcuMetadata[clickedId];
        } else {
          newPreset.lcuMetadata[clickedId] = clickedValue;
        }
        props.setPreset(newPreset);
      }
    }
  }

  function handleTooltipHeading(id: string) {
    if (id === 'axisConfig') return 'LCU Orientation';
    return '';
  }

  function handleToolDescription(id: string) {
    if (id === 'axisConfig')
      return 'This is the value of the orientation, displayed in the control panel together with an illustration';
    return '';
  }

  return (
    <div>
      <h4 className='prose-heading4 pb-5 mb-5 border-b border-gray-10'>
        <img src={systemSetupImg} className='inline mr-1 object-contain' />
        System setup settings
      </h4>
      <div className='grid grid-cols-[repeat(auto-fit,minmax(16rem,auto))] gap-10 border-b border-gray-10 pb-5'>
        {/* Sensor */}
        <div className='flex flex-col gap-4'>
          <h5 className='prose-heading5'>
            <img src={sensorImg} className='inline mr-1 object-contain' />
            Sensor
          </h5>
          {props.sensorData.map(({ name, value, id, unit, hasTooltip }) => (
            <div key={id} className='flex gap-2'>
              {props.selectBoxes && props.preset?.lcuMetadata && (
                <Checkbox
                  disabled={value === null || value === undefined}
                  active={
                    Object.keys(props.preset?.lcpMetadata).includes(id) ||
                    Object.keys(props.preset?.lcuMetadata).includes(id)
                  }
                  onClick={() => handleEnabledValues(name, value, id)}
                />
              )}
              <p
                className={`${
                  (value === null && props.isPreset) || (value === undefined && props.isPreset) ? 'text-gray-40' : ''
                }`}
              >
                {name}
              </p>
              {hasTooltip && (
                <Tooltip darkmode heading={handleTooltipHeading(id)} description={handleToolDescription(id)} />
              )}
              {value === null || value === undefined ? (
                <p className='text-gray-40 italic ml-auto'>{props.isPreset ? '-' : 'n/a'}</p>
              ) : (
                <p className='font-code ml-auto'>{Math.abs(value) + unit}</p>
              )}
            </div>
          ))}
        </div>
        {/* Rudder signal */}
        <div className='flex flex-col gap-4'>
          <h5 className='prose-heading5'>
            <img src={rudderImg} className='inline mr-1 object-contain' />
            Rudder signal
          </h5>
          {props.rudderData.map(({ name, value, id, unit }) => (
            <div key={id} className='flex gap-2'>
              {props.selectBoxes && props.preset?.lcuMetadata && (
                <Checkbox
                  disabled={value === null || value === undefined}
                  active={
                    Object.keys(props.preset?.lcpMetadata).includes(id) ||
                    Object.keys(props.preset?.lcuMetadata).includes(id)
                  }
                  onClick={() => handleEnabledValues(name, value, id)}
                />
              )}
              <p
                className={`${
                  (value === null && props.isPreset) || (value === undefined && props.isPreset) ? 'text-gray-40' : ''
                }`}
              >
                {name}
              </p>
              {value === null || value === undefined ? (
                <p className='text-gray-40 italic ml-auto'>{props.isPreset ? '-' : 'n/a'}</p>
              ) : (
                <p className='font-code ml-auto'>{value + unit}</p>
              )}
            </div>
          ))}
        </div>
        {/* Interceptor setup */}
        <div className='flex flex-col gap-4'>
          <h5 className='prose-heading5'>
            <img src={interceptorImg} className='inline mr-1 object-contain' />
            Interceptor setup
          </h5>
          {props.interceptorSetupData.map(({ name, value, id, unit }) => (
            <div key={id} className='flex gap-2'>
              {props.selectBoxes && props.preset?.lcuMetadata && (
                <Checkbox
                  disabled={value === null || value === undefined}
                  active={
                    Object.keys(props.preset?.lcpMetadata).includes(id) ||
                    Object.keys(props.preset?.lcuMetadata).includes(id)
                  }
                  onClick={() => handleEnabledValues(name, value, id)}
                />
              )}
              <p
                className={`${
                  (value === null && props.isPreset) || (value === undefined && props.isPreset) ? 'text-gray-40' : ''
                }`}
              >
                {name}
              </p>
              {value === null || value === undefined ? (
                <p className='text-gray-40 italic ml-auto'>{props.isPreset ? '-' : 'n/a'}</p>
              ) : (
                <p className='font-code ml-auto'>{value + unit}</p>
              )}
            </div>
          ))}
        </div>
        {/* Speed source */}
        <div className='flex flex-col gap-4'>
          <h5 className='prose-heading5'>
            <img src={speedImg} className='inline mr-1 object-contain' />
            Speed source
          </h5>
          {props.speedSourceData.map(({ name, value, id }) => (
            <div key={id} className='flex gap-2'>
              {props.selectBoxes && props.preset?.lcuMetadata && (
                <Checkbox
                  disabled={value === null || value === undefined}
                  active={
                    Object.keys(props.preset?.lcpMetadata).includes(id) ||
                    Object.keys(props.preset?.lcuMetadata).includes(id)
                  }
                  onClick={() => handleEnabledValues(name, value, id)}
                />
              )}
              <p
                className={`${
                  (value === null && props.isPreset) || (value === undefined && props.isPreset) ? 'text-gray-40' : ''
                }`}
              >
                {name}
              </p>
              {value === null || value === undefined ? (
                <p className='text-gray-40 italic ml-auto'>{props.isPreset ? '-' : 'n/a'}</p>
              ) : (
                <p className='font-code ml-auto'>
                  {value ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faXmark} />}
                </p>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
