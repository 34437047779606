import { StatusPill } from 'component-library';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { AppContext } from '../../../../contexts/AppContext';
import { BoatModel, EventLog, Oem, Preset, System } from '../../../../models/ApiModels';
import { GetAllPresets } from '../../../../services/PresetsServices';
import useRequest from '../../../../utils/net/useRequest';
import LogEventTimeData from './EventLogsTimeUserData';

interface Props {
  system?: System;
  event: EventLog;
  isUsersLoading: boolean;
  user: string;
  isHumphreeUser: boolean;
}

export default function LogDataChanged(props: Props) {
  const { currentOem } = useContext(AppContext);
  const [presets = []] = useRequest(() => GetAllPresets(props.system ? props.system.oemId : ''), []);
  const oems = useSelector((state: any) => state.oems.data);
  const boatModels = useSelector((state: any) => state.boatModels.boatModels);
  const isBoatModelId = 'BoatModelId';
  const boatModel = boatModels.find((boatModel: BoatModel) => boatModel.id === props.event.arguments[2]);
  const oldBoatModel = boatModels.find((boatModel: BoatModel) => boatModel.id === props.event.arguments[1]);
  const oem = oems.find((oem: Oem) => oem.id === props.event.arguments[2]) || currentOem;
  const oldOem = oems.find((oem: Oem) => oem.id === props.event.arguments[1]) || currentOem;
  const preset = presets.find((preset: Preset) => preset.id === props.event.arguments[2]);
  const oldPreset = presets.find((preset: Preset) => preset.id === props.event.arguments[1]);

  return (
    <div className='flex lg:flex-nowrap flex-wrap justify-between items-center border-b border-gray-10'>
      <div className='py-8 flex flex-wrap flex-row items-center gap-2'>
        {props.event.arguments[1] ? (
          <span className=' flex prose-paragraphBase text-gray-100'>
            {props.event.arguments[0] === isBoatModelId
              ? 'Boat model'
              : props.event.arguments[0] === 'OEM Tenant Id'
              ? 'OEM'
              : props.event.arguments[0] === 'PresetId'
              ? 'Preset'
              : props.event.arguments[0]}{' '}
            was changed from
          </span>
        ) : (
          <span className=' flex prose-paragraphBase text-gray-100'>
            {props.event.arguments[0] === isBoatModelId
              ? 'Boat model'
              : props.event.arguments[0] === 'OEM Tenant Id'
              ? 'OEM'
              : props.event.arguments[0] === 'PresetId'
              ? 'Preset'
              : props.event.arguments[0]}{' '}
            was added as
          </span>
        )}
        {props.event.arguments[1] && (
          <div className='flex flex-row items-center gap-2'>
            <StatusPill
              color='Light Red'
              text={
                props.event.arguments[0] === isBoatModelId
                  ? oldBoatModel?.name
                  : props.event.arguments[0] === 'OEM Tenant Id'
                  ? oldOem?.name
                  : props.event.arguments[0] === 'PresetId'
                  ? oldPreset?.name
                  : props.event.arguments[1]
              }
            />
            <span className=' flex prose-paragraphBase text-gray-100'> to </span>
          </div>
        )}
        <StatusPill
          color='Green'
          text={
            props.event.arguments[2] !== ''
              ? props.event.arguments[0] === isBoatModelId
                ? boatModel?.name
                : props.event.arguments[0] === 'OEM Tenant Id'
                ? oem?.name
                : props.event.arguments[0] === 'PresetId'
                ? preset?.name
                : props.event.arguments[2]
              : 'n/a'
          }
        />
      </div>
      <LogEventTimeData
        event={props.event}
        isUsersLoading={props.isUsersLoading}
        user={props.user}
        isHumphreeUser={props.isHumphreeUser}
      />
    </div>
  );
}
