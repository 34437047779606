import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Checkbox, Tooltip } from 'component-library';
import { SoftwareUpdate } from '../../../models/ApiModels';

interface Props {
  software: SoftwareUpdate;
  editMode: boolean;
  updateSoftware?: SoftwareUpdate;
  setUpdateSoftware: (software: SoftwareUpdate) => void;
}

function softwareUpdateDetailsInfo(props: Props) {
  return (
    <div className='flex flex-col'>
      <h1 className='prose-heading4 border-b border-gray-10 pb-5'>Version information</h1>

      <div className='flex flex-row justify-between border-b border-gray-10 py-5 items-center'>
        <h2 className='prose-paragraphBase mb-1 flex w-full flex-col'>
          <span className='flex gap-2'>System type</span>
        </h2>
        <p className='flex items-center prose-paragraphBase whitespace-nowrap'>{props.software.systemType}</p>
      </div>
      <div className='flex flex-row justify-between border-b border-gray-10 py-5 items-center'>
        <h2 className='prose-paragraphBase mb-1 flex w-full flex-col'>
          <span className='flex gap-2'>Unit</span>
        </h2>
        <p className='flex items-center prose-paragraphBase whitespace-nowrap'>{props.software.unit}</p>
      </div>
      <div className='flex flex-row justify-between border-b border-gray-10 py-5 items-center'>
        <h2 className='prose-paragraphBase mb-1 flex w-full flex-col'>
          <span className='flex gap-2'>Version name</span>
        </h2>
        <p className='flex items-center prose-paragraphBase whitespace-nowrap'>{props.software.version}</p>
      </div>
      <div className='flex flex-row justify-between border-b border-gray-10 py-5 items-center'>
        <h2 className='prose-paragraphBase mb-1 flex w-full flex-col'>
          <span className='flex gap-2'>
            Release status
            <Tooltip
              description='If checkbox is ticked, this release will be available as a default version for all systems that aren’t assigned to a group. Units default to the latest official version.'
              darkmode
            />
          </span>
        </h2>

        <span className='flex min-w-max gap-1 items-center prose-paragraphBase'>
          Version is{' '}
          <span className='flex flex-row'>{props.updateSoftware?.officialRelease ? 'official' : 'not official'}</span>
          <span>
            {props.updateSoftware?.officialRelease && !props.editMode && (
              <FontAwesomeIcon icon={faCheck} className='text-success-100' />
            )}
          </span>
        </span>
        {props.editMode && (
          <div className='ml-2'>
            <Checkbox
              active={props.updateSoftware?.officialRelease === true}
              onClick={() => {
                if (props.updateSoftware !== undefined) {
                  props.updateSoftware.officialRelease = !props.updateSoftware.officialRelease;
                  props.setUpdateSoftware({ ...props.updateSoftware });
                }
              }}
            />
          </div>
        )}
      </div>
      <div className='flex flex-row justify-between border-b border-gray-10 py-5 items-center'>
        <h2 className='prose-paragraphBase mb-1 flex w-full flex-col'>
          <span className='flex gap-2'>
            Download status
            <Tooltip
              description='If checkbox is ticked, the version can be dowloaded by units that are eligible for the version. If unchecked, downloads will be blocked. Useful if a version is going to be released soon but not yet, or needs to be paused.'
              darkmode
            />
          </span>
        </h2>
        <span className='flex min-w-max gap-1 items-center prose-paragraphBase'>
          Version is {!props.updateSoftware?.isActive && 'not'} available for download{' '}
          {props.updateSoftware?.isActive && !props.editMode && (
            <FontAwesomeIcon icon={faCheck} className='text-success-100' />
          )}
        </span>
        {props.editMode && (
          <div className='ml-2'>
            <Checkbox
              active={props.updateSoftware?.isActive === true}
              onClick={() => {
                if (props.updateSoftware !== undefined) {
                  props.updateSoftware.isActive = !props.updateSoftware.isActive;
                  props.setUpdateSoftware({ ...props.updateSoftware });
                }
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default softwareUpdateDetailsInfo;
