import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Input } from 'component-library';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { GetMeasurementsListData, measurementsListColumns } from '../../helpers/MeasurementsListSetup';
import { Measurement } from '../../models/ApiModels';
import { GetMeasurements } from '../../services/MeasurementsService';
import useRequest from '../../utils/net/useRequest';
import useWindowSize from '../../utils/UseWindowSize';
import Table from '../Table/Table';

export default function Measurements() {
  const size = useWindowSize();
  const [searchParams, setSearchParams] = useSearchParams({ searchValue: '' });
  const [searchValue, setSearchValue] = useState('');
  const [showBanner, setShowBanner] = useState(true);
  const [measurements = [], isMeasurementsLoading] = useRequest<Measurement[]>(() => GetMeasurements(), []);

  useEffect(() => {
    const searchValueFromParams = searchParams.get('searchValue');
    setSearchValue(searchValueFromParams || '');
  }, [searchParams]);

  return (
    <div className='max-w-[2000px]'>
      <div className='prose-heading3 mb-10'>Measurements</div>
      {isMeasurementsLoading ? (
        <div className='flex items-center flex-col gap-4 h-96 justify-center'>
          <span className='text-primary-400 prose-heading5'>Loading measurements...</span>
          <div className='w-12 h-12 border-l-2 border-primary-400 rounded-full animate-spin'></div>
        </div>
      ) : (
        <>
          <div className='mt-10 border border-gray-10 p-4 flex justify-between items-center'>
            <div className='w-1/3 mb-1'>
              <Input
                placeholder='Search...'
                icon={faSearch}
                iconbefore
                value={searchValue}
                onChange={(value: string) => {
                  searchParams.set('searchValue', value);
                  setSearchValue(value);
                  searchParams.set('page', '1');
                  setSearchParams(searchParams);
                }}
              />
            </div>
          </div>
          <Table
            data={GetMeasurementsListData(measurements)}
            columns={measurementsListColumns}
            searchValue={searchValue}
            type='measurements'
            setColumnVisibility={{
              targetName: size?.width > 1000 || size?.width < 640,
              templateName: size?.width > 1350 || size?.width < 640,
              targetSerial: size?.width > 1200 || size?.width < 640,
            }}
          />
        </>
      )}
    </div>
  );
}
