import { Oem } from '../../models/ApiModels';
import { Action, ActionType } from '../actionTypes';

interface State {
  data: Oem[];
}

const initialState: State = {
  data: [],
};

const oemsReducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case ActionType.ADD_OEMS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export default oemsReducer;
