import { Dropdown, Input } from 'component-library';
import Qty from 'js-quantities';
import { useEffect, useState } from 'react';
import { BoatModel } from '../../../../models/ApiModels';

interface Props {
  newBoatModel: BoatModel;
  setNewBoatModel: (newBoatModel: BoatModel) => void;
}

function Weight(props: Props) {
  const [currentUnit, setCurrentUnit] = useState<string>('kg');
  const [inputWeight, updateInputWeight] = useState<number>();
  const [fromUnit, setFromUnit] = useState('kg');
  const [toUnit, setToUnit] = useState('');

  useEffect(() => {
    if (currentUnit === 'kg') {
      setFromUnit('kg');
      setToUnit('lbs');
    } else if (currentUnit === 'lbs') {
      setFromUnit('lbs');
      setToUnit('kg');
    }
    if (inputWeight !== undefined) {
      conversion();
    }
  }, [currentUnit]);

  const conversion = () => {
    if (inputWeight !== undefined) {
      if (fromUnit !== undefined && toUnit !== undefined) {
        const qtyString = inputWeight + fromUnit;
        const qty = new Qty(qtyString);
        const resultNum = qty.to(toUnit);
        const { scalar } = resultNum;
        updateInputWeight(Math.round(scalar));
        updateBoatModel();
      }
    }
  };

  const updateBoatModel = () => {
    if (currentUnit === 'kg' && inputWeight !== undefined) {
      props.newBoatModel.dryWeight = inputWeight;
      props.setNewBoatModel({ ...props.newBoatModel });
    }
    if (currentUnit === 'lbs' && inputWeight !== undefined) {
      const qtyString = inputWeight + fromUnit;
      const qty = new Qty(qtyString);
      const resultNum = qty.to(toUnit);
      const { scalar } = resultNum;
      props.newBoatModel.dryWeight = scalar;
      props.setNewBoatModel({ ...props.newBoatModel });
    }
  };

  useEffect(() => {
    updateBoatModel();
  }, [inputWeight]);

  return (
    <div className='border-b border-gray-10 pb-6'>
      <span className='prose-heading5'>Weight</span>
      <div className='flex flex-wrap mt-2 gap-20'>
        <div className='w-[11rem]'>
          <Dropdown
            optionsData={[
              { id: 'kg', value: 'Kg' },
              { id: 'lbs', value: 'Lbs' },
            ]}
            label='Unit of meassure'
            placeholder='Kg'
            value={currentUnit.charAt(0).toUpperCase() + currentUnit.slice(1).toLowerCase()}
            onValueChosen={(id: string) => setCurrentUnit(id)}
            size='Medium'
          />
        </div>
        <div className='w-[11rem]'>
          <Input
            type='number'
            label='Dry weight'
            unitLabel={currentUnit === 'kg' ? 'kg' : 'lbs'}
            value={inputWeight}
            onChange={(input: number) => updateInputWeight(input)}
            tooltipDesc='The weight of the boat without any fluids or accessories added. Engine weight should be excluded as well.'
            tooltipDarkmode
            size='Medium'
          />
        </div>
      </div>
    </div>
  );
}

export default Weight;
