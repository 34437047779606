import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown } from 'component-library';
import { ChangeEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SoftwareUpdate } from '../../models/ApiModels';
import UploadFile from '../../utils/UploadFile';

function SoftwareUpdateUpload() {
  const initSoftware: SoftwareUpdate = {
    id: '',
    systemType: '',
    unit: '',
    version: '',
    originalFileName: '',
    officialRelease: false,
    isActive: false,
    description: '',
    fileSize: 0,
  };

  const [software, setSoftware] = useState<SoftwareUpdate>(initSoftware);
  const [loading, setLoading] = useState(false);
  const [descText, setDescText] = useState({ desc: '' });
  const navigate = useNavigate();

  return (
    <div className='flex flex-col w-full'>
      <div className='flex gap-2 prose-heading3 text-primary-400 items-center pb-16'>
        <span
          className='cursor-pointer'
          onClick={() => {
            setTimeout(() => {
              navigate('/software');
            }, 1);
          }}
        >
          Software update
        </span>
        <FontAwesomeIcon icon={faChevronRight} size='sm' />
        <span>Upload new version</span>
      </div>
      <div className='w-full flex flex-col'>
        <div className='pt-4 flex flex-col gap-6 w-1/3 relative'>
          <Dropdown
            label={'System type'}
            required
            optionsData={[
              { id: 'LCS', value: 'LCS' },
              { id: 'HCS5', value: 'HCS5' },
            ]}
            onValueChosen={(value: string) => {
              software.systemType = value;
              setSoftware({ ...software });
            }}
            placeholder={'-Select-'}
          />
          <Dropdown
            label={'Unit'}
            required
            optionsData={[
              { id: 'LCP', value: 'LCP' },
              { id: 'CP512', value: 'CP512' },
              { id: 'LCU', value: 'LCU' },
              { id: 'LSU', value: 'LSU' },
              { id: 'RCU', value: 'RCU' }
            ]}
            onValueChosen={(value: string) => {
              software.unit = value;
              setSoftware({ ...software });
            }}
            value={software.unit}
            placeholder={'-Select-'}
          />

          <div>
            <h2>Description/Brief release notes</h2>
            <textarea
              className='bg-gray-3 w-full min-h-[110px] resize-none mt-2 p-2 prose-paragraphSmaller focus:ring-1 focus:ring-primary-100 focus:border-transparent outline-none'
              placeholder='Add description...'
              value={descText.desc}
              onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                descText.desc = event.target.value;
                software.description = descText.desc;
                setDescText({ ...descText });
                setSoftware({ ...software });
              }}
            ></textarea>
          </div>
        </div>
        <div className='border-b w-full border-gray-10 my-5'></div>
      </div>
      <h2 className='mb-3'>Upload software</h2>
      <UploadFile
        data={software}
        setData={(file: any) => setSoftware({ ...file })}
        appendData={[
          { key: 'unit', value: software.unit },
          { key: 'systemType', value: software.systemType },
          { key: 'originalFileName', value: software.originalFileName },
          { key: 'description', value: software.description },
        ]}
        validData={software.originalFileName !== '' && software.systemType !== '' && software.unit !== ''}
        requestUrl='v1/Files/firmwares/upload'
        uploadType='software version'
        navigateToResponse
        navigateUrl='software/version'
        uploadButtonLabel='Create new version'
        cancelIsReturn
      />
      {loading && (
        <div className='w-full flex justify-center items-center h-full'>
          <div className='w-12 h-12 border-l-2 border-primary-400 rounded-full animate-spin'></div>
        </div>
      )}
    </div>
  );
}

export default SoftwareUpdateUpload;
