import { useSelector } from 'react-redux';
import { Dashboard } from '../../models/ApiModels';

interface Props {
  dashboardData: Dashboard;
  compare: boolean;
}

function DashBoardDataOverview(props: Props) {
  const oems = useSelector((state: any) => state.oems.data);

  const comparisonColor = (color: number) => {
    if (color > 0) {
      return 'text-success-100';
    } else if (color < 0) {
      return 'text-error-100';
    } else return 'text-gray-20';
  };

  const comparisonPositive = (value: number) => {
    if (value > 0) {
      return '+';
    } else return '';
  };

  return (
    <div className='sm:flex justify-around gap-4'>
      <div className='flex flex-col prose-heading1 text-primary-100 text-center'>
        {props.dashboardData?.systems.totalAmountOfSystems.count}
        {props.compare && (
          <span className={`prose-heading3 ${comparisonColor(props.dashboardData.systems.totalAmountOfSystems.diff)}`}>
            {comparisonPositive(props.dashboardData?.systems.totalAmountOfSystems.diff) +
              props.dashboardData?.systems.totalAmountOfSystems.diff}
          </span>
        )}
        <div className='prose-heading5 text-black'>Total systems</div>
      </div>
      <div className='flex flex-col prose-heading1 text-primary-100 text-center'>
        {props.dashboardData?.boats.totalAmountOfBoats.count}
        {props.compare && (
          <span className={`prose-heading3 ${comparisonColor(props.dashboardData.boats.totalAmountOfBoats.diff)}`}>
            {comparisonPositive(props.dashboardData?.boats.totalAmountOfBoats.diff) +
              props.dashboardData?.boats.totalAmountOfBoats.diff}
          </span>
        )}
        <div className='prose-heading5 text-black'>Total boat models</div>
      </div>
      <div className='flex flex-col prose-heading1 text-primary-100 text-center'>
        {oems.length}
        {props.compare && <div className='prose-heading3 text-gray-20'>0</div>}
        <div className='prose-heading5 text-black'>Total customers</div>
      </div>
    </div>
  );
}

export default DashBoardDataOverview;
