import { createColumnHelper } from '@tanstack/react-table';
import { StatusPill } from 'component-library';
import { BoatModel, Oem, PropulsionSystem } from '../models/ApiModels';
import { GetStatusColor } from './Custom';

type BoatModelsList = {
  oemName?: string;
  boatMake?: string;
  boatModel: string;
  series?: string;
  modelYear?: string;
  propulsion?: string[];
  status: string;
  boatModelId?: string;
};

export function GetBoatModelsListData(
  boatModels: BoatModel[],
  propulsionSystems: PropulsionSystem[],
  oem: Oem,
  oems: Oem[]
) {
  const modelList: BoatModelsList[] = boatModels.map((boatModel: BoatModel) => {
    const findOem = oems.find((oem: Oem) => oem.id === boatModel.oemTenantId)?.name;
    return {
      oemName: oem?.name ? oem.name : findOem ? findOem : '',
      boatMake: boatModel.make,
      boatModel: boatModel.name,
      propulsion: propulsionSystems
        .filter((system: PropulsionSystem) => {
          return boatModel.propulsionSystemIds.includes(system.id);
        })
        .map((system: PropulsionSystem) => {
          return system.name;
        }),
      status: boatModel.status,
      boatModelId: boatModel.id,
    };
  });
  return modelList;
}

const columnHelper = createColumnHelper<BoatModelsList>();

export const boatModelsListColumns = [
  columnHelper.accessor('oemName', {
    header: 'Oem',
    cell: (info) => {
      return <div className='truncate'>{info.getValue()}</div>;
    },
  }),
  columnHelper.accessor('boatMake', {
    header: 'Make',
    cell: (info) => {
      return <div className='truncate'>{info.getValue()}</div>;
    },
    enableHiding: true,
  }),
  columnHelper.accessor('boatModel', {
    header: 'Model',
    cell: (info) => {
      return <div className='truncate'>{info.getValue()}</div>;
    },
    enableHiding: true,
  }),
  columnHelper.accessor('propulsion', {
    header: 'Propulsion',
    cell: (info) => {
      const propulsionSystems = info.getValue();
      return (
        <div className='flex flex-wrap sm:flex-nowrap gap-2'>
          {propulsionSystems && propulsionSystems.length > 0 ? (
            propulsionSystems?.map((i: string, index) => {
              return (
                <div key={index}>
                  <StatusPill color='Dark Gray' text={i} />
                </div>
              );
            })
          ) : (
            <StatusPill color='Light Gray' text='Unknown' />
          )}
        </div>
      );
    },
  }),
  columnHelper.accessor('status', {
    header: 'Status',
    enableGlobalFilter: false,
    cell: (info) => <StatusPill color={GetStatusColor(info.getValue()) as any} text={info.getValue()} fixedSize />,
    size: 200,
  }),
];
