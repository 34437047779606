import { createColumnHelper } from '@tanstack/react-table';
import { StatusPill } from 'component-library';
import { User } from '../models/ApiModels';

type UserList = {
  id?: string;
  azureObjectId?: string;
  displayName?: string;
  oemTenantId?: string;
  roles?: string;
  email?: string;
  active?: boolean;
};

export function GetUserListData(users: User[]) {
  const userList: UserList[] = users.map((user: User) => {
    return {
      id: user.id,
      displayName: user.displayName,
      oemTenantId: user.oemTenantId,
      roles: user.roles,
      email: user.email,
      active: user.active,
    };
  });

  return userList;
}

const columnHelper = createColumnHelper<UserList>();

export const userListColumns = [
  columnHelper.accessor('displayName', {
    header: 'Name',
    cell: (info) => <div className='truncate'>{info.getValue()}</div>,
  }),
  columnHelper.accessor('email', {
    header: 'Email Address',
    cell: (info) => <div className='truncate'>{info.getValue()}</div>,
    size: 300,
  }),
  columnHelper.accessor('active', {
    header: 'Status',
    cell: (info) =>
      info.getValue() === true ? (
        <StatusPill color='Green' text='Active' />
      ) : (
        <StatusPill color='Dark Gray' text='Invited' />
      ),
    size: 100,
  }),
  columnHelper.accessor('roles', {
    header: 'Roles',
    cell: (info) => {
      return (
        <div className='2xl:w-full xl:w-72 flex flex-wrap flex-row gap-2 p-2 pl-0'>
          {info
            .getValue()
            ?.split(',')
            .map((role: string, index) => {
              const spaceOrUpperCase = role?.split(' ').find((role: string) => role === 'presetCertified')
                ? 'Preset Certified'
                : role.charAt(0).toUpperCase() + role.slice(1);
              return (
                <div key={index} className='last:mr-0'>
                  {role !== '' ? (
                    <StatusPill
                      text={spaceOrUpperCase}
                      color={role === 'presetCertified' || role === 'admin' ? 'Dark Gray' : 'Light Gray'}
                    />
                  ) : (
                    <StatusPill color={'Light Gray'} text={'Viewer'} />
                  )}
                </div>
              );
            })}
        </div>
      );
    },
    size: 280,
  }),
];
