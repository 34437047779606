import { OemIndirect } from '../../models/ApiModels';
type InfoProps = {
  newIndirectOem: OemIndirect;
  handleChange: (e: any) => void;
};
export const CreateIndirectCustomerInfo = ({ newIndirectOem, handleChange }: InfoProps) => {
  return (
    <div className='grid gap-5'>
      <h4 className='border-b w-full prose-heading4 border-gray-10 pb-5'>Customer Information</h4>
      <div className='grid max-w-md'>
        <label>
          Customer name: <span className='text-error-100'>*</span>
        </label>
        <input
          className='bg-gray-3 px-3 h-10 mt-2 prose-paragraphSmaller'
          type='text'
          name='name'
          placeholder='Input name...'
          value={newIndirectOem.name}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};
