import { Config } from './config';

export const msalConfig = {
  auth: {
    clientId: Config.b2c_client_id,
    authority: `https://${Config.b2c_domain}.b2clogin.com/${Config.b2c_domain}.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN`, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: `${Config.redirect_url}/`,
    knownAuthorities: [`https://${Config.b2c_domain}.b2clogin.com`],
    postLogoutRedirectUri: `${Config.redirect_url}/`,
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    allowRedirectInIframe: true,
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  redirectUri: `${Config.redirect_url}/`,
  scopes: [
    'openid',
    'profile',
    `https://${Config.b2c_domain}.onmicrosoft.com/portal-bff/bff.write`,
    `https://${Config.b2c_domain}.onmicrosoft.com/portal-bff/bff.read`,
  ],
};
