import { faArrowRight, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, Input, Tooltip } from 'component-library';
import _ from 'lodash';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { AppContext } from '../../../contexts/AppContext';
import { BoatModel, Oem, PresetList, System } from '../../../models/ApiModels';
import { GetAllPresets } from '../../../services/PresetsServices';
import useRequest from '../../../utils/net/useRequest';
import SelectBoatMakeDropdown from './SelectBoatMakeDropdown';

interface Props {
  system: System;
  currentOem?: Oem;
  setSystem: (system: System) => void;
  editMode: boolean;
}

function SystemDetailsBoatInfo(props: Props) {
  const { currentOemTenantId, isHumphreeUser } = useContext(AppContext);
  const boatModels = useSelector((state: any) => state.boatModels.boatModels);
  const filteredBoatModels = boatModels.filter((model: any) => model.oemTenantId === props.system.oemId);
  const navigate = useNavigate();
  const boatModel = boatModels.find((boatModel: BoatModel) => boatModel.id === props.system.boatModelId);
  const [presets = []] = useRequest(() => GetAllPresets(currentOemTenantId), []);

  function handleModelsInDropdown() {
    const selectedMake = boatModels.find((boatModel: BoatModel) => boatModel.id === props.system.boatModelId)?.make;
    const filteredAfterBrand = boatModels.filter((model: BoatModel) => model.make === selectedMake);

    if (props.system.boatModelId !== '') {
      const unassignBoatModel = {
        id: '',
        name: (
          <div className='text-error-100 prose-heading6'>
            Unassign boat model <FontAwesomeIcon icon={faXmark} size='lg' />
          </div>
        ),
      };
      filteredAfterBrand.push(unassignBoatModel);
    }

    return filteredAfterBrand.map((model: BoatModel) => {
      return {
        id: model.id,
        value: model.name,
      };
    });
  }

  function handlePresetsInDropdown() {
    const selectedModelId = boatModels.find((boatModel: BoatModel) => boatModel.id === props.system.boatModelId)?.id;
    const filteredAfterModel = presets.filter((preset: PresetList) => preset.boatModelId === selectedModelId);

    if (props.system.presetId !== '') {
      const unassignPreset = {
        id: '',
        name: (
          <div className='text-error-100 prose-heading6'>
            Unassign preset <FontAwesomeIcon icon={faXmark} size='lg' />
          </div>
        ),
      };
      filteredAfterModel.push(unassignPreset);
    }

    return filteredAfterModel.map((preset: any) => {
      return {
        id: preset.id,
        value: preset.name,
      };
    });
  }

  const filteredFirstData = [
    ..._.uniqBy<BoatModel>(filteredBoatModels, 'make').map((boat: BoatModel) => {
      return { id: boat.id, value: boat.make };
    }),
  ];

  const filteredSecondData = [
    ..._.uniqBy<BoatModel>(boatModels, 'make').map((boat: BoatModel) => {
      return { id: boat.id, value: boat.make };
    }),
  ];

  return (
    <div className='flex flex-col'>
      <h1 className='prose-heading4 border-b border-gray-10 pb-5'>Boat information</h1>

      <div className='flex flex-row justify-between items-center border-b border-gray-10 py-5'>
        <h2 className='prose-paragraphBase mb-1'>Brand / Make</h2>
        {props.editMode ? (
          <div className='w-[250px]'>
            <SelectBoatMakeDropdown
              placeholder='-Select brand / make-'
              firstData={filteredFirstData}
              secondData={filteredSecondData}
              onValueChosen={(id: any) => {
                props.system.boatModelId = id;
                props.setSystem({ ...props.system });
              }}
              value={boatModels.find((boatModel: BoatModel) => boatModel.id === props.system.boatModelId)?.make}
            />
          </div>
        ) : (
          <p className={`${!props.system.boatModelId && 'italic text-gray-40'} flex items-center prose-paragraphBase`}>
            {!props.system.boatModelId ? 'Not assigned' : boatModel?.make}
          </p>
        )}
      </div>
      <div className='flex flex-row justify-between items-center border-b border-gray-10 py-5'>
        <h2 className='prose-paragraphBase mb-1'>Boat model</h2>
        {props.editMode ? (
          <div className='w-[250px]'>
            <Dropdown
              label=''
              optionsData={handleModelsInDropdown()}
              placeholder='-Select boat model-'
              onValueChosen={(id: string) => {
                props.system.boatModelId = id;
                props.system.presetId = '';
                props.setSystem({ ...props.system });
              }}
              value={boatModels.find((boatModel: BoatModel) => boatModel.id === props.system.boatModelId)?.name}
            />
          </div>
        ) : (
          <p className={`${!props.system.boatModelId && 'italic text-gray-40'} flex items-center prose-paragraphBase`}>
            {!props.system.boatModelId ? 'Not assigned' : boatModel?.name}
          </p>
        )}
      </div>
      {props.system.boatModelId && (
        <div className='flex flex-row justify-between items-center border-b border-gray-10 py-5'>
          <h2 className='prose-paragraphBase mb-1 flex gap-2'>
            Setting preset
            <Tooltip
              description='A setting preset are predefined settings that will be synced with the system on the next internet connection. '
              darkmode
            />
          </h2>
          {props.editMode ? (
            <div className='w-[250px]'>
              <Dropdown
                label=''
                optionsData={handlePresetsInDropdown()}
                placeholder='-Select setting preset-'
                onValueChosen={(id: string) => {
                  props.system.presetId = id;
                  props.system.presetStatus = 'Pending';
                  props.setSystem({ ...props.system });
                }}
                value={presets.find((preset: any) => preset.id === props.system.presetId)?.name}
              />
            </div>
          ) : (
            <p className={`${!props.system.presetId && 'italic text-gray-40'} flex items-center prose-paragraphBase`}>
              {!props.system.presetId
                ? 'Not assigned'
                : presets.find((preset: any) => preset.id === props.system.presetId)?.name}
            </p>
          )}
        </div>
      )}
      <div className='flex flex-row justify-between items-center border-b border-gray-10 py-5'>
        <h2 className='prose-paragraphBase mb-1 flex gap-2'>
          Hull number
          <Tooltip
            description='The hull number is an identification number of a boat that is usually placed on the outside of the transom. It is also referred to as hull serial number / Yard no / Ship yard no. E.g., MIC 12345 S9 22.'
            darkmode
          />
        </h2>
        {props.editMode ? (
          <div className='w-[250px]'>
            <Input
              placeholder='Enter hull number...'
              onChange={(input: string) => {
                props.system.hullNumber = input;
                props.setSystem({ ...props.system });
              }}
              value={props.system.hullNumber ? props.system.hullNumber : ''}
            />
          </div>
        ) : (
          <p className={`${!props.system.hullNumber && 'italic text-gray-40'} prose-paragraphBase`}>
            {!props.system.hullNumber ? 'Not assigned' : props.system.hullNumber}
          </p>
        )}
      </div>
      {(boatModel !== undefined && isHumphreeUser) ||
      (boatModel !== undefined && boatModel.oemTenantId === props.system.oemId) ? (
        <Link
          to={'/models/' + boatModel?.id}
          className='flex justify-end pt-5 items-center prose-buttonStandard cursor-pointer'
        >
          Go to Boat model
          <FontAwesomeIcon icon={faArrowRight} className='mx-2' size='lg' />
        </Link>
      ) : null}
    </div>
  );
}

export default SystemDetailsBoatInfo;
