import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, Input, InvertButton, PrimaryButton } from 'component-library';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SoftwareGroup } from '../../models/ApiModels';
import { AddSoftwareGroup } from '../../services/SoftwareUpdateService';
import { ActionType } from '../../store/actionTypes';

function SoftwareUpdateCreate() {
  const initGroup: SoftwareGroup = {
    groupName: '',
    groupDescription: '',
    assignedSystemType: '',
    lcpFirmwareId: null,
    lcuFirmwareId: null,
    lsuFirmwareId: null,
    isForceDownload: false,
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [softwareGroup, setSoftwareGroup] = useState<SoftwareGroup>(initGroup);

  function CreateGroup() {
    setLoading(true);
    AddSoftwareGroup(softwareGroup)
      .then((response: any) => {
        setLoading(false);
        dispatch({
          type: ActionType.SET_SNACKBAR,
          payload: { heading: 'You created a new group!', status: 'success' },
        });
        setTimeout(() => {
          navigate(`/software/group/${response.id}`);
        }, 1);
      })
      .catch(() => {
        setLoading(false);
      });
  }

  const validGroupCreate = useMemo(() => {
    if (softwareGroup.assignedSystemType !== '' && softwareGroup.groupName !== '') {
      return true;
    }
  }, [softwareGroup]);

  return (
    <div className='flex flex-col w-full'>
      <div className='flex gap-2 prose-heading5 md:prose-heading3 text-primary-400 items-center pb-16'>
        <span
          className='cursor-pointer'
          onClick={() => {
            setTimeout(() => {
              navigate('/software');
            }, 1);
          }}
        >
          Software update
        </span>
        <FontAwesomeIcon icon={faChevronRight} size='sm' />
        <span>Create new group</span>
      </div>
      <div className='w-full flex flex-col'>
        <div className='pt-4 flex flex-col gap-6 w-full lg:w-1/2 xl:w-1/3 relative'>
          <Dropdown
            label={'System type'}
            required
            optionsData={[{ id: 'LCS', value: 'LCS' }]}
            onValueChosen={(value: string) => {
              softwareGroup.assignedSystemType = value;
              setSoftwareGroup({ ...softwareGroup });
            }}
            placeholder={'-Select-'}
          />
          <Input
            label='Group name'
            value={softwareGroup.groupName}
            onChange={(value: string) => {
              softwareGroup.groupName = value;
              setSoftwareGroup({ ...softwareGroup });
            }}
            placeholder='Enter text input...'
            mandatory
          />
        </div>
        <div className='flex gap-2 justify-end pt-10'>
          <InvertButton
            label='Cancel'
            disabled={loading}
            onClick={() => {
              setTimeout(() => {
                navigate('/software');
              }, 1);
            }}
          />
          <PrimaryButton
            label='Create new group'
            disabled={!validGroupCreate || loading}
            onClick={() => {
              CreateGroup();
            }}
          />
        </div>
      </div>

      {loading && (
        <div className='w-full flex justify-center items-center h-full'>
          <div className='w-12 h-12 border-l-2 border-primary-400 rounded-full animate-spin'></div>
        </div>
      )}
    </div>
  );
}

export default SoftwareUpdateCreate;
